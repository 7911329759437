<template>
    <thead class="v-data-table-header">
        <tr>
            <th v-for="header in headers"
                :key="header.text"
                :class="['column',
                        sortOptions.sortDesc ? 'desc' : 'asc',
                        header.value === sortOptions.sortBy ? 'active' : '',
                        header.sortable ? 'enable-sort' : 'disable-sort']"
                :tabindex="header.sortable ? 0 : -1"
                @click="header.sortable ? changeSort(header.value) : null">
                <div class="header-cell-content">
                    <div class="header-text">
                        {{ header.text }}
                    </div>
                    <v-icon class="sort-icon" v-if="header.sortable" small>arrow_upward</v-icon>
                </div>
            </th>
        </tr>
    </thead>
</template>
<script>
    export default {
        props: {
            headers: {
                type: Array,
                default: () => []
            },
            sortOptions: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            changeSort (value) {
                this.$emit('change-sort', value)
            }
        }
    }
</script>
<style lang="scss" scoped>

.header-cell-content {
    display: flex;
    flex-direction: row;
}

.header-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
}

.sort-icon {
    display: none;
    flex-shrink: 0;
}

.active {
    .sort-icon {
        display: block;
    }
}

</style>
