<template>
    <div class="font--bold">
        <a role="button"
            tabindex="0"
            @click="buttonAction"
            @keyup.enter="buttonAction">
            {{ text }}
        </a>
    </div>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: ''
            }
        },
        methods: {
            buttonAction () {
                this.$emit('button-action')
            }
        }
    }
</script>
