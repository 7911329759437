export let PHONE_NUMBER = /^[0-9]{1,15}$|^[0-9]{1,10}[xX]?[0-9]{0,5}$/ // max 15 numbers or 10 numbers with 5 extension numbers starts with x or X.
export let PHONE_NUMBER_ALLOWED_CHAR = /[()-]/g
export let POSTAL_CODE = /^(?=.{0,20}$)([A-Za-z0-9]+-[A-Za-z0-9]+)$|^[A-Za-z0-9]{0,20}$/ // '-' can be included between letters or numbers.
export let CLAIM_CODE = /.*/
export let ORG_NAME_ILLEGAL_CHAR = /[<>"\\]/
export let ILLEGAL_CHAR = /[<>'"\\]/
export let ILLEGAL_CHAR_GLOBAL = /[<>'"\\]/g
export const NON_DIGIT_REGEX = /\D/g
export const DIGIT_REGEX = /\d/g
export const UUID_REGEX = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/
export const VALID_CLAIM_CODE = /^[a-zA-Z0-9]{32}$/
export const BOARD_SERIAL_NUMBER = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,48}[a-zA-Z0-9]$/
export const EDUCATION_SW_PART_NUMBERS = /^(ED-SW|LUM-SW|LUM-SPK)-.*/
export let SERIAL_NUMBER = BOARD_SERIAL_NUMBER
export default SERIAL_NUMBER
