/**
 * Import the custom theming for the admin portal
 */
require('./main.scss')
require('./body.scss')
require('./fonts.scss')
require('./pagination.scss')
require('./menu-content.scss')
require('./dialog.scss')
require('./input.scss')
require('./fixed-headers-table.scss')
require('./table.scss')
require('./v-btn.scss')
require('./a.scss')
require('./icon.scss')
require('./label.scss')
require('./span.scss')
require('./mixins.scss')
