<template>
    <v-form ref="orgForm">
        <div class="px-3">
            <label for="orgName"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.name')}}</label>
            <v-text-field required :rules="[requiredRule, orgNameXssRule]" id="orgName" maxlength="255" v-model="name" autocomplete="off" autofocus text solo/>
        </div>
        <div class="px-3">
            <div><label for="industry"><span
                class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.industry')}}</label></div>
            <div>
<!--                Browser-autocomplete being ignored by chrome is a known issue. Workaround to disable is used.-->
<!--                Source: https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164-->
                <v-autocomplete class="border"
                          id="industry"
                          type="text"
                          autocomplete="disable-chrome-autocomplete"
                          clearable
                          flat
                          solo
                          maxlength="40"
                          v-model="industry"
                          :items="industryList"
                          item-value="value"
                          item-text="name"
                          :rules="[requiredRule]">
                    <template v-slot:item="data">
                        <v-list-item-content class='dropDown'>
                            <v-list-item-title>
                                <span :class='data.item.type'>{{data.item.name}}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>
        </div>
        <div class="px-3">
            <div><label for="country"><span
                class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.country')}}</label>
            </div>
            <v-autocomplete id="country" class="border selector" v-model="country"
                      :items="countryCodes"
                      item-text="name"
                      single-line
                      flat
                      solo
                      maxlength="80"
                      autocomplete="disable-chrome-autocomplete"
                      clearable
                      item-value="code"
                      :rules="[requiredRule]">
                <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                </template>
            </v-autocomplete>
        </div>
        <v-flex class="px-3">
            <label for="streetAddress"><span
                class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.street')}}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="streetAddress" maxlength="255" v-model="address" text solo/>
        </v-flex>
        <v-flex class="px-3">
            <label for="city"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.city')}}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="city" maxlength="40" v-model="city" text solo/>
        </v-flex>
        <v-flex class="px-3" v-if="!hasStateDropdown">
            <label for="state"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.state')}}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="state" maxlength="80" v-model="state" text solo/>
        </v-flex>
        <div v-else class="px-3">
            <div><label for="stateSelect"><span
                class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.state')}}</label></div>
            <div>
                <v-autocomplete id="stateSelect" class="border selector" v-model="state"
                          :items="stateCodes"
                          item-text="name"
                          single-line
                          text solo
                          maxlength="80"
                          autocomplete="disable-chrome-autocomplete"
                          clearable
                          item-value="code"
                          :rules="[requiredRule]">
                    <template slot="selection" slot-scope="data">
                        {{ data.item.name }}
                    </template>
                </v-autocomplete>
            </div>
        </div>
        <div class="px-3">
            <v-layout align-end>
                <v-flex xs6>
                    <label for="postalCode"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.postalCode')}}</label>
                    <v-text-field required :rules="[requiredRule, xssCharRule, postalRule]" id="postalCode" maxlength="20" v-model="zipPostal" text solo/>
                </v-flex>
                <v-flex xs6>
                    <label for="phone">{{$t('activation.assignEntitlement.createOrg.phone')}}</label>
                    <v-text-field id="phone" maxlength="15" v-model="phone" :rules="[phoneRule]" @keydown.space.prevent text solo/>
                </v-flex>
            </v-layout>
        </div>
        <div class="regionSelection" :class="{'region-selection-color': hasDataLocationDropdown}">
            <div class="px-3 pt-2">
                <div class="region-container">
                    <label for="region"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.region')}}</label>
                    <v-icon class="learn-more-icon" size="24" @click="openLearnMore()">help</v-icon>
                </div>
                <v-select id="region" class="border selector" v-model="region"
                          :items="regions"
                          item-value="code"
                          item-text="name"
                          single-line
                          flat
                          solo
                          maxlength="25"
                          autocomplete="disable-chrome-autocomplete"
                          :rules="[requiredRule, xssCharRule]">
                </v-select>
            </div>
            <div v-if="hasDataLocationDropdown" class="px-3 pb-2">
                <div>
                    <label for="dataLocation"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.dataLocation')}}</label>
                </div>
                <div>
                    <v-autocomplete id="dataLocation" v-model="dataLocation" class="data-location-dropdown"
                                    :items="dataLocations"
                                    item-value="code"
                                    item-text="name"
                                    single-line
                                    flat
                                    solo
                                    maxlength="25"
                                    autocomplete="disable-chrome-autocomplete"
                                    clearable
                                    :rules="[requiredRule, dataLocationWaring]">
                    </v-autocomplete>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script>
    import {mapState} from 'vuex'
    import enIndustryList from '../../../locales/en.json'
    import {entitlement, security} from '../../../utils/validate-utils.js'
    import strUtils from 'smarttech-identity-string-utils'
    import {PHONE_NUMBER, PHONE_NUMBER_ALLOWED_CHAR} from '../../../services/regex-patterns'
    import regionMappingService from '../../../services/region-mapping-service'
    import {DEFAULT as GLOBAL_REGION} from 'smarttech-identity-environment'

    export default {
        props: {
            openForm: {
                type: Object,
                default: function () {
                    return { isFormOpen: false, source: '' } // source: 'claim' or 'updateOrg'
                }
            }
        },
        data () {
            return {
                requiredRule: v => !!v || this.$t('applicationErrors.required'),
                xssCharRule: v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                orgNameXssRule: v => entitlement.validateOrgName(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                phoneRule: v => {
                    if (strUtils.isBlank(v)) {
                        return true
                    } else {
                        let digitsOnly = v.replace(/[xX()-]/g, '')
                        let allowedOnly = v.replace(PHONE_NUMBER_ALLOWED_CHAR, '')
                        return digitsOnly.length
                            ? PHONE_NUMBER.test(allowedOnly) ? true : this.$t('formErrors.canOnlyInclude')
                            : this.$t('formErrors.mustIncludeDigit')
                    }
                },
                postalRule: v => entitlement.validatePostalCode(v) ||
                                    this.$t('formErrors.canOnlyInclude').replace('x 0-9 ( ) -', 'a-Z 0-9 -'),
                dataLocationWaring: v => this.$t('management.regionSelectionDialog.migrationWarning'),
                countryCodes: regionMappingService.getCountryCodes(),
                regions: regionMappingService.getRegionStrings(),
                phoneNoInput: '',
                zipPostalInput: '',
                industryList: [],
                isCountryInitialized: false
            }
        },
        mounted: function () {
            this.industryList = Object.freeze(this.getIndustryList())
        },
        watch: {
            openForm (openFormObj) {
                if (openFormObj.isFormOpen) {
                    if (openFormObj.source === 'updateOrg') {
                        this.isCountryInitialized = strUtils.isNotBlank(this.country) // Check if country exists in SF

                        // If country doesn't exist and region is null, set region to global.
                        if (!this.isCountryInitialized && strUtils.isBlank(this.region)) {
                            this.region = GLOBAL_REGION
                        }

                        if (this.$refs.orgForm) {
                            this.$refs.orgForm.validate() // Validate fields when opening the dialog.
                        }
                    }
                } else {
                    if (openFormObj.source === 'updateOrg') {
                        this.$store.commit('clearActivationUserAccount')
                    }
                    this.zipPostalInput = ''
                    this.phoneNoInput = ''

                    if (this.$refs.orgForm) {
                        this.$refs.orgForm.resetValidation() // Clear validation to not show warnings before closing.
                    }
                }
            },
            country (newCountry, previousCountry) {
                // Skip region changing logic when org already has a country and a region when initialization.
                if (this.openForm.source === 'updateOrg' && this.isCountryInitialized && strUtils.isNotBlank(this.region)) {
                    this.isCountryInitialized = false
                    return
                }

                let previousCountrysRegion = regionMappingService.getRegionFromCountryCode(previousCountry)
                let newCountrysRegion = regionMappingService.getRegionFromCountryCode(newCountry)

                if (!newCountrysRegion) {
                    this.region = GLOBAL_REGION
                } else if (previousCountrysRegion !== newCountrysRegion &&
                    this.activation.userAccount.region !== newCountrysRegion) {
                    this.$store.commit('setCreatedOrgRegion', newCountrysRegion)
                } // else: changed country region is the same as before
                this.triggerStateValidation()
            }
        },
        methods: {
            triggerStateValidation () {
                this.$nextTick(() => {
                    if (this.openForm.source === 'updateOrg') {
                        this.$refs.orgForm.validate()
                    }
                })
            },
            getIndustryList () {
                let initial = this.$t('activation.assignEntitlement.createOrg.industryChoices')
                let english = enIndustryList.activation.assignEntitlement.createOrg.industryChoices
                let returnList = []
                for (let i = 0; i < initial.length; i++) {
                    returnList.push({
                        header: initial[i].title
                    })
                    for (let j = 0; j < initial[i].choice.length; j++) {
                        returnList.push({
                            name: initial[i].choice[j],
                            value: english[i].choice[j]
                        })
                    }
                }
                return returnList
            },
            openLearnMore () {
                window.open(this.$LINKS.REGION_SELECTION_LEARN_MORE)
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            name: {
                get () {
                    return this.activation.userAccount.name
                },
                set (value) {
                    this.$store.commit('setCreatedOrgName', value)
                }
            },
            address: {
                get () {
                    return this.activation.userAccount.address1
                },
                set (value) {
                    this.$store.commit('setCreatedOrgAddress', value)
                }
            },
            city: {
                get () {
                    return this.activation.userAccount.city
                },
                set (value) {
                    this.$store.commit('setCreatedOrgCity', value)
                }
            },
            country: {
                get () {
                    return this.activation.userAccount.country
                },
                set (value) {
                    this.$store.commit('setCreatedOrgState', '')
                    this.$store.commit('setCreatedOrgCountry', value)
                }
            },
            zipPostal: {
                get () {
                    return strUtils.isBlank(this.zipPostalInput) ? this.activation.userAccount.zipPostal : this.zipPostalInput
                },
                set (value) {
                    this.zipPostalInput = value
                    this.$store.commit('setCreatedOrgZipPostal', value.replace(/[\s]/g, ''))
                }
            },
            phone: {
                get () {
                    return strUtils.isBlank(this.phoneNoInput) ? this.activation.userAccount.phone : this.phoneNoInput
                },
                set (value) {
                    this.phoneNoInput = value
                    let cleanPhoneNo = value.replace(PHONE_NUMBER_ALLOWED_CHAR, '')
                    if (strUtils.isNotBlank(cleanPhoneNo)) {
                        value = cleanPhoneNo
                    }
                    this.$store.commit('setCreatedOrgPhone', value)
                }
            },
            state: {
                get () {
                    return this.activation.userAccount.state
                },
                set (value) {
                    this.$store.commit('setCreatedOrgState', value)
                }
            },
            industry: {
                get () {
                    return this.activation.userAccount.industry
                },
                set (value) {
                    this.$store.commit('setCreatedOrgIndustry', value)
                }
            },
            region: {
                get () {
                    return this.activation.userAccount.region
                },
                set (value) {
                    this.$store.commit('setCreatedOrgRegion', value)
                }
            },
            dataLocation: {
                get () {
                    return this.activation.userAccount.dataLocation
                },
                set (value) {
                    this.$store.commit('setCreatedOrgDataLocation', value)
                }
            },
            hasStateDropdown () {
                return (this.activation.userAccount.country === 'CA' || this.activation.userAccount.country === 'US')
            },
            stateCodes () {
                if (regionMappingService.getStates()[this.activation.userAccount.country]) {
                    return regionMappingService.getStates()[this.activation.userAccount.country]
                }
                return []
            },
            dataLocations () {
                let currentRegion = this.activation.userAccount.region
                let regionMap = regionMappingService.getRegionInfoByRegionName()
                if (regionMap.has(currentRegion)) {
                    return regionMap.get(currentRegion).countries
                }
                return []
            },
            hasDataLocationDropdown () {
                if (this.activation.userAccount.country && this.activation.userAccount.region) {
                    let regionAndCountryMatched = this.activation.userAccount.region ===
                        regionMappingService.getRegionFromCountryCode(this.activation.userAccount.country)

                    if (regionAndCountryMatched && this.activation.userAccount.dataLocation !== '') {
                        // If region and country are matched, data location should be set to empty string.
                        this.$store.commit('setCreatedOrgDataLocation', '')
                    }

                    // data location will be shown when region and country are NOT matched
                    return !regionAndCountryMatched
                }
                return false // default is not showing data location dropdown
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../theming/main.scss";
    $region-select-color: rgba(0, 0, 0, .05);

    .dropDown {
        padding-left: 10px;
        width: 100px;
    }

    .required-color {
        color: #d0011b;
    }

    .selector {
        color: rgba(0,0,0,0.87) !important;
    }

    .region-selection-color {
        background-color: $region-select-color;
    }

    .region-container {
        display: flex;
        justify-content: space-between;
    }

    .learn-more-icon {
        color:#159bd9 !important;
    }

    .data-location-dropdown {
       word-break: break-word;
    }
</style>
