<template>
    <v-dialog v-model="openDialog" persistent width=30%>
        <template v-slot:activator="{ on }">
            <v-btn id="openManageSync" v-on="on" class="primary white--text">{{sourceName}}</v-btn>
        </template>
        <v-card id="manageSyncModal">
            <v-card-title class="text-h5">{{ $t('manageProvisionModal.title', {sourceName: sourceName}) }}</v-card-title>
            <v-img
                :aspect-ratio="16/9"
                :height="100"
                contain
                :src="usefulLinks.connectImage">
            </v-img>
            <v-card-text>
                <v-layout>
                    <v-flex>
                        <DisconnectWarningModal class="disconnect-button"
                                                :sourceName="sourceName"
                                                :disconnectImage="usefulLinks.disconnectImage"
                                                :subscriptionId="subscriptionId"
                                                :organizationId="organizationId"
                                                @displayDisconnectWarning="closeDialog()"
                                                @exitDisconnectWarning="openManageDialog()"></DisconnectWarningModal>
                    </v-flex>
                </v-layout>

<!--                Below is the code for the formatted drop down menu (Daily, Paused, etc..), force sync button, and disconnect. Uncomment when implemented-->
<!--                Remove v-layout above containing DisconnectWarningModal-->
<!--                <v-layout>-->
<!--                    <v-flex xs7>-->
<!--                        <p class="future-sync-selector-title">{{ $t('manageProvisionModal.futureSyncs') }}</p>-->
<!--                        <v-select class="sync-state-selector border"-->
<!--                                  :items="syncStates"-->
<!--                                  item-text="text"-->
<!--                                  item-value="value"-->
<!--                                  v-model="newSyncState"-->
<!--                                  single-line></v-select>-->
<!--                    </v-flex>-->
<!--                    <v-flex xs5>-->
<!--                        <div class="alignment-spacer"></div>-->
<!--                        <v-btn id="forceSyncBtn"-->
<!--                               outlined primary block-->
<!--                               @click.native="forceSyncNow()">-->
<!--                            {{ $t('manageProvisionModal.forceSync')}}-->
<!--                        </v-btn>-->
<!--                        <DisconnectWarningModal class="disconnect-button"-->
<!--                                                :sourceName="sourceName"-->
<!--                                                :disconnectImage="usefulLinks.disconnectImage"-->
<!--                                                :subscriptionId="subscriptionId"-->
<!--                                                :organizationId="organizationId"-->
<!--                                                @displayDisconnectWarning="closeDialog()"-->
<!--                                                @exitDisconnectWarning="openManageDialog()"></DisconnectWarningModal>-->
<!--                    </v-flex>-->
<!--                </v-layout>-->
            </v-card-text>
            <v-card-text v-show='usefulLinks.helpString'>
                <p class="useful_links_header">{{ $t('manageProvisionModal.usefulLinks') }}</p>
                <div class="useful_link">
                    <i class="material-icons icon icon-open learn-more-icon">launch</i>
                    <a @click="openLearnMore()" class="learn-more">{{ usefulLinks.helpString }}</a>
                </div>
            </v-card-text>
            <v-card-text>
                <v-layout>
<!--                    Remove offset when Save and Close button is present-->
                    <v-flex xs6 offset-xs3>
                        <v-btn id="closeManageSync"
                               outlined block
                               @click.native="closeDialog()">
                            {{ $t('buttons.close') }}
                        </v-btn>
                    </v-flex>
<!--                    Save and close button, uncomment when required-->
<!--                    <v-flex xs6>-->
<!--                        <v-btn id="saveAndClose"-->
<!--                               primary light block-->
<!--                               class="white&#45;&#45;text"-->
<!--                               :disabled="!isUpdated"-->
<!--                               @click.native="saveAndClose()">-->
<!--                            {{ $t('buttons.saveAndClose') }}-->
<!--                        </v-btn>-->
<!--                    </v-flex>-->
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {SIS_SOURCES} from '../../enums/rostering-sources.js'
    import DisconnectWarningModal from './DisconnectWarningModal.vue'

    const DAILY = 'DAILY'
    const PAUSED = 'PAUSED'

    export default {
        components: {DisconnectWarningModal},
        props: ['subscriptionId', 'organizationId', 'source', 'sourceName'],
        data () {
            return {
                openDialog: false,
                syncStates: [
                    {text: '', value: ''},
                    {text: this.$t('manageProvisionModal.daily'), value: DAILY},
                    {text: this.$t('manageProvisionModal.paused'), value: PAUSED}
                ],
                newSyncState: '',
                originalSyncState: ''
            }
        },
        watch: {
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        computed: {
            usefulLinks () {
                let info = {
                    helpString: '',
                    helpLink: '',
                    connectImage: '',
                    disconnectImage: ''
                }
                switch (this.source) {
                case SIS_SOURCES.ClassLink:
                    info.helpString = this.$t('manageProvisionModal.learnMore', {source: 'ClassLink'})
                    info.helpLink = this.$LINKS.CLASSLINK_PROVISION_SUPPORT
                    info.connectImage = this.$IMAGES.CLASSLINK.connect
                    info.disconnectImage = this.$IMAGES.CLASSLINK.disconnect
                    break
                case SIS_SOURCES.Google:
                    info.helpString = this.$t('manageProvisionModal.learnMore', {source: 'Google'})
                    info.helpLink = this.$LINKS.GOOGLE_PROVISION_SUPPORT
                    info.connectImage = this.$IMAGES.GOOGLE.connect
                    info.disconnectImage = this.$IMAGES.GOOGLE.disconnect
                    break
                case SIS_SOURCES.Microsoft_SDS:
                case SIS_SOURCES.Microsoft_Groups:
                    info.helpString = this.$t('manageProvisionModal.learnMore', {source: 'Microsoft'})
                    info.helpLink = this.$LINKS.MICROSOFT_PROVISION_SUPPORT
                    info.connectImage = this.$IMAGES.MICROSOFT.connect
                    info.disconnectImage = this.$IMAGES.MICROSOFT.disconnect
                    break
                case SIS_SOURCES.Clever:
                    info.connectImage = this.$IMAGES.CLEVER.connect
                    info.disconnectImage = this.$IMAGES.CLEVER.disconnect
                    break
                };
                return info
            },
            isUpdated () {
                return this.newSyncState !== this.originalSyncState
            }
        },
        methods: {
            openLearnMore () {
                window.open(this.usefulLinks.helpLink)
            },
            saveAndClose () {
                window.console.log('Save and close button clicked')
            },
            closeDialog () {
                this.openDialog = false
            },
            openManageDialog () {
                this.openDialog = true
            }
        }
    }
</script>

<style scoped>
    .disconnect-button {
        width: 100%;
    }

    .alignment-spacer {
        height: 13px;
    }

    .future-sync-selector-title {
        margin-bottom: 0px;
    }

    .sync-state-selector {
        width: 95% !important;
    }

    .learn-more {
        color: #159bd9;
        text-decoration: none;
    }

    .learn-more:hover {
        text-decoration: underline;
    }

    .learn-more-icon {
        font-size: 16px !important;
        color: #159bd9 !important;
    }

    .useful_links_header {
        margin-bottom: 5px;
    }

    .useful_link {
        margin-left: 20px;
    }
</style>
