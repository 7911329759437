import { ENDPOINTS, PATHS, INTERNAL_SUBSCRIPTION_ID } from './endpoint-constants'

// This is the value of the query parameter included in the API URL.
const INTERNAL_IDENTIFIER_PARAM = INTERNAL_SUBSCRIPTION_ID

export default {
    INTERNAL_IDENTIFIER_PARAM,
    [ENDPOINTS.ADMIN_MANAGEMENT]: `/api/${PATHS.ADMIN_MANAGEMENT}`,
    [ENDPOINTS.CLAIM_ENTITLEMENT]: `/api/${PATHS.CLAIM_ENTITLEMENT}`,
    [ENDPOINTS.CLIENT_CONNECTION]: `/api/${PATHS.CLIENT_CONNECTION}`,
    [ENDPOINTS.DISCONNECT_SUBSCRIPTION]: `/api/${PATHS.DISCONNECT_SUBSCRIPTION}`,
    [ENDPOINTS.EXPERIENCES]: `/api/${PATHS.EXPERIENCES}`,
    [ENDPOINTS.FEATURE_SWITCHES]: `/api/${PATHS.FEATURE_SWITCHES}`,
    [ENDPOINTS.LEGACY_SUBSCRIPTIONS]: `/api/v2/${PATHS.LEGACY_SUBSCRIPTIONS}`,
    [ENDPOINTS.ORGANIZATIONS]: `/api/v2/${PATHS.ORGANIZATIONS}`,
    [ENDPOINTS.ORGANIZATIONS_RECENT]: `/api/${PATHS.ORGANIZATIONS_RECENT}`,
    [ENDPOINTS.RELATED_LEGACY_SUBSCRIPTIONS]: `/api/${PATHS.LEGACY_SUBSCRIPTIONS}`,
    [ENDPOINTS.RELATED_ORGANIZATIONS]: `/api/${PATHS.ORGANIZATIONS}`,
    [ENDPOINTS.RELATED_SUBSCRIPTIONS]: `/api/${PATHS.SUBSCRIPTIONS}`,
    [ENDPOINTS.SIGN_IN_ERROR]: `#/${PATHS.SIGN_IN_ERROR}`,
    [ENDPOINTS.STATUS]: `/api/${PATHS.STATUS}`,
    [ENDPOINTS.SUBSCRIPTION]: `/api/${PATHS.SUBSCRIPTION}`,
    [ENDPOINTS.SUBSCRIPTIONS]: `/api/v2/${PATHS.SUBSCRIPTIONS}`,
    [ENDPOINTS.SYNC_TEACHERS]: `/api/${PATHS.SYNC_TEACHERS}`,
    [ENDPOINTS.UPDATE_SF_COUNTER]: `/api/${PATHS.UPDATE_SF_COUNTER}`,
    [ENDPOINTS.USER_SETTINGS]: `/api/${PATHS.USER_SETTINGS}`,
    [ENDPOINTS.USERS]: `/api/${PATHS.USERS}`,
    [ENDPOINTS.VALIDATE_ENTITLEMENT_CLAIM]: `/api/${PATHS.VALIDATE_ENTITLEMENT_CLAIM}`,
    [ENDPOINTS.DISTRICT_LIBRARY]: `/api/${PATHS.DISTRICT_LIBRARY}`,
    [ENDPOINTS.UPDATE_ALLOW_UPLOAD]: `/api/${PATHS.UPDATE_ALLOW_UPLOAD}`
}
