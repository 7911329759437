<template>
    <v-dialog v-model="openDialog" persistent width=30%>
        <template v-slot:activator="{ on }">
            <v-btn id="openDisconnectWarning" v-on="on" outlined block>
                {{ $t('disconnectWarningModal.modalButton', {sourceName: sourceName})}}
            </v-btn>
        </template>
        <v-card id="disconnectWarningModal">
            <v-card-title class="text-h6 font-weight-bold text--primary">{{ $t('disconnectWarningModal.title', {sourceName: sourceName})}}</v-card-title>
            <v-img
                :aspect-ratio="16/9"
                :height="100"
                contain
                :src="disconnectImage">
            </v-img>
            <v-card-text v-html="$t('disconnectWarningModal.continue', {sourceName: sourceName})">
            </v-card-text>
            <v-card-text>
                <b>{{ $t('removeUsersModal.important') }}</b>{{ $t('disconnectWarningModal.warning', {sourceName: sourceName})}}
            </v-card-text>
            <v-card-text>
                <v-layout>
                    <v-flex xs6>
                        <v-btn id="goBackBtn"
                               outlined block
                               :disabled="loading"
                               @click.native="backToManageSyncModal()">
                            {{ $t('buttons.back') }}
                        </v-btn>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn id="continueDisconnect"
                               outlined block
                               color="#249CD6"
                               class="primary"
                               :disabled="loading"
                               :loading="loading"
                               @click.native="disconnectSync()">
                            {{ $t('buttons.continue') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
    import {SIS_SOURCES} from '../../enums/rostering-sources.js'
    import {bus} from '../../event-bus'
    import {ErrorCodes} from '../../enums'
    import ErrorHandler from '../../utils/error-handler'

    export default {
        name: 'DisconnectWarningModal',
        props: ['sourceName', 'disconnectImage', 'subscriptionId', 'organizationId'],
        data () {
            return {
                openDialog: false,
                loading: false,
                extTierId: this.$route.params.tierId
            }
        },
        computed: {
            tierType() {
                return this.$store.getters.getTierType(this.subscriptionId, this.extTierId)
            }
        },
        methods: {
            disconnectSync () {
                this.loading = true
                this.$store.dispatch('DisconnectAutoProvision', {
                    organizationId: this.organizationId,
                    subscriptionId: this.subscriptionId,
                    source: SIS_SOURCES.Manual,
                    tierType: this.tierType
                }).then(() => {
                    this.closeDialog()
                    bus.$emit('openPersistedModal', {
                        title: this.$t('manageProvisionModal.disconnectSuccessTitle'),
                        message: this.$t('manageProvisionModal.disconnectSuccess', {sourceName: this.sourceName}),
                        primaryActionLabel: this.$t('users.backToManageSoftware'),
                        primaryAction: () => {
                            this.$router.push('/')
                        }
                    })
                }).catch(err => {
                    this.closeDialog()

                    if (err.errorCode === ErrorCodes.SYNC_INPROGRESS) {
                        err.message = this.$t('disconnectWarningModal.syncInProgress')
                    } else {
                        err.message = this.$t('dialogs.errorMessage')
                    }

                    ErrorHandler.handleError(err, ErrorHandler.dialogueError)
                })
            },
            backToManageSyncModal () {
                this.openDialog = false
                this.$emit('exitDisconnectWarning')
            },
            closeManageSyncModal () {
                this.$emit('displayDisconnectWarning')
            },
            closeDialog () {
                this.loading = false
                this.openDialog = false
            }
        },
        watch: {
            openDialog (newState, oldState) {
                if (newState === true) {
                    this.closeManageSyncModal()
                }
            }
        }
    }
</script>

<style scoped>

</style>
