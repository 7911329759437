<template>
    <v-layout justify-center>
        <v-dialog v-model="isOpen" width=450px content-class="subscription-detail-dialog">
            <v-card class="compact">
                <v-card-title class="heading-container">
                    <v-layout class="subscription-details-heading">
                        <v-flex xs6 md7 lg7>
                            {{ $t('subscriptionDetails.title') }}
                        </v-flex>
                        <v-flex xs5 md4 lg4 class="contact-button-container">
                            <v-btn class="contact-smart-button text-wrap" @click="contactSmart">
                                <span class="button-text">
                                    {{ $t('subscriptionDetails.contactSmart') }}
                                </span>
                            </v-btn>
                        </v-flex>
                        <v-flex xs1 class="close-button">
                            <v-btn medium icon text color="#cccccc" @click.native="$emit('close-dialog')">
                                <i class="material-icons md-light close-icon">clear</i>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-title>
                <v-card-text class="subscription-detail-form">
                    <v-layout class="formDialog striped" v-for="detail in detailsFormatted" :key="detail.key">
                        <v-flex xs5 class="detail-labels">
                            <span> {{ detail.key }} </span>
                        </v-flex>
                        <v-flex xs7>
                            <div v-if="Array.isArray(detail.value)">
                                <v-layout v-for="item in detail.value" :key="item.key">
                                    <v-flex xs8> {{ item.leftColumn }} </v-flex>
                                    <v-flex xs4> {{ item.rightColumn }} </v-flex>
                                </v-layout>
                            </div>
                            <div v-else-if="detail.value">
                                <text-button v-if="detail.action"
                                    :text="detail.value"
                                    @button-action="detail.action">
                                </text-button>
                                <span v-else> {{ detail.value }} </span>
                            </div>
                            <span v-else>--</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import { mapMutations, mapActions } from 'vuex'
    import TextButton from '../components/TextButton.vue'
    import ErrorHandler from '../utils/error-handler'
    import SubscriptionsUtil from '../utils/subscriptions-util'

    export default {
        components: {
            TextButton
        },
        props: {
            subscription: {
                type: Object,
                default: () => {}
            }
        },
        data () {
            return {
                isOpen: true
            }
        },
        computed: {
            tiers() {
                return Object.values(this.subscription.tiers)
            },
            products() {
                return this.tiers.map(tier => {
                    const tierSeats = this.$tc('subscriptionDetails.entitlements', tier.quantity, { count: tier.quantity })
                    return { leftColumn: tier.name, rightColumn: tierSeats }
                })
            },
            isLicenseKeyRequested() {
                return !!this.subscription.licenseKeyRequested
            },
            detailsFormatted() {
                // In the exception case (subscriptions with both Spark and Standard tiers), it is possible for some fields to be different:
                // renewal date, activation date, reseller (extreme edge case), order number
                return [
                    {key: this.$t('subscriptionDetails.products'), value: this.products},
                    {key: this.$t('subscriptionDetails.subscriptionID'), value: this.subscription.msdSubscriptionNumber},
                    ...(this.isLicenseKeyRequested && this.subscription.serialNumber
                        ? [{ key: this.$t('subscriptionDetails.licenseKey'), value: this.subscription.serialNumber }]
                        : [{ key: this.$t('subscriptionDetails.licenseKey'), value: this.$t('subscriptionDetails.generate'), action: this.generateKey }]),
                    {key: this.$t('subscriptionDetails.activationDate'), value: this.getValue('startDateFormatted')},
                    {key: this.$t('subscriptionDetails.renewalDate'), value: this.getValue('usageEndDateFormatted')},
                    {key: this.$t('subscriptionDetails.organization'), value: this.subscription.accountName},
                    {key: this.$t('subscriptionDetails.reseller'), value: this.getValue('reseller')},
                    {key: this.$t('subscriptionDetails.orderNumber'), value: this.getValue('orderNumber')}
                ]
            }
        },
        watch: {
            isOpen (val) {
                this.$emit('close-dialog')
            }
        },
        methods: {
            ...mapMutations({
                updateSubscription: 'UpdateSubscription'
            }),
            ...mapActions({
                generateLicenseKey: 'generateLicenseKey',
                getUpdatedOrganizationSubscriptions: 'GetUpdatedOrganizationSubscriptions'
            }),
            updateSerialNumber(response) {
                const updatedSubscription = Object.values(response.data).find(subscription => subscription.id === this.subscription.id)
                SubscriptionsUtil.resolveSubscriptionInformation(updatedSubscription, this.subscription.organizationName)
                this.updateSubscription({ subscriptionId: this.subscription.id, serialNumber: updatedSubscription.serialNumber })
            },
            generateKey() {
                const organizationId = this.subscription.organizationId
                const subscriptionId = this.subscription.id
                this.generateLicenseKey({ organizationId, subscriptionId }).then(() => {
                    // Ideally, this call should return the license key once it's generated. As that's not happening for now (this might
                    // change in the future), we need to do another call for retrieving the serialNumber
                    this.getUpdatedOrganizationSubscriptions({ organizationId, respListener: this.updateSerialNumber })
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                })
            },
            contactSmart() {
                window.open(this.$LINKS.SUPPORT)
            },
            getValue(property) {
                if (this.tiers.length > 1) {
                    const differentValuesForTiers = this.tiers.some(tier => tier[property] !== this.tiers[0][property])
                    if (differentValuesForTiers) {
                        return this.tiers.map(tier => ({ leftColumn: tier.name, rightColumn: tier[property] || '--' }))
                    }
                }
                return this.tiers[0][property]
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../theming/main.scss";

.heading-container {
    padding-left: 50px !important;
    .subscription-details-heading {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        padding-top: 25px;
        padding-bottom: 15px;
        height: 100%;
        line-height: 40px;

        .contact-button-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .contact-smart-button {
                height: 40px;
                min-width: 175px;
                max-width: 100%;
                background: $pallete-white;
                border-color: $theme-primary;
                color: $theme-primary;
                overflow: hidden;
            }
        }
    }
}

.v-card__text.subscription-detail-form {
    padding: 0px 0px 50px 0px !important;

    .detail-labels {
        padding-left: 30px;
    }
}

.close-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
        font-size: 30px;
    }
}
</style>
