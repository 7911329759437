import { isEnabled } from '../services/feature-switch'
import { HYBRID_VIEW } from '../enums/feature-switch-constants'
import ErrorHandler from '../utils/error-handler'
import { LINKS } from '../config'

// TODO: encapsulate these consts into ClaimErrorUtils.
export const VALIDATE_SN = 'sn'
export const VALIDATE_CODE = 'code'
export const CLAIM_BOTH = 'claim'

export let ClaimErrorUtils = {
    getErrorMessage (errCode, source) {
        let errMsg
        const isHybridView = isEnabled(HYBRID_VIEW)
        switch (errCode) {
        case 'CLAIMEDCODE':
        case 'CLAIMEDSERIALNUMBER':
            errMsg = !isHybridView ? 'activation.errors.claimedCodeOrSerialNumber' : 'activation.errors.claimedCodeOrSerialNumberHybrid'
            break
        case 'INVALIDCODE':
            errMsg = !isHybridView ? 'activation.errors.invalidCode' : 'activation.errors.invalidCodeHybrid'
            break
        case 'INVALIDSERIALNUMBER':
            errMsg = !isHybridView ? 'activation.errors.invalidSerialNumber' : 'activation.errors.invalidSerialNumberHybrid'
            break
        case 'OLDCODE':
            errMsg = !isHybridView ? 'activation.errors.oldClaimCode' : 'activation.errors.oldClaimCodeHybrid'
            break
        case 'SERIALNUMBERTOOOLD':
            errMsg = !isHybridView ? 'activation.errors.oldSerialNumber' : 'activation.errors.oldSerialNumberHybrid'
            break
        case 'SAMETIEREXIST':
        case 'OTHERTIEREXIST':
            errMsg = 'activation.errors.otherSLSExistsClaimError'
            break
        case 'OTHERLEGACYEXIST':
            errMsg = 'activation.errors.legacyExistsError'
            break
        case 'BadUSZipCode':
        case 'BadCAPostalCode':
            errMsg = 'activation.errors.zipPostalCode'
            break
        default:
            switch (source) {
            case VALIDATE_SN:
                errMsg = 'activation.errors.validateMessage'
                break
            case VALIDATE_CODE:
                errMsg = !isHybridView ? 'activation.errors.validateMessageURL' : 'activation.errors.validateMessageURLHybrid'
                break
            default:
                errMsg = 'activation.errors.claimMessage'
                break
            }
            break
        }
        return errMsg
    },
    getErrorTitle (errCode) {
        let errTitle
        const isHybridView = isEnabled(HYBRID_VIEW)
        switch (errCode) {
        case 'OLDCODE':
        case 'SERIALNUMBERTOOOLD':
            errTitle = !isHybridView ? 'activation.errors.couldNotProcess' : 'activation.errors.couldNotProcessHybrid'
            break
        default:
            errTitle = 'activation.errors.title'
            break
        }
        return errTitle
    },
    getClaimError ({ err, productsNumber, productList, orgMsdSubscription }) {
        let stringParams = {}
        let errorGroup = ErrorHandler.claimActivationError
        if (err.message === 'SAMETIEREXIST' || err.message === 'OTHERTIEREXIST') {
            errorGroup = ErrorHandler.otherSLSExistsClaimError
            let subscriptionName = productList[0].Name
            let lastSubscriptionName = productList[productsNumber - 1].Name
            if (productsNumber > 1) {
                productList.forEach((product, i) => {
                    if (i > 0 && i < productsNumber - 1) {
                        subscriptionName += `, ${product.Name}`
                    }
                })
            }
            stringParams = { subscriptionName, lastSubscriptionName, link: LINKS.SUPPORT }

            const selectedOrgMsdSubscriptionId = orgMsdSubscription?.msdSubscriptionNumber
            // Non migrated subscriptions (SRM or non migrated SLS) don't have subscription Id. The message is different in this case.
            if (!selectedOrgMsdSubscriptionId) {
                err.message = 'OTHERLEGACYEXIST'
            } else {
                stringParams.subscriptionId = selectedOrgMsdSubscriptionId
            }
        } else if (err.message === 'BadUSZipCode' || err.message === 'BadCAPostalCode') {
            errorGroup = ErrorHandler.badPostalCodeActivationError
        }
        const message = this.getErrorMessage(err.message, CLAIM_BOTH)
        return { message, stringParams, errorGroup }
    }
}
