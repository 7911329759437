const UNCLAIMED_LICENSE = 'Unclaimed License Key'

let filterUnclaimed = function (assets) {
    let filteredAssets = {
        unclaimed: [],
        claimed: []
    }
    for (let i = 0; i < assets.length; i++) {
        let assetName = assets[i].assetName
        if (assetName !== undefined && assetName === UNCLAIMED_LICENSE) {
            filteredAssets.unclaimed.push(assets[i])
        } else {
            filteredAssets.claimed.push(assets[i])
        }
    }

    return filteredAssets
}

export default {
    updateSubscription: (updateInfo, store) => {
        let subscriptions = []
        let legacySubscription = {
            id: updateInfo.orgId,
            isLegacy: true,
            assets: []
        }
        let unclaimedLicenses = {
            id: updateInfo.orgId + '-uc',
            isUnclaimed: true,
            assets: []
        }

        if (!updateInfo.data) {
            console.error('Subscriptions: no subscription data received')
            return
        }

        // If there were subscriptions returned, update the store
        if (updateInfo.data.length) {
            if (updateInfo.isLegacy) {
                let filteredAssets = filterUnclaimed(updateInfo.data)
                unclaimedLicenses.assets = filteredAssets.unclaimed
                if (filteredAssets.claimed.length) {
                    legacySubscription.assets = filteredAssets.claimed
                    subscriptions.push(legacySubscription)
                }
            } else if (updateInfo.data[0].tiers) {
                // Migrated subscriptions dont have assets, they have tiers.
                subscriptions.push(updateInfo.data[0])
            } else {
                let filteredAssets = filterUnclaimed(updateInfo.data[0].assets)
                unclaimedLicenses.assets = filteredAssets.unclaimed
                updateInfo.data[0].assets = filteredAssets.claimed
                if (updateInfo.data[0].assets.length) {
                    subscriptions.push(updateInfo.data[0])
                }
            }
            if (unclaimedLicenses.assets.length) {
                subscriptions.push(unclaimedLicenses)
            }
            let org = store.getters.getOrganizations.find(org => org.id === updateInfo.orgId)

            if (subscriptions.length) {
                subscriptions.forEach(subscription => {
                    store.commit('SubscriptionReceived', {
                        organizationId: updateInfo.orgId,
                        organizationName: org.name,
                        subscription: subscription
                    })
                })
            }
        }
    }
}
