<template>
    <div>
        <v-expansion-panels focusable>
            <v-expansion-panel v-model="isExpand">
                <!-- eslint-disable-next-line -->
                <v-layout v-if="!isOther" v-for="license in licensePages[pageNumber]" :key="license.id">
                    <v-flex xs5 md4 offset-md1 lg4 offset-lg2>
                        <span>
                            <a @click="onLicenseDetailsOpened(license)">
                                {{license.serialNumber}}
                            </a>
                        </span>
                    </v-flex>
                    <v-flex xs3 lg3>
                        {{ $tc('licenses.seats', license.quantity, { count: license.quantity}) }}
                    </v-flex>
                    <v-flex xs3 lg3>
                        <span v-if="license.status === SubscriptionStates.EXPIRED" class="font--bold">{{ $t('statuses.expired') }}</span>
                        <span v-else>
                            <span v-if="license.usageEndDate">{{ $t('statuses.expires', { date: license.usageEndDateFormatted }) }}</span>
                            <span v-else>{{ $t('statuses.expires', { date: '--'}) }}</span>
                        </span>
                    </v-flex>
                </v-layout>
                <v-layout v-else>
                    <v-flex xs6 class="text--center">
                        <span>
                            <a @click="onLicenseDetailsOpened(license)">
                                {{license.serialNumber}}
                            </a>
                        </span>
                    </v-flex>
                    <v-flex xs6>
                        <span class="legacyProductName">{{ license.productName }}</span>
                    </v-flex>
                </v-layout>
                <v-layout v-if="pageCount >= 2" class="pager">
                    <v-flex xs5 offset-md3>
                        <v-pagination :length="thePageCount" v-model="page" :total-visible="6"></v-pagination>
                    </v-flex>
                </v-layout>
            </v-expansion-panel>
        </v-expansion-panels>
        <LicenseDetails v-if="openLicenseDetails"
                        :licenseDetails="openLicense"
                        @close-dialog="onLicenseDetailClosed"
        ></LicenseDetails>
    </div>
</template>

<script>
    import LicenseDetails from '../components/LicenseDetails.vue'
    import { SubscriptionStates } from '../enums/'

    const RENEWABLE_LICENSE_PREFIXES = ['NC-', 'MC-', 'MS-', 'PE-', 'MP-', 'SR-', 'RV-', 'RH-']
    const FNO_KEY_SUFFIX_REGEX = /.*-AA[A-Z]$/
    const FNO_KEY_SUFFIX_PLACEHOLDER = '-AAA'

    export default {
        props: ['licenses', 'isExpanded', 'isOther'],
        components: {
            LicenseDetails
        },
        data () {
            return {
                page: 1,
                pageCount: 0,
                licensePages: [],
                SubscriptionStates,
                openLicenseDetails: false,
                openLicense: {},
                isExpand: this.isExpanded
            }
        },
        computed: {
            pageNumber () {
                return this.page - 1
            },
            thePageCount () {
                return this.pageCount
            }
        },
        methods: {
            onLicenseDetailsOpened (license) {
                this.openLicense = license
                this.openLicenseDetails = true
            },
            onLicenseDetailClosed () {
                this.openLicense = {}
                this.openLicenseDetails = false
            },
            formatNotebookLicenses (licenses) {
                licenses.forEach(license => {
                    let licenseUC = license.serialNumber.toUpperCase()
                    if (RENEWABLE_LICENSE_PREFIXES.some(prefix => licenseUC.startsWith(prefix)) &&
                        !licenseUC.match(FNO_KEY_SUFFIX_REGEX)) {
                        license.serialNumber = license.serialNumber + FNO_KEY_SUFFIX_PLACEHOLDER
                    }
                })
            }
        },
        created: function () {
            let licenseCount = this.licenses.length
            this.pageCount = Math.floor((licenseCount + this.$UI_CONFIG.MAX_LICENSE_COUNT - 1) / this.$UI_CONFIG.MAX_LICENSE_COUNT)
            for (let x = 0, y = 0; y < this.pageCount; x += this.$UI_CONFIG.MAX_LICENSE_COUNT, y++) {
                this.licensePages[y] = this.licenses.slice(x, this.$UI_CONFIG.MAX_LICENSE_COUNT + x)
                this.formatNotebookLicenses(this.licensePages[y])
            }
        }
    }
</script>
