<template>
    <v-layout justify-end fill-height>
        <v-layout justify-end align-end wrap>
            <v-spacer style="width: 15px; max-width: 15px;"></v-spacer>
            <div class="activator-button-container">
                <v-btn id="inviteModalActivator"
                        class="primary white--text "
                        :disabled="!userHasValidPermissionInOrgs"
                        @click="openInviteAdminModal()">
                        {{ $t('management.menu.inviteNew') }}
                </v-btn>
            </div>
            <div class="activator-button-container">
                <RemoveAdminDialog></RemoveAdminDialog>
            </div>
        </v-layout>
        <v-flex xs5 grow class="search-options">
            <v-select
                v-model="searchRole"
                :items="rolesList"
                item-text="name"
                item-value="key"
                single-line
                solo flat
                :height=30
                class="search-option-single">
            </v-select>
            <v-text-field v-model="searchName"
                          autocomplete="disable-chrome-autocomplete"
                          :placeholder="$t('management.menu.search')"
                          text solo
                          :height=25
                          class="search-option-single">
            </v-text-field>
        </v-flex>
    </v-layout>
</template>

<script>
    import {bus} from '../../event-bus'
    import RemoveAdminDialog from './RemoveAdminDialog'
    import PermissionValidator from '../../utils/permission-validator'
    import {PERMISSIONS} from '../../enums/admin-management-constants'

    export default {
        name: 'AdminManagementDropdown',
        components: {RemoveAdminDialog},
        data () {
            return {
                rolesList: []
            }
        },
        computed: {
            searchName: {
                get () {
                    return this.$store.getters.getCurrentSearchByNameValue
                },
                set (value) {
                    this.$store.commit('filterAdminsByName', value)
                }
            },
            searchRole: {
                get () {
                    return this.$store.getters.getCurrentSearchByRoleValue
                },
                set (value) {
                    this.$store.commit('filterAdminsByRole', value)
                }
            },
            userHasValidPermissionInOrgs () {
                let orgs = Object.keys(this.$store.getters.getOrganizationsForValidation)
                for (let i = 0; i < orgs.length; i++) {
                    if (PermissionValidator.validateUserPermission(orgs[i], PERMISSIONS.ManageAdmins)) {
                        return true
                    }
                }
                return false
            }
        },
        methods: {
            openInviteAdminModal () {
                bus.$emit('openAdminInformationModal', {invite: true})
            },
            getRolesDropdownList () {
                let roleList = this.$t('management.adminRoles')
                let translatedRoles = [{
                    key: '',
                    name: this.$t('management.menu.allRoles')
                }]
                Object.keys(roleList).forEach(function mapKeys (key) {
                    translatedRoles.push({
                        key: key,
                        name: roleList[key].name
                    })
                })
                return translatedRoles
            }
        },
        beforeMount () {
            this.rolesList = Object.freeze(this.getRolesDropdownList())
        }
    }
</script>

<style scoped>

    .search-options {
        height: 100%;
        padding-left: 25px;
    }

    .search-option-single {
        max-height: 50%;
        margin: 0px;
        padding: 0px;
    }

    .activator-button-container {
        margin: 5px;
    }

</style>
