<template>
    <v-dialog v-model="openDialog" persistent width=30% :disabled="disabled">
        <template v-slot:activator="{ on }">
            <v-btn id="openAddUsers" v-on="on" :disabled="disabled" class="primary white--text">
                    {{ $t('addUsersModal.addUsers') }}
            </v-btn>
        </template>

        <v-card id="addUsersModal">
            <transition name="fade-transition" mode="out-in">
                <component
                    class="add-user-component"
                    :is="addUserComponent"
                    :loading="loading || loadingOneRoster || readingFile || loadingThirdParty"
                    :add-user="addUser"
                    :organization-id="organizationId"
                    :subscription-id="subscriptionId"
                    :activeProvisions="activeProvisions"
                    :nextComponent="nextComponent"
                    @change-component="changeComponent"
                    @show-provision-warning="provisionWarning"
                    @cancel-dialog="cancelDialog"
                    @add-users="addUsers"
                    @csv-upload="csvUpload"
                    @sync-teachers="syncTeachers"
                    @sync-thirdParty="syncThirdParty">
                </component>
            </transition>
        </v-card>
    </v-dialog>
</template>

<script>
    import {bus} from '../../event-bus'
    import AddUsersMenu from './AddUsersMenu.vue'
    import TextAddUsers from './TextAddUsers.vue'
    import CsvInstructions from './CsvInstructions.vue'
    import RetryCsvUpload from './RetryCsvUpload.vue'
    import RosterAddUsers from './RosterAddUsers.vue'
    import GoogleClassroomAddUsers from './GoogleClassroomAddUsers.vue'
    import MicrosoftAddUsers from './MicrosoftAddUsers.vue'
    import AddUsersWarning from './AddUsersWarning.vue'
    import {AddUserComponents, ErrorCodes, Sources} from '../../enums/'
    import ValidEmailUtil from '../../utils/valid-email-util'
    import ErrorHandler from '../../utils/error-handler'
    import { isEnabled } from '../../services/feature-switch'
    import { ONLY_DSL } from '../../enums/feature-switch-constants'
    import { TIER_TYPES } from '../../enums/subscription-constants'
    import { DSL_ROLES } from '../../enums/dsl-constants'

    const CSV_MATCH_REGEX = /.+\.csv$/
    const MAX_FAILED_CSV_ATTEMPTS = 3

    export default {
        props: ['organizationId', 'subscriptionId', 'disabled', 'open', 'cancelToken', 'onClose', 'activeProvisions', 'internalSubscriptionId', 'tierType'],
        components: {
            AddUsersMenu,
            TextAddUsers,
            RetryCsvUpload,
            CsvInstructions,
            RosterAddUsers,
            GoogleClassroomAddUsers,
            MicrosoftAddUsers,
            AddUsersWarning
        },
        data () {
            return {
                loading: false,
                loadingOneRoster: false,
                loadingThirdParty: false,
                readingFile: false,
                failedCsvAttempts: 0,
                openDialog: false,
                addUser: {
                    couldNotAdd: '',
                    error: false
                },
                nextComponent: '',
                addUserComponent: AddUserComponents.ADD_USERS_MENU
            }
        },
        watch: {
            'open' (to) {
                this.openDialog = to
            },
            'openDialog' (to, from) {
                this.$store.commit('setIsModalOpen', to)
                this.addUserComponent = AddUserComponents.ADD_USERS_MENU
            }
        },
        computed: {
            showUsersSettingsModal() {
                return this.isSparkSubscription && isEnabled(ONLY_DSL)
            },
            isSparkSubscription() {
                return this.tierType === TIER_TYPES.SPARK
            }
        },
        methods: {
            provisionWarning (options) {
                this.nextComponent = options.nextComponent
                this.changeComponent(AddUserComponents.ADD_USERS_WARNING)
            },
            changeComponent (component) {
                this.addUserComponent = component
            },
            showMessageDialog (title, message, traceId, errorCode) {
                bus.$emit('openSelfClosingModal', {
                    title,
                    message,
                    traceId,
                    errorCode
                })
            },
            showPersistedMessageDialog (title, message, traceId, errorCode) {
                bus.$emit('openPersistedModal', {
                    title: title,
                    message: message,
                    traceId: traceId,
                    errorCode: errorCode,
                    primaryActionLabel: this.$t('buttons.ok')
                })
            },
            isUserNameValid (user) {
                return ValidEmailUtil.isValidUpn(user)
            },
            getInvalidUserNames (users) {
                return users.filter(user => !this.isUserNameValid(user))
            },
            getValidUserNames (users) {
                const usersWithNoDuplicates = [...new Set(users)]
                const validUsers = usersWithNoDuplicates.filter(user => this.isUserNameValid(user))
                return validUsers.map(user => {
                    return {
                        email: user,
                        ...(this.isSparkSubscription && { dlRoles: [DSL_ROLES.VIEWER] })
                    }
                })
            },
            updateManualSource () {
                return new Promise((resolve, reject) => {
                    const source = this.$store.getters.getSubscriptionSource(this.subscriptionId)
                    switch (source) {
                    case Sources.SIS_SOURCES.Undecided:
                        this.$store.dispatch('UpdateSource', {
                            organizationId: this.organizationId,
                            subscriptionId: this.subscriptionId,
                            source: Sources.SIS_SOURCES.Manual,
                            internalSubscriptionId: this.internalSubscriptionId,
                            tierType: this.tierType,
                            tierId: this.$store.getters.getInternalTierId(this.subscriptionId, this.tierType)
                        }).then(() => {
                            resolve()
                        }).catch(err => {
                            reject(err)
                        })
                        break
                    case Sources.SIS_SOURCES.Manual:
                        resolve()
                        break
                    default:
                        // eslint-disable-next-line no-case-declarations
                        let error = new Error('Invalid subscription source ' + source)
                        error.traceId = ''
                        error.errorCode = ErrorCodes.SOURCE_UPDATE_ERROR
                        reject(error)
                    }
                })
            },
            addUsers (users) {
                this.loading = true
                return this.updateManualSource()
                    .then(() => {
                        this.addManualUsers(users)
                    }).catch(err => {
                        this.onSyncError(err)
                    })
            },
            addManualUsers (users) {
                this.addUser.couldNotAdd = ''
                let invalidUsers = this.getInvalidUserNames(users)
                let validUsers = this.getValidUserNames(users)
                this.$store.dispatch('AddUsersToSubscription', {
                    organizationId: this.organizationId,
                    subscriptionId: this.subscriptionId,
                    users: validUsers,
                    tierType: this.tierType
                }).then((failedUsers) => {
                    failedUsers = failedUsers.concat(invalidUsers.map(invalidUser => {
                        return { failed_email: invalidUser }
                    }))
                    this.loading = false
                    if (failedUsers.length) {
                        // aggregate results
                        let emails = []
                        for (let j = 0; j < failedUsers.length; j++) {
                            emails.push(failedUsers[j].failed_email)
                        }

                        this.addUser.couldNotAdd = emails.join(' ')
                        this.addUser.error = true
                        // If we could not upload all users, switch to text-add-users view for all failures
                        this.addUserComponent = AddUserComponents.TEXT_ADD_USERS
                    } else {
                        if (this.showUsersSettingsModal) {
                            this.$emit('open-initial-setup-modal')
                        } else {
                            this.showMessageDialog(this.$t('dialogs.success'), this.$tc('addUsersModal.successMessage', users.length))
                        }
                        this.clearState()
                    }
                }).catch(err => {
                    this.onSyncError(err)
                })
            },
            csvUpload (options) {
                let files = options.files
                if (files.length <= 0) {
                    // IE11 fires again when el.value='' so ignore if no files selected
                    return
                }
                let file = null
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name.match(CSV_MATCH_REGEX)) {
                        file = files[i]
                    }
                }
                if (file) {
                    const reader = new FileReader()

                    reader.onload = (e) => {
                        this.readingFile = false
                        this.processCsvFileUpload(reader.result)

                        // Set state of showCsvInstructions if available
                        if (options.showCsvInstructions || options.showCsvInstructions === false) {
                            this.$store.dispatch('UpdateUserSettings', {
                                showCsvInstructions: options.showCsvInstructions
                            })
                        }
                    }
                    reader.onerror = (e) => {
                        this.readingFile = false
                        this.csvUploadFailed()
                    }

                    this.readingFile = true
                    reader.readAsText(file)
                } else {
                    this.csvUploadFailed()
                }
            },
            csvUploadFailed () {
                this.failedCsvAttempts++
                if (this.addUserComponent === AddUserComponents.RETRY_CSV_UPLOAD && this.failedCsvAttempts >= MAX_FAILED_CSV_ATTEMPTS) {
                    this.showCsvUploadError()
                } else {
                    this.changeComponent(AddUserComponents.RETRY_CSV_UPLOAD)
                }
            },
            processCsvFileUpload (userTextRaw) {
                try {
                    let users = userTextRaw
                        .split(/[\r\n]+/)
                        .map(emailIn => {
                            let email = emailIn.trim()
                            // TODO: Because we're processing text directly from csv, we must check for wrapping double quotes
                            // Instead of this we should use a CSV lib to get emails
                            if (email && email.length > 2 && email.charAt(0) === '"' && email.charAt(email.length - 1) === '"') {
                                return email.substr(1, email.length - 2)
                            }
                            return email
                        })
                        .filter(email => email)
                    this.addUsers(users)
                } catch (err) {
                    this.showCsvUploadError(err)
                }
            },
            showCsvUploadError () {
                this.onSyncError({
                    errorGroup: ErrorHandler.dialogueError,
                    errorCode: ErrorCodes.ERROR_CSV_FILE_UPLOAD_CODE
                })
            },
            syncTeachers (districtId) {
                this.loadingOneRoster = true
                this.$store.dispatch('SyncTeacherToSubscription',
                    {
                        organizationId: this.organizationId,
                        subscriptionId: this.subscriptionId,
                        sisOrganizationId: districtId,
                        source: Sources.SIS_SOURCES.ClassLink,
                        tierType: this.tierType
                    })
                    .then(() => {
                        if (this.$store.getters.getLoadingStatus) {
                            this.cancelToken.cancel(true)
                        }
                        this.updateSubscriptionOnSyncSuccess(Sources.SIS_SOURCES.ClassLink)
                        if (this.showUsersSettingsModal) {
                            this.$emit('open-initial-setup-modal')
                        }
                    }).catch(err => {
                        this.onSyncError(err)
                    })
            },
            syncThirdParty (syncInfo) {
                let source = syncInfo.source
                this.loadingThirdParty = true
                let credentials = {
                    slsSubId: this.$store.getters.getInternalSubscriptionId(this.subscriptionId),
                    subscriptionId: this.subscriptionId,
                    orgId: this.organizationId,
                    tierType: this.tierType,
                    ...syncInfo
                }

                this.$store.dispatch('initiateClientConnection', credentials).then(() => {
                    if (this.$store.getters.getLoadingStatus) {
                        this.cancelToken.cancel(true)
                    }
                    this.updateSubscriptionOnSyncSuccess(source)
                    if (this.showUsersSettingsModal) {
                        this.$emit('open-initial-setup-modal')
                    }
                }).catch(err => {
                    if (err.closed) {
                        switch (err.status) {
                        case 'COMPLETED':
                            this.updateSubscriptionOnSyncSuccess(source)
                            break
                        case 'STARTED':
                            this.$store.dispatch('revokeConnectionGrant', err.grantCode).then(() => {
                                this.loadingThirdParty = false
                            }).catch(err => {
                                this.onSyncError(err)
                            })
                            break
                        case 'SIGNED_IN':
                            this.$store.dispatch('getConnectionStatus', err.grantCode).then(() => {
                                this.updateSubscriptionOnSyncSuccess(source)
                            }).catch(err => {
                                this.onSyncError(err)
                            })
                            this.showPersistedMessageDialog(this.$t('dialogs.success'), this.$t('applicationErrors.syncInProgress'))
                            break
                        default:
                            err.errorCode = ErrorCodes.INTERNAL_ERROR
                            this.onSyncError(err)
                            break
                        }
                    } else {
                        this.onSyncError(err)
                    }
                })
            },
            cancelDialog () {
                this.clearState()
                this.closeDialog()
            },
            clearState () {
                this.addUser.couldNotAdd = ''
                this.addUser.error = false
                this.loading = false
                this.loadingOneRoster = false
                this.loadingThirdParty = false
                this.failedCsvAttempts = 0
                this.readingFile = false
                this.closeDialog()
            },
            closeDialog () {
                this.openDialog = false
                if (typeof this.onClose === 'function') {
                    this.onClose()
                }
            },
            updateSubscriptionOnSyncSuccess (source) {
                this.clearState()
                this.$store.commit('UpdateSubscription', {
                    subscriptionId: this.subscriptionId,
                    source: source
                })
                this.$store.commit('RemoveAllSubscriptionsUserList')
            },
            onSyncError (error) {
                this.clearState()
                ErrorHandler.handleError(error, ErrorHandler.dialogueError)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
