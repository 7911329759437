// file to contain feature switch names.

export const ADMIN_PORTAL_SWITCH_CLIENT = 'adminportal.smarttech.com'
export const SERVICE_UNAVAILABLE = 'switch:adminportal.smarttech.com/service-unavailable'
export const AUTO_PROVISION_DISABLED = 'switch:adminportal.smarttech.com/auto-provision-disabled'
export const HYBRID_VIEW = 'switch:adminportal.smarttech.com/fe-only-hybrid-view'
export const SHOW_RELEASE_NOTIFICATION = 'switch:adminportal.smarttech.com/fe-only-show-whats-new'
export const SHOW_COMING_SOON_NOTIFICATION = 'switch:adminportal.smarttech.com/fe-only-show-coming-soon'
export const FUSE_API = 'switch:adminportal.smarttech.com/fe-only-fuse-api'
export const DEBUG_STOCKING_CLAIM_FLOW = 'switch:adminportal.smarttech.com/fe-only-debug-stocking-flow'
export const DEBUG_NON_STOCKING_CLAIM_FLOW = 'switch:adminportal.smarttech.com/fe-only-debug-non-stocking-flow'
export const ONLY_DSL = 'switch:adminportal.smarttech.com/fe-only-dsl'
export const SHOW_MANAGE_USERS_ONBOARDING = 'switch:adminportal.smarttech.com/show-manage-users-onboarding'
export const RESET_MANAGE_USERS_ONBOARDING = 'switch:adminportal.smarttech.com/reset-manage-users-onboarding'
export const SHOW_EXPIRY_MODAL = 'switch:adminportal.smarttech.com/show-expiry-modal'
export const SHOW_UNPOPULATED_SUBSCRIPTION_MODAL = 'switch:adminportal.smarttech.com/show-unpopulated-subscription-modal'
