<template>
    <v-dialog v-model="openDialog" width="30%" persistent>
        <v-card id="adminInformationForm">
            <v-card-text v-if="isInviteForm">
                <v-card-title class="text-h6 font-weight-bold text--primary dialog-header">{{ $t('management.adminDialog.inviteTitle') }}</v-card-title>
                <v-card-text>{{ $t('management.adminDialog.inviteMessage') }}</v-card-text>
                <v-layout class="dialog-component">
                    <v-flex xs6>
                        <RequiredTag/>
                        <label for="firstName">{{ $t('management.adminDialog.firstName') }}</label>
                        <v-text-field id="firstName"
                                      v-model="firstName"
                                      maxlength="75"
                                      :rules="[xssCharacters]"
                                      required text solo></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                        <RequiredTag/>
                        <label for="lastName">{{ $t('management.adminDialog.lastName') }}</label>
                        <v-text-field id="lastName"
                                      v-model="lastName"
                                      maxlength="75"
                                      :rules="[xssCharacters]"
                                      required text solo></v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-text v-else>
                <v-card-title class="text-h6 font-weight-bold text--primary dialog-header">{{ $t('management.adminDialog.updateTitle') }}</v-card-title>
                <v-layout class="dialog-component">
                    <v-flex xs6>
                        <label for="firstName">{{ $t('management.adminDialog.firstName') }}</label>
                        <div id="firstName" class="view-only-text font-weight-bold text--primary">{{ firstName }}</div>
                    </v-flex>
                    <v-flex xs6>
                        <label for="lastName">{{ $t('management.adminDialog.lastName') }}</label>
                        <div id="lastName" class="view-only-text font-weight-bold text--primary">{{ lastName }}</div>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component">
                    <RequiredTag v-show="isInviteForm"/>
                    <label for="email">{{ $t('management.adminDialog.email') }}</label>
                    <v-text-field v-if="isInviteForm"
                                  id="email"
                                  v-model="email"
                                  required autofocus
                                  text solo
                                  @paste="handleEmailPaste"
                                  @keydown.space.prevent
                                  @blur="checkUserExistence"
                                  @input="emailChanged"
                                  maxlength="254"
                                  :rules="[xssCharacters, validEmail]"/>
                    <p v-else class="view-only-text font-weight-bold text--primary">{{ email }}</p>
                    <div v-show="emailExistsError" class="error--text inline-email-error">
                        <span>{{ $t('management.adminDialog.emailError') }}</span>
                        <span><a @click="openExistingUser()">{{ $t('management.adminDialog.here') }}</a></span>
                    </div>
                    <div v-show="inlineEmailError" class="error--text inline-email-error">
                        <span>{{ $t('management.adminDialog.inactiveUserError') }}</span>
                    </div>
                </div>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component">
                    <RequiredTag/>
                    <label for="organization">{{$t('management.adminDialog.organization')}}</label>
                    <v-select id="organization"
                              class="border selector"
                              v-model="orgsSelected"
                              :items="isLoggedInUser() ? validAndInvalidOrgs : userOrganizations.valid"
                              :placeholder="getOrgSelectionText()"
                              item-text="name"
                              multiple
                              chips
                              single-line
                              text solo
                              item-value="id">
                        <template v-slot:prepend-item>
                            <v-list-item v-if="userOrganizations.valid.length > 0"
                                         ripple
                                         @click="selectAllOrgs">
                                <v-list-item-action>
                                    <v-icon>{{ selectAllIcon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('management.adminDialog.allOrgs') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">
                                {{ getOrgSelectionText(item.name) }}</span>
                        </template>
                        <template v-if="!isLoggedInUser()" v-slot:append-item>
                            <v-divider class="mt-2"></v-divider>
                            <v-list-item v-for="org in userOrganizations.invalid"
                                         :key="org.id"
                                         style="pointer-events: none;">
                                <v-list-item-action>
                                    <v-icon v-if="openedUserHasRoleInInvalidOrg(org.id)">check</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ org.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component">
                    <div class="role-label-container">
                        <span><RequiredTag/><label for="role">{{roleText}}</label></span>
                        <v-icon size="24" class="help-icon" @click="openAdminManagementInfo()">help</v-icon>
                    </div>
                    <v-select id="role"
                              class="border selector"
                              v-model="role"
                              :items="rolesList"
                              item-text="name"
                              :loading="loadingRoles"
                              :placeholder="$t('management.adminDialog.multipleRoles')"
                              attach="#role-dropdown-anchor"
                              text solo
                              @change="updateOrgRoleSelections"
                              item-value="key">
                        <template v-slot:selection="{item}">
                            {{ item.name }}
                        </template>
                        <template v-slot:item="{item}">
                            <div>
                                <div class="role-item-title">{{ item.name }}</div>
                                <div class="role-item-descriptor">{{ item.description }}</div>
                            </div>
                        </template>
                    </v-select>
                    <div id="role-dropdown-anchor" class="dropdown-anchor"></div>
                </div>
            </v-card-text>
            <v-card-text v-show="role !== 'multipleRoles'">
                <div class="dialog-component">
                    <p class="role-description">{{ getTranslatedRoleDescription(role) }}</p>
                </div>
            </v-card-text>
            <v-card-text>
                <OrgRoleDialog class="dialog-component"
                               :disabled="totalOrgsWithRole <= 1"
                               :orgAndRoleMapping="orgAndRoleMapping"
                               :invalidPermissionOrgs="invalidPermissionOrgs"
                               @orgRoleCustomizationOpened="openOrgRoleCustomization"
                               @orgRoleCustomizationClosed="closeOrgRoleCustomization"
                               @saveOrgRoleCustomization="saveOrgRoleCustomization">
                </OrgRoleDialog>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component">
                    <v-btn v-if="isInviteForm"
                           id="sendInvite"
                           block primary light
                           class="white--text"
                           :disabled="loading || !validateInviteForm"
                           :loading="loading"
                           @click="triggerRequest()">
                        {{ $t('management.adminDialog.invite') }}</v-btn>
                    <v-btn v-else
                           id="updateAdminInfo"
                           block primary light
                           class="white--text"
                           :disabled="loading || !validateEditForm"
                           :loading="loading"
                           @click="triggerRequest()">
                        {{ $t('management.adminDialog.update') }}</v-btn>
                    <v-btn id="closeAdminDialog"
                           block outlined
                           :disabled="loading"
                           @click="resetAndCloseDialog()">
                        {{ $t('buttons.cancel') }}</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {bus} from '../../event-bus'
    import ErrorHandler from '../../utils/error-handler'
    import PermissionValidator from '../../utils/permission-validator'
    import {PERMISSIONS, REQUEST_TYPE, ADMIN} from '../../enums/admin-management-constants'
    import ValidEmailUtil from '../../utils/valid-email-util'
    import {security} from '../../utils/validate-utils'
    import StrUtils from 'smarttech-identity-string-utils'
    import RequiredTag from '../RequiredTag'
    import {ErrorCodes} from '../../enums'
    import OrgRoleDialog from './OrgRoleDialog'
    import {orgSort} from '../../utils/organization-sorting-util'
    import {accessibilityLevelSort} from '../../utils/role-accessibility-sorting-util'
    import {ONLY_DSL} from '../../enums/feature-switch-constants'
    import {isEnabled} from '../../services/feature-switch'

    const MULTIPLE_ROLES = 'multipleRoles'
    const INACTIVE_USER = 'INACTIVE_USER'
    let orgSortByName = function (a, b) {
        return orgSort(a.name, b.name)
    }

    export default {
        name: 'AdminInfoDialog',
        components: {RequiredTag, OrgRoleDialog},
        data () {
            return {
                // Form related data
                openDialog: false,
                isInviteForm: true,
                loadingRoles: false,
                loading: false,
                rolesList: [],
                inlineEmailError: false,
                emailExistsError: false,
                invalidPermissionOrgs: {},
                orgNameMapping: {},
                // User related data
                firstName: '',
                lastName: '',
                email: '',
                orgsSelected: [],
                role: ADMIN,
                registrationPending: false,
                orgAndRoleMapping: {},
                // Validations
                validEmail: v => StrUtils.isBlank(v) ? true : ValidEmailUtil.isValid(v) || this.$t('googleClassroomModal.emailError'),
                xssCharacters: v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                // Edit Form Initial States (other fields not editable)
                initialRole: '',
                initialOrgAndRoleMapping: {}
            }
        },
        computed: {
            roleText() {
                return isEnabled(ONLY_DSL) ? this.$t('management.adminDialog.adminPortalRole') : this.$t('management.adminDialog.role')
            },
            userOrganizations () {
                let orgs = {
                    valid: [],
                    invalid: []
                }
                this.$store.getters.getDirectOrganizations.forEach(org => {
                    if (this.validateUserPermissionForOrg(org.id)) {
                        orgs.valid.push(org)
                        this.orgNameMapping[org.id] = org.name
                    } else {
                        orgs.invalid.push(org)
                    }
                })
                orgs.valid.sort(orgSortByName)
                orgs.invalid.sort(orgSortByName)
                return orgs
            },
            validAndInvalidOrgs () {
                return this.userOrganizations.valid.concat(this.userOrganizations.invalid)
            },
            allOrgsSelected () {
                if (this.isLoggedInUser()) {
                    return this.validAndInvalidOrgs.length === this.orgsSelected.length
                } else {
                    return this.userOrganizations.valid.length === this.orgsSelected.length
                }
            },
            selectAllIcon () {
                if (this.allOrgsSelected) {
                    return 'check_box'
                } else {
                    return 'check_box_outline_blank'
                }
            },
            validateInviteForm () {
                return this.validateEmail() && this.validateNameFields() && this.validateOrganizationSelection() &&
                    !this.emailExistsError && !this.inlineEmailError
            },
            validateEditForm () {
                return this.orgRoleSelectionUpdated()
            },
            totalOrgsWithRole () {
                return this.orgsSelected.length + Object.keys(this.invalidPermissionOrgs).length
            }
        },
        created () {
            this.getRolesForOrg()
            bus.$on('openAdminInformationModal', (modalDetails) => {
                this.isInviteForm = modalDetails.invite
                let admin = modalDetails.admin
                if (admin !== undefined) {
                    this.firstName = admin.firstName
                    this.lastName = admin.lastName
                    this.email = admin.email
                    this.registrationPending = admin.registrationPending
                    Object.values(admin.organizations).forEach(org => {
                        let validPermissionsForOrg = this.validateUserPermissionForOrg(org.orgId)
                        let canSelfRemove = this.isLoggedInUser()
                        if (validPermissionsForOrg || canSelfRemove) {
                            this.initialOrgAndRoleMapping[org.orgId] = {
                                orgId: org.orgId,
                                orgName: org.orgName,
                                role: org.role,
                                removeOnly: !validPermissionsForOrg
                            }
                            this.orgAndRoleMapping[org.orgId] = {
                                orgId: org.orgId,
                                orgName: org.orgName,
                                role: org.role,
                                removeOnly: !validPermissionsForOrg
                            }
                            this.orgsSelected.push(org.orgId)
                        } else {
                            this.invalidPermissionOrgs[org.orgId] = {orgId: org.orgId, orgName: org.orgName, role: org.role}
                        }
                    })
                    this.role = this.getRoleSelection()
                }
                this.openDialog = true
            })
        },
        watch: {
            '$route' () {
                this.resetAndCloseDialog()
            },
            'orgsSelected' (newSelected) {
                if (this.openDialog) {
                    let updatedOrgSelection = {}
                    newSelected.forEach(orgId => {
                        if (this.orgAndRoleMapping[orgId]) {
                            updatedOrgSelection[orgId] = this.orgAndRoleMapping[orgId]
                        } else if (this.isLoggedInUser() && this.initialOrgAndRoleMapping[orgId].removeOnly) {
                            updatedOrgSelection[orgId] = this.initialOrgAndRoleMapping[orgId]
                        } else {
                            updatedOrgSelection[orgId] = {
                                orgId: orgId,
                                orgName: this.orgNameMapping[orgId],
                                role: this.role === MULTIPLE_ROLES ? ADMIN : this.role,
                                removeOnly: false
                            }
                        }
                    })
                    this.orgAndRoleMapping = updatedOrgSelection
                    this.role = this.getRoleSelection()
                }
            },
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        methods: {
            constructAddRequest (request, org) {
                request.push({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    roleName: org.role,
                    orgId: org.orgId,
                    requestType: REQUEST_TYPE.ADD
                })
            },
            constructEditRequest (request, org) {
                request.push({
                    email: this.email,
                    roleName: org.role,
                    orgId: org.orgId,
                    requestType: REQUEST_TYPE.EDIT
                })
            },
            constructRemoveRequest (request, org) {
                this.lastAdminInOrgCheck(org)
                request.push({
                    email: this.email,
                    roleName: this.initialOrgAndRoleMapping[org.orgId].role,
                    orgId: org.orgId,
                    requestType: REQUEST_TYPE.REMOVE
                })
            },
            constructRequest (request) {
                let orgs = this.isLoggedInUser() ? this.validAndInvalidOrgs : this.userOrganizations.valid
                orgs.forEach(org => {
                    if (this.isAddedToOrg(org.id)) {
                        this.constructEditRequest(request, this.orgAndRoleMapping[org.id])
                    } else if (this.isRemovedFromOrg(org.id)) {
                        this.constructRemoveRequest(request, this.initialOrgAndRoleMapping[org.id])
                    } else if (this.isRoleChangedForOrg(org.id)) {
                        this.lastAdminInOrgCheck(this.orgAndRoleMapping[org.id])
                        this.constructEditRequest(request, this.orgAndRoleMapping[org.id])
                    }
                })
            },
            isAddedToOrg (orgId) {
                // Not in initial mapping, in final mapping : ADD/EDIT
                return !this.initialOrgAndRoleMapping[orgId] && this.orgAndRoleMapping[orgId]
            },
            isRemovedFromOrg (orgId) {
                // In initial mapping, not in final mapping : REMOVED
                return this.initialOrgAndRoleMapping[orgId] && !this.orgAndRoleMapping[orgId]
            },
            isRoleChangedForOrg (orgId) {
                // In initial mapping and in final mapping, different role : EDIT
                return this.initialOrgAndRoleMapping[orgId] &&
                    this.initialOrgAndRoleMapping[orgId].role !== this.orgAndRoleMapping[orgId].role
            },
            triggerRequest () {
                try {
                    let request = []
                    if (this.isInviteForm) {
                        Object.values(this.orgAndRoleMapping).forEach(org => {
                            this.constructAddRequest(request, org)
                        })
                    } else {
                        this.constructRequest(request)
                    }
                    this.loading = true
                    this.$store.dispatch('AddRemoveEditUsersFromOrganization', request).then(() => {
                        this.loading = false
                        this.resetAndCloseDialog()
                    }).catch(err => {
                        if (err.message === INACTIVE_USER) {
                            this.inlineEmailError = true
                            this.loading = false
                        } else {
                            this.handleError(err)
                        }
                    })
                } catch (err) {
                    this.handleError(err)
                }
            },
            // This method created in consideration of custom roles in the future where each organization can have different roles
            // We would want to only get the roles belonging to the selected organization in that case.
            // For now, it will just get roles if none are loaded since there are only 3 pre-defined roles.
            // Add @input="getRolesForOrg() to v-select for organization selection to make use of this.
            getRolesForOrg () {
                this.loadingRoles = true
                this.$store.dispatch('FetchRolesForOrganization').then(orgRoles => {
                    let translatedRoles = []
                    orgRoles.forEach(role => {
                        translatedRoles.push({
                            key: role.name,
                            name: this.getTranslatedRoleName(role.name),
                            description: this.getTranslatedRoleDescription(role.name)
                        })
                    })
                    this.rolesList = translatedRoles.sort(this.sortByAccessibility)
                    this.loadingRoles = false
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.dialogueError)
                    this.loadingRoles = false
                })
            },
            sortByAccessibility (a, b) {
                return accessibilityLevelSort(a.key, b.key)
            },
            getRoleSelection () {
                let roles = new Set()
                roles = this.getRoleSet(roles, false)
                if (roles.size > 1) {
                    return MULTIPLE_ROLES
                } else {
                    roles = this.getRoleSet(roles, true)
                    if (roles.size === 1) {
                        return roles.values().next().value
                    } else if (roles.size === 0) {
                        // Nothing selected, revert to default value of Admin
                        return ADMIN
                    } else {
                        return MULTIPLE_ROLES
                    }
                }
            },
            getRoleSet (roleSet, validPermissionOrgs) {
                Object.values(validPermissionOrgs ? this.orgAndRoleMapping : this.invalidPermissionOrgs).forEach(org => {
                    roleSet.add(org.role)
                })
                return roleSet
            },
            updateOrgRoleSelections () {
                Object.values(this.orgAndRoleMapping).forEach(org => {
                    if (!org.removeOnly) {
                        org.role = this.role
                    }
                })
            },
            openedUserHasRoleInInvalidOrg (orgId) {
                return this.invalidPermissionOrgs[orgId]
            },
            getOrgSelectionText (name) {
                let totalOrgs = this.userOrganizations.valid.length + this.userOrganizations.invalid.length
                let numOrgsForUser = this.orgsSelected.length + Object.keys(this.invalidPermissionOrgs).length

                if (numOrgsForUser === totalOrgs) {
                    return this.$t('management.adminDialog.allOrgs')
                } else if (numOrgsForUser === 1 && this.orgsSelected.length === 1) {
                    return name
                } else if (numOrgsForUser === 1 && this.orgsSelected.length === 0) {
                    return Object.values(this.invalidPermissionOrgs)[0].orgName
                } else if (numOrgsForUser === 0) {
                    return ''
                } else {
                    return this.$t('management.adminDialog.numOrgs', {quantity: numOrgsForUser})
                }
            },
            selectAllOrgs () {
                if (this.orgsSelected.length) {
                    this.orgsSelected = []
                } else {
                    this.userOrganizations.valid.forEach(org => {
                        this.orgsSelected.push(org.id)
                    })
                    if (this.isLoggedInUser()) {
                        this.userOrganizations.invalid.forEach(org => {
                            this.orgsSelected.push(org.id)
                        })
                    }
                }
            },
            getTranslatedRoleName (key) {
                return this.$t('management.adminRoles')[key]
                    ? this.$t('management.adminRoles')[key].name : key
            },
            getTranslatedRoleDescription (key) {
                if (isEnabled(ONLY_DSL)) {
                    return this.$t('management.adminRoles')[key]
                    ? this.$t('management.adminRoles')[key].descriptionDSL : ''
                }
                return this.$t('management.adminRoles')[key]
                    ? this.$t('management.adminRoles')[key].description : ''
            },
            // Functions for form validation
            handleEmailPaste (e) {
                // Stops the data from actually being pasted into box, we want to process it first then will assign then value
                e.stopPropagation()
                e.preventDefault()
                let clipboard = e.clipboardData || window.clipboardData
                let pasted = clipboard.getData('Text')

                this.email = security.sanitizeString(pasted).replace(/\s+/g, '')
            },
            validateUserPermissionForOrg (orgId) {
                return PermissionValidator.validateUserPermission(orgId, PERMISSIONS.ManageAdmins)
            },
            isLoggedInUser () {
                return !this.isInviteForm && PermissionValidator.isLoggedInUser(this.email)
            },
            orgRoleSelectionUpdated () {
                let initialOrgs = Object.keys(this.initialOrgAndRoleMapping)
                let finalOrgs = Object.keys(this.orgAndRoleMapping)
                if (initialOrgs.length !== finalOrgs.length) {
                    return true
                } else {
                    for (let i = 0; i < initialOrgs.length; i++) {
                        if (!this.orgAndRoleMapping[initialOrgs[i]]) {
                            return true
                        }
                        if (this.initialOrgAndRoleMapping[initialOrgs[i]].role !== this.orgAndRoleMapping[initialOrgs[i]].role) {
                            return true
                        }
                    }
                    return false
                }
            },
            validateNameFields () {
                return StrUtils.isNotBlank(this.firstName) && StrUtils.isNotBlank(this.lastName) && security.validate(this.firstName) &&
                    security.validate(this.lastName)
            },
            validateEmail () {
                return StrUtils.isNotBlank(this.email) && ValidEmailUtil.isValid(this.email) && security.validate(this.email)
            },
            validateOrganizationSelection () {
                return this.orgsSelected.length >= 1
            },
            lastAdminInOrgCheck (org) {
                if (this.initialOrgAndRoleMapping[org.orgId].role === ADMIN && !this.registrationPending &&
                    this.$store.getters.getAdminCountForOrgs[org.orgId] <= 1) {
                    let err = {
                        message: this.$t('management.removeAdmin.onlyAdminWarning', {organization: org.orgName}),
                        errorCode: ErrorCodes.DELETE_LAST_ADMIN_ERROR
                    }
                    throw err
                }
            },
            emailChanged () {
                this.emailExistsError = false
                this.inlineEmailError = false
            },
            openExistingUser () {
                if (this.emailExistsError) {
                    let existingAdmin = this.$store.getters.getAllLoadedAdmins[this.email.toLowerCase()]
                    this.resetAndCloseDialog()
                    bus.$emit('openAdminInformationModal', {
                        invite: false,
                        admin: existingAdmin
                    })
                }
            },
            checkUserExistence () {
                if (this.validateEmail(this.email) && this.$store.getters.getAllLoadedAdmins[this.email.toLowerCase()]) {
                    this.emailExistsError = true
                }
            },
            saveOrgRoleCustomization (customizedOrgRoles) {
                this.openDialog = true
                this.orgAndRoleMapping = customizedOrgRoles
                this.role = this.getRoleSelection()
            },
            openOrgRoleCustomization () {
                this.openDialog = false
            },
            closeOrgRoleCustomization () {
                this.openDialog = true
            },
            resetAndCloseDialog () {
                this.openDialog = false
                this.isInviteForm = true
                this.loadingRoles = false
                this.loading = false
                this.emailExistsError = false
                this.inlineEmailError = false
                this.invalidPermissionOrgs = {}
                this.orgNameMapping = {}
                this.firstName = ''
                this.lastName = ''
                this.email = ''
                this.orgsSelected = []
                this.role = ADMIN
                this.registrationPending = false
                this.orgAndRoleMapping = {}
                this.initialRole = ''
                this.initialOrgAndRoleMapping = {}
            },
            openAdminManagementInfo () {
                window.open(this.$LINKS.ADMIN_MANAGEMENT_SUPPORT)
            },
            handleError (err) {
                ErrorHandler.handleError(err, ErrorHandler.managementError)
                this.loading = false
                this.resetAndCloseDialog()
            }
        }
    }
</script>

<style lang="scss">
    @import "../../theming/dialog.scss";

    .dialog-header {
        word-break: break-word;
    }

    .dialog-component {
        padding: 0px 16px;
        overflow-wrap: break-word;
    }

    .role-description {
        outline: thin solid lightgray;
        padding: 10px 25px;
        margin-bottom: 0px;
        font-style: italic;
    }

    .view-only-text {
        padding-top: 5px;
    }

    .role-label-container {
        display: flex;
        justify-content: space-between;
    }

    .role-item-title {
        font-weight: 700;
    }

    .role-item-descriptor {
        font-style: italic;
        font-size: 14px;
        color: rgba(0,0,0,.54);
    }

    .inline-email-error {
        line-height: 1;
        font-size: 10px;
        position: relative;
        top: -10px;
    }

    .customization-disabled {
        pointer-events: none;
    }

    .help-icon {
        color:#159bd9 !important;
        padding-right:15px;
    }

    #role-dropdown-anchor .v-list-item {
        height: fit-content;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .dropdown-anchor {
        position: relative;
        top: -50px;
    }
</style>
