<template>
    <v-layout column>
        <v-card-title class="text-h5">
            {{ $t('addUsersModal.addUsersRetryCsvHeader') }}
        </v-card-title>

        <v-card-text>
            {{ $t('addUsersModal.addUsersCsvError') }}
        </v-card-text>

        <v-card-text>
            <v-layout>
                <v-flex xs6>
                    <input ref="csvFileInput"
                           type="file"
                           style="display:none"
                           @change="onCsvUpload"
                           accept=".csv">
                    <v-btn id="cancelAddUsers"
                           block outlined
                           :disabled="loading"
                           @click.native="cancel()">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                </v-flex>
                <v-flex xs6>
                    <v-btn id="addUsersByCSV"
                           block primary light
                           class="white--text"
                           :loading="loading"
                           :disabled="loading"
                           @click.native="triggerCsvFileInput()">
                        {{ $t('buttons.tryAgain') }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-layout>
</template>

<script>
    export default {
        props: ['loading'],
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            triggerCsvFileInput () {
                this.$refs.csvFileInput.click()
            },
            onCsvUpload () {
                let el = this.$refs.csvFileInput
                this.$emit('csv-upload', {files: el.files})
                el.value = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
