<template>
    <v-layout align-center justify-end fill-height wrap>
        <v-text-field v-model="search"
                      :placeholder="$t('users.searchText')"
                      text solo
                      class="search-option-single org-search">
        </v-text-field>
    </v-layout>
</template>

<script>
    export default {
        name: 'OrgManagementDropdown',
        computed: {
            search: {
                get () {
                    return this.$store.getters.getCurrentSearchOrgByNameValue
                },
                set (value) {
                    this.$store.commit('filterOrganizationsByName', value)
                }
            }
        }
    }
</script>

<style scoped>
    .org-search {
        max-width: 400px;
    }

</style>
