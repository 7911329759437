<template>
    <div class="activation-content-container">
        <v-layout column fill-height>
            <v-flex class="d-flex flex-column overflow-hidden normal-text">
                <div class="text-h6 text--center">{{ $t('activation.finalInformation.thank') }}</div>
                <div>{{ $t('activation.finalInformation.description') }}</div>
                <div class="font--bold text--center">{{ orgNameAssignedTo }}</div>
                <div>
                    {{ $t('activation.finalInformation.licenseKeyHelp') + ' ' + $t('activation.finalInformation.noteBody')}}
                </div>
                <div id="admin-container-top" style="height: 0px !important;"></div>
                <div v-show="adminsExist">{{ $t('activation.adminInformation.addAdmins') }}</div>
                <div class="admins-removed" v-show="showAllAdminsRemovedMessage" v-html="$t('activation.adminInformation.allAdminsRemoved')"></div>
                <div v-show="!adminsExist && !showAllAdminsRemovedMessage && errorCount === 0 && !adminsLoading"
                    class="no-admins">
                    {{ $t('activation.adminInformation.adminManagement') }}
                </div>
                <div v-show="adminsLoading">
                    <div class="loader text--center">
                        <v-progress-circular indeterminate size="40" color="#159bd9" ></v-progress-circular>
                    </div>
                </div>
                <div v-show="!adminsLoading">
                    <div v-show="adminsExist"
                        class="remove-all-container">
                        <div class="remove-all-btn"
                            @click="removeAll(true)">
                            {{ $t('buttons.removeAll') }}
                        </div>
                    </div>
                    <div id="admin-container"
                        v-show="adminsExist"
                        style="height: 250px; max-height: 250px;"
                        class="admin-list-container">
                        <role-selector v-for="admin in Object.values(roleMapping)"
                                    :key="admin.email"
                                    :id="admin.email"
                                    :roleMapping="roleMapping"
                                    :isValid="true">
                            <template v-slot:title>
                                <div class="admin-title-container">
                                    <v-icon class="remove-icon" color="#159bd9" @click="removeAdmin(admin.email)">remove_circle</v-icon>
                                    <p class="admin-name-container">{{ getFullName(admin.firstName, admin.lastName) }}</p>
                                </div>
                            </template>
                        </role-selector>
                    </div>
                </div>
                <div v-if="errorCount !== 0"
                    class="error-text pb-3"
                    :class="{'error-text-final': errorCount === maxErrorCount}"
                    v-html="getErrorMessage()">
                </div>
            </v-flex>
            <v-flex id="done-btn-container" class="button-container">
                <v-btn class="primary white--text claim-to-manage-button large btn--block done-btn"
                       v-on:click="close"
                       :disabled="loading || adminsLoading"
                       :loading="loading">
                    {{ $t('regionSetting.doneButton') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { MENU_STATE } from '../../../enums/subscription-menu-state.js'
    import RoleSelector from '../../Management/RoleSelector'
    import ErrorHandler from '../../../utils/error-handler'
    import { REQUEST_TYPE, ACCESSIBILITY_LEVEL } from '../../../enums/admin-management-constants'
    import { accessibilityLevelSort } from '../../../utils/role-accessibility-sorting-util'

    const MAX_ERROR_COUNT = 2

    export default {
        name: 'AddAdminInformation',
        components: { RoleSelector },
        data () {
            return {
                roleMapping: {},
                adminsLoading: false,
                loading: false,
                errorCount: 0,
                showAllAdminsRemovedMessage: false,
                storeRoleMapping: true,
                maxErrorCount: MAX_ERROR_COUNT
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            orgNameAssignedTo () {
                if (strUtils.isBlank(this.activation.selectedOrganizationName)) {
                    if (strUtils.isBlank(this.activation.userAccount.name)) {
                        return this.$t('activation.assignEntitlement.inputPlaceHolder')
                    }
                    return this.activation.userAccount.name
                }
                return this.activation.selectedOrganizationName
            },
            newOrgCreated () {
                return strUtils.isBlank(this.activation.selectedOrganizationId) && strUtils.isNotBlank(this.activation.userAccount.name)
            },
            adminsExist () {
                return Object.keys(this.roleMapping).length !== 0
            }
        },
        methods: {
            removeAll (clickEvent) {
                this.roleMapping = {}
                this.$delete(this.roleMapping, '')
                if (clickEvent) {
                    this.showAllAdminsRemovedMessage = true
                }
            },
            removeAdmin (email) {
                this.$delete(this.roleMapping, email)
                if (!this.adminsExist) {
                    this.showAllAdminsRemovedMessage = true
                }
            },
            getFullName (firstName, lastName) {
                return `${firstName} ${lastName}`
            },
            createEditRequest () {
                let request = []
                Object.values(this.roleMapping).forEach(admin => {
                    request.push({
                        email: admin.email,
                        roleName: admin.role,
                        orgId: admin.orgId,
                        requestType: REQUEST_TYPE.EDIT
                    })
                })
                return request
            },
            close () {
                if (this.adminsExist) {
                    this.loading = true
                    this.$store.dispatch('AddRemoveEditUsersFromOrganization', this.createEditRequest()).then(() => {
                        this.storeRoleMapping = false
                        this.exitClaimingProcess()
                    }).catch(() => {
                        this.errorCount++
                        if (this.errorCount === MAX_ERROR_COUNT) {
                            this.removeAll(false)
                        }
                        this.loading = false
                    })
                } else {
                    this.storeRoleMapping = false
                    this.exitClaimingProcess()
                }
            },
            exitClaimingProcess () {
                this.loading = false
                this.errorCount = 0
                this.showAllAdminsRemovedMessage = false
                this.roleMapping = {}
                this.$store.commit('cleanActivationMemory')
                this.$store.commit('setSubscriptionSelectedMenuOption', MENU_STATE.SUBSCRIPTION)
            },
            getErrorMessage () {
                return this.errorCount < MAX_ERROR_COUNT ? this.$t('activation.adminInformation.retrySubmission') : this.$t('activation.adminInformation.failedSubmission')
            },
            loadAdminsForUser () {
                this.$store.dispatch('FetchAdminsForUser').then(() => {
                    this.getNewActivationAdminList()
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.managementError)
                })
            },
            initializeAdminSelector () {
                this.adminsLoading = true
                if (this.$store.getters.isActivationAdminListLoaded) {
                    this.getExistingActivationAdminState()
                } else {
                    this.loadAdminsForUser()
                }
            },
            getLowerAccessibilityRole (a, b) {
                return ACCESSIBILITY_LEVEL[a] <= ACCESSIBILITY_LEVEL[b] ? a : b
            },
            sortByAccessibility (a, b) {
                return accessibilityLevelSort(a.role, b.role)
            },
            getExistingActivationAdminState () {
                let previousState = this.$store.getters.getExistingActivationAdminState
                this.errorCount = previousState.errorCount
                this.showAllAdminsRemovedMessage = previousState.showAllAdminsRemovedMessage
                previousState.adminList.forEach(admin => {
                    this.$set(this.roleMapping, admin.email, admin)
                })
                this.finishLoadingAdmins()
            },
            getNewActivationAdminList () {
                let adminList = []
                this.$store.getters.getAdminsOfAllOrgs.forEach(admin => {
                    let organizations = Object.values(admin.organizations)
                    let lowestRole = organizations[0].role
                    for (let i = 1; i < organizations.length; i++) {
                        lowestRole = this.getLowerAccessibilityRole(lowestRole, organizations[i].role)
                    }
                    adminList.push({
                        email: admin.email,
                        firstName: admin.firstName,
                        lastName: admin.lastName,
                        orgId: this.activation.userAccount.id,
                        role: lowestRole
                    })
                })
                adminList.sort(this.sortByAccessibility)
                adminList.forEach(admin => {
                    this.$set(this.roleMapping, admin.email, admin)
                })
                this.finishLoadingAdmins()
            },
            finishLoadingAdmins () {
                this.adjustAdminContainerHeight()
                this.adminsLoading = false
            },
            adjustAdminContainerHeight () {
                let newHeight = document.getElementById('done-btn-container').getBoundingClientRect().top -
                    document.getElementById('admin-container-top').getBoundingClientRect().bottom - 120
                let heightStr = `${newHeight}px`
                let container = document.getElementById('admin-container')
                container.style.height = heightStr
                container.style.maxHeight = heightStr
            }
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.adjustAdminContainerHeight)
            if (this.storeRoleMapping) {
                this.$store.commit('updateActivationAdminState', {
                    roleMapping: Object.values(this.roleMapping),
                    errorCount: this.errorCount,
                    showAllAdminsRemovedMessage: this.showAllAdminsRemovedMessage
                })
            }
        },
        mounted () {
            window.addEventListener('resize', this.adjustAdminContainerHeight)
            this.initializeAdminSelector()
        }
    }
</script>

<style scoped>
    .title {
        font-size: 28px;
        line-height: 1.07;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
        margin-bottom: 0px;
    }

    .thank-you {
        font-size: 18px;
    }

    .normal-text > div {
        font-size: 14px;
        line-height: 1.29;
        text-align: left;
        color: rgba(0, 1, 2, 0.7);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .loader {
        padding-top: 60px;
    }

    .done-btn {
        width: 575px;
    }

    .remove-all-container {
        padding-top: 5px;
        padding-bottom: 30px;
        width: 575px;
    }

    .remove-all-btn {
        right: 0px;
        color: #159bd9;
        float: right;
        line-height: 18px;
    }

    .remove-all-btn:hover {
        cursor: pointer;
    }

    .admin-list-container {
        overflow-y: auto;
        width: 575px;
    }

    .admin-title-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .remove-icon {
        padding-right: 10px;
    }

    .admin-name-container {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: bold;
    }

    .error-text {
        color: red !important;
        padding-top: 10px !important;
        width: 575px;
        font-size: 16px !important;
    }

    .error-text-final {
        padding-top: 45px !important;
    }

    .admins-removed {
        font-style: italic;
        padding-top: 50px !important;
    }

    .no-admins {
        width: 575px !important;
        padding-top: 130px !important;
    }

    .button-container {
        padding-bottom: 0;
        flex: 0 0 auto;
    }

</style>
