<template>
    <v-layout column>
        <v-card-title class="text-h6 font-weight-bold text--primary">{{ $t('googleClassroomModal.importAndSync') }}</v-card-title>
        <v-img
            :aspect-ratio="16/9"
            :height="70"
            contain
            :src="require('@/assets/google_sync.png')">
        </v-img>
        <v-card-text>
            <p><RequiredTag/>{{ $t('googleClassroomModal.provideInformation') + ':' }}</p>
        </v-card-text>
        <v-card-text>
            <v-layout class="radio-button-label">
                <v-radio-group v-model="useDefaultTeacherGroup">
                    <v-radio
                        v-bind:value=true
                        :disabled="loading">
                    </v-radio>
                </v-radio-group>
                <v-card-text class="radio-button-text">
                    {{ $t('googleClassroomModal.defaultTeachers') }}
                </v-card-text>
            </v-layout>
            <v-text-field
                v-bind:placeholder="$t('googleClassroomModal.sampleDomain')"
                :disabled="!useDefaultTeacherGroup || loading"
                v-model="domainName"
                flat
                solo
                @input="stripSpace(true)"
                class="input-text-field"
                maxlength="254"
                :rules="[xssCharacters, validDomain]">
            </v-text-field>
            <v-layout class="radio-button-label">
                <v-radio-group v-model="useDefaultTeacherGroup">
                    <v-radio
                        v-bind:value=false
                        :disabled="loading">
                    </v-radio>
                </v-radio-group>
                <v-card-text class="radio-button-text">
                    {{ $t('googleClassroomModal.groupEmail')}}
                </v-card-text>
            </v-layout>
            <v-text-field
                v-bind:placeholder="$t('googleClassroomModal.sampleEmail')"
                :disabled="useDefaultTeacherGroup || loading"
                v-model="groupKey"
                flat
                solo
                @input="stripSpace(false)"
                class="input-text-field"
                maxlength="254"
                :rules="[xssCharacters, validEmail]">
            </v-text-field>
        </v-card-text>
        <v-card-text>
            <p class="provision-message">
                <b>{{ $t('addUsersModal.note') }}</b>
                {{ $t('addUsersModal.provisionsMessage') }}
            </p>
            <a :href="$LINKS.GOOGLE_PROVISION_SUPPORT" target="_blank" class="learn-more">{{ $t('googleClassroomModal.learnMore') }}
                <i class="material-icons icon icon-open learn-more-icon">launch</i>
            </a>
        </v-card-text>
        <v-card-text v-if="useStackedButtons">
            <v-btn
                id="authorizeSync"
                block primary light
                class="white--text"
                :loading="loading"
                :disabled="loading || validateForm"
                @click="authorizeGoogleSync()">
                <span>{{ $t('googleClassroomModal.authorizeSync') }}</span>
                <br>
                <i class="material-icons icon icon-open">launch</i>
            </v-btn>
            <v-btn
                id="cancelGoogleSync"
                block
                outlined
                :disabled="loading"
                @click.native="cancel()"
            >{{ $t('buttons.cancel') }}</v-btn>
        </v-card-text>
        <v-card-text v-else>
            <v-layout>
                <v-flex md6>
                    <v-btn
                        id="cancelGoogleSync"
                        block
                        outlined
                        class="block-button"
                        :disabled="loading"
                        @click.native="cancel()"
                    >{{ $t('buttons.cancel') }}</v-btn>
                </v-flex>
                <v-flex md6>
                <v-btn
                    id="authorizeSync"
                    block primary light
                    class="block-button white--text"
                    :loading="loading"
                    :disabled="loading || validateForm"
                    @click="authorizeGoogleSync()">
                    <span>{{ $t('googleClassroomModal.authorizeSync') }}</span>
                    <br>
                    <i class="material-icons icon icon-open">launch</i>
                </v-btn>
            </v-flex>
            </v-layout>
        </v-card-text>
    </v-layout>
</template>

<script>
    import ValidEmailUtil from '../../utils/valid-email-util'
    import {security} from '../../utils/validate-utils'
    import StrUtils from 'smarttech-identity-string-utils'
    import {Sources} from '../../enums/'
    import ModalMixin from '../../mixins/sync-modal-mixin'
    import RequiredTag from '../RequiredTag'

    export default {
        props: ['loading'],
        name: 'ClassroomAddUsers',
        mixins: [ModalMixin],
        components: {RequiredTag},
        data () {
            return {
                useDefaultTeacherGroup: true,
                domainName: '',
                groupKey: '',
                defaultGroupKey: 'classroom_teachers',
                validEmail: v => StrUtils.isBlank(v) ? true : ValidEmailUtil.isValid(v) || this.$t('googleClassroomModal.emailError'),
                validDomain: v => StrUtils.isBlank(v) ? true : ValidEmailUtil.isValidDomain(v) || this.$t('googleClassroomModal.domainError'),
                xssCharacters: v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters')
            }
        },
        computed: {
            validateForm () {
                if (this.useDefaultTeacherGroup) {
                    return StrUtils.isBlank(this.domainName) ? true : !ValidEmailUtil.isValidDomain(this.domainName)
                } else {
                    return StrUtils.isBlank(this.groupKey) ? true : !ValidEmailUtil.isValid(this.groupKey)
                }
            }
        },
        methods: {
            stripSpace (defaultTeacherGroup) {
                if (defaultTeacherGroup) {
                    this.domainName = this.domainName.replace(/\s+/g, '')
                } else {
                    this.groupKey = this.groupKey.replace(/\s+/g, '')
                }
            },
            cancel () {
                this.$emit('cancel-dialog')
            },
            authorizeGoogleSync () {
                let classroomInfo = {
                    source: Sources.SIS_SOURCES.Google
                }
                if (this.useDefaultTeacherGroup) {
                    let constructedGroupKey = this.defaultGroupKey + '@' + this.domainName
                    classroomInfo.groupKey = constructedGroupKey
                } else {
                    classroomInfo.groupKey = this.groupKey
                }

                let googleDialog = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,width=620,height=750')
                classroomInfo.windowRef = googleDialog
                this.$emit('sync-thirdParty', classroomInfo)
            }
        }
    }
</script>

<style>
    .learn-more {
        color: #159bd9;
        text-decoration: none;
    }

    .learn-more:hover {
        text-decoration: underline;
    }

    .learn-more-icon {
        font-size: 16px !important;
        color: #159bd9 !important;
    }

    .provision-message {
        padding-bottom: 5px;
    }

    .radio-button-text {
        padding: 5px 10px !important;
    }

    .input-text-field {
        padding-left: 38px !important;
    }

    .v-input--selection-controls {
        margin-top: 4px !important;
        margin-right: -30px !important;;
    }

    .radio-button-label {
        max-height: 48px !important;
    }

    .v-radio {
        margin-right: 20px;
    }

    .block-button {
        max-width: 100%;
    }
</style>
