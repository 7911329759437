import * as SortProps from '../enums/sort-props'

let comparitors = {}

comparitors[SortProps.NAME] = function (a, b, descMultiplier, secondSortProperty) {
    if (secondSortProperty && b.name === a.name) {
        return comparitors[secondSortProperty](a, b, descMultiplier)
    }
    return descMultiplier * a.name.localeCompare(b.name)
}

comparitors[SortProps.ORG] = function (a, b, descMultiplier) {
    return descMultiplier * a.accountName.localeCompare(b.accountName)
}

comparitors[SortProps.USAGE] = function (a, b, descMultiplier) {
    if (a.isLegacy) {
        return 1
    } else if (b.isLegacy) {
        return -1
    }
    let seatsRemainingA = a.quantity - a.assignedUsersCount
    let seatsRemainingB = b.quantity - b.assignedUsersCount
    return descMultiplier * (seatsRemainingB - seatsRemainingA)
}

comparitors[SortProps.SUBSCRIPTION_ID] = function (a, b, descMultiplier) {
    return descMultiplier * a.msdSubscriptionNumber.localeCompare(b.msdSubscriptionNumber)
}

comparitors[SortProps.EXPIRY] = function (a, b, descMultiplier, secondSortProperty) {
    if (a.isLegacy || b.isLegacy) {
        if (!a.status) {
            return 1
        } else if (!b.status) {
            return -1
        }
    }
    if (secondSortProperty && b.nextRenewalDate === a.nextRenewalDate) {
        return comparitors[secondSortProperty](a, b, descMultiplier)
    }
    return descMultiplier * (a.nextRenewalDate - b.nextRenewalDate)
}

export default comparitors
