export const encodeQueryParams = function (dict) {
    return Object.keys(dict).filter(key => dict[key]).map(key => {
        const encodedKey = encodeURIComponent(key)
        const encodedValue = encodeURIComponent(dict[key])
        return `${encodedKey}=${encodedValue}`
    }).join('&')
}

export const buildPath = function (path, replacements = {}, queryParams = {}) {
    let fullUrl = path
    let pathParams = Object.getOwnPropertyNames(replacements)

    for (let i = 0; i < pathParams.length; i++) {
        let param = pathParams[i]
        fullUrl = fullUrl.replace('{' + param + '}', replacements[param])
    }

    const queryParamsStr = encodeQueryParams(queryParams)
    if (queryParamsStr) {
        fullUrl = `${fullUrl}?${queryParamsStr}`
    }

    return fullUrl
}
