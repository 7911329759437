<template>
    <v-dialog v-model="showModal" persistent>
        <v-card>
            <v-card-title class='text-h6 font-weight-bold text--primary title'>
                {{ title }}
            </v-card-title>
            <v-card-text v-if="showResellersText" v-html="resellersText"></v-card-text>
            <v-card-text v-else>
                <p v-html="announcement"></p>
                <br>
                <p v-html="claimInformation"></p>
            </v-card-text>
            <v-card-text>
                <v-layout>
                    <v-flex>
                        <v-btn v-if="step < totalSteps" class='white--text darken-1' block primary @click.native="nextStep">
                            {{ this.$t('buttons.next') }}
                        </v-btn>
                        <v-btn v-else class='white--text darken-1' block primary @click.native="closeModal">
                            {{ this.$t('buttons.gotIt') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {isEnabled} from '../../services/feature-switch.js'
import {SHOW_RELEASE_NOTIFICATION, SHOW_COMING_SOON_NOTIFICATION} from '../../enums/feature-switch-constants'
import ErrorHandler from '../../utils/error-handler'
import {DialogStates} from '../../enums'

export default {
    data () {
        return {
            showModal: false,
            seenSaasyComingSoonNotification: false,
            seenSaasyReleaseNotification: false,
            step: 1
        }
    },
    mounted() {
        this.fetchUserSettings()
    },
    computed: {
        totalSteps () {
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION) && !isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                return 1
            }
            return 2
        },
        title () {
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION) && !isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                return this.$t('management.comingSoonHybridNotification.title')
            }
            return this.step === 1 ? this.$t('onboarding.modal.reseller.title') : this.$t('management.releaseHybridNotification.title')
        },
        announcement () {
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION) && !isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                return this.$t('management.comingSoonHybridNotification.announcement', {monthDayYear: 'January 1st, 2077'})
            }
            return this.$t('management.releaseHybridNotification.announcement')
        },
        claimInformation () {
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION) && !isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                return this.$t('management.comingSoonHybridNotification.activateInformation')
            }
            return this.$t('management.releaseHybridNotification.activateInformation')
        },
        showResellersText() {
            return isEnabled(SHOW_RELEASE_NOTIFICATION) && this.step === 1
        },
        resellersText() {
            const link = this.$LINKS.PARTNER_PORTAL
            return this.$t('onboarding.modal.reseller.description', { link })
        },
        hasSeenRequiredNotifications () {
            if (isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                return this.seenSaasyReleaseNotification
            }
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION)) {
                return this.seenSaasyComingSoonNotification
            }
            return true
        }

    },
    methods: {
        async fetchUserSettings () {
            try {
                await this.$store.dispatch('FetchUserSettings')
            } catch (err) {
                ErrorHandler.handleError(err, ErrorHandler.dialogueError)
                return
            }
            this.seenSaasyComingSoonNotification = this.$store.state.userSettings.seenSaasyComingSoonNotification
            this.seenSaasyReleaseNotification = this.$store.state.userSettings.seenSaasyReleaseNotification

            // if the user has already seen both or release notifications then change dialog state to hybrid resolved
            if (this.hasSeenRequiredNotifications) {
                this.$store.commit('ChangeDialogState', DialogStates.HYBRID_RESOLVED)
                return
            }
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
            this.updateUserSettings()
            this.$store.commit('ChangeDialogState', DialogStates.HYBRID_RESOLVED)
        },
        nextStep () {
            this.step++
        },
        updateUserSettings () {
            let settings = {}
            if (isEnabled(SHOW_RELEASE_NOTIFICATION)) {
                this.seenSaasyReleaseNotification = true
                settings.seenSaasyReleaseNotification = true
            }
            if (isEnabled(SHOW_COMING_SOON_NOTIFICATION)) {
                this.seenSaasyComingSoonNotification = true
                settings.seenSaasyComingSoonNotification = true
            }
            this.$store.dispatch('UpdateUserSettings', settings)
        }
    }
}
</script>

<style scoped lang="scss">
.title {
word-break: normal;
overflow-wrap: anywhere;
}
</style>
