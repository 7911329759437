<template>
    <div class="claim-information-hybrid">
        <v-layout fill-height column justify-center class="content-container">
            <v-flex class="top-container mb-3">
                <div class="common-text">
                    <div class="text--center thank-you">
                        {{ $t('activation.finalInformation.thank') }}
                    </div>
                    <div>
                        {{ $t('activation.finalInformation.descriptionHybrid') }}
                    </div>
                    <div class="text--center org-name medium-font">
                        <strong>{{ orgNameAssignedTo }}</strong>
                    </div>
                    <div>
                        <strong>{{ $t('activation.finalInformation.note') }}</strong>
                        <span v-html="noteText"></span>
                    </div>
                </div>
                <div v-if="isEducationSoftware" class="educational-text pt-15">
                    <v-layout justify-center class="pb-10">
                        <v-flex xs1><img :src="icons.loading"></v-flex>
                        <v-flex xs1 align-end>
                            <div class="d-flex justify-end pr-2">
                                <span class="medium-font pt-5">1.</span>
                            </div>
                        </v-flex>
                        <v-flex xs10 class="pt-5">
                            <div class="medium-font pb-2">
                                 {{ $t('activation.finalInformation.stepOne.title') }}
                            </div>
                            <div>
                                 {{ $t('activation.finalInformation.stepOne.description', {email: activationEmail}) }}
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout justify-center>
                        <v-flex xs1><img :src="icons.addUsers"/></v-flex>
                        <v-flex xs1 align-end>
                            <div class="d-flex justify-end pr-2">
                                <span class="medium-font pt-5">2.</span>
                            </div>
                        </v-flex>
                        <v-flex xs10 class="pt-5">
                            <div class="medium-font pb-2" v-html="$t('activation.finalInformation.stepTwo')"></div>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
            <v-flex class="button-container">
                <v-btn class="primary white--text large btn--block bottom-button" @click="close">
                    {{ $t('buttons.goToManageSoftware') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { MENU_STATE } from '../../../enums/subscription-menu-state.js'
    import { LINKS } from '../../../config'
    import addUsers from '../../../assets/add_users.svg'
    import loadingIcon from '../../../assets/loading-icon.svg'
    import { EDUCATION_SW_PART_NUMBERS } from '../../../services/regex-patterns'

    export default {
        name: 'ClaimInformationHybrid',
        data() {
            return {
                icons: {
                    loading: loadingIcon,
                    addUsers: addUsers
                }
            }
        },
        methods: {
            ...mapMutations([
                'setSubscriptionSelectedMenuOption',
                'cleanActivationMemory'
            ]),
            close () {
                this.cleanActivationMemory()
                this.setSubscriptionSelectedMenuOption(MENU_STATE.SUBSCRIPTION)
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            ...mapGetters([
                'userProfile'
            ]),
            orgNameAssignedTo () {
                if (strUtils.isBlank(this.activation.selectedOrganizationName)) {
                    if (strUtils.isBlank(this.activation.userAccount.name)) {
                        return this.$t('activation.assignEntitlement.inputPlaceHolder')
                    }
                    return this.activation.userAccount.name
                }
                return this.activation.selectedOrganizationName
            },
            isEducationSoftware() {
                return !!this.activation.productList.find(product => EDUCATION_SW_PART_NUMBERS.test(product.PartNumber))
            },
            noteText() {
                const SMARTSupportLink = `<a href="${LINKS.SUPPORT}" target="_blank">${this.$t('activation.smartSupport')}</a>`
                return this.$t('activation.finalInformation.noteNewSoftware', {SMARTSupport: SMARTSupportLink})
            },
            activationEmail() {
                return this.userProfile.email
            }
        },
        beforeDestroy () {
            this.close()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../theming/main.scss";

    $container-width: 575px;
    $education-sw-margin: 30px;

    .claim-information-hybrid {
        height: 100%;
        max-width: calc(#{$container-width} + 2 * #{$education-sw-margin});
    }

    .content-container {
        color: $pallete-dark-gray;
        line-height: 1.29;
    }

    .thank-you {
        font-size: 28px;
        margin-bottom: 50px;
    }

    .medium-font {
        font-size: 18px;
    }

    .common-text {
        padding: 0 $education-sw-margin;
    }

    .top-container {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .button-container {
        flex: 0 0 auto;
        padding: 0 $education-sw-margin;
        .bottom-button {
            width: 575px;
            height: 57px !important;
            font-size: 24px;
        }
    }

    .org-name {
        padding-top: 10px;
        padding-bottom: 30px;
    }

</style>
