import { ENDPOINTS, PATHS, UNIT_ID } from './endpoint-constants'

// This is the value of the query parameter included in the API URL.
const INTERNAL_IDENTIFIER_PARAM = UNIT_ID

export default {
    [ENDPOINTS.ADMIN_MANAGEMENT]: `/api/v3/${PATHS.ADMIN_MANAGEMENT}`,
    [ENDPOINTS.CLAIM_ENTITLEMENT]: `/api/v3/${PATHS.CLAIM_ENTITLEMENT}`,
    [ENDPOINTS.CLIENT_CONNECTION]: `/api/v3/${PATHS.CLIENT_CONNECTION}`,
    [ENDPOINTS.DISCONNECT_SUBSCRIPTION]: `/api/v3/${PATHS.DISCONNECT_SUBSCRIPTION}`,
    [ENDPOINTS.EXPERIENCES]: `/api/${PATHS.EXPERIENCES}`,
    [ENDPOINTS.FEATURE_SWITCHES]: `/api/${PATHS.FEATURE_SWITCHES}`,
    [ENDPOINTS.LEGACY_SUBSCRIPTIONS]: `/api/v3/${PATHS.LEGACY_SUBSCRIPTIONS}`,
    [ENDPOINTS.ORGANIZATIONS]: `/api/v3/${PATHS.ORGANIZATIONS}`,
    [ENDPOINTS.ORGANIZATIONS_RECENT]: `/api/${PATHS.ORGANIZATIONS_RECENT}`,
    [ENDPOINTS.RELATED_LEGACY_SUBSCRIPTIONS]: `/api/v3/${PATHS.LEGACY_SUBSCRIPTIONS}`,
    [ENDPOINTS.RELATED_ORGANIZATIONS]: `/api/v3/${PATHS.ORGANIZATIONS}`,
    [ENDPOINTS.RELATED_SUBSCRIPTIONS]: `/api/${PATHS.SUBSCRIPTIONS}`,
    [ENDPOINTS.SIGN_IN_ERROR]: `#/${PATHS.SIGN_IN_ERROR}`,
    [ENDPOINTS.STATUS]: `/api/${PATHS.STATUS}`,
    [ENDPOINTS.SUBSCRIPTION]: `/api/v3/${PATHS.SUBSCRIPTION}`,
    [ENDPOINTS.SUBSCRIPTIONS]: `/api/v3/${PATHS.SUBSCRIPTIONS_POST}`,
    [ENDPOINTS.SYNC_TEACHERS]: `/api/v3/${PATHS.SYNC_TEACHERS}`,
    [ENDPOINTS.UPDATE_SF_COUNTER]: `/api/v3/${PATHS.UPDATE_SF_COUNTER}`,
    [ENDPOINTS.USER_SETTINGS]: `/api/${PATHS.USER_SETTINGS}`,
    [ENDPOINTS.USERS]: `/api/v3/${PATHS.USERS}`,
    [ENDPOINTS.VALIDATE_ENTITLEMENT_CLAIM]: `/api/v3/${PATHS.VALIDATE_ENTITLEMENT_CLAIM}`,
    [ENDPOINTS.DISTRICT_LIBRARY]: `/api/${PATHS.DISTRICT_LIBRARY}`,
    [ENDPOINTS.UPDATE_ALLOW_UPLOAD]: `/api/${PATHS.UPDATE_ALLOW_UPLOAD}`,
    [ENDPOINTS.GENERATE_LICENSE_KEY]: `/api/v3/${PATHS.GENERATE_LICENSE_KEY}`,
    INTERNAL_IDENTIFIER_PARAM
}
