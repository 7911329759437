import { createApiClient } from '../services/api-client'

export default {
    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    executeRequest: (organizationId, nextToken, listener) => {
        const apiClient = createApiClient()
        apiClient.getSubscriptionsForOrganization(organizationId, nextToken)
            .then((result) => {
                let response = {
                    orgId: organizationId,
                    data: result.json,
                    nextToken: result.nextToken,
                    isLegacy: false,
                    error: false
                }

                if (listener) {
                    listener(response)
                }
            })
            .catch(error => {
                let response = {
                    orgId: organizationId,
                    data: error,
                    isLegacy: false,
                    error: true
                }

                if (listener) {
                    listener(response)
                }
            })
    },
    executeRequestHybrid: (organizationsIds, nextToken, listener) => {
        const apiClient = createApiClient()
        apiClient.getSubscriptionsForOrganizations(organizationsIds, nextToken).then((result) => {
            organizationsIds.forEach((orgId, index) => {
                // If an organization doesn't have subscriptions, the response won't return that organization.
                // This might change in the future. See slack thread: https://smarttechnologies.slack.com/archives/C043ZDVG3MZ/p1697039452668429?thread_ts=1697027883.558869&cid=C043ZDVG3MZ
                const orgSubscriptions = result.json.find(org => org.id === orgId)
                const data = orgSubscriptions ? [...orgSubscriptions.sfSubscriptions, ...orgSubscriptions.msdSubscriptions] : []
                const response = {
                    orgId,
                    data,
                    nextToken: result.nextToken,
                    isLegacy: false,
                    error: false,
                    remainingOrgsInBatch: index !== organizationsIds.length - 1
                }
                listener && listener(response)
            })
        }).catch(error => {
            // If none of the organizations in the request has subscriptions, although the call is successful, FUSE returns 404.
            // We should treat it as a successful response in order to avoid error messages to the user when there shouldn't be.
            // This might change in the future. See slack thread: https://smarttechnologies.slack.com/archives/C043ZDVG3MZ/p1697039452668429?thread_ts=1697027883.558869&cid=C043ZDVG3MZ
            if (error.statusCode === 404) {
                organizationsIds.forEach((id, index) => {
                    const response = {
                        orgId: id,
                        data: [],
                        remainingOrgsInBatch: index !== organizationsIds.length - 1
                    }

                    listener && listener(response)
                })
            } else if (listener) {
                listener({ data: error, error: true })
            }
        })
    }
}
