<template>
    <div/>
</template>

<script>
    import ErrorHandler from '../utils/error-handler'

    export default {
        mounted () {
            ErrorHandler.handleError({
                errorGroup: this.$route.query.errorGroup,
                traceId: this.$route.query.traceId,
                errorCode: this.$route.query.errorCode,
                message: this.$route.query.message
            })
        },

        created: function () {
            this.$store.commit('error', true)
        }
    }
</script>
