export const TIER_TYPES = {
    SPARK: 'SPARK',
    BASE: 'BASE'
}

export const TIER_NAMES = {
    SPARK: 'Lumio Spark Plan',
    BASE: 'Lumio Standard Plan'
}

// The order in which tiers are sorted in the BE is inconsistent, so we need to sort them on the FE following this order.
// If more tiers are added in the future, their order must be added here as well.
export const TIERS_DISPLAY_ORDER = [TIER_TYPES.SPARK, TIER_TYPES.BASE]

export const NON_MIGRATED_EDU_SOFTWARE_NAME = 'Lumio Standard Plan & SMART Notebook Plus'
