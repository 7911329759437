<template>
    <v-dialog v-model="openDialog" persistent width="30%" :disabled="selected.length === 0">
        <template v-slot:activator="{ on }">
            <v-btn id="openRemoveAdmin" v-on="on" class="white--text" color="red" :disabled="selected.length === 0">
                {{ $t('management.menu.remove') }}
            </v-btn>
        </template>
        <v-card id="removeAdminModal">
            <v-card-title class="text-h6 font-weight-bold text--primary">{{ $t('management.removeAdmin.remove') }}</v-card-title>
            <div>
                <v-card-text v-if="selected.length === 1" class="text--secondary">
                    {{ $t('management.removeAdmin.singleAdmin') }}
                </v-card-text>

                <v-card-text v-else>
                    {{ $t('management.removeAdmin.multipleAdmins', { quantity: selected.length}) }}
                </v-card-text>
            </div>
            <v-card-text>
                <v-layout>
                    <v-flex xs6>
                        <v-btn id="cancelRemoveAdmin"
                               outlined block
                               :disabled="loading"
                               @click="closeDialog()">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn id="removeAdmin"
                               error block
                               class="white--text"
                               style="border-color: red !important; background-color: red !important"
                               :loading="loading"
                               :disabled="loading"
                               @click="removeSelectedAdmins()">
                            {{ $t('buttons.remove') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ErrorHandler from '../../utils/error-handler'
    import {bus} from '../../event-bus'
    import {ErrorCodes} from '../../enums'
    import {REQUEST_TYPE, ADMIN} from '../../enums/admin-management-constants'

    export default {
        name: 'RemoveAdminDialog',
        data () {
            return {
                openDialog: false,
                loading: false
            }
        },
        computed: {
            selected () {
                return this.$store.getters.getSelectedAdminsInAdminTable
            }
        },
        methods: {
            closeDialog () {
                this.loading = false
                this.openDialog = false
            },
            removeSelectedAdmins () {
                try {
                    let changeRequest = []
                    this.selected.forEach(admin => {
                        admin.orgRoles.forEach(org => {
                            if (org.role === ADMIN && !admin.registrationPending) {
                                this.lastAdminInOrgCheck(org)
                            }
                            changeRequest.push({
                                email: admin.email,
                                orgId: org.orgId,
                                roleName: org.role,
                                requestType: REQUEST_TYPE.REMOVE
                            })
                        })
                    })
                    this.loading = true
                    this.$store.dispatch('AddRemoveEditUsersFromOrganization', changeRequest).then(() => {
                        this.closeDialog()
                        this.showMessageDialog(this.$t('dialogs.success'))
                        bus.$emit('adminsRemoved')
                    }).catch(err => {
                        this.handleError(err)
                    })
                } catch (err) {
                    this.handleError(err)
                }
            },
            showMessageDialog (title, message, traceId, errorCode) {
                bus.$emit('openSelfClosingModal', {
                    title,
                    message,
                    traceId,
                    errorCode
                })
            },
            lastAdminInOrgCheck (org) {
                if (this.$store.getters.getAdminCountForOrgs[org.orgId] <= 1) {
                    let err = {
                        message: this.$t('management.removeAdmin.onlyAdminWarning', {organization: org.orgName}),
                        errorCode: ErrorCodes.DELETE_LAST_ADMIN_ERROR
                    }
                    throw err
                }
            },
            handleError (err) {
                this.closeDialog()
                ErrorHandler.handleError(err, ErrorHandler.managementError)
            }
        }
    }
</script>
