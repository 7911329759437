<template>
    <div class="activation-main-container" :class="{'activation-first-page': step===1}" >
        <v-icon class="exit-button" large v-if="showExitButton" v-on:click="close">clear</v-icon>
        <div class="activation-container-content">
            <v-layout align-center column justify-start fill-height>
                <v-flex v-if="step===1">
                    <claim-activation :step-id="1"></claim-activation>
                </v-flex>
                <v-flex v-else-if="step<5" class="flex-container">
                    <div class="steps-container">
                        <div class="header">{{ headerText }}</div>
                        <v-layout align-center class="stepper-step" justify-center>
                            <v-flex :class="{'stepper-complete': step > 1, 'stepper-current': step === 1}"
                                    @click="stepperNavigationGoTo(1)">
                                {{ $t('activation.componentTitle.initial') }}
                            </v-flex>
                            <v-flex class="arrow-container"><v-icon :class="['arrow-icon', {visible : step > 1}]">arrow_right_alt</v-icon></v-flex>
                            <v-flex :class="{'stepper-complete': step > 2, 'stepper-current': step === 2}"
                                    @click="stepperNavigationGoTo(2)">
                                {{ $t('activation.componentTitle.details') }}
                            </v-flex>
                            <v-flex class="arrow-container"><v-icon :class="['arrow-icon', {visible : step > 2}]">arrow_right_alt</v-icon></v-flex>
                            <v-flex :class="{'stepper-complete': step > 3, 'stepper-current': step === 3}"
                                    @click="stepperNavigationGoTo(3)">
                                {{ $t('activation.componentTitle.assignToOrg') }}
                            </v-flex>
                            <v-flex class="arrow-container"><v-icon :class="['arrow-icon', {visible : step > 3}]">arrow_right_alt</v-icon></v-flex>
                            <v-flex :class="{'stepper-complete': step > 4, 'stepper-current': step === 4}"
                                    @click="stepperNavigationGoTo(4)">
                                {{ $t('activation.componentTitle.review') }}
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="step-content-container overflow-y-hidden">
                        <extend-offer :step-id="2" v-if="step===2"></extend-offer>
                        <assign-entitlement :step-id="3" v-else-if="step===3"></assign-entitlement>
                        <submit-claim :step-id="4" v-else-if="step===4"></submit-claim>
                    </div>
                </v-flex>
                <div v-else-if="adminsLoading" class="spinner fill-height d-flex align-center">
                    <v-progress-circular indeterminate size="40" color="#159bd9"></v-progress-circular>
                </div>
                <v-flex v-else-if="showHybridView" class="flex-container">
                    <add-admin-information-hybrid v-if="newOrgCreated && step===5 && (nonEmptyAdminList || fetchAdminsError)"
                        :fetch-admins-error="fetchAdminsError">
                    </add-admin-information-hybrid>
                    <claim-information-hybrid v-else></claim-information-hybrid>
                </v-flex>
                <v-flex v-else class="flex-container">
                    <add-admin-information v-if="newOrgCreated"></add-admin-information>
                    <claim-information v-else></claim-information>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import AssignEntitlement from './AssignEntitlement.vue'
    import SubmitClaim from './SubmitClaim.vue'
    import ExtendOffer from './ExtendOffer.vue'
    import ClaimActivation from './ClaimActivation.vue'
    import ClaimInformation from './ClaimInformation.vue'
    import ClaimInformationHybrid from './ClaimInformationHybrid.vue'
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import StrUtils from 'smarttech-identity-string-utils'
    import AddAdminInformation from './AddAdminInformation.vue'
    import AddAdminInformationHybrid from './AddAdminInformationHybrid.vue'
    import { isEnabled } from '../../../services/feature-switch'
    import { HYBRID_VIEW } from '../../../enums/feature-switch-constants'

    export default {
        name: 'Activation',
        components: {
            ClaimActivation,
            ExtendOffer,
            AssignEntitlement,
            SubmitClaim,
            ClaimInformation,
            ClaimInformationHybrid,
            AddAdminInformation,
            AddAdminInformationHybrid
        },
        data () {
            return {
                adminsLoading: false,
                fetchAdminsError: false
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            ...mapGetters([
                'getAdminsOfAllOrgs',
                'isActivationAdminListLoaded'
            ]),
            step: {
                get () {
                    return this.activation.processSteps
                },
                set (value) {
                    if (value === this.activation.processSteps) {
                        return
                    }
                    this.setActivationProcessSteps(value)
                }
            },
            showHybridView() {
                return isEnabled(HYBRID_VIEW)
            },
            showExitButton() {
                return this.step > 1 && (this.step < 5 || !this.showHybridView)
            },
            newOrgCreated() {
                return StrUtils.isBlank(this.activation.selectedOrganizationId) && StrUtils.isNotBlank(this.activation.userAccount.name)
            },
            headerText() {
                if (this.showHybridView) {
                    return this.$t('activation.titleHybrid')
                }
                return this.$t('activation.title')
            },
            nonEmptyAdminList() {
                return this.getAdminsOfAllOrgs?.length > 0
            }
        },
        methods: {
            ...mapMutations([
                'cleanActivationMemory',
                'setActivationProcessSteps'
            ]),
            ...mapActions([
                'FetchAdminsForUser'
            ]),
            initializeAdminSelector () {
                if (!this.isActivationAdminListLoaded) {
                    this.adminsLoading = true
                    this.$store.dispatch('FetchAdminsForUser').catch(() => {
                        this.fetchAdminsError = true
                    }).finally(() => {
                        this.adminsLoading = false
                    })
                }
            },
            stepperNavigationGoTo (stepValue) {
                if (stepValue < this.step) {
                    this.step = stepValue
                }
            },
            close () {
                this.cleanActivationMemory()
            }
        },
        watch: {
            step(newVal) {
                if (newVal === 5 && this.newOrgCreated && this.showHybridView) {
                    this.initializeAdminSelector()
                }
            }
        }
    }
</script>
<style lang="scss">
    @import "../../../theming/main.scss";

    .activation-main-container {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        position: relative;
    }

    .exit-button {
        width: 30px;
        height: 25px;
        color: $pallete-offwhite !important;
        position: absolute !important;
        right: 40px;
        top: 40px;
        cursor: pointer;
        z-index: 2;
    }

    .activation-content-container {
        height: 100%;
        width: 100vw;
        max-width: 605px;
    }

    .button-container {
        width: 100%;
        padding: 10px 0px 10px 0px;
    }

    .button-container-absolute {
        position: absolute;
        bottom: 3vh;
        left:0;
        width: 100%;
        text-align: center !important;
    }

    .activation-button {
        width: 100%;
        max-width: 600px;
        max-height: 57px;
        height: 100% !important;
        margin: auto !important;
    }

    .claim-to-manage-button {
        width: 100%;
        max-width: 800px;
        max-height: 57px;
        height: 100% !important;
        margin: auto !important;
    }

    .activation-button.large, .claim-to-manage-button.large {
        height: 57px !important;
        font-size: 24px !important;
    }

    .activation-first-page {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: #f3f3f3 !important;
    }

    .header {
        font-size: 1.5em;
        padding-bottom: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
    }

    .sub-header {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
        padding: 24px 0px 20px 0px;
    }

    .description {
        text-align: center;
    }

    .left-align {
        text-align: left;
    }

    .right-align {
        text-align: right;
    }

    .center-align {
        text-align: center;
    }

    .remove-padding {
        padding: 0;
    }

    .disabled {
        color: rgba(0, 1, 2, 0.4);
    }

    .width-100 {
        width: 100%;
    }

    .height-100 {
        height: 100%;
    }

    .pos-relative {
        position: relative;
    }

    .pos-absolute {
        position: absolute !important;
    }

    .text-ellipsis {
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>

<style lang="scss" scoped>
    @import '../../../theming/main.scss';

    .stepper-complete {
        color: $pallete-dark-gray;
    }

    .stepper-complete:hover {
        color: #19a1d3;
        cursor: pointer;
    }

    .stepper-step {
        color: rgba(0, 1, 2, 0.3);
        padding-bottom: 6vh;
    }

    .stepper-current {
        color: $pallete-dark-gray;
        font-weight: bold;
    }

    .stepper-step > .flex:not(.arrow-container) {
        flex: 0 0 auto;
    }

    .arrow-container {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow-icon {
            opacity: 0;
            color: $pallete-offwhite;
            transform: scale(4, 0.8);
            &.visible {
                opacity: 1;
            }
        }
    }

    .activation-container-content {
        padding: 50px 0;
        height: 100%;
        min-width: 500px;
        border: 1px solid $theme-primary;
        background-color: $pallete-white;
        .flex-container {
            flex-direction: column;
            display: flex;
            height: 100%;
            flex: 1;
            .steps-container {
                padding: 0 40px;
                flex-grow: 0;
            }
            .step-content-container {
                flex-grow: 1;
            }
        }
    }

</style>
