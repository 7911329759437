import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import {defaultState} from './storeDefault'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: defaultState,
    getters: getters,
    actions: actions,
    mutations: mutations
})
