<template>
    <v-dialog v-model="openDialog" width="590px" persistent content-class="org-update-dialog-new">
        <v-card class="pa-0 d-flex flex-column overflow-y-hidden">
            <template v-if="showTabs">
                <v-tabs v-model="tab" fixed-tabs hide-slider class="flex-grow-0">
                    <v-tab value="information" class="font--bold">{{ $t('management.orgUpdateDialogNew.information') }}</v-tab>
                    <v-tab value="settings" class="font--bold">{{ $t('management.orgUpdateDialogNew.settings') }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="flex-grow-1 visible-scrollbar">
                    <v-card-title class="text-h6 font-weight-bold d-block text-center pt-6 px-12 pb-0">{{ originalOrg.name }}</v-card-title>
                    <v-tab-item key="information">
                        <org-information :open-form="{...orgFormProp, readOnly: isTechInstructor}" :role="originalOrg.role.name"/>
                    </v-tab-item>
                    <v-tab-item key="settings">
                        <org-library-settings
                            :allow-upload="allowUpload"
                            :is-spark-organization="isSparkOrganization"
                            :libraryId="districtLibrary.districtId"
                            @allow-upload-modified="onAllowUploadModified"/>
                    </v-tab-item>
                </v-tabs-items>
            </template>

            <template v-else>
                <v-card-title class="text-h6 font-weight-bold d-block text-center pt-6 px-12 pb-0">{{ originalOrg.name }}</v-card-title>
                <org-information class="visible-scrollbar" :open-form="{...orgFormProp, readOnly: true}" :role="originalOrg.role.name"/>
            </template>

            <div class="buttons-container py-7 px-11">
                <template v-if="showTabs">
                    <v-btn class="white--text my-0"
                        block text primary light
                        :disabled="confirmButtonDisabled"
                        @click="triggerRequest">
                        {{ $t('buttons.updateOrg') }}
                    </v-btn>
                    <v-btn class="cancel-btn v-btn-no-background my-1"
                        block
                        text
                        @click="warningLeaveDialog">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn class="white--text my-0"
                        block
                        text
                        primary
                        light
                        @click="$emit('close-dialog')">
                        {{ $t('buttons.close') }}
                    </v-btn>
                </template>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'
    import OrgLibrarySettings from './OrgLibrarySettings.vue'
    import OrgInformation from '../OrgInformation.vue'
    import { entitlement } from '../../utils/validate-utils'
    import ErrorHandler from '../../utils/error-handler'
    import { bus } from '../../event-bus'
    import { ADMIN, TECH_INSTRUCTOR } from '../../enums/admin-management-constants'

    export default {
        name: 'OrgUpdateDialogNew',
        components: {
            OrgLibrarySettings,
            OrgInformation
        },
        data () {
            return {
                tab: null,
                openDialog: false,
                originalOrg: {},
                districtLibrary: {},
                sentRequest: false,
                allowUpload: false,
                allowUploadSavedValue: false
            }
        },
        props: {
            org: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            ...mapGetters([
                'orgSparkSubscription',
                'getDistrictLibraryByOrg',
                'subscriptionsByOrganization'
            ]),
            isUpdatedOrgValid () {
                return this.isOrgUpdated &&
                    entitlement.validateCreatedOrganization(this.activation.userAccount)
            },
            orgFormProp () {
                return {
                    isFormOpen: this.openDialog,
                    source: 'updateOrg'
                }
            },
            confirmButtonDisabled () {
                return this.sentRequest || (!this.isUpdatedOrgValid && !this.allowUploadModified)
            },
            showTabs() {
                const role = this.originalOrg.role.name
                return role === ADMIN || role === TECH_INSTRUCTOR
            },
            isTechInstructor () {
                return this.originalOrg.role.name === TECH_INSTRUCTOR
            },
            allowUploadModified() {
                return this.allowUpload !== this.allowUploadSavedValue
            },
            isSparkOrganization() {
                return !!this.orgSparkSubscription(this.org.id)
            },
            isOrgUpdated () {
                return this.isElementUpdated('name') || this.isElementUpdated('address', 'address1') ||
                    this.isElementUpdated('city') || this.isElementUpdated('state') ||
                    this.isElementUpdated('country') || this.isElementUpdated('zipPostal') ||
                    this.isElementUpdated('phone') || this.isElementUpdated('industry') ||
                    this.isElementUpdated('region') || this.isElementUpdated('dataLocation')
            },
            isSubscriptionLoaded() {
                return !!this.subscriptionsByOrganization(this.org.id)
            }
        },
        watch: {
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            },
            // We need subscription to be loaded to know if its SPARK
            // If is not loaded yet wait until is done
            isSubscriptionLoaded: {
                immediate: true,
                handler(subscriptions) {
                    if (subscriptions) {
                        this.onDialogCreated()
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                'updateAllowUploadForDL'
            ]),
            onDialogCreated() {
                try {
                    this.$store.commit('setOrgInformation', this.org)
                    this.setLocalOrg(this.org)
                    if (this.showTabs && this.isSparkOrganization) {
                        this.districtLibrary = this.getDistrictLibraryByOrg(this.org.id)
                        const { allowUpload } = this.districtLibrary
                        this.allowUpload = allowUpload
                        this.allowUploadSavedValue = allowUpload
                    }
                    this.openDialog = true
                } catch (err) {
                    this.handleError(err)
                }
            },
            resetAndCloseDialog () {
                this.sentRequest = false
                this.$store.commit('setIsModalOpen', false)
                this.openDialog = false
                this.setLocalOrg({})
                this.$emit('close-dialog')
            },
            warningLeaveDialog() {
                this.openDialog = false
                if (this.isOrgUpdated || this.allowUploadModified) {
                    bus.$emit('openModal', {
                        title: this.$t('management.orgUpdateDialogNew.unsavedModal.title'),
                        message: this.$t('management.orgUpdateDialogNew.unsavedModal.description'),
                        primaryActionLabel: this.$t('buttons.leave'),
                        secondaryActionLabel: this.$t('buttons.stay'),
                        primaryButtonRed: true,
                        primaryAction: () => {
                            this.resetAndCloseDialog()
                        },
                        secondaryAction: () => {
                            this.openDialog = true
                        }
                    })
                } else {
                    this.resetAndCloseDialog()
                }
            },
            setLocalOrg (orgToUpdate) {
                this.originalOrg.id = orgToUpdate.id ? orgToUpdate.id : ''
                this.originalOrg.name = orgToUpdate.name ? orgToUpdate.name : ''
                this.originalOrg.address = orgToUpdate.address ? orgToUpdate.address : ''
                this.originalOrg.city = orgToUpdate.city ? orgToUpdate.city : ''
                this.originalOrg.country = orgToUpdate.country ? orgToUpdate.country : ''
                this.originalOrg.state = orgToUpdate.state ? orgToUpdate.state : ''
                this.originalOrg.zipPostal = orgToUpdate.zipPostal ? orgToUpdate.zipPostal : ''
                this.originalOrg.industry = orgToUpdate.industry ? orgToUpdate.industry : ''
                this.originalOrg.phone = orgToUpdate.phone ? orgToUpdate.phone : ''
                this.originalOrg.region = orgToUpdate.region ? orgToUpdate.region : ''
                this.originalOrg.dataLocation = orgToUpdate.dataLocation ? orgToUpdate.dataLocation : ''
                this.originalOrg.role = orgToUpdate.role ? orgToUpdate.role : ''
            },
            triggerRequest () {
                this.sentRequest = true
                try {
                    if (this.allowUploadModified) {
                        // TODO: Handle errors when API is ready
                        const { id, internalSubscriptionId } = this.orgSparkSubscription(this.org.id)
                        this.updateAllowUploadForDL({
                            extOrgId: this.org.id,
                            districtLibraryId: this.districtLibrary.districtId,
                            subId: internalSubscriptionId,
                            extSubId: id,
                            val: this.allowUpload
                        }).catch(err => {
                            this.handleError(err)
                        })
                    }
                    this.$store.dispatch('UpdateOrgInfo', this.createRequestBody())
                        .catch(err => {
                            this.handleError(err)
                        })

                    this.allowUploadSavedValue = this.allowUpload
                    this.resetAndCloseDialog()
                } catch (err) {
                    this.handleError(err)
                }
            },
            isElementUpdated (orgKey, userAccountKey) {
                return userAccountKey ? this.originalOrg[orgKey] !== this.activation.userAccount[userAccountKey]
                    : this.originalOrg[orgKey] !== this.activation.userAccount[orgKey]
            },
            createRequestBody () {
                const currentData = this.activation.userAccount
                const originalData = this.originalOrg

                let toBeUpdatedOrg = {
                    id: currentData.id,
                    ...(originalData.name !== currentData.name && { name: currentData.name}),
                    ...(originalData.address !== currentData.address1 && { address1: currentData.address1}),
                    ...(originalData.city !== currentData.city && { city: currentData.city}),
                    ...(originalData.country !== currentData.country && { country: currentData.country}),
                    ...(originalData.state !== currentData.state && { state: currentData.state}),
                    ...(originalData.zipPostal !== currentData.zipPostal && { zipPostal: currentData.zipPostal}),
                    ...(originalData.phone !== currentData.phone && { phone: currentData.phone}),
                    ...(originalData.industry !== currentData.industry && { industry: currentData.industry}),
                    ...(originalData.region !== currentData.region && { region: currentData.region}),
                    ...(originalData.dataLocation !== currentData.dataLocation && { dataLocation: currentData.dataLocation})
                }

                return toBeUpdatedOrg
            },
            handleError (err) {
                ErrorHandler.handleError(err, ErrorHandler.orgUpdateError)
                this.resetAndCloseDialog()
            },
            onAllowUploadModified(value) {
                this.allowUpload = value
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../theming/main.scss';
@import '../../theming/mixins.scss';
.org-update-dialog-new div {
    color:  $pallete-dark-gray;
}

.v-card {
    height: calc(100vh - 100px);
    background-color: $pallete-light-gray !important;
    & > * {
        background-color: $pallete-light-gray;
    }
}

.visible-scrollbar {
    @include visible-scrollbar;
}

.v-tab {
    background-color: $theme-primary;
    color: $pallete-white !important;
    text-transform: none;
    font-size: 18px !important;
    letter-spacing: normal;
}

.v-tab--active {
    background-color: $pallete-light-gray;
    color: #000102 !important;
    font-weight: 700;
}

.buttons-container {
    margin-top: auto;
    .v-btn {
        font-size: 16px;
        &:not(.cancel-btn, .v-btn--disabled) {
            background-color: $theme-primary;
        }

        &.v-btn--disabled {
            border: 2px solid $pallete-offwhite;
        }

        &.cancel-btn {
            color: $theme-primary;
        }
    }
}

</style>
