import Q from 'q'
import 'whatwg-fetch'

import orgUtil from '../utils/organization-util'
import { store } from '../store/store'
import DataValidator from 'smarttech-identity-data-validator'
import { validateUUID } from '../utils/validate-utils'

import { FUSE_API, HYBRID_VIEW, DEBUG_STOCKING_CLAIM_FLOW } from '../enums/feature-switch-constants.js'
import { isEnabled } from './feature-switch.js'
import { executeDelete, executeGet, executePost, executePut } from './http'
import endpoints from '../enums/endpoints'
import endpointsHybrid from '../enums/endpoints-hybrid'
import { buildPath } from '../utils/url-helper'
import {
    fakeLegacySubscriptionsResponse,
    fakeOrganizationsResponse,
    fakeSubscriptionsResponse,
    fakeUsersResponse
} from '../utils/fake-hybrid-data'
import { fakeValidateClaimResponse } from '../utils/fake-claim-code-data'

const USER_BATCH_SET_LIMIT = 100
const USER_BATCH_DELETE_LIMIT = 1000

export function createApiClient() {
    if (isEnabled(FUSE_API)) {
        return new ClientApi(endpointsHybrid)
    }
    return new ClientApi(endpoints)
}

const ClientApi = class {
    constructor(endpoints) {
        this.endpoints = endpoints
        this.internalIdentifierParam = endpoints.INTERNAL_IDENTIFIER_PARAM
    }

    getStatus() {
        return executeGet(this.endpoints.STATUS)
    }

    getDirectOrganizations(next) {
        let endpoint = this.endpoints.ORGANIZATIONS
        if (isEnabled(FUSE_API)) {
            if (next) {
                endpoint += `?next=${next}`
            }
        } else if (isEnabled(HYBRID_VIEW)) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(fakeOrganizationsResponse)
                }, 500)
            })
        } else {
            endpoint += '?type=' + orgUtil.constants.TYPE.DIRECT

            if (next) {
                endpoint += '&next=' + next
            }
        }
        return executeGet(endpoint)
    }

    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    getRelatedOrganizations(next) {
        let endpoint = this.endpoints.RELATED_ORGANIZATIONS
        endpoint += '?type=' + orgUtil.constants.TYPE.RELATED

        if (next) {
            endpoint += '&next=' + next
        }

        return executeGet(endpoint)
    }

    getRecentOrganizations() {
        if (isEnabled(HYBRID_VIEW) && !isEnabled(FUSE_API)) {
            return Promise.resolve({json: []})
        }
        return executeGet(this.endpoints.ORGANIZATIONS_RECENT)
    }

    cacheRecentOrg(recentOrg) {
        return executePut(this.endpoints.ORGANIZATIONS_RECENT, recentOrg)
    }

    getUserSettings() {
        return executeGet(this.endpoints.USER_SETTINGS)
    }

    updateUserSettings(userSettings) {
        return executePut(this.endpoints.USER_SETTINGS, userSettings)
    }

    getLegacySubscriptionsForOrganization(organizationId, next) {
        let apiLocation
        if (isEnabled(FUSE_API)) {
            apiLocation = this.endpoints.LEGACY_SUBSCRIPTIONS
        } else if (isEnabled(HYBRID_VIEW)) {
            return Promise.resolve(fakeLegacySubscriptionsResponse[organizationId])
        } else {
            let relatedOrganizationMap = store.getters.getRelatedOrganizationMap
            apiLocation = (organizationId in relatedOrganizationMap) ? this.endpoints.RELATED_LEGACY_SUBSCRIPTIONS : this.endpoints.LEGACY_SUBSCRIPTIONS
        }

        let endpoint = buildPath(apiLocation, {
            organizationId: organizationId
        }, {
            next
        })

        return executeGet(endpoint)
    }

    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    getSubscriptionsForOrganization(organizationId, next) {
        let relatedOrganizationMap = store.getters.getRelatedOrganizationMap
        let apiLocation = (organizationId in relatedOrganizationMap) ? this.endpoints.RELATED_SUBSCRIPTIONS : this.endpoints.SUBSCRIPTIONS
        let endpoint = buildPath(apiLocation, {
            organizationId: organizationId
        }, {
            next
        })

        return executeGet(endpoint)
    }

    getSubscriptionsForOrganizations(organizationIds, next) {
        if (isEnabled(HYBRID_VIEW) && !isEnabled(FUSE_API)) {
            return Promise.resolve(fakeSubscriptionsResponse)
        }
        let endpoint = this.endpoints.SUBSCRIPTIONS
        if (next) {
            endpoint += `?next=${next}`
        }
        return executePost(endpoint, organizationIds)
    }

    getUsersForSubscription(organizationId, subscriptionId, next, internalIdentifier) {
        if (isEnabled(HYBRID_VIEW) && !isEnabled(FUSE_API)) {
            return Promise.resolve(fakeUsersResponse)
        }

        let endpoint = buildPath(this.endpoints.USERS, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier,
            next
        })

        return executeGet(endpoint)
    }

    getSubscription(organizationId, subscriptionId) {
        if (!validateUUID(organizationId) || !validateUUID(subscriptionId)) {
            DataValidator.throwInternalError('invalid orgnization id or subscription id')
        }
        let endpoint = buildPath(this.endpoints.SUBSCRIPTION, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        })

        return executeGet(endpoint)
    }

    setUsersForSubscription(organizationId, subscriptionId, users, internalIdentifier) {
        let endpoint = buildPath(this.endpoints.USERS, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier
        })

        // send maximum 10 requests at the same time and wait for the result before sending next 10 requests
        let chunk = USER_BATCH_SET_LIMIT * 10
        var succeeded = []
        var failed = []
        var p = Promise.resolve({ succeeded, failed })
        for (let x = 0, y = users.length; x < y; x += chunk) {
            let usersBatch = (users.slice(x, x + chunk))
            // TODO: remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
            if (!isEnabled(FUSE_API)) {
                // we need to flatten the array of users into an array of emails which is what V2 expects
                usersBatch = usersBatch.map(user => {
                    return user.email
                })
            }

            p = p.then((result) => {
                succeeded = succeeded.concat(result.succeeded)
                failed = failed.concat(result.failed)

                return this.setUsersToSubscriptionBatch(endpoint, usersBatch)
            })
        }

        return p.then(result => {
            succeeded = succeeded.concat(result.succeeded)
            failed = failed.concat(result.failed)

            return this.updateSFCounter(organizationId, subscriptionId, internalIdentifier)
        }).then(() => {
            return {
                succeeded,
                failed
            }
        })
    }

    removeUsersFromSubscription(organizationId, subscriptionId, users, internalIdentifier) {
        if (!users.length) {
            DataValidator.throwInternalError('No users selected for removal')
        }

        let endpoint = buildPath(this.endpoints.USERS, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier
        })

        let chunk = USER_BATCH_DELETE_LIMIT * 10
        var p = Promise.resolve()
        for (let x = 0, y = users.length; x < y; x += chunk) {
            let userBatch = users.slice(x, x + chunk)

            p = p.then(() => this.removeUsersFromSubscriptionBatch(endpoint, userBatch))
        }

        return p.then(() => {
            this.updateSFCounter(organizationId, subscriptionId, internalIdentifier)
        })
    }

    validateClaimCodeOrSerialNumberClaim(claimCode, serialNumber, eligibleUnitsPurchased) {
        // Do a fake code validation whn debugging claim flows
        if (isEnabled(DEBUG_STOCKING_CLAIM_FLOW)) {
            return Promise.resolve(fakeValidateClaimResponse)
        }
        if (claimCode === '' && serialNumber === '') {
            DataValidator.throwInternalError('no claim available')
        }

        let endpoint = this.endpoints.VALIDATE_ENTITLEMENT_CLAIM
        return executePost(endpoint, {
            claimCode: claimCode,
            serialNumber: claimCode === '' ? serialNumber : '',
            eligibleUnitsPurchased: claimCode === '' ? eligibleUnitsPurchased : 0
        }, !!serialNumber)
    }

    claimEntitlement(body, claimCode, serialNumber) {
        if (claimCode === '' && serialNumber === '') {
            DataValidator.throwInternalError('no claim available')
        }

        let endpoint = this.endpoints.CLAIM_ENTITLEMENT
        return executePost(endpoint, body)
    }

    syncTeachers(organizationId, subscriptionId, sisOrganizationId, internalIdentifier, source) {
        let errMsg = 'Error cannot sync teachers'
        DataValidator.shouldNotBeBlank(subscriptionId, 'subscription id', errMsg)
        DataValidator.shouldNotBeBlank(organizationId, 'organization id', errMsg)
        DataValidator.shouldNotBeBlank(sisOrganizationId, 'sis organization id', errMsg)
        DataValidator.shouldNotBeBlank(internalIdentifier, 'internal subscription id', errMsg)
        DataValidator.shouldNotBeBlank(source, 'source', errMsg)

        let endpoint = buildPath(this.endpoints.SYNC_TEACHERS, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        })

        return executePost(endpoint, {
            sisOrganizationId: sisOrganizationId,
            [this.internalIdentifierParam]: internalIdentifier,
            source: source
        })
    }

    updateSource(organizationId, subscriptionId, internalIdentifier, source, internalSubscriptionId, tierType, tierId) {
        let errMsg = 'Invalid parameters for updating source'
        DataValidator.shouldNotBeBlank(subscriptionId, 'subscription id', errMsg)
        DataValidator.shouldNotBeBlank(organizationId, 'organization id', errMsg)
        DataValidator.shouldNotBeBlank(internalIdentifier, 'internal subscription id', errMsg)
        DataValidator.shouldNotBeBlank(source, 'source', errMsg)

        let endpoint = buildPath(this.endpoints.SUBSCRIPTION, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier
        })

        let payload = { source }
        // TODO: remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
        if (isEnabled(FUSE_API)) {
            payload = {...payload, subscriptionId: internalSubscriptionId, extSubscriptionId: subscriptionId, tierType, tierId}
        }
        return executePut(endpoint, payload)
    }

    initiateClientConnection(internalIdentifier, orgId, groupKey, source) {
        let errMsg = 'Error cannot sync teachers'
        DataValidator.shouldNotBeBlank(internalIdentifier, 'internal sub id', errMsg)
        DataValidator.shouldNotBeBlank(orgId, 'organization id', errMsg)
        DataValidator.shouldNotBeBlank(groupKey, 'group key', errMsg)

        let endpoint = this.endpoints.CLIENT_CONNECTION
        return executePost(endpoint, {
            [this.internalIdentifierParam]: internalIdentifier,
            groupKey: groupKey,
            organizationId: orgId,
            source: source
        })
    }

    getConnectionStatus(code) {
        DataValidator.shouldNotBeBlank(code, 'code', 'Error cannot sync teachers')

        let endpoint = this.endpoints.CLIENT_CONNECTION + '/status?code=' + code

        return executeGet(endpoint)
    }

    revokeConnectionGrant(code) {
        DataValidator.shouldNotBeBlank(code, 'code')

        let endpoint = this.endpoints.CLIENT_CONNECTION
        return executeDelete(endpoint, {
            code: code
        })
    }

    disconnectAutoProvision(organizationId, subscriptionId, internalIdentifier) {
        DataValidator.shouldNotBeBlank(organizationId, 'organization id')
        DataValidator.shouldNotBeBlank(subscriptionId, 'subscription id')

        let endpoint = buildPath(this.endpoints.DISCONNECT_SUBSCRIPTION, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier
        })

        return executeDelete(endpoint, null)
    }

    getExperience(experienceId) {
        DataValidator.shouldNotBeBlank(experienceId, 'experience id')
        return executeGet(this.endpoints.EXPERIENCES + '/' + experienceId, false)
    }

    createExperience(state) {
        DataValidator.shouldNotBeBlank(state, 'state')
        return executePost(this.endpoints.EXPERIENCES, { state: state }, false)
    }

    getAdminsForUserOrganizations(nextToken) {
        let endpoint = this.endpoints.ADMIN_MANAGEMENT
        if (nextToken) {
            endpoint += '/?next=' + nextToken
        }
        return executeGet(endpoint)
    }

    clearChangeNotification(orgIds) {
        return executePost(this.endpoints.ADMIN_MANAGEMENT + '/clearNotifications', orgIds)
    }

    roleChangeRequestForOrganization(changeRequest) {
        changeRequest.forEach(contact => {
            DataValidator.shouldNotBeBlank(contact.orgId, 'Organization Id')
            DataValidator.shouldNotBeBlank(contact.email, 'email')
            DataValidator.shouldNotBeBlank(contact.requestType, 'request type')
        })
        let endpoint = this.endpoints.ADMIN_MANAGEMENT + '/manage'
        return executePost(endpoint, changeRequest)
    }

    getRolesForOrganization() {
        // TODO: Add orgId later when custom roles are implemented
        return executeGet(this.endpoints.ADMIN_MANAGEMENT + '/roles')
    }

    updateOrganizations(organizations) {
        return executePut(this.endpoints.ORGANIZATIONS, organizations)
    }

    updateSFCounter(organizationId, subscriptionId, internalIdentifier) {
        // call update SF counter API after all batches are done
        let updateSFCounterEndpoint = buildPath(this.endpoints.UPDATE_SF_COUNTER, {
            organizationId: organizationId,
            subscriptionId: subscriptionId
        }, {
            [this.internalIdentifierParam]: internalIdentifier
        })

        return executePost(updateSFCounterEndpoint, '')
    }

    setUsersToSubscriptionBatch(endpoint, emails) {
        let promises = []
        let chunk = USER_BATCH_SET_LIMIT
        for (let x = 0, y = emails.length; x < y; x += chunk) {
            let tempArray = emails.slice(x, x + chunk)
            let promise = executePost(endpoint, tempArray)
            promises.push(promise)
        }

        return Q.all(promises)
            .then(function (responses) {
                let succeeded = []
                let failed = []

                for (let i = 0; i < responses.length; i++) {
                    let response = responses[i].json
                    // TODO: V3 returns succeeded while V2 returns added.
                    // We set the returned added from V2 as succeeded.
                    // Remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
                    succeeded = succeeded.concat(response.added || response.succeeded)
                    failed = failed.concat(response.failed)
                }

                return {
                    succeeded, failed
                }
            })
    }

    removeUsersFromSubscriptionBatch(endpoint, users) {
        let chunk = USER_BATCH_DELETE_LIMIT
        let promises = []
        for (let x = 0, y = users.length; x < y; x += chunk) {
            let tempArray = users.slice(x, x + chunk)
            let promise = executeDelete(endpoint, tempArray)
            promises.push(promise)
        }

        return Q.all(promises)
    }

    readFeatureFlagsFromServer() {
        const url = this.endpoints.FEATURE_SWITCHES
        return executeGet(url, false)
    }

    loadDLMetadata({orgId, extOrgId, subId, extSubId}) {
        const endpoint = buildPath(this.endpoints.DISTRICT_LIBRARY, {
            orgId
        }, {
            extOrgId,
            subId,
            extSubId
        })
        return executeGet(endpoint)
    }

    updateAllowUploadForDL({ districtLibraryId, extOrgId, subId, extSubId, val }) {
        const endpoint = buildPath(this.endpoints.UPDATE_ALLOW_UPLOAD, {
            districtLibraryId,
            extOrgId
        }, {
            subId,
            extSubId
        })

        return executePut(endpoint, { allowUpload: val })
    }

    generateLicenseKey({organizationId, subscriptionId}) {
        let endpoint = buildPath(this.endpoints.GENERATE_LICENSE_KEY, {
            organizationId,
            subscriptionId
        })

        return executePost(endpoint)
    }
}
