<template>
    <v-navigation-drawer v-show="appInitialized" app absolute clipped permanent transparent style="top:80px" height="calc(100vh - 80px)" margin-top="0px" width="167px">
        <hr id="top-separator" class="navigation-drawer-list-separator">
        <v-list class="pt-0">
            <v-list-item class="navigation-items"
            v-for="item in navigationTabs"
            :key="item.title"
            :class="{'selected' : selected === item.pathValue}"
            @click="$router.push(item.pathValue)">
                <v-list-item-content v-if="item.pathValue!=='/manage'">
                    <v-list-item-title class="navigation-title"
                                    :class="{'selected' : selected === item.pathValue}">
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item-content>
                <callout v-else :popover-props="getCurrentStepProps">
                    <template v-slot:outerPopper>
                        <v-list-item-content>
                            <v-list-item-title class="navigation-title"
                                            :class="{'selected' : selected === item.pathValue}">
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </callout>
            </v-list-item>
        </v-list>
        <hr class="navigation-drawer-list-separator">
        <a :href="$LINKS.IP_DOC" target="_blank" class="legal-footer white--text">{{ $t('navigation.footer.ipDoc') }}</a>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import router from '../routes.js'
    import {INITIALIZED} from '../enums/application-states'
    import Callout from '../components/dialogs/Callout.vue'

    const MANAGE = '/manage'
    const PRODUCTS = '/products'
    const USERS = '/users'

    export default {
        components: {
            Callout
        },
        data () {
            return {
                drawer: null,
                softwareTab: { title: this.$t('navigation.software'), pathValue: PRODUCTS },
                organizationTab: { title: this.$t('navigation.organization'), pathValue: MANAGE },
                right: null,
                selected: ''
            }
        },
        computed: {
            ...mapGetters([
                'getManageUsersOnboardingStep'
            ]),
            navigationTabs () {
                if (this.$store.getters.getDirectOrganizations.length) {
                    return [this.softwareTab, this.organizationTab]
                } else {
                    return [this.softwareTab]
                }
            },
            appInitialized () {
                return this.$store.getters.appState === INITIALIZED
            },
            onboarding() {
                return [
                    {
                        step: 3,
                        placement: 'right',
                        title: this.$t('onboarding.users.step3.title'),
                        description: this.$t('onboarding.users.step3.description'),
                        distance: 67
                    },
                    {
                        step: 4,
                        placement: 'right',
                        title: this.$t('onboarding.users.step4.title'),
                        description: this.$t('onboarding.users.step4.description'),
                        distance: 67
                    }
                ]
            },
            getCurrentStepProps() {
                return this.onboarding.find(callout => callout.step === this.getManageUsersOnboardingStep)
            }
        },
        watch: {
            '$route' (to, from) {
                if (to.path === MANAGE) {
                    this.selected = MANAGE
                } else {
                    this.selected = PRODUCTS
                }
            }
        },
        beforeMount () {
            if (router.currentRoute.path.includes(USERS)) {
                this.selected = PRODUCTS
            } else {
                this.selected = router.currentRoute.path
            }
        }
    }
</script>

<style lang="scss" scoped>

    .v-list {
        padding: 0;
    }
    .v-navigation-drawer:after {
        width: 0px;
    }

    .v-navigation-drawer {
        padding: 0;
        width: 167px;

        .legal-footer {
            margin-left: 18px;
            position: absolute;
            bottom: 10px;
        }
    }

    .navigation-drawer-list-separator {
        height:3px;
        border-width:0;
        color:rgba(255, 255, 255, 0.1);
        background-color:rgba(255, 255, 255, 0.1);
    }

    #top-separator {
        margin-top: 80px !important;
    }

    .navigation-items {
        cursor: pointer;
    }

    .navigation-title {
        color: white;
        font-size: 18px;
    }

    .selected {
        background-color: #f3f3f3;
        color: #1987ba;
        font-weight: bold;
    }

</style>
