<template >
    <div class="callout-inner">
        <v-flex class="close-button">
            <v-btn small icon text color="#cccccc" @click.native="$emit('onDismiss')">
                <i class="material-icons md-light close-icon">clear</i>
            </v-btn>
        </v-flex>
        <div class="content">
            <div class="title">{{ innerProps.title }}</div>
            <div class="description">
                <p>{{ innerProps.description }}</p>
            </div>
        </div>
        <v-flex align-self-center class="footer">
            <div class="progress-counter-container">
                <div class="progress-counter"> {{ progressCounter }} </div>
            </div>
            <div class="progress-buttons">
                <v-btn class="learn-more-button"
                        v-if="hasLearnMoreButton"
                        text
                        @click="onLearnMore"> {{ $t('buttons.learnMore') }}
                </v-btn>
                <v-btn class="confirm-button primary white--text"
                        v-if="hasConfirmButton"
                        @click="onNextClicked"> {{ !isLastStep ? $t('buttons.next') : $t('buttons.finish') }}
                </v-btn>
            </div>
            <div class="progress-indicator"
                v-if="hasProgressIndicator"
                :style="{ width: progressSliderWidth }">
            </div>
        </v-flex>
    </div>
</template>
<script>
export default {
    props: {
        innerProps: {
            type: Object,
            default: () => ({})
        },
        progressIndicator: {
            type: Object,
            default: null
        },
        hasConfirmButton: {
            type: Boolean,
            default: true
        },
        hasLearnMoreButton: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        hasProgressIndicator() {
            return !!this.progressIndicator
        },
        progressCounter() {
            return this.hasProgressIndicator ? `${this.progressIndicator.current}/${this.progressIndicator.total}` : ''
        },
        progressSliderWidth() {
            return this.hasProgressIndicator ? `${(this.progressIndicator.current / this.progressIndicator.total) * 100}%` : '0%'
        },
        isLastStep() {
            return this.hasProgressIndicator && this.progressIndicator.current === this.progressIndicator.total
        }
    },
    methods: {
        onLearnMore() {
            window.open(this.$LINKS.ORG_LIBRARIES_SUPPORT, '_blank')
        },
        onNextClicked() {
            this.$emit('onNextClicked')
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../theming/main.scss";
.callout-inner {
    box-sizing: border-box;
    min-width: 300px;
    padding: 20px;
    right: 10px;

    .close-button {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        right: 10px;
        top: 10px;

        i {
            font-size: 25px;
        }
    }
    .title {
        line-height: 26px;
        margin-bottom: 20px;
        margin-top: 14px;
        font-family: 'Verveine', Arial, sans-serif;
        font-size: 30px;
    }
    .description {
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        width: 259px;
        color: rgba(0, 1, 2, 0.70);
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-top: 32px;
        .progress-counter-container {
            .progress-counter {
                font-size: 14px;
                font-weight: 400;
                user-select: none;
            }
        }
        .progress-indicator {
            position: absolute;
            background-color: $theme-primary;
            height: 5px;
            bottom: 0;
            left: 0;
        }
        .progress-buttons {
            display: flex;
            .confirm-button {
                min-width: 98px;
                max-width: 270px;
                max-height: 30px;
                font-size: 16px;
                line-height: 0.75;
                font-weight: bold;
                padding: 14px 16px;
                border-radius: 2px;
                overflow-wrap: break-word;
                cursor: pointer;
            }

            .later-button {
                min-width: 72px;
                max-width: 270px;
                max-height: 40px;
                font-size: 16px;
                line-height: 0.75;
                font-weight: bold;
                border: none;
                background: none;
                padding: 14px 16px;
                overflow-wrap: break-word;
                cursor: pointer;
            }
            .learn-more-button {
                min-width: 98px;
                max-width: 270px;
                max-height: 30px;
                color: $theme-primary;
                font-weight: 700;
            }
        }
    }
}
</style>
