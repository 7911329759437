import environ from 'smarttech-identity-environment'
import strUtils from 'smarttech-identity-string-utils'
import TranslationService from '../services/translation-service'

var regionInfoByRegionName = new Map() // {region:{"countries":[{countryCode: countryCode - countryName}]}, ...}
var states = {} // {countryCode:{stateCode: stateCode - stateName}}
var regionStrings = [] // [{region:regionString}]
var countryStrings = [] // sorted array: [{countryCode: countryCode - countryName}]

function getCodeNameFormatString (code, name) {
    return strUtils.format('({0}) - {1}', code, name)
}

export default {
    init () {
        let countryListsByRegions = environ.getCountryListsByRegions()

        try {
            let regionStringByRegionName = TranslationService.t('region') // "region": {"regionName": {"title": ""}}
            let codes = TranslationService.t('countryCodes')
            let countryNameByCountryCode = new Map(codes.map(obj => [Object.keys(obj)[0], Object.values(obj)[0]])) // {"code":"name"}

            if (countryListsByRegions && regionStringByRegionName && countryNameByCountryCode) {
                for (let [region, countryList] of countryListsByRegions) {
                    let countriesArray = []

                    countryList.forEach(countryCode => {
                        let countryObj = {
                            code: countryCode,
                            name: getCodeNameFormatString(countryCode, countryNameByCountryCode.get(countryCode))
                        }
                        countriesArray.push(countryObj)
                    })

                    regionStrings.push({code: region, name: regionStringByRegionName[region].title})
                    regionInfoByRegionName.set(region, {countries: countriesArray})
                }

                // Sort global and eu countries alphabetically.
                for (let countryList of regionInfoByRegionName.values()) {
                    countryStrings = countryStrings.concat(countryList.countries)
                        .sort(function (a, b) { return a.code < b.code ? -1 : 1 })
                }
            } else {
                throw new Error()
            }

            let stateCodes = TranslationService.t('stateCodes') // {"CountryCode": [{"StateCode": "StateName"}], ...}

            for (let [countryCode, stateCodeList] of Object.entries(stateCodes)) {
                states[countryCode] = []
                stateCodeList.forEach(stateCode => {
                    states[countryCode].push({
                        code: Object.keys(stateCode)[0],
                        name: getCodeNameFormatString(Object.keys(stateCode)[0], Object.values(stateCode)[0])
                    })
                })
            }
        } catch (err) {
            throw new Error('Failed to initialize region mapping service.')
        }
    },

    getRegionFromCountryCode (countryCode) {
        for (const [key, value] of regionInfoByRegionName.entries()) {
            if (value.countries.map(countryArray => countryArray.code).includes(countryCode)) {
                return key
            }
        }

        return null
    },

    getRegionStrings () {
        return regionStrings
    },

    getRegionInfoByRegionName () {
        return regionInfoByRegionName
    },

    getStates () {
        return states
    },

    getCountryCodes () {
        return countryStrings
    }
}
