import { createApiClient } from '../services/api-client'

export default {
    executeRequest: (organizationId, nextToken, listener) => {
        const apiClient = createApiClient()
        apiClient.getLegacySubscriptionsForOrganization(organizationId, nextToken)
            .then(result => {
                let response = {
                    orgId: organizationId,
                    data: result.json,
                    nextToken: result.nextToken,
                    isLegacy: true,
                    error: false
                }

                if (listener) {
                    listener(response)
                }
            })
            .catch(error => {
                let response = {
                    orgId: organizationId,
                    data: error,
                    isLegacy: true,
                    error: true
                }

                if (listener) {
                    listener(response)
                }
            })
    }
}
