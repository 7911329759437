<template>
    <div class="white--text login-component" v-if="username">
        <span class="font--bold">{{ $t('login.welcome') }} {{username}}</span>

        <v-menu left bottom offset-y min-width="170" :nudge-top="-10" content-class="login-component-menu">
            <template v-slot:activator="{ on }">
                <v-badge :value="showAlert" bottom right overlap class="outage-badge">
                    <template v-slot:badge>
                        <v-icon v-on="on" medium color="yellow darken-3" class="outage-icon">error</v-icon>
                    </template>
                    <v-btn id="userMenu" medium v-on="on" icon width="36" height="36" class="white">
                        <b class="initial-button font--bold primary--text">{{initial}}</b>
                    </v-btn>
                </v-badge>
            </template>

            <v-list>
                <v-list-item @click.native="openHelp()" id="helpLink">
                    <v-list-item-title>{{ $t('login.help') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.native="openPrivacyDoc()" id="privacyDocLink">
                    <v-list-item-title>{{ $t('login.privacyDoc') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.native="signOut()" id="signOut">
                    <v-list-item-title>{{ $t('login.signOut') }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider v-if="showAlert" class="outage-divider">
            </v-divider>
            <v-card v-if="showAlert" class="outage-message-container">
                <v-card-title class="outage-title-container">
                    <v-icon color="yellow darken-3">error</v-icon>
                    <span class="outage-title yellow--text text--darken-3"><strong>{{ $t('outage.title') }}</strong></span>
                </v-card-title>
                <v-card-text class="outage-text-container">
                    <span class="outage-message">{{ alertMessage }}</span>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import {OUTAGE_SALESFORCE_DATA, OUTAGE_SALESFORCE} from '../enums/outage-codes'
    import {mapState} from 'vuex'
    import {idAuth} from '../services/identity-auth'

    export default {
        data: function () {
            return {
                activationOn: true
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            username () {
                var profileInfo = this.$store.getters.userProfile
                return profileInfo.name ? profileInfo.name.split('@')[0] : ''
            },
            initial () {
                return this.username ? this.username[0].toUpperCase() : ''
            },
            showAlert () {
                return this.$store.getters.showOutageAlert
            },
            alertMessage () {
                let outageType = this.$store.getters.outageType
                if (outageType === OUTAGE_SALESFORCE_DATA) {
                    return this.$t('outage.salesforceData')
                } else if (outageType === OUTAGE_SALESFORCE) {
                    return this.$t('outage.salesforce')
                } else {
                    return this.$t('outage.entitlements')
                }
            }
        },
        methods: {
            signOut () {
                idAuth.logout()
                localStorage.removeItem('seenUnpopulatedOrgs')
            },
            openHelp () {
                window.open(this.$LINKS.HELP)
            },
            openPrivacyDoc () {
                window.open(this.$LINKS.PRIVACY_DOC)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../theming/main.scss';
    .outage-divider {
        width: 100%;
        height: 2px;
        background-color: #ccc;
        opacity: 0.15;
    }
    .outage-message-container {
        max-width: 200px;
    }
    .initial-button {
        font-size: 18px;
        background-color: $pallete-white;
        border-color: $pallete-white;
    }
    .outage-icon {
        font-size: 1.8rem;
    }
    .outage-title {
        padding-left: 6px;
    }
    .outage-title-container {
        padding: 8px 16px;
    }
    .outage-text-container {
        padding-top: 0;
        font-size: 12px;
        line-height: 1.2;
    }
</style>
<style lang="scss">
    .login-component {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .login-component-menu {
        background-color: white;
    }
</style>
