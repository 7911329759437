// Rostering sources
// Key is displayed in UI and value is used for comparision for source.
export const SIS_SOURCES = {
    ClassLink: 'oneroster:classlink',
    Google: 'google_classroom',
    Microsoft_Groups: 'microsoft_groups',
    Microsoft_SDS: 'microsoft_sds',
    Clever: 'clever',
    Manual: 'manual',
    Undecided: 'undecided'
}
