<template>
    <div class="expiry-modal">
        <div class="title"> {{ $tc('expiryModal.renewSubscriptions', subscriptionsCount) }} </div>

        <div class="about-to-expire" v-if="warningSubscriptions.length > 0">
            {{ getMessage(sortedWarningSubscriptions, 'expiryModal.warningMessage') }}
        </div>
        <div class="expired" v-if="expiredSubscriptions.length > 0">
            {{ getMessage(sortedExpiredSubscriptions, 'expiryModal.expiredMessage') }}
        </div>
        <div class="to-renew-subscriptions">
            {{ $t('expiryModal.toRenewSubscriptions') }}
        </div>
        <div class="organizations-info">
            <div class="d-flex">
                <div class="item"><strong>{{ $t('expiryModal.organization') }}</strong></div>
                <div class="item"><strong>{{ $t('expiryModal.subscriptionId') }}</strong></div>
            </div>
            <div v-for="sub in sortedWarningSubscriptions" :key="sub.id" class="d-flex sub-row">
                <div class="item">{{ sub.accountName }}</div>
                <div class="item">{{ sub.msdSubscriptionNumber }}</div>
            </div>
            <div v-for="sub in sortedExpiredSubscriptions" :key="sub.id" class="d-flex sub-row">
                <div class="item">{{ sub.accountName }}</div>
                <div class="item">{{ sub.msdSubscriptionNumber }}</div>
            </div>
        </div>
        <div class="be-sure-renew">
            {{ $t('expiryModal.beSureRenew') }}
        </div>
        <div class="features d-flex flex-column">
            <div class="d-flex align-center">
                <img :src="icons.organization" class="logo">
                {{ $t('expiryModal.organizationFeature') }}
            </div>
            <div class="d-flex align-center">
                <img :src="icons.administrator" class="logo"/>
                {{ $t('expiryModal.administratorFeature') }}
            </div>
        </div>

        <v-btn id="expireModalConfirmButton"
                   block primary light
                   class="white--text mt-9 confirm-button"
                   height="40"
                   @click="onClick">
                {{ $t('buttons.gotIt') }}
        </v-btn>
    </div>
</template>
<script>
import administrator from '../assets/administrator-feature.svg'
import organization from '../assets/org-library-feature.svg'
import DateUtil from '../utils/date-util'

const MONTH_LONG_FORMAT = 'long'

export default {
    name: 'ExpiryModal',
    data() {
        return {
            icons: {
                administrator,
                organization
            }
        }
    },
    props: {
        expiredSubscriptions: Array,
        warningSubscriptions: Array
    },
    computed: {
        subscriptionsCount() {
            return this.expiredSubscriptions.length + this.warningSubscriptions.length
        },
        sortedWarningSubscriptions() {
            const sortedByDate = this.warningSubscriptions.toSorted((a, b) => new Date(a.nextRenewalDate) - new Date(b.nextRenewalDate))
            sortedByDate.forEach(subscription => {
                subscription.nextRenewalDate = DateUtil.getFormattedDate(new Date(subscription.nextRenewalDate), { month: MONTH_LONG_FORMAT })
            })
            return sortedByDate
        },
        sortedExpiredSubscriptions() {
            const sortedByDate = this.expiredSubscriptions.toSorted((a, b) => new Date(a.nextRenewalDate) - new Date(b.nextRenewalDate))
            sortedByDate.forEach(subscription => {
                subscription.nextRenewalDate = DateUtil.getFormattedDate(new Date(subscription.nextRenewalDate), { month: MONTH_LONG_FORMAT })
            })
            return sortedByDate
        }

    },
    methods: {
        getMessage(subscriptions, message) {
            let formattedNames = ''
            let formattedDates = ''
            let lastSubscriptionName = ''
            let lastExpireDate = ''
            if (subscriptions.length === 1) {
                formattedNames = subscriptions.map(subscription => subscription.accountName).join(', ')
                formattedDates = subscriptions.map(subscription => subscription.nextRenewalDate).join(', ')
            } else {
                const names = subscriptions.map(subscription => subscription.accountName)
                const dates = subscriptions.map(subscription => subscription.nextRenewalDate)
                lastSubscriptionName = names.pop()
                lastExpireDate = dates.pop()

                formattedNames = names.join(', ')
                formattedDates = dates.join(', ')
                if (subscriptions.length > 2) {
                    formattedNames = formattedNames + ','
                    formattedDates = formattedDates + ','
                }
            }
            return this.$tc(message,
            subscriptions.length,
                {
                    subscriptionNames: formattedNames,
                    expireDates: formattedDates,
                    lastSubscriptionName,
                    lastExpireDate
                })
        },
        onClick() {
            this.$emit('on-close-modal')

             // Convert current date to YYYY-MM-DD string
            const subscriptionExpiryConfirmedDate = new Date().toISOString().split('T')[0]
            this.$store.dispatch('UpdateUserSettings', {
                subscriptionExpiryConfirmedDate
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../theming/mixins.scss';

.expiry-modal {
    padding: 25px 14px;
    font-size: 14px;

    div {
        margin-bottom: 15px;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
    }
    .features {
        gap: 10px;
        .logo {
            width: 69px;
            margin-right: 10px;
        }
    }
    .confirm-button {
        font-size: 18px !important;
    }
    .organizations-info {
        width: 100%;
        font-size: 14px;
        margin-bottom: 15px;
        max-height: 105px;
        @include visible-scrollbar;

        .item {
                width: 100%;
                margin: 0;
            }

        .sub-row {
            gap: 15px;
            padding-right: 15px;
            margin: 0;
        }
    }
}
</style>
