<template>
    <span class="required-color">* </span>
</template>

<script>
    export default {
        name: 'RequiredTag'
    }
</script>

<style scoped>
    .required-color {
        color: #d0011b;
    }
</style>
