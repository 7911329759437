export const MANAGEMENT = {
    admin: 'admin_management',
    org: 'org_management'
}

export const PERMISSIONS = {
    ManageAdmins: 'manage_admins',
    ManageOrgs: 'manage_orgs',
    ManageUsers: 'manage_users',
    ClaimSubscriptions: 'claim_subscriptions'
}

export const REQUEST_TYPE = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    REMOVE: 'REMOVE'
}

export const ACCESSIBILITY_LEVEL = {
    admin: 2,
    techInstructor: 1,
    supervisor: 0
}

export const ADMIN = 'admin'
export const SUPERVISOR = 'supervisor'
export const TECH_INSTRUCTOR = 'techInstructor'
