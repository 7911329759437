import idpUtils from 'smarttech-identity-idp-utils'
import { DSL_ROLES } from '../enums/dsl-constants'
import { TIER_TYPES } from '../enums/subscription-constants'

const MAX_DISPLAY_USER_NAME_CHARS = 64

const shortenDisplayName = (name) => {
    if (name && name.length > MAX_DISPLAY_USER_NAME_CHARS) {
        return `${name.substring(0, MAX_DISPLAY_USER_NAME_CHARS)}...`
    }
    return name
}

const getHighestLibraryRole = (roles) => {
    // DSL_ROLES is defined as an object with roles with properties listed from lowest to highest
    const dlRolesAsSortedArray = Object.values(DSL_ROLES)
    return roles.reduce((highestRole, role) => {
        const roleIndex = dlRolesAsSortedArray.indexOf(role)
        const highestRoleIndex = dlRolesAsSortedArray.indexOf(highestRole)
        return roleIndex > highestRoleIndex ? role : highestRole
    }, dlRolesAsSortedArray[0])
}

export const sanitizeUserData = (user, tierType) => {
    if (!user.name) {
        user.name = ''
    } else {
        user.name = shortenDisplayName(user.name)
    }
    if (!user.idps) {
        user.idps = ['––']
    } else {
        user.idps = user.idps.map(idp => idpUtils.getIdpReadableName(idp))
    }
    user.uid = `${user.id}:${user.email}`
    // include role in library only for Spark subscriptions
    if (tierType === TIER_TYPES.SPARK) {
        // Make sure users with no role set are set to VIEWER
        if (!user.dlRoles || user.dlRoles.length === 0) {
            user.dlRoles = [DSL_ROLES.VIEWER]
        }
        // We keep dlRoles with all roles but we need to display and handle the highest role only so we add a new property to the user
        user.dlHighestRole = getHighestLibraryRole(user.dlRoles)
    }

    return user
}
