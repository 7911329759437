import {store} from '../store/store'

let checkUserPermissionForOrg = function (orgId, permission) {
    let userPermissionForOrg = store.getters.getOrganizationsForValidation[orgId]
    if (userPermissionForOrg && userPermissionForOrg.role) {
        return userPermissionForOrg.role.permissions.includes(permission)
    } else {
        // Cannot find the user's permissions.
        return false
    }
}

export default {
    validateUserPermission (orgId, permission) {
        return checkUserPermissionForOrg(orgId, permission)
    },
    isLoggedInUser (email) {
        return store.getters.userProfile.email === email
    }
}
