<template>
    <div class="unpopulated-subscriptions-modal d-flex">
        <div class="d-flex left-column">
            <img :src="icons.organization" class="logo">
        </div>
        <div class="right-column">
            <div class="title"> {{ $tc('organizationalLibrariesModal.title', organizations.length) }} </div>
            <div class="description"> {{ $t('organizationalLibrariesModal.description') }} </div>
            <div class="organizations-container">
                <div class="d-flex flex-row organizations-row" v-for="org in organizations" :key="org.id">
                    <strong class="organizations-name">{{ org.name }}</strong>
                    <text-button
                        :text="$t('subscriptions.addUsers')"
                        @button-action="manageUsers(org.id)"
                        class="organizations-users">
                    </text-button>
                </div>
            </div>
            <div class="footer-description"> {{ $t('organizationalLibrariesModal.footerDescription') }} </div>
            <div class="confirm-button">
                <v-btn id="unpopulatedModalConfirmButton"
                    block primary light
                    class="white--text"
                    height="40px"
                    @click="onClick">
                    {{ $t('buttons.gotIt') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>

import TextButton from '../components/TextButton.vue'
import { mapGetters } from 'vuex'
import organization from '../assets/org-library-feature.svg'
import { TIER_TYPES } from '../enums/subscription-constants'

export default {
    data() {
        return {
            icons: {
                organization
            }
        }
    },
    components: {
        TextButton
    },
    props: {
        organizations: Array
    },
    computed: {
        ...mapGetters([
            'orgSparkSubscription'
        ])
    },
    methods: {
        onClick() {
            const organizationIds = this.organizations.map(org => org.id)
            this.markAsSeenUnpopulatedModalForOrgs(organizationIds)
            this.$emit('on-close-modal')
        },
        markAsSeenUnpopulatedModalForOrgs(organizationIds) {
            let seenUnpopulatedOrgs = localStorage.getItem('seenUnpopulatedOrgs')
            if (!seenUnpopulatedOrgs) {
                seenUnpopulatedOrgs = JSON.stringify(organizationIds)
            } else {
                const parsedSeenUnpopulatedOrgs = JSON.parse(seenUnpopulatedOrgs)
                parsedSeenUnpopulatedOrgs.push(...organizationIds)
                const mergedArray = [...new Set(parsedSeenUnpopulatedOrgs)]
                seenUnpopulatedOrgs = JSON.stringify(mergedArray)
            }
            localStorage.setItem('seenUnpopulatedOrgs', seenUnpopulatedOrgs)
        },
        manageUsers(organizationId) {
            const subscription = this.orgSparkSubscription(organizationId)
            // We don't want to display the modal again when the users access users screen and they come back
            const organizationIds = this.organizations.map(org => org.id)
            this.markAsSeenUnpopulatedModalForOrgs(organizationIds)
            this.$router.push({
                name: 'users',
                params: { organizationId, subscriptionId: subscription.id, tierId: subscription.tiers[TIER_TYPES.SPARK].id },
                query: this.$route.query
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.unpopulated-subscriptions-modal {
    padding: 25px 14px 17px 14px;
    font-size: 14px;
    gap: 10px;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .description {
        text-align: left;
        margin-bottom: 15px;
    }
    .left-column {
        align-items: center;
    }
    .right-column {
        overflow: hidden;
    }
    .logo {
        width: 115px;
    }
    .organizations-container {
        margin-bottom: 15px;
        margin-right: 10px;
        font-size: 14px;
        .organizations-row {
            margin-bottom: 10px;
            justify-content: space-between;
            .organizations-name {
                flex: 1;
                margin-right: 5px;
                max-height: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .confirm-button {
        margin-top: 15px;
        width: 174px;
        float: right;
    }
}
</style>
