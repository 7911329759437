<template>
    <div class="management-route scrollable-activation">
        <v-container fluid>
            <management-dropdown class="management-toolbar"></management-dropdown>
            <admin-table v-if="selectedMenuOption === adminTable"></admin-table>
            <organization-table v-else
                @open-org-update-dialog-new="openOrgUpdateDialogNew">
            </organization-table>
        </v-container>
        <admin-info-dialog></admin-info-dialog>
        <org-update-dialog></org-update-dialog>
        <org-update-dialog-new
            v-if="orgUpdateDialogNewOpen"
            :org="selectedOrg"
            @close-dialog="closeDialog">
        </org-update-dialog-new>
    </div>
</template>

<script>
    import ManagementDropdown from '../components/Management/ManagementDropdown'
    import AdminInfoDialog from '../components/Management/AdminInfoDialog'
    import AdminTable from '../components/Management/AdminTable'
    import OrganizationTable from '../components/Management/OrganizationTable'
    import OrgUpdateDialog from '../components/Management/OrgUpdateDialog'
    import OrgUpdateDialogNew from '../components/Management/OrgUpdateDialogNew'
    import ResizeMixin from '../mixins/resize-mixin'
    import { MANAGEMENT, ADMIN, TECH_INSTRUCTOR } from '../enums/admin-management-constants'
    import { mapActions, mapGetters } from 'vuex'
    import ErrorHandler from '../utils/error-handler'

    export default {
        name: 'Management',
        components: {
            AdminInfoDialog,
            AdminTable,
            ManagementDropdown,
            OrganizationTable,
            OrgUpdateDialog,
            OrgUpdateDialogNew
        },
        mixins: [ResizeMixin],
        data () {
            return {
                orgUpdateDialogNewOpen: false,
                selectedOrg: null
            }
        },
        computed: {
            ...mapGetters([
                'orgSparkSubscription'
            ]),
            adminTable () {
                return MANAGEMENT.admin
            },
            orgTable () {
                return MANAGEMENT.org
            },
            selectedMenuOption () {
                return this.$store.getters.getManagementSelectedMenuOption
            },
            directOrganizationsCount () {
                return this.$store.getters.getDirectOrganizationsCount
            }
        },
        watch: {
            'directOrganizationsCount' (newCount) {
                this.checkDirectOrgCount(newCount)
            }
        },
        methods: {
            ...mapActions([
                'loadDLMetadata',
                'FetchSLSSubscription'
            ]),
            checkDirectOrgCount (orgCount) {
                if (orgCount === 0) {
                    this.$router.push('/products')
                }
            },
            async openOrgUpdateDialogNew(org) {
                try {
                    const showTabs = org.role.name === ADMIN || org.role.name === TECH_INSTRUCTOR
                    if (showTabs) {
                        const orgSparkSubscription = this.orgSparkSubscription(org.id)
                        if (orgSparkSubscription) {
                            const {id, relevantTier} = orgSparkSubscription
                            const res = await this.FetchSLSSubscription({organizationId: org.id, subscriptionId: id, tierType: relevantTier})
                            const {extOrganizationId, extSubscriptionId, organizationId, subscriptionId} = res
                            await this.loadDLMetadata({extOrgId: extOrganizationId, extSubId: extSubscriptionId, orgId: organizationId, subId: subscriptionId})
                        }
                    }
                    this.openDialog(org)
                } catch (error) {
                    ErrorHandler.handleError(error, ErrorHandler.dialogueError)
                }
            },
            openDialog(org) {
                this.selectedOrg = org
                this.orgUpdateDialogNewOpen = true
            },
            closeDialog() {
                this.$store.commit('setIsModalOpen', false)
                this.orgUpdateDialogNewOpen = false
                this.selectedOrg = null
            }
        },
        mounted () {
            this.checkDirectOrgCount(this.directOrganizationsCount)
        }
    }
</script>

<style lang="scss" scoped>
    .scrollable-activation {
        overflow: auto;
    }

    .management-toolbar {
        height: 70px;
    }

</style>
