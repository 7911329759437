<template>
    <v-form ref="orgInfoForm" :readonly="readOnly" :class="{ 'read-only': readOnly, 'new-org': isNewOrg }">
        <div class="org-name" v-if="isNewOrg">
            <label for="orgName"><span class="required-color">* </span>{{ $t('management.orgInformationForm.name') }}</label>
            <v-text-field required :rules="[requiredRule, orgNameXssRule]" id="orgName" maxlength="255" v-model="name" autocomplete="off" text solo/>
        </div>
        <div class="industry">
            <label>
                <span class="required-color">* </span>{{ $t('management.orgInformationForm.industry') }}
            </label>
            <div>
                <v-autocomplete id="industry"
                                type="text"
                                class="border"
                                :clearable="!readOnly"
                                :append-icon="readOnly? '': '$dropdown'"
                                flat
                                solo
                                maxlength="40"
                                v-model="industry"
                                :items="industryList"
                                item-value="value"
                                item-text="name"
                                :rules="[requiredRule]">
                    <template v-slot:item="data">
                        <v-list-item-content class='dropdown'>
                            <v-list-item-title>
                                <span :class='data.item.type'>{{ data.item.name }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>
        </div>
        <!-- When editing an organization, the org name field is displayed after the Industry one -->
        <div class="org-name" v-if="!isNewOrg">
            <label for="orgName"><span class="required-color">* </span>{{ $t('management.orgInformationForm.name') }}</label>
            <v-text-field required :rules="[requiredRule, orgNameXssRule]" id="orgName" maxlength="255" v-model="name" autocomplete="off" text solo/>
        </div>
        <div>
            <div><label for="country"><span
                class="required-color">* </span>{{ $t('management.orgInformationForm.country') }}</label>
            </div>
            <v-autocomplete id="country"
                            v-model="country"
                            class="border selector"
                            :items="countryCodes"
                            item-text="name"
                            single-line
                            flat
                            solo
                            maxlength="80"
                            :clearable="!readOnly"
                            :append-icon="readOnly? '': '$dropdown'"
                            item-value="code"
                            :rules="[requiredRule]">
                <template v-slot:item="data">
                    <v-list-item-content class='dropdown'>
                        <v-list-item-title>
                            <span :class='data.item.type'>{{ data.item.name }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </div>
        <v-flex>
            <label for="streetAddress"><span
                class="required-color">* </span>{{ $t('management.orgInformationForm.street') }}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="streetAddress" maxlength="255" v-model="address" text solo/>
        </v-flex>
        <v-flex>
            <label for="city"><span class="required-color">* </span>{{ $t('management.orgInformationForm.city') }}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="city" maxlength="40" v-model="city" text solo/>
        </v-flex>
        <v-flex v-if="!hasStateDropdown">
            <label for="state"><span class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.state')}}</label>
            <v-text-field required :rules="[requiredRule, xssCharRule]" id="state" maxlength="80" v-model="state" text solo/>
        </v-flex>
        <div v-else>
            <div><label for="stateSelect"><span
                class="required-color">* </span>{{$t('activation.assignEntitlement.createOrg.state')}}</label></div>
            <div>
                <v-autocomplete id="stateSelect" class="border selector" v-model="state"
                        :items="stateCodes"
                        item-text="name"
                        single-line
                        text solo
                        maxlength="80"
                        autocomplete="disable-chrome-autocomplete"
                        clearable
                        item-value="code"
                        :rules="[requiredRule]">
                    <template slot="selection" slot-scope="data">
                        {{ data.item.name }}
                    </template>
                </v-autocomplete>
            </div>
        </div>
        <div>
            <v-layout align-end>
                <v-flex xs4 mr-5>
                    <label for="postalCode"><span class="required-color">* </span>{{ $t('management.orgInformationForm.postalCode') }}</label>
                    <v-text-field required :rules="[requiredRule, xssCharRule, postalRule]" id="postalCode" maxlength="20" v-model="zipPostal" text solo/>
                </v-flex>
                <v-flex xs8>
                    <label for="phone">{{ $t('management.orgInformationForm.phone') }}</label>
                    <v-text-field id="phone" maxlength="15" v-model="phone" :rules="[phoneRule]" @keydown.space.prevent text solo/>
                </v-flex>
            </v-layout>
        </div>
        <div class="region-selection">
            <div class="pt-2">
                <div class="region-container">
                    <label for="region"><span class="required-color">* </span>{{ $t('management.orgInformationForm.region') }}</label>
                    <v-icon class="learn-more-icon" size="24" @click="openLearnMore">help</v-icon>
                </div>
                <!-- In a readonly v-form, the content inside the v-select is not selectable. Below line is a workaround for fixing it -->
                <v-text-field v-if="readOnly" id="region" v-model="regionName" :rules="[requiredRule, xssCharRule]" text solo/>
                <v-select v-else
                    id="region"
                    v-model="region"
                    class="border selector"
                    :items="regions"
                    item-value="code"
                    item-text="name"
                    single-line
                    flat
                    solo
                    maxlength="25"
                    :append-icon="readOnly? '': '$dropdown'"
                    :rules="[requiredRule, xssCharRule]">
                </v-select>
            </div>
            <div v-if="hasDataLocationDropdown" class="pb-2">
                <div>
                    <label for="dataLocation"><span class="required-color">* </span>{{ $t('activation.assignEntitlement.createOrg.dataLocation') }}</label>
                </div>
                <v-autocomplete id="dataLocation"
                                v-model="dataLocation"
                                class="data-location-dropdown"
                                :items="dataLocations"
                                item-value="code"
                                item-text="name"
                                single-line
                                flat
                                solo
                                maxlength="25"
                                :append-icon="readOnly? '': '$dropdown'"
                                :clearable="!readOnly"
                                :rules="!readOnly && [requiredRule, dataLocationWaring]">
                </v-autocomplete>
            </div>
        </div>
    </v-form>
</template>

<script>
    import { mapState } from 'vuex'
    import enIndustryList from '../locales/en.json'
    import { entitlement, security } from '../utils/validate-utils.js'
    import strUtils from 'smarttech-identity-string-utils'
    import { PHONE_NUMBER, PHONE_NUMBER_ALLOWED_CHAR } from '../services/regex-patterns'
    import regionMappingService from '../services/region-mapping-service'
    import { DEFAULT as GLOBAL_REGION } from 'smarttech-identity-environment'
    import { bus } from '../event-bus'

    export default {
        name: 'OrgInformationForm',
        props: {
            openForm: {
                type: Object,
                default: function () {
                    return { isFormOpen: false, source: '', readOnly: false } // source: 'claim' or 'updateOrg'
                }
            }
        },
        data () {
            return {
                requiredRule: v => !!v || this.$t('applicationErrors.required'),
                xssCharRule: v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                orgNameXssRule: v => entitlement.validateOrgName(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                phoneRule: v => {
                    if (strUtils.isBlank(v)) {
                        return true
                    } else {
                        let digitsOnly = v.replace(/[xX()-]/g, '')
                        let allowedOnly = v.replace(PHONE_NUMBER_ALLOWED_CHAR, '')
                        return digitsOnly.length
                            ? PHONE_NUMBER.test(allowedOnly) ? true : this.$t('formErrors.canOnlyInclude')
                            : this.$t('formErrors.mustIncludeDigit')
                    }
                },
                postalRule: v => entitlement.validatePostalCode(v) ||
                                    this.$t('formErrors.canOnlyInclude').replace('x 0-9 ( ) -', 'a-Z 0-9 -'),
                dataLocationWaring: v => this.$t('management.regionSelectionDialog.migrationWarning'),
                countryCodes: regionMappingService.getCountryCodes(),
                regions: regionMappingService.getRegionStrings(),
                phoneNoInput: '',
                industryList: [],
                isCountryInitialized: false
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            name: {
                get () {
                    return this.activation.userAccount.name
                },
                set (value) {
                    this.$store.commit('setCreatedOrgName', value)
                }
            },
            address: {
                get () {
                    return this.activation.userAccount.address1
                },
                set (value) {
                    this.$store.commit('setCreatedOrgAddress', value)
                }
            },
            city: {
                get () {
                    return this.activation.userAccount.city
                },
                set (value) {
                    this.$store.commit('setCreatedOrgCity', value)
                }
            },
            country: {
                get () {
                    return this.activation.userAccount.country
                },
                set (value) {
                    this.$store.commit('setCreatedOrgState', '')
                    this.$store.commit('setCreatedOrgCountry', value)
                }
            },
            zipPostal: {
                get () {
                    return this.activation.userAccount.zipPostal
                },
                set (value) {
                    this.$store.commit('setCreatedOrgZipPostal', value.replace(/[\s]/g, ''))
                }
            },
            phone: {
                get () {
                    return strUtils.isBlank(this.phoneNoInput) ? this.activation.userAccount.phone : this.phoneNoInput
                },
                set (value) {
                    this.phoneNoInput = value
                    let cleanPhoneNo = value.replace(PHONE_NUMBER_ALLOWED_CHAR, '')
                    if (strUtils.isNotBlank(cleanPhoneNo)) {
                        value = cleanPhoneNo
                    }
                    this.$store.commit('setCreatedOrgPhone', value)
                }
            },
            state: {
                get () {
                    return this.activation.userAccount.state
                },
                set (value) {
                    this.$store.commit('setCreatedOrgState', value)
                }
            },
            industry: {
                get () {
                    return this.activation.userAccount.industry
                },
                set (value) {
                    this.$store.commit('setCreatedOrgIndustry', value)
                }
            },
            region: {
                get () {
                    return this.activation.userAccount.region
                },
                set (value) {
                    this.$store.commit('setCreatedOrgRegion', value)
                }
            },
            dataLocation: {
                get () {
                    return this.activation.userAccount.dataLocation
                },
                set (value) {
                    this.$store.commit('setCreatedOrgDataLocation', value)
                }
            },
            isNewOrg() {
                return this.openForm.source === 'claim'
            },
            readOnly() {
                return this.openForm.readOnly
            },
            regionName() {
                return this.regions.find(region => region.code === this.region)?.name
            },
            hasStateDropdown () {
                return (this.activation.userAccount.country === 'CA' || this.activation.userAccount.country === 'US')
            },
            stateCodes () {
                if (regionMappingService.getStates()[this.activation.userAccount.country]) {
                    return regionMappingService.getStates()[this.activation.userAccount.country]
                }
                return []
            },
            dataLocations () {
                let currentRegion = this.activation.userAccount.region
                let regionMap = regionMappingService.getRegionInfoByRegionName()
                if (regionMap.has(currentRegion)) {
                    return regionMap.get(currentRegion).countries
                }
                return []
            },
            hasDataLocationDropdown () {
                if (this.activation.userAccount.country && this.activation.userAccount.region) {
                    let regionAndCountryMatched = this.activation.userAccount.region ===
                        regionMappingService.getRegionFromCountryCode(this.activation.userAccount.country)

                    if (regionAndCountryMatched && this.activation.userAccount.dataLocation !== '') {
                        // If region and country are matched, data location should be set to empty string.
                        this.$store.commit('setCreatedOrgDataLocation', '')
                    }

                    // data location will be shown when region and country are NOT matched
                    return !regionAndCountryMatched
                }
                return false // default is not showing data location dropdown
            }
        },
        mounted () {
            this.industryList = Object.freeze(this.getIndustryList())
            if (!this.isNewOrg) {
                this.isCountryInitialized = strUtils.isNotBlank(this.country) // Check if country exists in SF

                // If country doesn't exist and region is null, set region to global.
                if (!this.isCountryInitialized && strUtils.isBlank(this.region)) {
                    this.region = GLOBAL_REGION
                }
            }

            bus.$on('close-org-dialog', () => {
                this.$refs.orgInfoForm.resetValidation()
            })
        },
        watch: {
            country(newCountry, previousCountry) {
                // Skip region changing logic when org already has a country and a region when initialization.
                if (!this.isNewOrg && this.isCountryInitialized && strUtils.isNotBlank(this.region)) {
                    this.isCountryInitialized = false
                    return
                }

                let previousCountrysRegion = regionMappingService.getRegionFromCountryCode(previousCountry)
                let newCountrysRegion = regionMappingService.getRegionFromCountryCode(newCountry)

                if (!newCountrysRegion) {
                    this.region = GLOBAL_REGION
                } else if (previousCountrysRegion !== newCountrysRegion &&
                    this.activation.userAccount.region !== newCountrysRegion) {
                    this.$store.commit('setCreatedOrgRegion', newCountrysRegion)
                } // else: changed country region is the same as before
                this.triggerFormValidation()
            }
        },
        methods: {
            triggerFormValidation () {
                this.$nextTick(() => {
                    if (!this.isNewOrg) {
                        this.$refs.orgInfoForm.validate()
                    }
                })
            },
            getIndustryList () {
                let initial = this.$t('activation.assignEntitlement.createOrg.industryChoices')
                let english = enIndustryList.activation.assignEntitlement.createOrg.industryChoices
                let returnList = []
                for (let i = 0; i < initial.length; i++) {
                    returnList.push({
                        header: initial[i].title
                    })
                    for (let j = 0; j < initial[i].choice.length; j++) {
                        returnList.push({
                            name: initial[i].choice[j],
                            value: english[i].choice[j]
                        })
                    }
                }
                return returnList
            },
            openLearnMore () {
                window.open(this.$LINKS.REGION_SELECTION_LEARN_MORE)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../theming/main.scss";
    .dropdown {
        padding-left: 10px;
        width: 100px;
    }

    .required-color {
        color: $theme-error;
    }

    .selector {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .region-container {
        display: flex;
        justify-content: space-between;
    }

    .learn-more-icon {
        color: $theme-primary !important;
    }

    .data-location-dropdown {
       word-break: break-word;
    }

</style>
