<template>
    <div class="assign-entitlement">
        <v-layout align-center column fill-height overflow-y-hidden>
            <v-flex class="top-container">
                <v-flex v-if="isStockingFlow" class="width-100">
                    <div class="claim-flow-header pb-4">{{ stockingFlowHeaderText }}</div>
                    <div class="left-align pb-15">
                        <v-layout v-for="(product, index) in reducedProductList" :key="product.id">
                            <v-flex :class="{clickable: shouldShowOverflowProduct(index)}" @click="showOverflowProductDialog = shouldShowOverflowProduct(index)">{{ product.Name }}</v-flex>
                            <v-flex v-if="!isHybridViewEnabled" class="right-align">{{ product.Quantity }} {{ $t('activation.assignEntitlement.seats') }}</v-flex>
                            <v-flex v-else class="right-align">{{ product.Quantity }} {{ $t('activation.assignEntitlement.entitlements') }}</v-flex>
                        </v-layout>
                    </div>
                    <div class="description left-align pt-10 pb-3">
                        <span class="required">*</span>
                        {{ $t('activation.assignEntitlement.assignDescription') }}
                    </div>
                </v-flex>
                <v-flex v-else class="width-100">
                    <div class="claim-flow-header text--center pb-4">{{ nonStockingFlowHeaderText }}</div>
                    <v-layout class="pos-relative" v-for="(product, index) in reducedProductList" :key="product.id">
                        <v-flex :class="{clickable: shouldShowOverflowProduct(index)}"
                                @click="showOverflowProductDialog = shouldShowOverflowProduct(index)">
                            {{ product.Name }}
                        </v-flex>
                        <v-flex v-if="!isHybridViewEnabled" class="right-align">{{ product.Quantity }} {{ $t('activation.assignEntitlement.seats') }}</v-flex>
                        <v-flex v-else class="right-align">{{ product.Quantity }} {{ $t('activation.assignEntitlement.entitlements') }}</v-flex>
                    </v-layout>
                    <div class="claim-flow-subheader text--center"><span class="required">* </span>
                        {{ $t('activation.assignEntitlement.urlFlow.assignDescription') }}
                    </div>
                </v-flex>
                <div class="width-100">
                    <div :class="['custom-input-box', 'pos-relative', {'placeholder':!validateCheck}]" @click="openDialog">
                        <div class="width-90">
                            <span class="text-ellipsis">{{ selectedOrg }}</span>
                        </div>
                        <span v-if="showSubscriptionsLoadingSpinner" class="pos-absolute playlist-icon-position">
                            <v-progress-circular indeterminate size="25" width="3" color="#159bd9"></v-progress-circular>
                        </span>
                        <v-icon v-else class="pos-absolute playlist-icon-position clickable icon-primary">playlist_add</v-icon>
                    </div>
                </div>
                <v-dialog width="575px" scrollable v-model="dialog">
                    <v-card class="width-100">
                        <v-card-title class="org-select-box">{{ $t('activation.assignEntitlement.inputPlaceHolder') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-toolbar v-show="this.allOrgs.all.length > 0" flat dense color="white">
                            <v-text-field prepend-icon="search"
                                        flat
                                        solo
                                        v-model="searchParam"
                                        v-on:input="filterOrgList"
                                        :placeholder="$t('activation.assignEntitlement.searchOrgs')"
                                        hide-details
                                        single-line>
                            </v-text-field>
                        </v-toolbar>
                        <v-card-text id="org-list"
                                    v-on:scroll.prevent="handleScroll"
                                    class="dialog-max-height"
                                    v-if="this.allOrgs.all.length > 0">
                                    <ul class="org-list">
                            <li v-for="org in this.organizationList" v-on:click="selectedOrg = org" :key="org.id">
                                <a class="list-item-wrapper">
                                    <div class="list-item">
                                        <div class="list-item-text">{{ org.name }}</div>
                                    </div>
                                </a>
                            </li>

                            </ul>
                        </v-card-text>
                        <v-card-text v-else class="dialog-max-height">
                            <v-layout align-center justify-center column fill-height>
                                <div class="width-100">
                                    <div class="claim-flow-subheader text--center">
                                        {{ $t('activation.assignEntitlement.noOrgs.header') }}
                                    </div>
                                    <div class="description no-org-add-org-description" v-html="noOrgAddOrgDescription"/>
                                    <div class="description no-org-personal-header">
                                        {{ $t('activation.assignEntitlement.noOrgs.personalHeader') }}
                                    </div>
                                    <div class="description" v-html="noOrgAddPersonalDescription"/>
                                </div>
                            </v-layout>

                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-layout class="add-org-box" v-on:click="createOrg" align-center justify-start fill-height>
                                <v-icon large color="primary" class="icon-padding">add</v-icon>
                                {{ $t('activation.assignEntitlement.addOrganization') }}
                            </v-layout>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog width="575px" persistent scrollable v-model="showCreateOrgForm">
                    <v-card>
                        <v-card-title class="add-org-dialog pos-relative">
                            {{ $t('activation.assignEntitlement.addOrganization') }}
                            <v-icon class="exit-button" v-on:click="closeOrgDialog()">clear</v-icon>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <org-information v-if="isOnlyDslEnabled" :open-form="orgFormProp"/>
                            <create-organization-form v-else :open-form="orgFormProp"></create-organization-form>
                        </v-card-text>
                        <div class="dialog-btn-padding">
                            <v-btn :disabled="!validateCreatedOrg"
                                class="primary white--text activation-button large btn--block"
                                v-on:click="addOrganization">
                                {{ $t('buttons.addOrganization') }}
                            </v-btn>
                        </div>
                    </v-card>
                </v-dialog>
                <div v-if="showErrorText" class="error-text" v-html="alreadySubscribedErrorText"></div>
            </v-flex>

            <v-flex class="button-container">
                <div class="description" v-if="!isStockingFlow && !isHybridViewEnabled">{{ $t('activation.claim.description') }}</div>
                <v-btn :disabled="!validateCheck || showCreateOrgForm || dialog || loading"
                       class="activation-button large primary white--text btn--block"
                       :loading="loading" v-on:click="next">
                    {{ buttonText }}
                </v-btn>
            </v-flex>
        </v-layout>
        <v-dialog max-width="576px" v-model="showOverflowProductDialog">
            <v-card>
                <v-card-title class='text-h5'>
                    <v-flex xs10>{{ $t('activation.assignEntitlement.otherProductTitle') }}</v-flex>
                </v-card-title>
                <v-card-text class="product-overflow-information">
                    <div v-for="product in productListOverThreshold" :key="product.id">
                        <v-layout nowrap>
                            <v-flex xs8>{{ product.Name }}</v-flex>
                            <v-flex v-if="!isHybridViewEnabled" class="right-align" xs4>{{ product.Quantity }}
                                {{ $t('activation.assignEntitlement.seats') }}
                            </v-flex>
                            <v-flex v-else class="right-align" xs4>{{ product.Quantity }}
                                {{ $t('activation.assignEntitlement.entitlements') }}
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-layout>
                        <v-flex xs12>
                            <v-btn block class='white--text darken-1' primary
                                   v-on:click="showOverflowProductDialog = false">
                                {{ $t('buttons.ok') }}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { entitlement } from '../../../utils/validate-utils'
    import CreateOrganizationForm from './CreateOrganizationForm.vue'
    import OrgInformation from '../../OrgInformation.vue'
    import { AppStates } from '../../../enums'
    import { ClaimErrorUtils } from '../../../utils/claim-error-util'
    import PermissionValidator from '../../../utils/permission-validator'
    import { PERMISSIONS } from '../../../enums/admin-management-constants'
    import ErrorHandler from '../../../utils/error-handler'
    import { bus } from '../../../event-bus'
    import { isEnabled } from '../../../services/feature-switch'
    import { HYBRID_VIEW, ONLY_DSL, DEBUG_NON_STOCKING_CLAIM_FLOW } from '../../../enums/feature-switch-constants'
    import { EDUCATION_SW_PART_NUMBERS } from '../../../services/regex-patterns'
    import LicenseRequestService from '../../../services/license-request-service'
    import SubscriptionUpdater from '../../../utils/subscription-updater'

    let sortByName = function (a, b) {
        let lName = a.name.toLowerCase()
        let rName = b.name.toLowerCase()
        if (lName < rName) {
            return -1
        }
        if (lName > rName) {
            return 1
        }
        return 0
    }

    export default {
        name: 'AssignEntitlement',
        components: {
            CreateOrganizationForm,
            OrgInformation
        },
        props: ['stepId'],
        data () {
            return {
                dialog: false,
                selected: '',
                showCreateOrgForm: false,
                radioGroup: 'org',
                loading: false,
                productOverflowThreshold: 4,
                productListIncludeIndex: 3,
                showOverflowProductDialog: false,
                batchSize: 20,
                searchParam: '',
                cachedSearchParam: '',
                allOrgs: {
                    loaded: [],
                    all: [],
                    orgsLoaded: 0
                },
                filteredOrgs: {
                    loaded: [],
                    all: [],
                    orgsLoaded: 0
                },
                showSubscriptionsLoadingSpinner: false
            }
        },
        computed: {
            ...mapState([
                'organizations',
                'activation'
            ]),
            ...mapGetters([
                'orgHasSLSSubscription',
                'appState',
                'getDirectOrganizations',
                'orgMsdSubscription',
                'orgHasSubscriptionsLoaded'
            ]),
            isOnlyDslEnabled () {
                return !!isEnabled(ONLY_DSL)
            },
            selectedOrg: {
                get () {
                    if (strUtils.isBlank(this.activation.selectedOrganizationName)) {
                        if (strUtils.isBlank(this.activation.userAccount.name) || !this.validateCheck) {
                            return this.$t('activation.assignEntitlement.inputPlaceHolder')
                        }
                        return this.activation.userAccount.name
                    }
                    return this.activation.selectedOrganizationName
                },
                set (value) {
                    const organizationId = value.id
                    if (!this.orgHasSubscriptionsLoaded(organizationId)) {
                        this.showSubscriptionsLoadingSpinner = true
                        this.FetchSubscriptionsForOrganizationHybrid({ organizationId, respListener: this.onSubscriptionUpdateReceived })
                    }
                    this.setSelectedOrganizationId(organizationId)
                    this.setSelectedOrganizationName(value.name)
                    this.dialog = false
                }
            },
            alreadySubscribedErrorText() {
                const subscriptionName = this.$t('activation.assignEntitlement.educationSubscription')
                return this.$t('activation.assignEntitlement.orgAlreadySubscribed', {
                    organization: this.activation.selectedOrganizationName,
                    subscriptionName,
                    link: this.$LINKS.SUPPORT
                })
            },
            stockingFlowHeaderText() {
                if (this.isHybridViewEnabled) {
                    return this.$t('activation.assignEntitlement.titleHybrid')
                }
                return this.$t('activation.assignEntitlement.title')
            },
            nonStockingFlowHeaderText() {
                if (this.isHybridViewEnabled) {
                    return this.$t('activation.assignEntitlement.urlFlow.titleHybrid')
                }
                return this.$t('activation.assignEntitlement.urlFlow.title')
            },
            isHybridViewEnabled() {
                return isEnabled(HYBRID_VIEW)
            },
            orgFormProp () {
                return {
                    isFormOpen: this.showCreateOrgForm,
                    source: 'claim'
                }
            },
            productThresholdMet () {
                return this.activation.productList.length > this.productOverflowThreshold
            },
            organizationList () {
                return strUtils.isBlank(this.searchParam) ? this.allOrgs.loaded : this.filteredOrgs.loaded
            },
            validateCreatedOrg () {
                return entitlement.validateCreatedOrganization(this.activation.userAccount)
            },
            validateCheck () {
                return strUtils.isNotBlank(this.activation.selectedOrganizationId) ? !this.validateCreatedOrg : this.validateCreatedOrg
            },
            productList () {
                return this.reducedProductList.map(product => {
                    return strUtils.format(this.$t('activation.assignEntitlement.description'),
                        product.quantity || product.Quantity,
                        product.name || product.Name)
                })
            },
            productListOverThreshold () {
                if (this.productThresholdMet) {
                    return this.activation.productList.slice(this.productListIncludeIndex, this.activation.productList.length)
                }
                return []
            },
            reducedProductList () {
                let returnList = []
                let productList = this.activation.productList
                if (!this.productThresholdMet) {
                    return productList
                }
                for (let i = 0; i < productList.length; i++) {
                    if (i < this.productListIncludeIndex) {
                        returnList.push({
                            Name: productList[i].Name,
                            Quantity: productList[i].Quantity
                        })
                    } else if (i === this.productListIncludeIndex) {
                        returnList.push({
                            Name: this.$t('activation.assignEntitlement.productOverflowText'),
                            Quantity: productList[i].Quantity
                        })
                    } else {
                        returnList[this.productListIncludeIndex].Quantity += productList[i].Quantity
                    }
                }
                return returnList
            },
            isStockingFlow () {
                return strUtils.isBlank(this.activation.redeem.code)
            },
            buttonText () {
                return this.$t('buttons.continue')
            },
            noOrgAddOrgDescription () {
                return strUtils.format(this.$t('activation.assignEntitlement.noOrgs.description'),
                    this.$t('activation.assignEntitlement.addOrganization').bold())
            },
            noOrgAddPersonalDescription () {
                return strUtils.format(this.$t('activation.assignEntitlement.noOrgs.personalDescription'),
                    this.$t('activation.assignEntitlement.addOrganization').bold())
            },
            appInitialized () {
                return this.appState
            },
            showErrorText () {
                // Show the warning only when activating education software and the selected org already has another education software assigned.
                const activatingEducationSW = this.activation.productList.find(product => EDUCATION_SW_PART_NUMBERS.test(product.PartNumber))
                return this.isHybridViewEnabled && this.orgHasSLSSubscription(this.activation.selectedOrganizationId) && activatingEducationSW
            }
        },
        created () {
            bus.$on('openCreateOrgForm', this.createOrg)
        },
        beforeDestroy () {
            bus.$off('openCreateOrgForm', this.createOrg)
        },
        watch: {
            appInitialized (newState, oldState) {
                if (newState === AppStates.INITIALIZED) {
                    this.allOrgs.all = this.getDirectOrganizations
                        .filter(org => this.validateClaimingPermissionsForOrg(org.id))
                        .sort(sortByName)
                    this.loadOrganizations()
                }
            }
        },
        methods: {
            ...mapMutations([
                'setSelectedOrganizationId',
                'setSelectedOrganizationName',
                'setCreatedOrgIsIndividual',
                'clearSelectedOrganization',
                'clearActivationUserAccount',
                'setActivationProcessSteps',
                'setOrgWithLoadedSubscriptions'
            ]),
            ...mapActions([
                'claimEntitlement',
                'FetchSubscriptionsForOrganizationHybrid'
            ]),
            onSubscriptionUpdateReceived (updateInfo, isLoadingCompleted) {
                // Use bus to send response event to detach call from request's Promise scope
                if (updateInfo.error) {
                    LicenseRequestService._cancelRequests()
                } else {
                    SubscriptionUpdater.updateSubscription(updateInfo, this.$store)
                    if (isLoadingCompleted) {
                        this.setOrgWithLoadedSubscriptions(updateInfo.orgId)
                    }
                }
                // Show spinner until we receive the education subscriptions response, as the error message depends on that response
                // and not on the legacy subscriptions one
                if (!updateInfo.isLegacy) {
                    this.showSubscriptionsLoadingSpinner = false
                }
            },
            validateClaimingPermissionsForOrg (orgId) {
                return PermissionValidator.validateUserPermission(orgId, PERMISSIONS.ClaimSubscriptions)
            },
            loadOrganizations () {
                let orgList = strUtils.isBlank(this.searchParam) ? this.allOrgs : this.filteredOrgs
                if (!(orgList.loaded.length < orgList.all.length)) {
                    return
                }
                let end = orgList.orgsLoaded + this.batchSize
                end = (end > orgList.all.length) ? orgList.all.length : end
                orgList.loaded = orgList.loaded.concat(orgList.all.slice(orgList.orgsLoaded, end))
                orgList.orgsLoaded = end
            },
            handleScroll (event) {
                let element = event.target
                if (element.scrollTop >= element.scrollHeight - element.clientHeight - 100) {
                    this.loadOrganizations()
                }
            },
            filterOrgList () {
                let query = new RegExp(this.searchParam, 'i')
                this.resetLoadedOrgs(this.filteredOrgs)
                if (!query) {
                    this.filteredOrgs.all = []
                } else if (query.test(this.cachedSearchParam) && (this.searchParam.length > this.cachedSearchParam.length)) {
                    this.filteredOrgs.all = this.filteredOrgs.all.filter(org => query.test(org.name) &&
                        this.validateClaimingPermissionsForOrg(org.id))
                } else {
                    this.filteredOrgs.all = this.getDirectOrganizations.filter(org => query.test(org.name) &&
                        this.validateClaimingPermissionsForOrg(org.id))
                }
                this.cachedSearchParam = this.searchParam.toLowerCase()
                this.loadOrganizations()
            },
            resetLoadedOrgs (orgList) {
                orgList.loaded = []
                orgList.orgsLoaded = 0
            },
            updatePersonalUse () {
                if (this.radioGroup === 'org') {
                    this.setCreatedOrgIsIndividual(false)
                } else {
                    this.setCreatedOrgIsIndividual(true)
                }
            },
            addOrganization () {
                this.clearSelectedOrganization()
                this.showCreateOrgForm = false
            },
            openDialog () {
                this.dialog = true
            },
            createOrg () {
                this.showCreateOrgForm = true
                this.dialog = false
            },
            closeOrgDialog () {
                this.showCreateOrgForm = false
                this.dialog = true
                this.clearActivationUserAccount()
                bus.$emit('close-org-dialog')
            },
            next () {
                if (this.validateCheck) {
                    // if code flow we need to submit claim here.
                    if (this.activation.redeem.code.length) {
                        return this.submitClaim()
                    } else {
                        this.setActivationProcessSteps(this.stepId + 1)
                    }
                }
            },
            submitClaim () {
                this.loading = true
                return this.claimEntitlement()
                    .then(result => {
                        this.loading = false
                        this.setActivationProcessSteps(this.stepId + 1)
                    })
                    .catch(err => {
                        // Fake error used to test the new partial claim activation error flow
                        if (isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
                            const subscriptionName = 'Fake Subscription Name'
                            const subscriptionId = 'fakeSubId1'
                            err.message = this.$t(ClaimErrorUtils.getErrorMessage('OTHERTIEREXIST'), { subscriptionName, subscriptionId })
                            err.traceId = 'fakeErrorTraceId1'
                            ErrorHandler.handleError(err, ErrorHandler.otherSLSExistsClaimError)
                        } else {
                            const productsNumber = this.activation.productList.length
                            const { message, stringParams, errorGroup } = ClaimErrorUtils.getClaimError({
                                err,
                                productsNumber,
                                productList: this.activation.productList,
                                orgMsdSubscription: this.orgMsdSubscription(this.activation.selectedOrganizationId)
                            })
                            err.message = this.$tc(message, productsNumber, stringParams)
                            ErrorHandler.handleError(err, errorGroup)
                        }
                        this.loading = false
                    })
            },
            shouldShowOverflowProduct (index) {
                return index + 1 === this.productOverflowThreshold && this.productThresholdMet
            }
        },
        mounted () {
            this.selected = this.activation.selectedOrganizationId
            if (this.appState === AppStates.INITIALIZED) {
                this.allOrgs.all = this.getDirectOrganizations
                    .filter(org => this.validateClaimingPermissionsForOrg(org.id))
                    .sort(sortByName)
                this.loadOrganizations()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../theming/main.scss";

    .claim-flow-header {
        color: $pallete-dark-gray;
        font-size: 1.5em;
    }

    .claim-flow-subheader {
        color: $pallete-dark-gray;
        font-size: 18px;
        padding-top: 80px;
        padding-bottom: 25px;
    }

    .assign-entitlement {
        height: 100%;
        width: 605px;
    }

    .top-container {
        width: 100%;
        padding: 0 15px;
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        .custom-input-box {
            width: 100%;
            cursor: pointer;
            min-height: 45px;
            padding: 13px;
            border: solid 1px #19a1d3;
            background-color: #ffffff;
            position: relative;
            overflow: hidden;
            &:not(.placeholder) {
                .text-ellipsis {
                    color: $pallete-dark-gray;
                    font-weight: bold;
                }
            }
        }
    }

    .button-container {
        width: 100%;
        flex: 0 0 auto;
        padding: 10px 15px 0 15px;

        .activation-button {
            margin: 0;
            padding: 0;
        }
    }

    .org-list {
        list-style-type: none;
    }

    .list-item-wrapper {
        width: 100%;
        height: 40px;
        display: inline-block;
        &:hover {
            background: lightgray;
        }
    }

    .list-item {
        height: 100%;
        width: 100%;
        display: inline-block;
    }

    .list-item-text {
        padding-left: 10px;
        padding-top: 9.5px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
    }

    .add-org-box {
        cursor: pointer;
        font-size: 22px;
        line-height: 3.18;
        color: rgba(0, 1, 2, 0.7);
    }

    .org-select-box {
        font-size: 22px;
        line-height: 0.82;
        padding: 20px 40px;
        color: rgba(0, 1, 2, 0.7);
    }

    .add-org-dialog {
        font-size: 22px;
        line-height: 0.82;
        padding: 20px 40px;
        color: rgba(0, 1, 2, 0.7);
    }

    .exit-button {
        top: 10px;
        right: 10px;
    }

    .dialog-max-height {
        height: 80vh;
        max-height: 915px;
    }

    .playlist-icon-position {
        right: 10px;
        top: 25%;
    }

    .no-org-add-org-description {
        padding: 30px 0px;
    }

    .no-org-personal-header {
        padding: 30px 0 0 0;
    }

    .icon-padding {
        padding: 10px;
    }

    .clickable {
        cursor: pointer;
        color: #017db7;
    }

    .clickable:hover {
        text-decoration: underline;
    }

    .product-overflow-information {
        max-height: 400px;
        overflow: auto;
    }

    .width-90 {
        width: 90%;
    }

    .dialog-btn-padding {
        padding: 27px;
    }

    .error-text {
        color: red !important;
        padding-top: 15px !important;
    }
</style>
