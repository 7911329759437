<template>
    <VDropdown class="callout"
                :shown="displayCallout"
                :disabled="!displayCallout"
                :autoHide="false"
                :distance="distance"
                :placement="popoverProps.placement">
        <slot name="outerPopper"></slot>
        <template #popper>
            <callout-inner
            :innerProps="innerProps"
             :progress-indicator="getProgressIndicator"
             @onDismiss="onDismiss"
             @onNextClicked="onNextClicked">
        </callout-inner>
        </template>
    </VDropdown>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CalloutInner from './CalloutInner.vue'
import { SHOW_MANAGE_USERS_ONBOARDING } from '../../enums/feature-switch-constants'
import { isEnabled } from '../../services/feature-switch'

const USERS = 'users'
const DEFAULT_DISTANCE = 20

export default {
    components: {
        CalloutInner
    },
    props: {
        popoverProps: {
            type: Object,
            default: () => ({
            })
        },
        disableAtWidth: {
            type: Number,
            default: 650
        }
    },
    data() {
        return {
            isDisabled: false
        }
    },
    computed: {
        ...mapGetters([
            'getIsModalOpen',
            'shouldTriggerOnboarding',
            'getManageUsersOnboardingStep',
            'getOnboardingTotalSteps',
            'getIsOnboardingActiveFlow'
        ]),
        innerProps() {
            return {
                title: this.popoverProps.title,
                description: this.popoverProps.description
            }
        },
        getProgressIndicator() {
            return {
                current: this.getManageUsersOnboardingStep,
                total: this.getOnboardingTotalSteps
            }
        },
        distance() {
            return this.popoverProps.distance || DEFAULT_DISTANCE
        },
        displayCallout() {
            return this.shouldTriggerOnboarding && !this.getIsModalOpen && !this.popoverProps.hide && isEnabled(SHOW_MANAGE_USERS_ONBOARDING) &&
            !!this.popoverProps.step && !this.isDisabled
        },
        isCurrentManageUsersStep() {
            return this.getManageUsersOnboardingStep === this.popoverProps.step
        }
    },
    mounted() {
        // Activate the onboarding flow when the current step is active, and the callout is being displayed.
        // This ensures that the onboarding won't trigger when switching to another route and the callout isn't
        // displayed for any reason, such as a non-spark org.
        if (this.isCurrentManageUsersStep) {
            this.$store.commit('setIsOnboardingActiveFlow', this.displayCallout)
        }
        if (window.innerWidth < this.disableAtWidth) {
            this.isDisabled = true
        }
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    watch: {
        '$route' (to, from) {
            // When we shift from a route to another, we skip the onboarding by groups
            if (this.getIsOnboardingActiveFlow && from.name === USERS) {
                this.onSkipGroup()
            } else if (this.getIsOnboardingActiveFlow && (this.getManageUsersOnboardingStep === 3 || this.getManageUsersOnboardingStep === 4)) {
                this.UpdateOnboardingStepOptimistically({ manageUsersOnboardingStep: 0 })
            }
        },
        displayCallout (newVal) {
            if (this.isCurrentManageUsersStep) {
                this.$store.commit('setIsOnboardingActiveFlow', newVal)
            }
        }
    },
    methods: {
        ...mapActions([
            'UpdateOnboardingStepOptimistically'
        ]),
        onResize() {
            if (window.innerWidth < this.disableAtWidth) {
                this.onDismiss()
            }
        },
        onDismiss() {
            this.UpdateOnboardingStepOptimistically({ manageUsersOnboardingStep: 0 })
        },
        onSkipGroup() {
            if (this.getManageUsersOnboardingStep === 1 || this.getManageUsersOnboardingStep === 2) {
                this.UpdateOnboardingStepOptimistically({ manageUsersOnboardingStep: 3 })
            } else {
                this.UpdateOnboardingStepOptimistically({ manageUsersOnboardingStep: 0 })
            }
        },
        onNextClicked() {
            if (this.getManageUsersOnboardingStep === this.getOnboardingTotalSteps) {
                this.onDismiss()
            } else {
                this.UpdateOnboardingStepOptimistically({ manageUsersOnboardingStep: (this.getManageUsersOnboardingStep + 1) })
            }
        }
    }
}

</script>
<style lang="scss">
$arrow-size: 18px;
$arrow-size-offset: -$arrow-size + 1;

.v-popper__popper {
    [class*='text-'] {
            font-family: Arial, sans-serif !important;
    }
    font-family: Arial, sans-serif !important;
}
.v-popper--theme-dropdown .v-popper__inner {
    border-radius: 0px !important;
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    border-width: 0 $arrow-size $arrow-size $arrow-size;
    top: $arrow-size-offset !important;
    left: -10px !important;
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner {
    border-width: 0 $arrow-size $arrow-size $arrow-size;
    top: $arrow-size-offset !important;
    left: -10px !important;
}

.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer {
    top: -10px !important;
    left: $arrow-size-offset !important;
    border-width: $arrow-size $arrow-size $arrow-size 0;
}

.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner {
    top: -10px !important;
    left: $arrow-size-offset !important;
    border-width: $arrow-size $arrow-size $arrow-size 0;
}

.callout {
    height: fit-content;
}

</style>
