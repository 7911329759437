<template>
    <div>
        <v-card-text v-if="!isClaimFlow" class="text-center px-12">{{ descriptionText }}</v-card-text>
        <v-card-text :class="{'px-12': !isClaimFlow}">
            <div class="py-0">
                <org-information-form :open-form="openForm"/>
            </div>
        </v-card-text>
    </div>
</template>

<script>
    import OrgInformationForm from './OrgInformationForm.vue'
    import { ADMIN, TECH_INSTRUCTOR, SUPERVISOR } from '../enums/admin-management-constants'

    export default {
        name: 'OrgInformation',
        components: {
            OrgInformationForm
        },
        props: {
            openForm: {
                type: Object,
                required: true
            },
            role: {
                type: String,
                default: ADMIN,
                validator: (value) => {
                    return [ADMIN, TECH_INSTRUCTOR, SUPERVISOR].includes(value)
                }
            }
        },
        computed: {
            isClaimFlow() {
                return this.openForm.source === 'claim'
            },
            descriptionText() {
                return this.role === ADMIN ? this.$t('management.orgUpdateDialogNew.descriptionUpdate') : this.$t('management.orgUpdateDialogNew.descriptionView')
            }
        }
    }
</script>
