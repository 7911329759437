import TranslationService from '../services/translation-service.js'

const END_OF_DAY_HOURS = 23
const END_OF_DAY_MINUTES = 59
const END_OF_DAY_SECONDS = 59
const END_OF_DAY_MILLIS = 999

export default {
    resolveDateEndOfDay (dateString) {
        return this.resolveDate(dateString, true)
    },
    resolveDate (dateString, endOfDay) {
        if (dateString && dateString.length) {
            let dateParts = dateString.split('-')
            let date

            if (endOfDay) {
                date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2],
                    END_OF_DAY_HOURS, END_OF_DAY_MINUTES, END_OF_DAY_SECONDS, END_OF_DAY_MILLIS)
            } else {
                date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
            }

            if (!isNaN(date.getTime())) {
                // ensure we have a valid date
                return date
            }
        }

        return null
    },
    getFormattedDate (date, options) {
        if (date) {
            return date.toLocaleDateString(TranslationService.getLocale(), {
                day: options?.day || 'numeric',
                month: options?.month || 'short',
                year: options?.year || 'numeric'
            })
        }
    }
}
