import { SubscriptionStates } from '../enums/'

const STATUS_ORDER = [SubscriptionStates.ACTIVE, SubscriptionStates.UNKNOWN, SubscriptionStates.EXPIRED]

export default {
    canSortByDate (a, b) {
        return !!(a.usageEndDate && b.usageEndDate)
    },
    canSortByStatus (a, b) {
        return a.status !== b.status
    },
    sortByDate (a, b) {
        return a.usageEndDate - b.usageEndDate
    },
    sortByStatus (a, b) {
        return STATUS_ORDER.indexOf(a.status) - STATUS_ORDER.indexOf(b.status)
    },
    sortBySerial (a, b) {
        return a.serialNumber.localeCompare(b.serialNumber)
    }
}
