<template>
    <v-dialog v-model="openDialog" width="590px" persistent content-class="edit-users-library-role-modal overflow-visible">
        <v-card class="pa-0 d-flex flex-column" color="#F3F3F3">
            <div class="close-modal-btn">
                <v-btn absolute top right large icon text color="#d5d5d5" @click.native="onCloseDialog">
                    <v-icon large color="#d5d5d5">close</v-icon>
                </v-btn>
            </div>
            <v-card-title class="px-8 my-4 text-h6 font-weight-bold">{{ $t('users.districtLibrary.editLibraryRoles.title') }}</v-card-title>
            <div class="form-wrapper px-8 py-4">
                <div class="change-all-users pl-2 pr-2 mb-4" v-if="selectedUsers.length > 1">
                    <v-layout>
                        <v-flex xs12 sm6 class="align-center">
                            <div class="label"> {{ $t('users.districtLibrary.editLibraryRoles.changeAllUsersRoleTo') }} </div>
                        </v-flex>
                        <v-flex xs12 sm6 class="align-center">
                            <div class="dropdown library-role-dropdown">
                                <v-select
                                    class="border selector overflow-visible"
                                    text solo
                                    :items="libraryRolesListWithEmptyOption"
                                    item-value="key"
                                    item-text="name"
                                    hide-details
                                    v-model="changeAllUsersRoleValue"
                                    attach="#libraryrole-dropdown-anchor-all-users"
                                    :label="'--'"
                                    :placeholder="'--'"
                                    @change="onChangeAllUsersRole"
                                >
                                    <template v-slot:selection="{item}">
                                        {{ item.name }}
                                    </template>
                                    <template v-slot:item="{item}">
                                        <div class="role-item">
                                            <div class="role-item-title">{{ item.name }}</div>
                                            <div class="role-item-descriptor">{{ item.description }}</div>
                                        </div>
                                    </template>
                                </v-select>
                                <div id="libraryrole-dropdown-anchor-all-users" class="libraryrole-dropdown-anchor"></div>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="change-per-user">
                    <v-layout class="roles-header align-center">
                        <v-flex xs12>
                            <span class="header-label"> {{ $t('users.displayName') }} </span>
                        </v-flex>
                        <v-flex xs12>
                            <v-layout align-center>
                                    <v-flex class="text-truncate">
                                        <span class="header-label">
                                            {{ $t('users.role') }}
                                        </span>
                                    </v-flex>
                                    <v-flex class="text-right reset-all-roles text-truncate">
                                        <v-btn text color="#007AFF" @click="onResetAllRoles" class="reset-all-text-button" :disabled="isPristine">
                                            <span class="header-label">
                                                {{ $tc('users.districtLibrary.editLibraryRoles.resetRoles', selectedUsers.length) }}
                                            </span>
                                        </v-btn>
                                    </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <div class="users-scrollable-wrapper">
                        <div class="users-scrollable">
                            <v-layout class="user-row" pa-2 v-for="(user, index) in selectedUsers" :key="index" :class="index % 2 === 0 ? 'bg-darker' : ''">
                                <v-flex xs12 sm6 class="d-flex align-center pr-3">
                                    <div class="label" :title="getDisplayName(user)"> {{ getDisplayName(user) }} </div>
                                </v-flex>
                                <v-flex xs12 sm6 class="align-center">
                                    <div class="dropdown library-role-dropdown">
                                        <v-select
                                            class="border selector overflow-visible"
                                            text solo
                                            :items="libraryRolesList"
                                            v-model="libraryRoleMapping[user.uid].role"
                                            item-value="key"
                                            item-text="name"
                                            :ref="'libraryrole-dropdown-' + index"
                                            :attach="'#libraryrole-dropdown-anchor-' + index"
                                            hide-details
                                        >
                                            <template v-slot:selection="{item}">
                                                {{ item.name }}
                                            </template>
                                            <template v-slot:item="{item}">
                                                <div class="role-item">
                                                    <div class="role-item-title">{{ item.name }}</div>
                                                    <div class="role-item-descriptor">{{ item.description }}</div>
                                                </div>
                                            </template>
                                        </v-select>
                                        <div :id="'libraryrole-dropdown-anchor-' + index" class="libraryrole-dropdown-anchor"></div>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                </div>
                <div class="action-buttons py-2">
                    <v-layout column>
                        <v-flex xs12 class="text-left" v-if="showNoAdminWarning">
                            <div class="form-notification">
                                <span class="error--text">
                                    {{ $t('users.districtLibrary.editLibraryRoles.noAdminWarning') }}
                                </span>
                            </div>
                        </v-flex>
                        <v-flex xs12>
                            <v-btn
                                large
                                class="white--text darken-1 font-medium"
                                block
                                primary
                                :outlined="isPristine"
                                :disabled="isPristine"
                                :loading="saving"
                                @click.native="onSaveButtonClicked">
                                    {{ $t('buttons.save') }}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            <v-overlay :value="saving" absolute color="white"></v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import { DSL_ROLES } from '../../enums/dsl-constants'
    import { bus } from '../../event-bus'
    import ErrorHandler from '../../utils/error-handler'

    export default {
        name: 'UsersEditLibraryRoleModal',
        data () {
            return {
                openDialog: false,
                changeAllUsersRoleValue: null,
                libraryRoleMapping: {},
                saving: false
            }
        },
        props: {
            selectedUsers: {
                type: Array,
                required: true
            },
            organizationId: {
                type: String,
                required: true
            },
            subscriptionId: {
                type: String,
                required: true
            },
            tierType: {
                type: String,
                required: true
            }
        },
        created () {
            this.openDialog = true
            this.selectedUsers.forEach(user => {
                this.$set(this.libraryRoleMapping, user.uid, {
                    role: user.dlRoles[0]
                })
            })
        },
        watch: {
            allUsersSetToTheSameRole (newVal, oldVal) {
                if (!newVal && oldVal) {
                    this.changeAllUsersRoleValue = null
                }
            }
        },
        computed: {
            libraryRolesList () {
                const libraryRoles = Object.keys(DSL_ROLES)
                const rolesList = libraryRoles.map(role => {
                    return {
                        key: role,
                        name: this.$tc(`users.districtLibrary.dslRoles.${role.toLowerCase()}.name`, 1),
                        description: this.$t(`users.districtLibrary.dslRoles.${role.toLowerCase()}.description`)
                    }
                })
                return rolesList
            },
            libraryRolesListWithEmptyOption () {
                return [
                    {
                        key: null,
                        name: '--',
                        description: ''
                    },
                    ...this.libraryRolesList
                ]
            },
            showNoAdminWarning() {
                const usersWithCurrentChanges = this.$store.getters.users.map(user => {
                    const userWithRoleChangesApplied = this.roleChangesApplied.find(userWithRoleChange => userWithRoleChange.email === user.email)
                    if (userWithRoleChangesApplied) {
                        return {
                            ...user,
                            dlRoles: userWithRoleChangesApplied.dlRoles
                        }
                    } else {
                        return user
                    }
                })
                const noAdminsInUsersWithCurrentChanges = usersWithCurrentChanges.filter(user => {
                    return user.dlRoles.includes(DSL_ROLES.ADMIN)
                }).length === 0
                return !this.isPristine && noAdminsInUsersWithCurrentChanges
            },
            roleChangesApplied() {
                return this.selectedUsers.filter(user => {
                    return this.libraryRoleMapping[user.uid].role !== user.dlRoles[0]
                }).map(user => {
                    return {
                        ...user,
                        dlRoles: [this.libraryRoleMapping[user.uid].role]
                    }
                })
            },
            isPristine () {
                return this.roleChangesApplied.length === 0
            },
            allUsersSetToTheSameRole() {
                const roles = Object.values(this.libraryRoleMapping).map(role => role.role)
                return roles.every(role => role === roles[0])
            }
        },
        methods: {
            onCloseDialog() {
                if (this.isPristine) {
                    this.$emit('close-edit-library-roles-modal', false)
                } else {
                    // Unsaved changes dialog
                    this.openDialog = false
                    bus.$emit('openModal', {
                        title: this.$t('users.districtLibrary.editLibraryRoles.unsavedChangesDialog.title'),
                        message: this.$t('users.districtLibrary.editLibraryRoles.unsavedChangesDialog.message'),
                        primaryActionLabel: this.$t('buttons.leave'),
                        secondaryActionLabel: this.$t('buttons.stay'),
                        primaryButtonRed: true,
                        primaryAction: () => {
                            this.$emit('close-edit-library-roles-modal', false)
                        },
                        secondaryAction: () => {
                            this.$emit('close-dialog')
                            this.openDialog = true
                            this.$nextTick(() => {
                                // In this case the main dialog is temporarily hidden setting openDialog to false in order to show the
                                // unsaved changes dialog so when the user chooses to stay we need to set the state back to open manually
                                this.$store.commit('setIsModalOpen', true)
                            })
                        }
                    })
                }
            },
            getDisplayName(user) {
                return user.name || user.email
            },
            onResetAllRoles () {
                // set back initial values for roles from selectedUsers
                this.selectedUsers.forEach(user => {
                    this.$set(this.libraryRoleMapping[user.uid], 'role', user.dlRoles[0])
                })
                this.changeAllUsersRoleValue = null
            },
            onChangeAllUsersRole () {
                if (!this.changeAllUsersRoleValue) {
                    this.onResetAllRoles()
                    return
                }
                this.selectedUsers.forEach(user => {
                    this.$set(this.libraryRoleMapping[user.uid], 'role', this.changeAllUsersRoleValue)
                })
            },
            onSaveButtonClicked () {
                this.saving = true
                const users = this.roleChangesApplied.map(({ email, dlRoles }) => ({ email, dlRoles }))
                this.$store.dispatch('setUserDistrictLibraryRolesInSubscription', {
                    organizationId: this.organizationId,
                    subscriptionId: this.subscriptionId,
                    users,
                    tierType: this.tierType
                }).then((failedUsers) => {
                    if (failedUsers.length > 0) {
                        // TODO: before PR merge: review with UX if we want to show a notification for failed users
                        // and how should that work
                        console.error('Could not update role for the following users: ', failedUsers.map(user => user.email).join(', '))
                    }
                    this.saving = false
                    this.$emit('close-edit-library-roles-modal', true)
                }).catch(err => {
                    this.saving = false
                    this.$emit('close-edit-library-roles-modal', true)
                    ErrorHandler.handleError(err, ErrorHandler.dialogueError)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../theming/main.scss";

.form-wrapper {
    font-size: 16px;
}
.close-modal-btn {
    position: relative;
}

.users-scrollable-wrapper {
    border-bottom: solid 1px $pallete-offwhite;
    max-height: calc(100vh - 420px);
    height: 440px;
    overflow-y: auto;
}
.users-scrollable {
    .user-row {
        .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.form-notification {
    font-size: 14px;
}
.reset-all-text-button {
    min-width: 0!important;
    max-width: 100%!important;
}
.header-label {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.bg-darker {
    background-color: #E4E4E4;
}
.software-portal .action-buttons {
    .v-btn {
        &.v-btn--disabled[disabled]:not(.v-btn--icon):not(.v-btn--text) {
            border: 2px solid $pallete-offwhite;
            background-color: transparent!important;
            color: $pallete-offwhite;
        }
    }
}
</style>
<style lang="scss">
@import "../../theming/main.scss";
.libraryrole-dropdown-anchor {
    position: relative;
    top: -46px;
}
.library-role-dropdown {
    .v-menu__content {
        max-width: 180px;
        margin-top: 12px;
        border: solid 1px #19a1d3 !important;
    }
    .v-input.v-text-field:not(.v-input--select).v-input--is-focused:not(.input--error) .v-input__slot {
        box-shadow: none!important;
    }
    .v-menu__content .v-list .v-list-item {
        // border bottom of 2px grey except for the last child
        padding: 0 12px;
        &:not(:last-child) {
            border-bottom: 2px solid #E4E4E4;
        }
        &:hover {
            font-weight: normal!important;
            background-color: $pallete-light-gray;
        }
        .role-item {
            padding: 8px 0px;
            color: $pallete-dark-gray;
        }
        .role-item-title {
            font-weight: 700;
            line-height: 16px;
            margin-bottom:12px;
            font-size: 16px;
        }

        .role-item-descriptor {
            font-size: 14px;
            color: rgba(0,0,0,.54);
            font-style: normal;
            line-height: 16px;
        }
    }
    .v-text-field label {
        padding: 0!important;
    }
    .v-input .v-input__control .v-input__slot .v-select__slot .v-select__selections {
        font-size: 16px;
        font-weight: 700;
    }

}
.reset-all-roles .v-btn .v-btn__content {
  max-width: 100%;
}
</style>
