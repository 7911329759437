<template>
    <v-dialog v-model="openCustomOrgRoleDialog" width="30%" persistent>
        <template v-slot:activator="{ on }">
            <div id="rolePerOrgCustomizer"
                v-on="on"
                @click="viewOrgRoleCustomization()"
                class="dialog-component customize-role-activator"
                :class="{'customization-disabled': disabled}">
                <v-icon class="activator"
                    :class="{'activator-disabled': disabled}">group</v-icon>
                <span class="activator activator-text"
                    :class="{'activator-disabled': disabled }">
                    {{ $t('management.rolePerOrgDialog.title') }}
                </span>
            </div>
        </template>
        <v-card>
            <v-card-text>
                <v-card-title class="text-h6 font-weight-bold text--primary">
                    {{ $t('management.rolePerOrgDialog.title') }}
                </v-card-title>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component selection-container-title">
                    <v-layout>
                        <v-flex xs6>
                            {{ $t('management.rolePerOrgDialog.orgName') }}
                        </v-flex>
                        <v-flex xs6>
                            {{ $t('management.adminDialog.role') }}
                        </v-flex>
                    </v-layout>
                </div>
                <div class="dialog-component org-role-selection-container">
                    <RoleSelector v-for="(org, index) in orderedValidOrgs"
                                  class="organization-container"
                                  :key="org.orgId"
                                  :id="org.orgId"
                                  :roleMapping="localOrgRoleMapping"
                                  :isValid="true">
                        <template v-slot:title>
                            <div class="org-title">
                                {{ getOrganizationLabel(org.orgName, index, true) }}
                            </div>
                        </template>
                    </RoleSelector>
                    <RoleSelector v-for="(org, index) in Object.values(invalidPermissionOrgs).sort(orgNameSort)"
                                  class="organization-container"
                                  :key="org.orgId"
                                  :id="org.orgId"
                                  :roleMapping="invalidPermissionOrgs"
                                  :isValid="false">
                        <template v-slot:title>
                            <div class="org-title">
                                {{ getOrganizationLabel(org.orgName, index, false) }}
                            </div>
                        </template>
                    </RoleSelector>
                </div>
            </v-card-text>
            <v-card-text>
                <div class="dialog-component">
                    <v-btn id="saveButton"
                           block primary light
                           class="white--text"
                           :disabled="!hasValidPermissionOrgs()"
                           @click="saveOrgRoleCustomization()">
                        {{ $t('buttons.save') }}
                    </v-btn>
                    <v-btn id="backButton"
                           block outlined
                           @click="closeOrgRoleCustomization()">
                        {{ $t('buttons.back') }}
                        </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import RoleSelector from './RoleSelector'
    import {orgSort} from '../../utils/organization-sorting-util'
    import DeepCopyUtil from '../../utils/deep-copy-util'

    export default {
        name: 'OrgRoleDialog',
        props: ['invalidPermissionOrgs', 'orgAndRoleMapping', 'disabled'],
        components: {RoleSelector},
        data () {
            return {
                openCustomOrgRoleDialog: false,
                localOrgRoleMapping: {}
            }
        },
        watch: {
            'openCustomOrgRoleDialog' (newValue, oldValue) {
                if (newValue === true && oldValue === false) {
                    this.localOrgRoleMapping = DeepCopyUtil.createObjectDeepCopy(this.orgAndRoleMapping)
                }
            }
        },
        computed: {
            orderedValidOrgs () {
                return Object.values(this.localOrgRoleMapping).sort(this.orgNameSort)
            },
            numValidOrgs () {
                return Object.keys(this.localOrgRoleMapping).length
            }
        },
        methods: {
            getOrganizationLabel (orgName, index, isValid) {
                let num = isValid ? (index + 1) : (this.numValidOrgs + index + 1)
                return `${num}. ${orgName}`
            },
            orgNameSort (a, b) {
                return orgSort(a.orgName, b.orgName)
            },
            hasValidPermissionOrgs () {
                return Object.keys(this.localOrgRoleMapping).length > 0
            },
            viewOrgRoleCustomization () {
                this.$emit('orgRoleCustomizationOpened')
                this.openCustomOrgRoleDialog = true
            },
            closeOrgRoleCustomization () {
                this.$emit('orgRoleCustomizationClosed')
                this.resetOrgRoleCustomizationDialog()
            },
            saveOrgRoleCustomization () {
                this.$emit('saveOrgRoleCustomization', this.localOrgRoleMapping)
                this.resetOrgRoleCustomizationDialog()
            },
            resetOrgRoleCustomizationDialog () {
                this.localOrgRoleMapping = {}
                this.openCustomOrgRoleDialog = false
            }
        }
    }
</script>

<style scoped>
    .dialog-component {
        padding: 0px 16px;
        overflow-wrap: break-word;
    }

    .organization-container:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

    .org-role-selection-container {
        max-height: 420px;
        overflow-y: auto;
    }

    .selection-container-title {
        font-weight: bold;
        padding-bottom: 15px;
    }

    .activator-text {
        padding-left: 10px;
        font-weight:bold;
    }

    .activator {
        color:#159bd9 !important;
    }

    .activator-disabled {
        color: #7a8b99 !important;
        opacity: 0.5;
    }

    .org-title {
        height: 100%;
        padding-left: 5px;
        padding-top: 14px;
        width: fit-content;
    }

    .customize-role-activator {
        display: inline-block;
        cursor: pointer;
        align-items: center;
    }

    .customization-disabled {
        pointer-events: none;
    }

    .v-card__title {
        font-size: 16px;
        padding: 16px 16px 10px;
    }
</style>
