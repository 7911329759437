export function scrollToTopOfTable (tableName) {
    document.getElementById(tableName).getElementsByTagName('tbody')[0].scrollTo(0, 0)
};

export function changeSortByOrder (sortOptions, column, allowNoSort = false) {
    if (sortOptions.sortBy === column) {
        if (allowNoSort && sortOptions.sortDesc) {
            sortOptions.sortDesc = null
            sortOptions.sortBy = null
        } else {
            sortOptions.sortDesc = !sortOptions.sortDesc
        }
    } else {
        sortOptions.sortBy = column
        sortOptions.sortDesc = false
    }
};
