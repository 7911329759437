<template>
    <div>
        <v-dialog v-model='open' persistent attach="#modal-mount" :width="model.width">
            <v-card>
                <div v-if="!showComponent">  <!-- Check for custom component && feature flag -->
                    <v-card-title class='text-h6 font-weight-bold text--primary title flex-nowrap'>
                        <v-flex>
                            {{model.title}}
                        </v-flex>
                        <v-flex class="align-self-start">
                            <div class="text--right" v-if='model.closable'>
                                <v-btn class ="v-btn--colorblack" medium icon text @click.native="closeClicked()">
                                    <i class="material-icons md-light md-inactive">clear</i>
                                </v-btn>
                            </div>
                        </v-flex>
                    </v-card-title>
                    <v-card-text>
                        <p v-html="model.message"></p>
                        <div class="trace-id" v-if="model.traceId || model.errorCode">
                            <v-layout>
                                <v-flex xs8>
                                    <small>{{model.traceId}}</small>
                                </v-flex>
                                <v-flex xs4 class="text--right">
                                    <small>{{model.errorCode}}</small>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                    <v-card-text>

                        <v-layout v-if='model.secondaryActionLabel && model.primaryActionLabel' wrap class="modal-buttons">
                            <v-flex xs6>
                                <v-btn class="modal-button" block outlined @click.native='secondaryActionClicked()'>
                                    {{model.secondaryActionLabel}}
                                </v-btn>
                            </v-flex>
                            <v-flex xs6>
                                <v-btn class='modal-button white--text darken-1' :class="{'red-button': model.primaryButtonRed}" block primary @click.native='primaryActionClicked()'>
                                    {{model.primaryActionLabel}}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout v-else-if='model.primaryActionLabel'>
                            <v-flex xs12>
                                <v-btn class='white--text darken-1' block primary @click.native='primaryActionClicked()'>
                                    {{model.primaryActionLabel}}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </div>
                <div v-else>
                    <component :is="model.propComponent" v-bind="model.componentProps" @on-close-modal="closeModal"></component> <!-- Provide an event to close modal -->
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { bus } from '../../event-bus'

    let getDefaultConfiguration = () => {
        return {
            closable: false,
            autoCloseIn: null,
            traceId: '',
            errorCode: '',
            title: '',
            message: '',
            primaryActionLabel: '',
            secondaryActionLabel: '',
            primaryButtonRed: false,
            width: undefined,
            primaryAction: () => {
            },
            secondaryAction: () => {
            },
            closeAction: () => {
            },
            propComponent: () => {
            },
            componentProps: {}
        }
    }

    export default {
        data () {
            return {
                open: false,
                model: getDefaultConfiguration(),
                currErrorCode: ''
            }
        },
        computed: {
            showComponent() {
                return !!Object.keys(this.model.propComponent).length
            }
        },
        created () {
            bus.$on('openModal', this.openModal)
            bus.$on('openClosableModal', this.openClosableModal)
            bus.$on('openPersistedModal', this.openPersistedModal)
            bus.$on('openSelfClosingModal', this.openSelfClosingModal)
        },
        beforeDestroy () {
            bus.$off('openModal', this.openModal)
            bus.$off('openClosableModal', this.openClosableModal)
            bus.$off('openPersistedModal', this.openPersistedModal)
            bus.$off('openSelfClosingModal', this.openSelfClosingModal)
        },
        watch: {
            '$route' (to, from) {
                this.closeModal()
            },
            'open' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        methods: {
            openPersistedModal (options) {
                options = Object.assign({
                    closable: false,
                    autoCloseIn: null
                }, options)

                this.openModal(options)
            },
            openClosableModal (options) {
                options = Object.assign({
                    closable: true
                }, options)

                this.openModal(options)
            },
            openSelfClosingModal (options) {
                options = Object.assign({
                    closable: false,
                    autoCloseIn: 2
                }, options)

                this.openModal(options)
            },
            openModal (options) {
                // Check if an error dialog with same error is already open
                if (this.open && this.currErrorCode === options.errorCode) {
                    return
                }

                if (options.errorCode) {
                    this.currErrorCode = options.errorCode
                }

                let self = this
                self.model = Object.assign(getDefaultConfiguration(), options)

                // this timeout is a workaround for a vuetify issue:
                // the modal backdrop disappears when closing and re-opening in quick succession
                setTimeout(() => {
                    self.open = true
                }, 500)

                if (self.model.autoCloseIn) {
                    setTimeout(() => {
                        self.closeModal()
                    }, self.model.autoCloseIn * 1000)
                }
            },
            closeModal () {
                this.open = false
                if (this.currErrorCode) {
                    this.currErrorCode = ''
                }
            },
            closeClicked () {
                this.closeModal()
                this.model.closeAction()
            },
            primaryActionClicked () {
                this.closeModal()
                this.model.primaryAction()
            },
            secondaryActionClicked () {
                this.closeModal()
                this.model.secondaryAction()
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../theming/main.scss';
    .title {
        word-break: normal;
    }
    .trace-id {
        margin: 5px 0 -5px;
    }

    .modal-buttons {
        .v-btn.modal-button.red-button {
            background-color: $theme-error !important;
            border-color: $theme-error !important;
        }
    }

</style>
