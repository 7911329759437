<template>
    <v-dialog v-model="openDialog" persistent width=30% :disabled="disabled">
        <template v-slot:activator="{ on }">
            <v-btn id="openRemoveUsers" v-on="on" class="white--text" color="red">
                <span v-if="allUsersSelected">{{ $t('removeUsersModal.removeAllUsers') }}</span>
                <span v-else>{{ $tc('removeUsersModal.removeUsers', users.length) }}</span>
            </v-btn>
        </template>
        <v-card id="removeUsersModal">
            <div v-if="allUsersSelected">
                <v-card-title class="text-h5">{{ $t('removeUsersModal.removeAllConfirm') }}</v-card-title>
                <v-card-text v-html="$t('removeUsersModal.removeAllConfirmQuestion')"></v-card-text>
                <v-card-text v-html="$tc('removeUsersModal.removeConfirmText1', users.length)"></v-card-text>
                <template v-if="isSparkSubscription">
                    <v-card-text v-html="$t('removeUsersModal.spark.removeAllConfirmText2')"></v-card-text>
                </template>
            </div>
            <div v-else>
                <template v-if="users.length === 1">
                    <v-card-title class="text-h5">{{ $tc('removeUsersModal.removeConfirm') }}</v-card-title>
                    <v-card-text v-html="$tc('removeUsersModal.removeSingleConfirmQuestion')"></v-card-text>
                    <v-card-text v-html="$tc('removeUsersModal.removeConfirmText1')"></v-card-text>
                    <template v-if="isSparkSubscription">
                        <v-card-text v-if="isRemovingRelevantRoles" v-html="$t('removeUsersModal.spark.removeConfirmText2Single', { roles: rolesRemovedString })"></v-card-text>
                    </template>
                </template>
                <template v-else>
                    <v-card-title class="text-h5">{{ $tc('removeUsersModal.removeConfirm', users.length ) }}</v-card-title>
                    <v-card-text v-html="$tc('removeUsersModal.removeConfirmQuestion', users.length)"></v-card-text>
                    <v-card-text v-html="$tc('removeUsersModal.removeConfirmText1', users.length)"></v-card-text>
                    <template v-if="isSparkSubscription">
                        <v-card-text v-if="isRemovingRelevantRoles" v-html="$t('removeUsersModal.spark.removeConfirmText2', { roles: rolesRemovedString })"></v-card-text>
                    </template>
                </template>
            </div>
            <v-card-text>
                <v-layout>
                    <v-flex xs6>
                        <v-btn id="cancelRemoveUsers"
                               outlined block
                               :disabled="loading"
                               @click.native="openDialog = false">
                                   {{ $t('buttons.cancel') }}
                        </v-btn>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn id="removeUsers"
                               error light block
                               class="white--text red"
                               :loading="loading"
                               :disabled="loading"
                               @click.native="removeSelectedUsers();">
                                   {{ $t('buttons.remove') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {bus} from '../../event-bus'
    import ErrorHandler from '../../utils/error-handler'
    import { TIER_TYPES } from '../../enums/subscription-constants'
    import { DSL_ROLES } from '../../enums/dsl-constants'

    export default {
        props: ['organizationId', 'subscriptionId', 'disabled', 'users', 'success', 'allUsersSelected', 'tierType'],
        data () {
            return {
                openDialog: false,
                loading: false,
                selected: []
            }
        },
        computed: {
            isSparkSubscription () {
                return this.tierType === TIER_TYPES.SPARK
            },
            totalUsersInSubscription () {
                let subscription = this.$store.getters.getSubscriptionById(this.subscriptionId)
                return subscription ? subscription.assignedUsersCount : 0
            },
            successCallback () {
                if (typeof this.success !== 'function') {
                    return () => {}
                }

                return this.success
            },
            isRemovingRelevantRoles () {
                return this.relevantRolesRemoved.length > 0
            },
            relevantRolesRemoved () {
                const libraryRolesTriggeringWarningMessage = [DSL_ROLES.ADMIN, DSL_ROLES.EDITOR]
                const allUsers = this.$store.getters.users
                let rolesPresent = []
                // Include a role only if the amount of users we are about to remove having this role is the total amount of users having this role in the organization
                // as in that case the library would experience a loss of functionalities
                libraryRolesTriggeringWarningMessage.forEach(role => {
                    const totalUsersCountForRole = allUsers.filter(user => user.dlRoles.includes(role)).length
                    const selectedUsersCountForRole = this.users.filter(user => user.dlRoles.includes(role)).length
                    if (totalUsersCountForRole > 0 && totalUsersCountForRole === selectedUsersCountForRole) {
                        rolesPresent.push(role)
                    }
                })
                return rolesPresent
            },
            rolesRemovedString() {
                // Note: the string for ADMIN is normally 'Library Administrator' and not 'Administrator except for this use, so we use adminShort instead
                // In any case due to the context of the string we need the plural version of it
                if (this.isRemovingRelevantRoles) {
                    return this.relevantRolesRemoved.map(role => {
                        if (role === DSL_ROLES.ADMIN) {
                            return this.$tc('users.districtLibrary.dslRoles.adminShort', 2)
                        } else {
                            return this.$tc(`users.districtLibrary.dslRoles.${role.toLowerCase()}.name`, 2)
                        }
                    }).join(', ')
                } else {
                    return ''
                }
            }
        },
        watch: {
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        methods: {
            showMessageDialog (title, message, traceId, errorCode) {
                bus.$emit('openSelfClosingModal', {
                    title,
                    message,
                    traceId,
                    errorCode
                })
            },
            removeSelectedUsers () {
                this.loading = true

                this.$store.dispatch('RemoveUsersFromSubscription', {
                    organizationId: this.organizationId,
                    subscriptionId: this.subscriptionId,
                    users: this.users,
                    tierType: this.tierType
                }).then(() => {
                    this.showMessageDialog(this.$t('dialogs.success'), this.$tc('removeUsersModal.successMessage', this.users.length))
                    this.successCallback()
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.dialogueError)
                }).then(() => {
                    this.loading = false
                    this.closeDialog()
                })
            },
            closeDialog () {
                this.openDialog = false
            }
        }
    }
</script>
