<template>
  <v-layout column>
    <v-card-title class="text-h6 font-weight-bold text--primary">{{ $t('roster.importAndSync') }}</v-card-title>
    <v-img
        :aspect-ratio="16/9"
        contain
        :height="70"
        :src="require('@/assets/classlink_sync.png')">
    </v-img>
      <v-card-text>{{ $t('roster.syncTeachersMessage') }}</v-card-text>
      <v-card-text>
          <p class="textfield-label"><span class="required-color">* </span>{{ $t('roster.tenantId') }}</p>
          <v-text-field
              maxlength="10"
              flat
              solo
              mask="##########"
              v-model="districtValue">
          </v-text-field>
      </v-card-text>
      <v-card-text>
          <p class="provision-message">
              <b>{{ $t('addUsersModal.note') }}</b>
              {{ $t('addUsersModal.provisionsMessage') }}
          </p>
          <a :href="$LINKS.CLASSLINK_PROVISION_SUPPORT" target="_blank" class="learn-more">{{ $t('roster.learnMore') }}
              <i class="material-icons icon icon-open learn-more-icon">launch</i>
          </a>
      </v-card-text>
    <v-card-text>
      <v-layout>
        <v-flex xs6>
          <v-btn
            id="cancelImportTeachers"
            block
            outlined
            :disabled="loading"
            @click.native="cancel()"
          >{{ $t('buttons.cancel') }}</v-btn>
        </v-flex>
        <v-flex xs6>
          <v-btn
            id="importTeachers"
            block
            primary
            light
            class="white--text"
            :loading="loading"
            :disabled="loading || !districtValue"
            @click.native="triggerTeacherImport()"
          >{{ $t('buttons.import') }}</v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-layout>
</template>

<script>
    export default {
        props: ['loading'],
        name: 'RosterAddUsers',
        data () {
            return {
                districtValue: ''
            }
        },
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            triggerTeacherImport () {
                this.$emit('sync-teachers', this.districtValue)
            }
        }
    }
</script>

<style>

    .learn-more {
        color: #159bd9;
        text-decoration: none;
    }

    .learn-more:hover {
        text-decoration: underline;
    }

    .learn-more-icon {
        font-size: 16px !important;
        color: #159bd9 !important;
    }

    .provision-message {
        padding-bottom: 5px;
    }

    .required-color {
        color: #d0011b;
    }

    .v-card__text > .textfield-label {
        margin-bottom: 0px;
    }
</style>

<style scoped>
</style>
