import {bus} from '../event-bus'
import BrowserHelper from '../utils/browser-util'
const MAX_VIEWPORT_WIDTH = 1024
const MAX_VIEWPORT_HEIGHT = 600

export default {
    mounted () {
        this.checkBrowserResolution()
    },
    methods: {
        checkBrowserResolution () {
            let seenMessage = this.$store.state.confirmedBrowserResizeWarning
            let size = BrowserHelper.getViewportSize()
            if (!seenMessage && (size.width < MAX_VIEWPORT_WIDTH || size.height < MAX_VIEWPORT_HEIGHT)) {
                bus.$emit('openPersistedModal', {
                    title: this.$t('applicationErrors.invalidResolutionError'),
                    message: this.$t('applicationErrors.invalidResolutionMessage'),
                    primaryActionLabel: this.$t('buttons.dismiss')
                })
                this.$store.commit('ConfirmBrowserResizeWarning')
            }
        }
    }
}
