<template>
    <div class="seat-count">
        <item-with-tooltip :tooltip-text="tooltipText">
            <template v-slot:item>
                <span :class="{ 'error--text': assignedUsersCount > totalSeats }">
                    {{ assignedUsersCount }} / {{ totalSeats }}
                </span>
            </template>
        </item-with-tooltip>
    </div>
</template>
<script>
    import ItemWithTooltip from './ItemWithTooltip.vue'
    export default {
        components: {
            ItemWithTooltip
        },
        props: {
            assignedUsersCount: {
                type: Number,
                default: 0
            },
            totalSeats: {
                type: Number,
                default: 0
            }
        },
        computed: {
            tooltipText() {
                return this.$t('subscriptions.entitlementsHovered')
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

.seat-count {
    width: 4.5rem;
    cursor: default;
    vertical-align: middle;
    line-height: 1.1rem;
    display: inline-block;
    white-space: nowrap;
    font-weight: 700;
    color: $pallete-medium-gray;
}

</style>
