import Vue from 'vue'
import VueRouter from 'vue-router'

import SubscriptionsRouter from './routes/SubscriptionsRouter'
import Redirect from './routes/Redirect'
import Users from './routes/Users'
import Error from './routes/Error'
import PageNotFound from './routes/PageNotFound'
import LoginPage from './routes/LoginPage'
import Management from './routes/Management'

Vue.use(VueRouter)

const routes = [
    {
        path: '/softwareportal/*',
        redirect: to => {
            console.info('Old dest: ' + to.path)
            let newDest = to.path.substr(15)
            if (newDest === '/signin') {
                newDest = '/login'
            }
            console.info('New dest: ' + newDest)
            return newDest
        },
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'signout',
        path: '/signout',
        component: Redirect,
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'activation',
        path: '/activation',
        component: Redirect,
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'redirect',
        path: '/',
        component: Redirect,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'login',
        path: '/login',
        component: LoginPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'products',
        path: '/products',
        component: SubscriptionsRouter,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'error',
        path: '/error*',
        component: Error,
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'users',
        path: '/:organizationId/:subscriptionId/:tierId?/users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'manage',
        path: '/manage',
        component: Management,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'notfound',
        path: '*',
        component: PageNotFound,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
