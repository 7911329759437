import {idAuth} from '../services/identity-auth'
import {ClaimErrorUtils, VALIDATE_CODE} from './claim-error-util'
import {LINKS} from '../config'
import {bus} from '../event-bus'
import {ErrorCodes} from '../enums'
import TranslationService from '../services/translation-service'
import BodyUtil from './body-visibility-util'
import StrUtils from 'smarttech-identity-string-utils'
import {MENU_STATE} from '../enums/subscription-menu-state'
import {store} from '../store/store'

// error groups

let errGroups = {
    claimValidationError: 'claimCodeValidationError',
    environmentError: 'Failed to determine environment',
    idLibraryLoadError: 'Unable to load id Library',
    idLibraryError: 'idLibraryError',
    unKnownError: 'Unknown Error',
    signinError: 'signinError',
    dialogueError: 'dialogueError',
    managementError: 'managementError',
    featureSwitchError: 'Unable to get feature switches from ID',
    orgPermissionError: 'orgPermissionError',
    subscriptionError: 'subscriptionError',
    orgUpdateError: 'orgUpdateError',
    emptyAccessToken: 'user signed out',
    claimActivationError: 'claimActivationError',
    otherSLSExistsClaimError: 'otherSLSExistsClaimError',
    badPostalCodeActivationError: 'badPostalCodeActivationError'
}

let printErrorMsgToConsole = function (errorMsg, error) {
    // eslint-disable-next-line no-console
    console.error(errorMsg, error) // keep the detailed reason in case the error message is translated or formalized.
}

let showDefaultErrorPage = function (error, errorGroup) {
    BodyUtil.showBody()
    document.getElementById('no-deps').classList.remove('hidden')
    printErrorMsgToConsole(errorGroup, error)

    if (errorGroup === errGroups.idLibraryError) {
        // Attempt to logout and redirect to login page if there is a id library error
        idAuth.logout()

        setTimeout(() => {
            window.location.href = '/'
        }, 500)
    }
}

let getDialogueErrorMessage = function (err) {
    let errorMessage
    switch (err.errorCode) {
    case ErrorCodes.INVALID_TENANT_ID:
        errorMessage = TranslationService.t('applicationErrors.invalidTenantId')
        break
    case ErrorCodes.SYNC_INPROGRESS:
        errorMessage = TranslationService.t('applicationErrors.syncInProgress')
        break
    default:
        if (err.traceId || !err.message) {
            errorMessage = TranslationService.t('dialogs.errorMessage')
        }
        break
    }
    return errorMessage
}

let getDetailedError = function (errGroup, traceId, errCode, errMsg) {
    if (errGroup === errGroups.idLibraryError) {
        if (!idAuth.isAuthenticated()) {
            errGroup = errGroups.signinError
        }
    }

    let error = {
        traceId: traceId,
        errorCode: errCode,
        title: errGroup,
        message: errMsg,
        primaryActionLabel: TranslationService.t('login.signOut'),
        primaryAction: function () {
            if (idAuth.isAuthenticated()) {
                idAuth.logout()
            } else {
                window.location.href = '/'
            }
        }
    }

    printErrorMsgToConsole(error.message)

    switch (errGroup) {
    case errGroups.claimValidationError:
        error.title = TranslationService.t(ClaimErrorUtils.getErrorTitle(error.message))
        error.message = TranslationService.t(ClaimErrorUtils.getErrorMessage(error.message, VALIDATE_CODE))
        error.primaryActionLabel = TranslationService.t('buttons.close')
        error.secondaryActionLabel = TranslationService.t('buttons.contactSupport')
        error.primaryAction = function () {
            window.location.href = '/'
        }
        error.secondaryAction = function () {
            window.open(LINKS.SUPPORT)
        }
        break

    case errGroups.signinError:
        error.title = TranslationService.t('applicationErrors.signInError')
        error.message = TranslationService.t('applicationErrors.signInErrorMessage')
        error.primaryActionLabel = TranslationService.t('buttons.tryAgain')
        break

    case errGroups.idLibraryError:
        error.title = TranslationService.t('applicationErrors.signInError')
        error.message = TranslationService.t('applicationErrors.signInErrorMessage')
        error.primaryActionLabel = TranslationService.t('buttons.tryAgain')
        break

    case errGroups.dialogueError:
        error.title = TranslationService.t('dialogs.error')
        error.message = getDialogueErrorMessage(error)
        error.primaryActionLabel = TranslationService.t('buttons.ok')
        error.primaryAction = function () {
        }
        break

    case errGroups.managementError:
        if (error.errorCode === ErrorCodes.DELETE_LAST_ADMIN_ERROR) {
            error.title = TranslationService.t('management.removeAdmin.cantRemove')
            error.primaryActionLabel = TranslationService.t('buttons.cancel')
            if (StrUtils.isBlank(error.traceId)) {
                error.primaryAction = function () {
                }
            } else {
                error.primaryAction = function () {
                    window.location.href = '/manage'
                }
            }
        } else {
            error.title = TranslationService.t('applicationErrors.genericError')
            if (error.traceId || !error.message) {
                error.message = TranslationService.t('dialogs.errorMessage')
            }
            error.primaryActionLabel = TranslationService.t('buttons.ok')
            error.primaryAction = function () {
                window.location.href = '/manage'
            }
        }
        break

    case errGroups.orgPermissionError:
        error.title = TranslationService.t('management.notification.title')
        error.message = TranslationService.t('management.notification.generic')
        error.primaryActionLabel = TranslationService.t('buttons.ok')
        error.primaryAction = function () {
            window.location.href = '/'
        }
        break

    case errGroups.subscriptionError:
        error.title = TranslationService.t('dialogs.error')
        error.message = TranslationService.t('dialogs.errorMessage')
        error.primaryActionLabel = TranslationService.t('users.backToManageSoftware')
        error.primaryAction = function () {
            window.location.href = '/'
        }
        break

    case errGroups.orgUpdateError:
        error.title = TranslationService.t('management.orgUpdateDialog.errorTitle')
        error.message = TranslationService.t('management.orgUpdateDialog.errorMessage')
        error.primaryActionLabel = TranslationService.t('buttons.gotIt')
        error.primaryAction = function () {
        }
        break

    case errGroups.claimActivationError:
        error.title = TranslationService.t('activation.errors.title')
        error.primaryActionLabel = TranslationService.t('buttons.close')
        error.secondaryActionLabel = TranslationService.t('buttons.contactSupport')
        error.primaryAction = function () {
            store.commit('setSubscriptionSelectedMenuOption', MENU_STATE.SUBSCRIPTION)
            store.commit('cleanActivationMemory')
        }
        error.secondaryAction = function () {
            window.open(LINKS.SUPPORT)
        }
        break

    case errGroups.badPostalCodeActivationError:
        error.title = TranslationService.t('activation.errors.zipPostalCodeTitle')
        error.primaryActionLabel = TranslationService.t('buttons.close')
        error.secondaryActionLabel = TranslationService.t('buttons.contactSupport')
        error.primaryAction = function () {
            store.commit('setCreatedOrgZipPostal', '')
            bus.$emit('openCreateOrgForm')
        }
        error.secondaryAction = function () {
            window.open(LINKS.SUPPORT)
        }
        break

    case errGroups.otherSLSExistsClaimError:
        error.title = TranslationService.t('activation.errors.couldNotActivate')
        error.primaryActionLabel = TranslationService.t('buttons.gotIt')
        error.primaryAction = function () {
            store.commit('setSubscriptionSelectedMenuOption', MENU_STATE.SUBSCRIPTION)
            store.commit('cleanActivationMemory')
        }
        break

    default:
        error.title = TranslationService.t('applicationErrors.genericError')

        // show the raw English version of error message if trace Id not available.
        if (error.traceId || !error.message) {
            error.message = TranslationService.t('applicationErrors.genericMessage')
        }
        error.primaryActionLabel = TranslationService.t('buttons.close')
        error.primaryAction = function () {
        }
        break
    }
    return error
}

let openErrorDialogue = function (errGroup, traceId, errCode, errMsg) {
    bus.$emit('openPersistedModal', getDetailedError(errGroup, traceId, errCode, errMsg))
}

export default {
    signinError: errGroups.signinError,
    claimValidationError: errGroups.claimValidationError,
    environmentError: errGroups.environmentError,
    idLibraryLoadError: errGroups.idLibraryLoadError,
    idLibraryError: errGroups.idLibraryError,
    unKnownError: errGroups.unKnownError,
    dialogueError: errGroups.dialogueError,
    managementError: errGroups.managementError,
    featureSwitchError: errGroups.featureSwitchError,
    orgPermissionError: errGroups.orgPermissionError,
    subscriptionError: errGroups.subscriptionError,
    orgUpdateError: errGroups.orgUpdateError,
    emptyAccessToken: errGroups.emptyAccessToken,
    claimActivationError: errGroups.claimActivationError,
    otherSLSExistsClaimError: errGroups.otherSLSExistsClaimError,
    badPostalCodeActivationError: errGroups.badPostalCodeActivationError,

    // This is the only public method that should be accessed.
    handleError (error, errorGroup) {
        // If user signs out and doesn't have access token, emptyAccessToken error is thrown from apiClient
        // to stop the promise chain
        if (error.errorGroup === this.emptyAccessToken) {
            // Log out user again just in case and skip displaying an error dialog
            idAuth.logout()
            return
        }

        if (error.statusCode === ErrorCodes.FORBIDDEN) {
            errorGroup = this.orgPermissionError
        } else {
            errorGroup = errorGroup || this.unKnownError
        }

        if (TranslationService.isAppInitialized()) {
            // this is to formalize ID JS Lib Error, AP Page Error, and AP API Error
            let errGroup = error.errorGroup || errorGroup
            let traceId = error.traceId || ''
            let errMsg = error.message || ''
            let errCode = error.errorCode || error.code || ErrorCodes.INTERNAL_ERROR

            openErrorDialogue(errGroup, traceId, errCode, errMsg)
        } else {
            showDefaultErrorPage(error, errorGroup)
        }
    }
}
