<template>
    <v-dialog v-model="openDialog" persistent id="region-selection-dialog" attach="#notification-mount" width="unset">
        <v-card class="region-selection-notification"
                :style="{width: showSimplifiedRegionSelection ? '430px' : '590px'}">
            <v-card-title id="regionSelectionTitle" class="text-h5 region-selection-notification-title">
                {{ $t('management.regionSelectionDialog.title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('management.regionSelectionDialog.description') }}
                <br v-if="showSimplifiedRegionSelection">
                <a class="learn-more-link"
                   :href="$LINKS.REGION_SELECTION_LEARN_MORE" target="_blank">
                    {{ $t('management.regionSelectionDialog.learnMore') }}
                </a>
            </v-card-text>
            <div v-if="showSimplifiedRegionSelection" id="simplifiedNotificationDialog">
                <v-container align-center ma-0 pa-0>
                    <v-layout wrap>
                        <v-flex>
                            <v-flex><label for="region" class="font-weight-bold">{{ $t('activation.assignEntitlement.createOrg.region') }}</label></v-flex>
                            <v-select id="singleRegion" class="border selector" v-model="singleRegion"
                                            :items="regionList"
                                            item-text="name"
                                            item-value="code"
                                            solo
                                            flat
                                            hide-details>
                            </v-select>
                        </v-flex>
                        <v-flex v-if="hasDataLocationDropdown" class="software-portal-input-box">
                            <v-flex><label for="dataLocation" class="font-weight-bold">{{ $t('activation.assignEntitlement.createOrg.dataLocation') }}</label></v-flex>
                            <v-flex>
                                <v-autocomplete id="singleDataLocation" class="overflow-hidden text-no-wrap text-truncate data-location-dropdown" v-model="singleDataLocation"
                                          :items="dataLocations()"
                                          item-text="name"
                                          item-value="code"
                                          hide-details
                                          flat
                                          solo
                                          autocomplete="disable-chrome-autocomplete"
                                          clearable>
                                </v-autocomplete>
                                <v-card-text class="migration-warning">
                                    {{ $t('management.regionSelectionDialog.migrationWarning') }}
                                </v-card-text>
                            </v-flex>
                        </v-flex>
                        <a class="font-weight-bold text-subtitle-1 customize-region"
                           @click="showDetailedView">
                            {{ $t('management.regionSelectionDialog.customize') }}
                        </a>
                    </v-layout>
                </v-container>
            </div>
            <div v-else id="detailedNotificationDialog">
                <v-container align-center ma-0 pa-0>
                    <v-layout wrap justify-start>
                        <v-flex xs6 class="org-list-label text-subtitle-1 font-weight-bold">
                            {{ $t('management.rolePerOrgDialog.orgName') }}
                        </v-flex>
                        <v-flex xs6 class="org-list-label text-subtitle-1 font-weight-bold">
                            {{ $t('activation.assignEntitlement.createOrg.region') }}
                        </v-flex>
                    </v-layout>
                    <v-layout class="org-list-scroll-container" column>
                        <v-layout v-for="(org, index) in orgList" :key="org.id" class="detailed-region-selection-container">
                            <v-layout column>
                                <v-layout pa-2>
                                    <v-flex class="org-number">
                                        {{ index + 1 }}.
                                    </v-flex>
                                    <v-flex xs6 class="region-selection-container-text overflow-hidden text-no-wrap text-truncate">
                                        {{org.name}}
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="orgList[index].region"
                                                  :items="regionList"
                                                  item-text="name"
                                                  item-value="code"
                                                  single-line
                                                  hide-details
                                                  flat
                                                  solo
                                                  class="overflow-hidden text-no-wrap text-truncate">
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-if="hasDataLocationDropdownInDetailedView(org)" column>
                                    <v-layout justify-start pa-2>
                                        <v-flex class="org-number">
                                        </v-flex>
                                        <v-flex xs6 class="region-selection-container-text">
                                            {{ $t('activation.assignEntitlement.createOrg.dataLocation') }}
                                        </v-flex>
                                        <v-flex xs6>
                                            <v-autocomplete v-model="orgList[index].dataLocation"
                                                      :items="dataLocations(org)"
                                                      item-text="name"
                                                      item-value="code"
                                                      flat
                                                      solo
                                                      hide-details
                                                      clearable
                                                      autocomplete="disable-chrome-autocomplete"
                                                      class="overflow-hidden text-no-wrap text-truncate data-location-dropdown">
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout justify-start pb-2>
                                        <v-flex xs12>
                                            <v-card-text class="migration-warning">
                                                {{ $t('management.regionSelectionDialog.migrationWarning') }}
                                            </v-card-text>
                                        </v-flex>
                                    </v-layout>
                                </v-layout>
                            </v-layout>
                        </v-layout>
                    </v-layout>
                </v-container>
            </div>
            <v-btn id="updateOrgInfo"
                   block primary light
                   class="white--text"
                   height="40px"
                   :disabled="!isRegionValid || submitOrg"
                   @click="triggerRequest()">
                {{ $t('buttons.confirm') }}
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
    import {PERMISSIONS} from '../../enums/admin-management-constants'
    import StrUtils from 'smarttech-identity-string-utils'
    import {DialogStates} from '../../enums/index'
    import regionMappingService from '../../services/region-mapping-service'
    import {entitlement} from '../../utils/validate-utils.js'
    import ErrorHandler from '../../utils/error-handler'
    import PermissionValidator from '../../utils/permission-validator'
    import {DEFAULT as GLOBAL_REGION, EU1} from 'smarttech-identity-environment'

    export default {
        name: 'RegionSelectionDialog',
        data () {
            return {
                openDialog: false,
                showSimplifiedRegionSelection: false,
                regionList: regionMappingService.getRegionStrings(),
                currentRegion: '',
                orgsByRegion: new Map(), // {region:[orgList]}
                orgList: [],
                initialSingleRegion: '',
                singleRegionValue: '',
                singleDataLocationValue: '',
                hasDataLocationDropdown: false,
                submitOrg: false,
                regionModels: []
            }
        },
        computed: {
            isRegionValid () {
                return this.orgList.every(org =>
                    entitlement.validateRegionAndDataLocation(org.country, org.region, org.dataLocation))
            },
            singleRegion: {
                get () {
                    return this.singleRegionValue
                },
                set (value) {
                    this.singleRegionValue = value
                    this.orgList.forEach(org => {
                        org.region = value
                    })
                    this.hasDataLocationDropdown = this.initialSingleRegion !== value
                    if (!this.hasDataLocationDropdown) {
                        this.orgList.forEach(org => {
                            org.dataLocation = '' // reset data location when region and country are matched.
                        })
                    }
                }
            },
            singleDataLocation: {
                get () {
                    return this.singleDataLocationValue
                },
                set (value) {
                    this.singleDataLocationValue = value
                    this.orgList.forEach(org => {
                        org.dataLocation = value
                    })
                }
            }
        },
        mounted () {
            let directOrgs = this.$store.getters.getDirectOrganizations

            if (directOrgs && directOrgs.length > 0) {
                let regionUnselectedOrgs = []

                for (let org of directOrgs) {
                    if (this.hasAdminPermission(org.id) && StrUtils.isBlank(org.region) &&
                        regionMappingService.getRegionFromCountryCode(org.country)) {
                        // make region and dataLocation reactive and reset the value
                        this.$set(org, 'region', '')
                        this.$set(org, 'dataLocation', '')
                        regionUnselectedOrgs.push(org)
                    }
                }

                // If there's orgs with null regions, show a region selection popup.
                if (regionUnselectedOrgs.length > 0) {
                    this.orgsByRegion = this.createOrgsByRegionMap(regionUnselectedOrgs)
                }
            }

            if (this.orgsByRegion.size > 0) {
                for (let [region, orgs] of this.orgsByRegion.entries()) {
                    if (region === GLOBAL_REGION) {
                        this.updateGlobalRegionOrgs(orgs)
                    } else {
                        this.orgList = this.orgList.concat(orgs)
                    }
                }

                if (this.orgList.length > 0) {
                    this.initialSingleRegion = EU1
                    this.singleRegionValue = EU1
                    this.showSimplifiedRegionSelection = true
                    this.openDialog = true
                } else {
                    this.resolveDialogStatus()
                }
            } else {
                this.resolveDialogStatus()
            }
        },
        methods: {
            updateGlobalRegionOrgs (globalOrgs) {
                let orgListToUpdate = []

                globalOrgs.forEach(org => {
                    let orgToUpdate = {}
                    orgToUpdate.id = org.id
                    orgToUpdate.region = GLOBAL_REGION
                    orgToUpdate.dataLocation = ''
                    orgListToUpdate.push(orgToUpdate)
                })

                // No error handling because it's a background job
                this.$store.dispatch('UpdateOrganizationRegion', orgListToUpdate)
            },
            closeAndResetDialog () {
                this.openDialog = false
                this.showSimplifiedRegionSelection = false
                this.submitOrg = false
                this.resolveDialogStatus()
            },
            createOrgsByRegionMap (orgs) {
                let orgsByRegionMap = new Map()

                orgs.forEach(org => {
                    if (org.country) {
                        let region = regionMappingService.getRegionFromCountryCode(org.country)

                        if (region) {
                            this.addOrgByRegion(orgsByRegionMap, region, org)
                        } else {
                            this.addOrgByRegion(orgsByRegionMap, GLOBAL_REGION, org)
                        }
                    } // If country is null, we don't set the region.
                })

                return orgsByRegionMap
            },
            addOrgByRegion (orgsByRegionMap, region, org) {
                org.region = region
                orgsByRegionMap.has(region) ? orgsByRegionMap.get(region).push(org) : orgsByRegionMap.set(region, [org])
            },
            dataLocations (org) {
                let regionMap = regionMappingService.getRegionInfoByRegionName()

                if (org) {
                    if (StrUtils.isNotBlank(org.region) && regionMap.has(org.region)) {
                        return regionMap.get(org.region).countries
                    }
                } else {
                    if (StrUtils.isNotBlank(this.singleRegionValue) && regionMap.has(this.singleRegionValue)) {
                        return regionMap.get(this.singleRegionValue).countries
                    }
                }

                return []
            },
            triggerRequest () {
                this.submitOrg = true

                try {
                    let toBeUpdatedOrgs = this.createRequestBody(this.orgList)

                    this.$store.dispatch('UpdateOrganizationRegion', toBeUpdatedOrgs)
                        .catch(err => {
                            this.handleError(err)
                        })
                    this.closeAndResetDialog()
                } catch (err) {
                    this.closeAndResetDialog()
                    this.handleError(err)
                }
            },
            createRequestBody () {
                let toBeUpdatedOrgs = []

                this.orgList.forEach(org => {
                    let orgToUpdate = {}
                    orgToUpdate.id = org.id
                    orgToUpdate.region = org.region
                    orgToUpdate.dataLocation = StrUtils.isBlank(org.dataLocation) ? '' : org.dataLocation
                    toBeUpdatedOrgs.push(orgToUpdate)
                })

                return toBeUpdatedOrgs
            },
            handleError (err) {
                ErrorHandler.handleError(err, ErrorHandler.orgUpdateError)
            },
            hasAdminPermission (orgId) {
                return PermissionValidator.validateUserPermission(orgId, PERMISSIONS.ManageOrgs)
            },
            showDetailedView () {
                this.showSimplifiedRegionSelection = false
                this.initialSingleRegion = ''
                this.singleRegionValue = ''
                this.singleDataLocationValue = ''
            },
            hasDataLocationDropdownInDetailedView (org) {
                if (!org.region || regionMappingService.getRegionFromCountryCode(org.country) === org.region) {
                    org.dataLocation = '' // reset data location when region and country are matched.
                    return false
                } else {
                    return true
                }
            },
            resolveDialogStatus () {
                this.$store.commit('ChangeDialogState', DialogStates.REGION_RESOLVED)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../theming/main.scss";

    .region-selection-notification {
        padding: 34px 30px 30px;
        background-color: $pallete-light-gray;
        object-fit: contain;
    }

    .v-card__text {
        padding: 5px 0px;
        word-break: break-word;
    }

    .region-selection-notification-title {
        margin: 0 2px 11px 0;
        padding: 0;
        word-break: break-word;
    }

    .detailed-region-selection-container:nth-of-type(odd) {
        background-color: lightgray;
    }

    .org-number {
        font-size: 14px !important;
        color: #000102;
        line-height: 1.69;
        flex-basis: 4%;
        flex-grow: 0;
        max-width: 4%;
    }

    .region-selection-container-text {
        font-size: 14px !important;
        color: #000102;
        min-width: 30px;
        line-height: 1.69;
    }

    .data-location-dropdown {
        border: solid 1px #ff0000;
        background-color: $pallete-white;
    }

    .org-list-label {
        color: rgba(0, 1, 2, 0.7);
        padding: 10px 0;
    }
    .org-list-scroll-container {
        border-top: solid 2px  rgba(0,0,0,0.1);
        border-bottom: solid 2px rgba(0,0,0,0.1);
        max-height: 450px;
        overflow-y: auto;
    }

    .migration-warning {
        font-size: 12px;
        color: rgba(255, 0, 0, 0.7);
        padding: 0 5px 0 6px;
    }

    .customize-region {
        width: 268px;
        height: 17px;
        margin: 15px 0 0;
    }

    .learn-more-link {
        text-decoration: none;
        white-space: nowrap;
    }
</style>
