import SubscriptionRequestController from '../controllers/subscription-request-controller'
import LegacyRequestController from '../controllers/legacy-request-controller'

export default {
    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    sendRequest: (organizationId, organizationInfo, listener) => {
        if (!organizationInfo.subscription.hasLoaded) {
            let nextToken = organizationInfo.subscription.nextToken
            SubscriptionRequestController.executeRequest(organizationId, nextToken, listener)
        } else if (!organizationInfo.legacy.hasLoaded) {
            let nextToken = organizationInfo.legacy.nextToken
            LegacyRequestController.executeRequest(organizationId, nextToken, listener)
        }
    },
    sendSubscriptionsRequest: (organizationsIds, listener, nextToken) => {
        SubscriptionRequestController.executeRequestHybrid(organizationsIds, nextToken, listener)
    },
    sendLegacyRequest: (organizationId, listener, nextToken) => {
        LegacyRequestController.executeRequest(organizationId, nextToken, listener)
    }
}
