import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueMask from 'v-mask'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

// Input masks no longer supported in Vuetify 2
// Need third party plugin
Vue.use(VueMask)

export default new Vuetify({
    icons: {
        iconfont: 'md'
    }
})
