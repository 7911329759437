<template>
    <v-dialog v-model="openDialog" persistent width="430" max-width="430" attach="#notification-mount">
        <v-card v-if="showRegularNotifications" id="notificationDialog" class="notification">
            <v-card-title id="notificationTitle" class="text-h6 font-weight-bold text--primary notification-title">
                {{ $t('management.notification.title') }}
            </v-card-title>
            <v-card-text id="removedNotification"
                         v-show="removedOrgs !== 0"
                         v-html="getRemovedNotification()">
            </v-card-text>

            <v-card-text id="permissionNotification"
                         v-show="roleChangedOrgs !== 0"
                         v-html="getRoleChangeNotification()">
            </v-card-text>
            <v-card-text>
                <v-btn @click="submitClearNotificationsRequest()"
                       block primary light
                       class="white--text">
                    {{ $t('buttons.gotIt') }}
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card v-else class="notification">
            <v-card-title id="inviteTitle" class="text-h6 font-weight-bold text--primary notification-title">
                {{ $t('management.notification.inviteTitle') }}
            </v-card-title>
            <v-card-text id="inviteNotification"
                         v-html="getInviteNotification()">
            </v-card-text>
            <v-card-text>
                <v-layout justify-space-between>
                    <div>
                        <v-btn id="decline"
                            class="decline-btn"
                            outlined block
                            @click="declineInvitation()">
                            {{ $t('buttons.decline') }}
                        </v-btn>
                    </div>
                    <div>
                        <v-btn id="removeAdmin"
                            block primary light
                            class="white--text accept-btn"
                            @click="acceptInvitation()">
                            {{ $t('buttons.accept') }}
                        </v-btn>
                    </div>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {NOTIFICATION} from '../../enums/notification-types'
    import StrUtils from 'smarttech-identity-string-utils'
    import {DialogStates} from '../../enums/index'
    import ErrorHandler from '../../utils/error-handler'

    export default {
        name: 'NotificationDialog',
        data () {
            return {
                openDialog: false,
                showRegularNotifications: false,
                invitedOrgs: 0,
                removedOrgs: 0,
                roleChangedOrgs: 0,
                invitedBy: '',
                removedBy: '',
                roleChangedBy: '',
                invitationNotificationOrgs: [],
                regNotificationOrgs: [],
                accepted: [],
                declined: []
            }
        },
        methods: {
            closeAndResetDialog () {
                this.openDialog = false
                this.showRegularNotifications = false
                this.invitedOrgs = 0
                this.removedOrgs = 0
                this.roleChangedOrgs = 0
                this.invitedBy = ''
                this.removedBy = ''
                this.roleChangedBy = ''
                this.invitationNotificationOrgs = []
                this.regNotificationOrgs = []
                this.accepted = []
                this.declined = []
                this.resolveDialogStatus()
            },
            submitClearNotificationsRequest () {
                this.accepted = this.accepted.concat(this.regNotificationOrgs)
                this.$store.dispatch('ClearRoleChangeNotification', {
                    accepted: this.accepted,
                    declined: this.declined
                }).catch(err => {
                    ErrorHandler.handleError(err)
                })
                this.closeAndResetDialog()
            },
            declineInvitation () {
                this.declined = this.declined.concat(this.invitationNotificationOrgs)
                this.submitOrOpenNextNotification()
            },
            acceptInvitation () {
                this.accepted = this.accepted.concat(this.invitationNotificationOrgs)
                this.submitOrOpenNextNotification()
            },
            submitOrOpenNextNotification () {
                if (this.regNotificationOrgs.length === 0) {
                    this.submitClearNotificationsRequest()
                } else {
                    this.showRegularNotifications = true
                }
            },
            getInviteNotification () {
                return this.$t('management.notification.invited', {
                    organization: this.getOrganizationText(this.invitedOrgs),
                    changedBy: this.getChangedByText(this.invitedBy)
                })
            },
            getRemovedNotification () {
                return this.$t('management.notification.removed', {
                    organization: this.getOrganizationText(this.removedOrgs),
                    changedBy: this.getChangedByText(this.removedBy)
                })
            },
            getRoleChangeNotification () {
                return this.$t('management.notification.roleChange', {
                    organization: this.getOrganizationText(this.roleChangedOrgs),
                    changedBy: this.getChangedByText(this.roleChangedBy)
                })
            },

            getChangedByText (currentValue) {
                return StrUtils.isBlank(currentValue) ? this.$t('management.notification.admin') : currentValue
            },
            getOrganizationText (currentValue) {
                if (Number.isInteger(currentValue) && currentValue !== 0) {
                    return this.$t('management.adminDialog.numOrgs', {quantity: currentValue})
                } else {
                    return currentValue
                }
            },
            updateOrgsForNotification (value, orgName) {
                // Value a string of the org name if there is only 1 organization. If there are multiple organizations (2 or more),
                // the value is changed to an integer to count number of orgs to display (ie. 4 organizations)
                if (value === 0) {
                    value = orgName
                } else if (!Number.isInteger(value) && StrUtils.isNotBlank(value)) {
                    value = 2
                } else {
                    value++
                }
                return value
            },
            updateChangedByForNotification (value, changedBy) {
                if (StrUtils.isBlank(value)) {
                    value = changedBy
                } else if (value !== this.$t('management.notification.admin') && value !== changedBy) {
                    value = this.$t('management.notification.admin')
                }
                return value
            },
            updateNotificationStrings (orgString, changedByString, notification) {
                let updated = {}
                updated.orgString = this.updateOrgsForNotification(orgString, notification.org)
                if (notification.changedBy) {
                    updated.changedBy = this.updateChangedByForNotification(changedByString, notification.changedBy)
                } else {
                    updated.changedBy = changedByString
                }
                return updated
            },
            resolveNotification (notification) {
                switch (notification.type) {
                case NOTIFICATION.removed:
                    this.regNotificationOrgs.push(notification.orgId);
                    ({orgString: this.removedOrgs, changedBy: this.removedBy} = this.updateNotificationStrings(this.removedOrgs, this.removedBy, notification))
                    break
                case NOTIFICATION.roleChanged:
                    this.regNotificationOrgs.push(notification.orgId);
                    ({orgString: this.roleChangedOrgs, changedBy: this.roleChangedBy} = this.updateNotificationStrings(this.roleChangedOrgs, this.roleChangedBy, notification))
                    break
                case NOTIFICATION.invitation:
                    this.invitationNotificationOrgs.push(notification.orgId);
                    ({orgString: this.invitedOrgs, changedBy: this.invitedBy} = this.updateNotificationStrings(this.invitedOrgs, this.invitedBy, notification))
                    break
                }
            },
            resolveDialogStatus () {
                this.$store.commit('ChangeDialogState', DialogStates.NOTIFICATION_RESOLVED)
            }
        },
        computed: {
            appIsInitialized () {
                return this.$store.getters.appState
            }
        },
        mounted () {
            if (this.$store.getters.getCurrentNotifications.length > 0) {
                this.$store.getters.getCurrentNotifications.forEach(notification => {
                    this.resolveNotification(notification)
                })
                this.showRegularNotifications = this.invitationNotificationOrgs.length === 0
                this.openDialog = true
            } else {
                this.resolveDialogStatus()
            }
        }
    }
</script>

<style scoped>
    .notification {
        padding: 30px 40px;
    }

    .decline-btn {
        width: 167px;
        max-width: 167px;
    }

    .accept-btn {
        width: 167px;
        max-width: 167px;
    }

    .v-card__text {
        padding: 5px 0px !important;
    }

    .notification-title {
        padding: 16px 0px 10px 0px !important;
    }
</style>
