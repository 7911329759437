<template>
    <div class="csv-image-frame">
        <img src="../../assets/csv-instructions.png">
        <span class="instruction-one">{{ $t('addUsersModal.csvInstructionsOneColumn') }}</span>
        <span class="instruction-two">{{ $t('addUsersModal.csvInstructionsOneEmail') }}</span>
        <span class="instruction-three">{{ $t('addUsersModal.csvInstructionsNoDelimiter') }}</span>
    </div>
</template>

<script>
    export default {
        name: 'CsvInstructionImage'
    }
</script>

<style lang="scss" scoped>
.csv-image-frame {
    position: relative;
    margin: 0 auto;
    height: 125px;
    width: 360px;
}
.csv-image-frame > * {
    position: absolute;
}
.instruction-one {
    left: 172px;
    top: 6px;
}
.instruction-two {
    left: 200px;
    top: 40px;
}
.instruction-three {
    left: 195px;
    top: 80px;
}
</style>
