export let orgSort = function (a, b) {
    let lName = a.toLowerCase()
    let rName = b.toLowerCase()

    if (lName < rName) {
        return -1
    }
    if (lName > rName) {
        return 1
    }

    return 0
}
