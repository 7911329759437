let instance
let currentLocale = 'en'

export default {
    initialized: false,

    init (vueInstance) {
        instance = vueInstance
        // Ensure the currentLocale we return is one we support
        if (instance.$i18n.availableLocales.includes(instance.$i18n.locale)) {
            currentLocale = instance.$i18n.locale
        }
        this.initialized = true
    },

    isAppInitialized () {
        return this.initialized
    },

    t (key) {
        if (!instance) {
            throw new Error('Translation Service not initialized')
        }

        return instance.$t(key)
    },

    tc (key, count) {
        if (!instance) {
            throw new Error('Translation Service not initialized')
        }

        return instance.$tc(key, count)
    },

    tv (key, values) {
        if (!instance) {
            throw new Error('Translation Service not initialized')
        }

        return instance.$t(key, values)
    },

    getLocale () {
        return currentLocale
    }
}
