<template>
    <v-app id="softwarePortal">

        <v-app-bar app flat clipped-left>
            <v-header></v-header>
            <v-spacer></v-spacer>
            <login-component v-if="isloggedIn"></login-component>
        </v-app-bar>

        <v-navigation v-if="isloggedIn"></v-navigation>

        <main>
            <v-summary></v-summary>
            <div :class="{'sign-in-page' : !isloggedIn}" class="route-view-override">
                <router-view :class="{'route-view' : isloggedIn}"></router-view>
            </div>
        </main>
        <div id="notification-mount"></div>
        <div id="modal-mount"></div>
        <HybridNotificationModal v-if="isHybridNotificationOn"></HybridNotificationModal>
        <notification-dialog v-if="isNotificationOn"></notification-dialog>
        <RegionSelectionDialog v-if="isRegionSelectionOn"></RegionSelectionDialog>
        <modal></modal>
    </v-app>
</template>

<script>
    import VHeader from '../components/VHeader.vue'
    import LoginComponent from '../components/LoginComponent.vue'
    import VNavigation from '../components/VNavigation.vue'
    import VSummary from '../components/VSummary.vue'
    import Modal from '../components/dialogs/Modal.vue'
    import claimCodeService from '../services/claim-code'
    import productListService from '../services/product-list'
    import {MENU_STATE} from '../enums/subscription-menu-state'
    import {AppStates, DialogStates} from '../enums'
    import NotificationDialog from '../components/dialogs/NotificationDialog'
    import RegionSelectionDialog from '../components/dialogs/RegionSelectionDialog'
    import HybridNotificationModal from '../components/dialogs/HybridNotificationModal'
    import { RESET_MANAGE_USERS_ONBOARDING } from '../enums/feature-switch-constants'
    import { isEnabled } from '../services/feature-switch'

    export default {
        name: 'main',
        components: {
            VHeader,
            VNavigation,
            LoginComponent,
            VSummary,
            Modal,
            NotificationDialog,
            RegionSelectionDialog,
            HybridNotificationModal
        },
        data () {
            return {
                isNotificationOn: false,
                isRegionSelectionOn: false,
                isHybridNotificationOn: false
            }
        },
        computed: {
            isloggedIn () {
                return !!this.$store.getters.accessToken
            },
            appStatus () {
                return this.$store.getters.appState
            },
            dialogStatus () {
                return this.$store.getters.dialogState
            }
        },
        beforeMount () {
            if (claimCodeService.getClaimCode()) {
                this.$store.commit('setActivationRedeemCode', claimCodeService.getClaimCode())
                this.$store.commit('setSubscriptionSelectedMenuOption', MENU_STATE.ACTIVATION)
                this.$store.commit('setProductList', productListService.getProductList())
            }
            if (isEnabled(RESET_MANAGE_USERS_ONBOARDING)) {
                this.$store.dispatch('UpdateUserSettings', { manageUsersOnboardingStep: 1 })
            }
        },
        watch: {
            appStatus (newState) {
                if (newState === AppStates.INITIALIZED) {
                    this.$store.commit('ChangeDialogState', DialogStates.INITIALIZED)
                }
            },
            dialogStatus (newState) {
                if (newState === DialogStates.INITIALIZED) {
                    this.isHybridNotificationOn = true
                } else if (newState === DialogStates.HYBRID_RESOLVED) {
                    this.isHybridNotificationOn = false
                    this.isNotificationOn = true
                } else if (newState === DialogStates.NOTIFICATION_RESOLVED) {
                    this.isNotificationOn = false
                    this.isRegionSelectionOn = true
                } else if (newState === DialogStates.REGION_RESOLVED) {
                    this.isRegionSelectionOn = false
                } else {
                    this.isNotificationOn = false
                    this.isRegionSelectionOn = false
                }
            },
            // This watcher prevents the modal state from remaining true during navigation to another route
            // while the modal is open, triggered by clicking the back arrow.
            // Note that currently, keeping a modal open during route changes isn't a requirement for the app.
            // However, future modifications can include the necessary logic to accommodate this if needed.
            '$route' () {
                if (this.$store.getters.getIsModalOpen) {
                    this.$store.commit('setIsModalOpen', false)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import '../theming/main.scss';
    .v-app-bar {
        box-shadow: none;
        height: auto;
    }

    .route-view-override {
        background: $pallete-white;
        top: 80px;
        left: 167px;
        padding: 0;
        position: absolute;
        width: calc(100vw - 167px);
        height: calc(100vh - 80px);
        overflow: hidden;
        border-top: 3px solid rgba(213, 213, 213, 0.6);

        &.sign-in-page {
            left: 0px;
            width: 100vw;
            border: none;
        }
    }

    .route-view {
        background-color: $pallete-routeView;
        width: 100%;
        height: 100%;
    }

    .v-application {
        [class*='text-'] {
            font-family: Arial, sans-serif !important;
        }
        font-family: Arial, sans-serif !important;
    }

    #softwarePortal .v-toolbar,
    #softwarePortal .toolbar__content,
    #softwarePortal .v-navigation-drawer,
    .v-application {
        background-color: transparent;
    }

    .v-navigation-drawer--permanent.v-navigation-drawer--clipped {
        margin-top: 120px
    }

    .v-application--toolbar > main > .container {
        min-height: calc(100vh - 120px);
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 200px;
        width: 100vw;
    }
</style>

<style>
    #notification-mount .v-dialog__content {
        z-index: 202 !important;
    }

    #modal-mount .v-dialog__content {
        z-index: 203 !important;
    }

    .v-overlay {
        z-index: 201 !important;
    }
</style>
