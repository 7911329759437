import {UI_CONFIG} from '../config.js'
import { SubscriptionStates } from '../enums/'
import LicensesUtil from '../utils/licenses-util'
import TiersUtil from './tiers-util.js'
import DateUtil from '../utils/date-util'
import TranslationService from '../services/translation-service'
import { isEnabled } from '../services/feature-switch.js'
import { FUSE_API, HYBRID_VIEW } from '../enums/feature-switch-constants.js'
import { NON_MIGRATED_EDU_SOFTWARE_NAME, TIERS_DISPLAY_ORDER } from '../enums/subscription-constants.js'

let determineSubscriptionStatus = (subscription) => {
    let currentDate = new Date()
    let warningDate = new Date()
    if (!isEnabled(HYBRID_VIEW)) {
        warningDate.setMonth(warningDate.getMonth() + UI_CONFIG.EXPIRATION_WARNING_THRESHOLD_IN_MONTHS)
        warningDate = DateUtil.resolveDateEndOfDay(warningDate.getUTCFullYear() + '-' + (warningDate.getUTCMonth() + 1) + '-' + warningDate.getUTCDate())
    } else {
        warningDate.setDate(warningDate.getDate() + UI_CONFIG.EXPIRATION_WARNING_THRESHOLD_IN_DAYS)
    }

    let endDate = subscription.nextRenewalDate
    let status = SubscriptionStates.ACTIVE

    if (endDate <= warningDate) {
        status = SubscriptionStates.WARNING
    }
    if (endDate < currentDate) {
        status = SubscriptionStates.EXPIRED
    }

    subscription.status = status
}

let determineNextRenewalDate = (subscription) => {
    if (subscription.assets && subscription.assets[0]) {
        subscription.nextRenewalDate = subscription.assets[0].usageEndDate
        subscription.nextRenewalDateFormatted = isEnabled(FUSE_API)
            ? DateUtil.getFormattedDate(subscription.assets[0].usageEndDate)
            : subscription.assets[0].usageEndDateFormatted
    } else if (subscription.tiers && subscription.relevantTier) {
        const relevantTierInfo = subscription.tiers[subscription.relevantTier]
        subscription.nextRenewalDate = relevantTierInfo.usageEndDate
        subscription.nextRenewalDateFormatted = relevantTierInfo.usageEndDateFormatted
    }
}

let determineMostRelevantTier = subscription => {
    let latestDate, relevantTier
    for (const tierName in subscription.tiers) {
        const startDate = subscription.tiers[tierName].dateShipped
        if (!latestDate || startDate > latestDate) {
            latestDate = startDate
            relevantTier = tierName
        }
    }

    subscription.relevantTier = relevantTier
}

let determineInfoFromRelevantTier = subscription => {
    const relevantTierInfo = subscription.tiers[subscription.relevantTier]
    subscription.reseller = relevantTierInfo.partnerCompanyName
    subscription.orderNumber = relevantTierInfo.orderNumber
    subscription.licenseKeyRequested = relevantTierInfo.licenseKeyRequested
    subscription.serialNumber = relevantTierInfo.serialNumber
    subscription.startDate = new Date(relevantTierInfo.dateShipped)
    subscription.startDateFormatted = DateUtil.getFormattedDate(subscription.startDate)
}

let resolveOtherSubscriptionInformation = (subscription, orgName) => {
    subscription.name = subscription.isLegacy ? TranslationService.t('subscriptions.legacySubscriptionName')
        : TranslationService.t('subscriptions.UnclaimedLicenseName')
    if (orgName) {
        subscription.accountName = orgName
    }
    if (isEnabled(FUSE_API)) {
        subscription.usageEndDate = DateUtil.getFormattedDate(subscription.usageEndDate)
    }
}

let resolveNonMigratedSubscriptionInformation = (subscription, orgName) => {
    subscription.name = isEnabled(HYBRID_VIEW) ? NON_MIGRATED_EDU_SOFTWARE_NAME : TranslationService.t('subscriptions.sloConsolidationName')
    determineNextRenewalDate(subscription)
    determineSubscriptionStatus(subscription)

    if (orgName) {
        subscription.accountName = orgName
    }
    subscription.assignedUsersCount = isEnabled(FUSE_API) ? parseInt(subscription.assignedUsers) : parseInt(subscription.assignedUsersCount)
    subscription.quantity = parseInt(subscription.quantity)
}

let resolveMigratedSubscriptionInformation = (subscription, orgName) => {
    subscription.name = TranslationService.t('subscriptions.educationSmartSoftware')
    sortTiers(subscription)
    determineMostRelevantTier(subscription)
    determineNextRenewalDate(subscription)
    determineInfoFromRelevantTier(subscription)
    determineSubscriptionStatus(subscription)
    if (orgName) {
        subscription.accountName = orgName
    }
}

let sortTiers = (subscription) => {
    const sortedTiers = {}
    TIERS_DISPLAY_ORDER.forEach(tier => {
        if (subscription.tiers[tier]) {
            sortedTiers[tier] = subscription.tiers[tier]
        }
    })
    subscription.tiers = sortedTiers
}

let hasProductInfo = (sub) => {
    // Check if subscription contains product information
    // Just checking if productName exists (could there be more fields to check?)
    return !!(sub.productName)
}

let mergeAssets = (to, from) => {
    if (to.assets && from.assets && from.assets.length) {
        to.assets = to.assets.concat(from.assets)
    } else if ((!to.assets || !to.assets.length) && from.assets) {
        to.assets = from.assets
    }

    return to
}

export default {
    resolveSubscriptionInformation: (subscription, orgName) => {
        // Migrated Subscription use tiers, non migrated subscriptions use assets
        if (subscription.tiers) {
            TiersUtil.resolveTierInformation(subscription.tiers)
            resolveMigratedSubscriptionInformation(subscription, orgName)
        } else {
            LicensesUtil.resolveLicenseInformation(subscription.assets, orgName)
            LicensesUtil.sortByStatus(subscription)

            if (subscription.isLegacy || subscription.isUnclaimed) {
                resolveOtherSubscriptionInformation(subscription, orgName)
            } else {
                resolveNonMigratedSubscriptionInformation(subscription, orgName)
            }
        }
    },
    mergeSubscriptionDetails: (a, b) => {
        if (a.id !== b.id) {
            throw Error('Subscription Id mismatch')
        }

        if (hasProductInfo(a)) {
            a = mergeAssets(a, b)
            return a
        } else {
            b = mergeAssets(b, a)
            return b
        }
    }
}
