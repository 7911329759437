import { SubscriptionStates } from '../enums/'
import LicensesSortingUtil from './license-sorting-util'
import DateUtil from '../utils/date-util'
import { isEnabled } from '../services/feature-switch'
import { FUSE_API } from '../enums/feature-switch-constants'

let determineStatus = (license) => {
    if (license.usageEndDate) {
        license.status = license.usageEndDate < new Date() ? SubscriptionStates.EXPIRED : SubscriptionStates.ACTIVE
    } else {
        license.status = SubscriptionStates.UNKNOWN
    }
}

let resolveLicenseInformation = (license, orgName) => {
    if (license.usageEndDate) {
        license.usageEndDate = isEnabled(FUSE_API) ? new Date(license.usageEndDate) : DateUtil.resolveDateEndOfDay(license.usageEndDate)
        license.usageEndDateFormatted = DateUtil.getFormattedDate(license.usageEndDate)
    }
    if (isEnabled(FUSE_API)) {
        license.startDate = new Date(license.dateShipped)
        license.startDateFormatted = DateUtil.getFormattedDate(license.startDate)
        license.distributor = license.distributorName
        license.reseller = license.partnerCompanyName
        if (orgName) {
            license.accountName = orgName
        }
    } else if (license.startDate) {
        license.startDate = DateUtil.resolveDate(license.startDate)
        license.startDateFormatted = DateUtil.getFormattedDate(license.startDate)
    }
    determineStatus(license)
}

export default {
    resolveLicenseInformation (licenses, orgName) {
        for (let i = 0; i < licenses.length; i++) {
            resolveLicenseInformation(licenses[i], orgName)
        }
    },
    sortByStatus: (subscription) => {
        subscription.assets = subscription.assets.sort(function (a, b) {
            if (LicensesSortingUtil.canSortByStatus(a, b)) {
                return LicensesSortingUtil.sortByStatus(a, b)
            }
            if (LicensesSortingUtil.canSortByDate(a, b)) {
                return LicensesSortingUtil.sortByDate(a, b)
            }
            return LicensesSortingUtil.sortBySerial(a, b)
        })
    },
    sortByProductName: (subscription) => {
        subscription.assets = subscription.assets.sort(function (a, b) {
            // HACK: There are some licenses without 'productName', but why???
            if (!a.productName && !b.productName) {
                return 0
            } else if (!a.productName) {
                return 1
            } else if (!b.productName) {
                return -1
            }

            return a.productName.localeCompare(b.productName)
        })
    }
}
