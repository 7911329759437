<template>
    <div class="admin-route">
        <div class="no-overflow-table-container">
            <v-data-table
                id="adminTable"
                :items="admins"
                :headers="headers"
                :loading="loading"
                :options.sync="options"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.sortDesc"
                :custom-sort="customSortBy"
                :headers-length=5
                :no-data-text="$t('management.orgTable.noMatches')"
                hide-default-header
                hide-default-footer
                class="fixed-headers"
                loader-height=3
                loading-text="">
                <template v-slot:header="{ props: { headers } }">
                        <thead class="v-data-table-header">
                            <tr>
                                <th align="left" tabindex=0>
                                    <span v-if="showSelectAll" @click="Object.keys(selected).length === 0 && loading ? null : selectAll()">
                                        <i class="material-icons icon"
                                            v-if="noAdminsSelected"
                                            :disabled="noAdminsSelected && loading">
                                            check_box_outline_blank
                                        </i>
                                        <i class="material-icons icon"
                                            v-else-if="Object.keys(selected).length === admins.length && admins.length > 0"
                                            :disabled="noAdminsSelected && loading">
                                            check_box
                                        </i>
                                        <i class="material-icons icon"
                                            v-else
                                            :disabled="noAdminsSelected && loading">
                                            check
                                        </i>
                                    </span>
                                </th>
                                <th align="left"
                                    v-for="header in headers"
                                    :key="header.text"
                                    :class="['column',
                                        sortOptions.sortDesc ? 'desc' : 'asc',
                                        header.value === sortOptions.sortBy ? 'active' : '',
                                        header.sortable ? 'enable-sort' : 'disable-sort'
                                    ]"
                                    :tabindex="header.sortable ? 0 : -1"
                                    @click="header.sortable ? changeSort(header.value) : null"                                    >
                                    <div class="header-text">
                                        {{ header.text }}
                                    </div>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                </th>
                            </tr>
                        </thead>
                </template>
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <div v-if="props.item.hasPermissionInAllOrgs" @click="adminSelected(props.item)">
                                <i class="material-icons check-box">
                                    {{ determineChecked(props.item.email) }}
                                </i>
                            </div>
                        </td>
                        <td>
                            <a class="font-weight-bold"
                            @click="openEditAdminDialog(props.item)">
                                {{props.item.firstName + ' ' + props.item.lastName}}
                            </a>
                        </td>
                        <td>{{props.item.email}}</td>
                        <td>{{props.item.roleNameLabel}}</td>
                        <td>{{props.item.orgNameLabel}}</td>
                    </tr>
                </template>
                <template slot="footer">
                    <div :colspan="headers.length" v-if="admins.length > 0" class="footer position-relative">
                        <div class="admin-count">
                            <div>
                                {{ $t('users.users')}}
                                <span v-if="loading">{{ $t('users.loading') }}</span>
                                <span v-else>{{admins.length}}</span>
                            </div>
                        </div>
                        <div class="text-center pt-2 pagination-position">
                            <v-pagination v-model="options.page" :length="pages" :total-visible="9" @input="scrollToTop()"/>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>

    import {bus} from '../../event-bus'
    import ErrorHandler from '../../utils/error-handler'
    import {scrollToTopOfTable, changeSortByOrder} from '../../utils/pagination-utils'
    import PermissionValidator from '../../utils/permission-validator'
    import {PERMISSIONS, ACCESSIBILITY_LEVEL} from '../../enums/admin-management-constants'
    import {orgSort} from '../../utils/organization-sorting-util'
    import TranslationService from '../../services/translation-service'
    import {ONLY_DSL} from '../../enums/feature-switch-constants'
    import {isEnabled} from '../../services/feature-switch'

    const USERS_PER_PAGE = 50
    const SORT_PROPS = {
        FIRSTNAME: 'firstName',
        EMAIL: 'email',
        ROLE_LABEL: 'roleName',
        ORG_LABEL: 'orgNameLabel'
    }

    let comparitors = {}
    let determineHighestRole = function (admin) {
        let orgs = Object.values(admin.organizations)
        if (admin.roleNameLabel === TranslationService.t('management.adminDialog.multipleRoles')) {
            let highestRole = orgs[0].role
            for (let i = 1; i < orgs.length; i++) {
                if (ACCESSIBILITY_LEVEL[orgs[i].role] > ACCESSIBILITY_LEVEL[highestRole]) {
                    highestRole = orgs[i].role
                }
            }
            return {role: highestRole, hasMultiple: true}
        } else {
            return {role: orgs[0].role, hasMultiple: false}
        }
    }

    comparitors[SORT_PROPS.FIRSTNAME] = function (a, b, descMultiplier) {
        return descMultiplier * a.firstName.localeCompare(b.firstName)
    }

    comparitors[SORT_PROPS.EMAIL] = function (a, b, descMultiplier) {
        return descMultiplier * a.email.localeCompare(b.email)
    }

    comparitors[SORT_PROPS.ROLE_LABEL] = function (a, b, descMultiplier) {
        let {role: highestRoleA, hasMultiple: hasMultipleA} = determineHighestRole(a)
        let {role: highestRoleB, hasMultiple: hasMultipleB} = determineHighestRole(b)
        if (ACCESSIBILITY_LEVEL[highestRoleA] > ACCESSIBILITY_LEVEL[highestRoleB]) {
            return descMultiplier * -1
        } else if (ACCESSIBILITY_LEVEL[highestRoleA] < ACCESSIBILITY_LEVEL[highestRoleB]) {
            return descMultiplier * 1
        } else {
            if (!hasMultipleA && hasMultipleB) {
                return descMultiplier * -1
            } else if (hasMultipleA && !hasMultipleB) {
                return descMultiplier * 1
            } else {
                return 0
            }
        }
    }

    comparitors[SORT_PROPS.ORG_LABEL] = function (a, b, descMultiplier) {
        let numOrgsA = Object.keys(a.organizations).length
        let numOrgsB = Object.keys(b.organizations).length
        if (numOrgsA === 1 && numOrgsB === 1) {
            return descMultiplier * orgSort(Object.values(a.organizations)[0].orgName,
                Object.values(b.organizations)[0].orgName)
        } else {
            return descMultiplier * (numOrgsB - numOrgsA)
        }
    }

    export default {
        name: 'AdminTable',
        data () {
            return {
                options: {
                    itemsPerPage: USERS_PER_PAGE,
                    page: 1
                },
                sortOptions: {
                    sortBy: SORT_PROPS.EMAIL,
                    sortDesc: false
                },
                loading: false,
                showSelectAll: false,
                selected: {}
            }
        },
        computed: {
            // TODO moving headers to computed property to have access to FS value, it can be moved back in data once we get ride of
            // the use of the fe-only-dsl FS
            headers() {
                return [
                    {text: this.$t('management.adminTable.name'), value: SORT_PROPS.FIRSTNAME, align: 'left', sortable: true},
                    {text: this.$t('management.adminTable.email'), value: SORT_PROPS.EMAIL, align: 'left', sortable: true},
                    {text: this.roleText, value: SORT_PROPS.ROLE_LABEL, align: 'left', sortable: true},
                    {text: this.$t('management.adminTable.org'), value: SORT_PROPS.ORG_LABEL, align: 'left', sortable: true}
                ]
            },
            roleText() {
                return isEnabled(ONLY_DSL) ? this.$t('management.adminTable.adminPortalRole') : this.$t('management.adminTable.role')
            },
            totalOrganizations () {
                return this.$store.getters.getDirectOrganizations.length
            },
            admins () {
                return [...this.$store.getters.getAdminsForCurrentOrganization].map(admin => {
                    admin.orgNameLabel = this.getOrganizationLabel(Object.values(admin.organizations))
                    admin.roleNameLabel = this.getRoleNameLabel(Object.values(admin.organizations))
                    admin.hasPermissionInAllOrgs = this.hasPermissionInOrgs(admin.email, Object.keys(admin.organizations))
                    return admin
                })
            },
            pages () {
                return Math.ceil(this.admins.length / this.options.itemsPerPage)
            },
            noAdminsSelected () {
                return this.selected === null || Object.keys(this.selected).length === 0
            },
            currentSelectedOrganization () {
                return this.$store.getters.getCurrentOrganizationInAdminTable
            }
        },
        watch: {
            'admins' () {
                this.updatePageIndex()
            },
            'currentSelectedOrganization' () {
                this.loadAdminsForOrg()
            }
        },
        methods: {
            determineChecked (email) {
                return this.selected[email] === undefined ? 'check_box_outline_blank' : 'check_box'
            },
            adminSelected (admin) {
                let key = admin.email
                if (this.selected[key]) {
                    this.$delete(this.selected, key)
                } else {
                    this.$set(this.selected, key, {
                        email: admin.email,
                        orgRoles: Object.values(admin.organizations),
                        registrationPending: admin.registrationPending
                    })
                }
                this.$store.commit('setSelectedAdminsInAdminTable', Object.values(this.selected))
            },
            hasPermissionInOrgs (email, orgs) {
                if (PermissionValidator.isLoggedInUser(email)) {
                    this.showSelectAll = true
                    return true
                } else {
                    for (let i = 0; i < orgs.length; i++) {
                        if (!PermissionValidator.validateUserPermission(orgs[i], PERMISSIONS.ManageAdmins)) {
                            return false
                        }
                    }
                    this.showSelectAll = true
                    return true
                }
            },
            loadAdminsForOrg () {
                this.loading = true
                if (!this.$store.getters.areAdminsLoadedForUser) {
                    this.$store.dispatch('FetchAdminsForUser').then(() => {
                        this.$store.commit('filterAdminsForSelectedOrg')
                        this.loading = false
                    }).catch(err => {
                        ErrorHandler.handleError(err, ErrorHandler.managementError)
                        this.loading = false
                    })
                } else {
                    this.$store.commit('filterAdminsForSelectedOrg')
                    this.loading = false
                }
            },
            getOrganizationLabel (adminOrgs) {
                if (adminOrgs.length === 1) {
                    return adminOrgs[0].orgName
                } else if (this.totalOrganizations === adminOrgs.length) {
                    return this.$t('management.adminDialog.allOrgs')
                } else {
                    return this.$t('management.adminDialog.numOrgs', {quantity: adminOrgs.length})
                }
            },
            getRoleNameLabel (adminOrgs) {
                if (adminOrgs.length === 1) {
                    return this.$t('management.adminRoles')[adminOrgs[0].role].name
                } else {
                    let multipleRoles = false
                    let role = adminOrgs[0].role
                    for (let i = 0; i < adminOrgs.length; i++) {
                        if (adminOrgs[i].role !== role) {
                            multipleRoles = true
                            break
                        }
                    }
                    return multipleRoles ? this.$t('management.adminDialog.multipleRoles')
                        : this.$t('management.adminRoles')[role].name
                }
            },
            selectAll () {
                if (Object.keys(this.selected).length) {
                    this.selected = {}
                    this.$delete(this.selected, '')
                } else {
                    this.admins.forEach(admin => {
                        if (admin.hasPermissionInAllOrgs) {
                            let key = admin.email
                            this.$set(this.selected, key, {
                                email: admin.email,
                                orgRoles: Object.values(admin.organizations),
                                registrationPending: admin.registrationPending
                            })
                        }
                    })
                }
                this.$store.commit('setSelectedAdminsInAdminTable', Object.values(this.selected))
            },
            openEditAdminDialog (adminInfo) {
                bus.$emit('openAdminInformationModal', {
                    invite: false,
                    admin: adminInfo
                })
            },
            customSortBy (items, sortProps, descArray) {
                const index = sortProps[0]
                const desc = descArray[0]
                let descMultiplier = (desc ? -1 : 1)
                if (index) {
                    return items.sort(function (a, b) {
                        return comparitors[index](a, b, descMultiplier)
                    })
                } else {
                    return items.sort(function (a, b) {
                        a.email.localeCompare(b.email)
                    })
                }
            },
            changeSort (column) {
                changeSortByOrder(this.sortOptions, column)
            },
            scrollToTop () {
                scrollToTopOfTable('adminTable')
            },
            updatePageIndex () {
                this.options.page = 1
            }
        },
        beforeMount () {
            bus.$on('adminsRemoved', () => {
                this.selected = {}
                this.$delete(this.selected, '')
            })
            this.loadAdminsForOrg()
        },
        beforeDestroy () {
            // clear selected admins from store as well
            this.$store.commit('setSelectedAdminsInAdminTable', [])
        }
    }
</script>

<style lang="scss" scoped>
    .scrollable-activation {
        overflow: auto;
    }

    .check-box:hover {
        cursor: pointer;
    }

    .admin-count {
        font-size: 10pt;
        color: #555555;
        top: 0;
        left: 0;
        position: absolute;
        padding: 20px 20px;
        padding-left: 10px;
    }

    .pagination-position {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .position-relative {
        position: relative;
    }
</style>
<style lang="scss">
    @import "../../theming/fixed-headers-table.scss";

    .admin-route{
        @include table-fixed-headers(
            $containerHeight: 100vh,
            $heightOffset: 235px,
            $containerWidth: 100vw,
            $widthOffset: 200px,
            $headerHeight: 56px,
            $numCols: 5,
            $colRatios: 5 23.75 23.75 23.75 23.75
        )
    }
</style>
