import Requestor from '../utils/license-requestor'

const EXPIRY_PERIOD_MILLIS = 1000 * 60 * 20 // 20 minutes
const REQUEST_INTERVAL_MILLIS = 250

let expiredDataListener = null
let responseListener = null
let currentOrgsList = []
let organizationStatus = {}
let isProcessingRequest = false
let requestIntervalId

// TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
let processRequests = () => {
    if (isProcessingRequest || requestIntervalId) {
        // We're already processing
        return
    }

    requestIntervalId = setInterval(() => {
        if (!isProcessingRequest && !currentOrgsList.length) {
            clearInterval(requestIntervalId)
            requestIntervalId = 0
        } else if (!isProcessingRequest) {
            currentOrgsList = getUpdatedOrganizationList()

            // Stop processing requests if we have completed loading of all the organizations
            if (!currentOrgsList.length) {
                clearInterval(requestIntervalId)
                requestIntervalId = 0
                return
            }

            let currOrgId = currentOrgsList[0]

            isProcessingRequest = true

            Requestor.sendRequest(currOrgId, organizationStatus[currOrgId], onResponseReceived)
        }
    }, REQUEST_INTERVAL_MILLIS)
}

const processRequestsHybrid = () => {
    currentOrgsList = getUpdatedOrganizationList()
    Requestor.sendSubscriptionsRequest(currentOrgsList, onSubscriptionsResponseReceived)
    currentOrgsList.forEach(currOrgId => {
        Requestor.sendLegacyRequest(currOrgId, onLegacyResponseReceived)
    })
}

let getUpdatedOrganizationList = () => {
    currentOrgsList.forEach(orgId => {
        if (!organizationStatus[orgId]) {
            createOrResetOrganization(orgId)
        }
    })

    return currentOrgsList.filter(orgId => !isOrganizationLicenseLoadingCompleted(orgId))
}

let updateOrganizationsIfExpired = () => {
    currentOrgsList.forEach(orgId => {
        let orgInfo = organizationStatus[orgId]
        if (!orgInfo || orgInfo.expires < Date.now()) {
            if (expiredDataListener && orgInfo && orgInfo.expires < Date.now()) {
                expiredDataListener(orgId)
            }

            createOrResetOrganization(orgId)
        }
    })
}

let createOrResetOrganization = (organizationId) => {
    organizationStatus[organizationId] = {
        legacy: {
            nextToken: null,
            hasLoaded: false
        },
        subscription: {
            nextToken: null,
            hasLoaded: false
        },
        expires: Date.now() + EXPIRY_PERIOD_MILLIS
    }
}

// TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
// It's been now replaced by onSubscriptionsResponseReceived and onLegacyResponseReceived
let onResponseReceived = (response) => {
    if (!isProcessingRequest) {
        // Check if this is due to an unwanted response; otherwise, it's because
        // the current request was cancelled
        if (requestIntervalId) {
            console.error('license-request-service:  unwanted response received')
        }
        return
    }

    if (response.error) {
        responseListener(response)
    } else {
        let orgStatus
        if (response.isLegacy) {
            orgStatus = organizationStatus[response.orgId].legacy
        } else {
            orgStatus = organizationStatus[response.orgId].subscription
        }
        if (response.nextToken) {
            orgStatus.nextToken = response.nextToken
        } else {
            orgStatus.hasLoaded = true
        }

        if (responseListener) {
            let isLoadingCompleted = currentOrgsList.length === 1 && isOrganizationLicenseLoadingCompleted(response.orgId)
            responseListener(response, isLoadingCompleted)
        } else {
            console.error('license-request-service:  No response listener was registered!')
        }
    }

    isProcessingRequest = false
}

const handleSubscriptionsResponse = (response, orgStatus) => {
    if (response.nextToken && !response.remainingOrgsInBatch) {
        orgStatus.nextToken = response.nextToken
    } else {
        orgStatus.hasLoaded = true
    }

    if (responseListener) {
        let isLoadingCompleted = isOrganizationLicenseLoadingCompleted(response.orgId)
        responseListener(response, isLoadingCompleted)
    } else {
        console.error('license-request-service:  No response listener was registered!')
    }
}

const onSubscriptionsResponseReceived = (response) => {
    if (response.error) {
        responseListener(response)
    } else {
        handleSubscriptionsResponse(response, organizationStatus[response.orgId].subscription)
    }
    if (response.nextToken && !response.remainingOrgsInBatch) {
        Requestor.sendSubscriptionsRequest(currentOrgsList, onSubscriptionsResponseReceived, response.nextToken)
    }
}

const onLegacyResponseReceived = (response) => {
    if (response.error) {
        responseListener(response)
    } else {
        handleSubscriptionsResponse(response, organizationStatus[response.orgId].legacy)
    }
    if (!response.error && response.nextToken) {
        Requestor.sendLegacyRequest(response.orgId, onLegacyResponseReceived, response.nextToken)
    }
}

let isOrganizationLicenseLoadingCompleted = (organizationId) => {
    return organizationStatus[organizationId].legacy.hasLoaded &&
      organizationStatus[organizationId].subscription.hasLoaded
}

export default {
    _getOrganizationStatus: () => {
        return organizationStatus
    },

    _cancelRequests: () => {
        clearInterval(requestIntervalId)
        requestIntervalId = 0
        isProcessingRequest = false
    },

    registerDataExpiredListener: (listener) => {
        expiredDataListener = listener
    },

    registerResponseListener: (listener) => {
        responseListener = listener
    },

    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    requestLicenses: (organizationIds) => {
        // We're pausing loading of these orgs, so update their expiry now
        if (currentOrgsList.length) {
            currentOrgsList.forEach(orgId => {
                organizationStatus[orgId].expires = Date.now() + EXPIRY_PERIOD_MILLIS
            })
        }

        // Clear all the current orgs and add the ones we haven't
        // completed license loading for
        currentOrgsList = []
        organizationIds.forEach(orgId => {
            currentOrgsList.push(orgId)
        })

        // Reset organization loading info if info is stale
        updateOrganizationsIfExpired()

        let numOrgsToProcess = getUpdatedOrganizationList().length

        if (numOrgsToProcess) {
            processRequests()
        }

        return numOrgsToProcess
    },

    requestLicensesHybrid: (organizationIds) => {
        // We're pausing loading of these orgs, so update their expiry now
        if (currentOrgsList.length) {
            currentOrgsList.forEach(orgId => {
                organizationStatus[orgId].expires = Date.now() + EXPIRY_PERIOD_MILLIS
            })
        }

        // Clear all the current orgs and add the ones we haven't
        // completed license loading for
        currentOrgsList = []
        organizationIds.forEach(orgId => {
            currentOrgsList.push(orgId)
        })

        // Reset organization loading info if info is stale
        updateOrganizationsIfExpired()

        const allOrgsLoaded = organizationIds.every(orgId => isOrganizationLicenseLoadingCompleted(orgId))
        if (!allOrgsLoaded) {
            processRequestsHybrid()
        }

        return allOrgsLoaded
    },

    requestUpdatedOrgSubscriptions: (organizationIds) => {
        // We need to do the POST /api/v3/organizations/subscriptions call instead of the GET api/v3/{organizationId}/subscription/{subscriptionId}
        // one, as in the last one the serialNumber is not returned.
        Requestor.sendSubscriptionsRequest(organizationIds, onSubscriptionsResponseReceived)
    }
}
