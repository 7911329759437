<template>
    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
                <slot name="item"></slot>
            </span>
        </template>
        <div class="white-tooltip"> {{ tooltipText }} </div>
    </v-tooltip>
</template>
<script>
    export default {
        props: {
            tooltipText: {
                type: String,
                required: true
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

// Override default v-tooltip styles
.v-tooltip__content {
    padding: 0;
    opacity: 1;
}

.white-tooltip {
    width: 250px;
    border-radius: 0;
    background: $pallete-white;
    padding: 10px;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 1, 2, 0.7);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.white-tooltip::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(calc(-50% + 4px));
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.2);
    filter: blur(4px);
    z-index: -1;
}

.white-tooltip::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $pallete-white;
}

</style>
