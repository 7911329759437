import { SubscriptionStates } from '../enums'
import DateUtil from './date-util'
import { UI_CONFIG } from '../config.js'
import { TIER_TYPES, TIER_NAMES } from '../enums/subscription-constants'

let determineStatus = (tier) => {
    if (tier.usageEndDate) {
        let currentDate = new Date()
        let warningDate = new Date()
        warningDate.setDate(warningDate.getDate() + UI_CONFIG.EXPIRATION_WARNING_THRESHOLD_IN_DAYS)
        let endDate = tier.usageEndDate
        let status = SubscriptionStates.ACTIVE

        if (endDate <= warningDate) {
            status = SubscriptionStates.WARNING
        }
        if (endDate < currentDate) {
            status = SubscriptionStates.EXPIRED
        }
        tier.status = status
    } else {
        tier.status = SubscriptionStates.UNKNOWN
    }
}

let resolveTierInformation = (tierName, tierInfo) => {
    if (tierInfo.assignedUserCount != null) {
        tierInfo.assignedUsersCount = tierInfo.assignedUserCount
    }
    if (tierInfo.usageEndDate) {
        tierInfo.usageEndDate = new Date(tierInfo.usageEndDate)
        tierInfo.usageEndDateFormatted = DateUtil.getFormattedDate(tierInfo.usageEndDate)
    }
    if (tierInfo.dateShipped) {
        tierInfo.startDate = new Date(tierInfo.dateShipped)
        tierInfo.startDateFormatted = DateUtil.getFormattedDate(tierInfo.startDate)
    }
    if (tierName === TIER_TYPES.BASE) {
        tierInfo.name = TIER_NAMES.BASE
    } else if (tierName === TIER_TYPES.SPARK) {
        tierInfo.name = TIER_NAMES.SPARK
    }
    tierInfo.reseller = tierInfo.partnerCompanyName
    determineStatus(tierInfo)
}

export default {
    resolveTierInformation (tiers) {
        for (const tierName in tiers) {
            resolveTierInformation(tierName, tiers[tierName])
        }
    }
}
