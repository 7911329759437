import strUtils from 'smarttech-identity-string-utils'
let productList

export default {
    init () {
        let productListElement = document.getElementById('product_list')
        if (productListElement != null) {
            if (strUtils.isNotBlank(productListElement.dataset.productList)) {
                productList = JSON.parse(productListElement.dataset.productList)
                productListElement.parentNode.removeChild(productListElement)
            }
        }
    },

    getProductList () {
        return productList
    }
}
