import { createApiClient } from './api-client'

const CACHE_REQUEST_DELAY_MILLIS = 1000

let currOrgToCache = null
let newOrgToCache = null
let isCacheInProgress = false
let requestIntervalId = null

let processCacheRequest = () => {
    const apiClient = createApiClient()
    requestIntervalId = setInterval(() => {
        if (isCacheInProgress) {
            return
        }

        isCacheInProgress = true
        apiClient.cacheRecentOrg(currOrgToCache)
            .then(() => {
                if (!newOrgToCache) {
                    clearInterval(requestIntervalId)
                    requestIntervalId = 0
                } else {
                    currOrgToCache = newOrgToCache
                    newOrgToCache = null
                }

                isCacheInProgress = false
            })
            .catch(() => {
                isCacheInProgress = false
                newOrgToCache = null
                currOrgToCache = null
                clearInterval(requestIntervalId)
                requestIntervalId = 0
            })
    }, CACHE_REQUEST_DELAY_MILLIS)
}

export default {
    cacheRecentOrg: (org) => {
        if (isCacheInProgress) {
            newOrgToCache = org
        } else {
            // Check if we are between cache requests, just-in-case,
            // and just cancel the running cache, since it will be redundant
            if (requestIntervalId) {
                clearInterval(requestIntervalId)
                requestIntervalId = 0
            }

            currOrgToCache = org
            processCacheRequest()
        }
    }
}
