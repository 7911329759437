import {NOTIFICATION} from '../enums/notification-types'
import Vue from 'vue'

let newNameObject = (orgName) => {
    return {
        name: orgName,
        count: 0
    }
}

let nameAndTrackOrganization = (trackOrgNames, org, newOrgs, directOrgs) => {
    if (trackOrgNames[org.name]) {
        trackOrgNames[org.name].count++
        org.name += ' ' + trackOrgNames[org.name].count
    } else {
        trackOrgNames[org.name] = newNameObject(org.name)
    }
    newOrgs.push(org)
    if (directOrgs) {
        directOrgs[org.id] = org.id
    }
}
let insertOrganization = (orgNameTracker, orgList, orgMap, orgManagement, org) => {
    if (orgManagement && !orgManagement[org.id]) {
        Vue.set(orgManagement, org.id, {role: org.role})
    }
    if (orgMap && !orgMap[org.id]) {
        nameAndTrackOrganization(orgNameTracker, org, orgList, orgMap)
    }
}

let resolveNotifications = (notifications, org) => {
    if (org.active && !org.previousActive) {
        notifications.push({
            type: NOTIFICATION.invitation,
            org: org.name,
            orgId: org.id,
            changedBy: org.changedBy
        })
    } else if (!org.active && org.previousActive) {
        notifications.push({
            type: NOTIFICATION.removed,
            org: org.name,
            orgId: org.id,
            changedBy: org.changedBy
        })
        return undefined
    } else if (org.role && org.previousRole && (org.previousRole !== org.role.name)) {
        notifications.push({
            type: NOTIFICATION.roleChanged,
            org: org.name,
            orgId: org.id,
            from: org.previousRole,
            to: org.role.name,
            changedBy: org.changedBy
        })
    }
    delete org.previousRole
    delete org.active
    delete org.previousActive
    delete org.changedBy
    return org
}

export default {
    // todo remove the resolveDuplicates function when fully integrated off of it.
    /**
     * loop through all organizations and build up a new array with all duplicates removed. Initially we only add the direct organizations to the final array (newOrgs). Later on we loop through the indirect orgs that remain.
     * Assumptions : There will never be duplicated organizations with the same Id but different name.
     *             : There should never be a duplicated org both containing isDirect = true
     * @param {orgs} The initial organization list received.
     */
    resolveDuplicates: (initialOrganizations) => {
        let indirectOrgsTable = []
        let directOrgsTable = []
        let trackOrgNames = []
        let directOrgs = []
        let relatedOrgs = []
        initialOrganizations.all.forEach((org) => {
            if (!indirectOrgsTable[org.id]) {
                if (org.isDirect === true) {
                    nameAndTrackOrganization(trackOrgNames, org, directOrgs, directOrgsTable)
                } else {
                    if (!directOrgsTable[org.id]) {
                        indirectOrgsTable[org.id] = org
                    }
                }
            } else {
                if (org.isDirect === true) {
                    delete indirectOrgsTable[org.id]
                    nameAndTrackOrganization(trackOrgNames, org, directOrgs, directOrgsTable)
                }
            }
        })
        // Finally, loop through all the indirect organizations
        Object.keys(indirectOrgsTable).forEach(currentKey => {
            let org = indirectOrgsTable[currentKey]
            nameAndTrackOrganization(trackOrgNames, org, relatedOrgs)
        })
        initialOrganizations.all = directOrgs.concat(relatedOrgs)
        initialOrganizations.direct = directOrgs
        initialOrganizations.related = relatedOrgs
    },

    insertDirectOrganizationList: (organizations, orgList) => {
        orgList.forEach((org) => {
            if (org) {
                insertOrganization(organizations.tracking.directNameTracker, organizations.direct, organizations.tracking.directMap, organizations.permissionsByOrg, org)
            }
        })
    },

    insertRelatedOrganizationList: (organizations, orgList) => {
        orgList.forEach((org) => {
            insertOrganization(organizations.tracking.directNameTracker, organizations.related, organizations.tracking.relatedMap, null, org)
        })
    },
    resolveNotificationsPerOrganization: (notifications, orgList) => {
        let resolved = []
        orgList.forEach((org) => {
            resolved.push(resolveNotifications(notifications, org))
        })
        return resolved
    },
    constants: {
        TYPE: {
            DIRECT: 'direct',
            RELATED: 'related'
        }
    }
}
