<template>
    <div class="non-migrated-entry expandable-row">
        <tr class="table-row no-hover row-border">
            <td class="product">
                <div class="d-flex align-center">
                    <caution-icon v-if="showCautionIcon"></caution-icon>
                    <div class="align-center d-flex font--bold">
                        {{subscription.name}}
                    </div>
                </div>
            </td>
            <td class="org-name" tabindex="0">{{ subscription.accountName }}</td>
            <td>
                <div v-if="subscription.assignedUsersCount || subscription.quantity">
                    <v-layout>
                        <v-flex xs6 align-self-center>
                            <user-count
                                :assigned-users-count="subscription.assignedUsersCount"
                                :total-seats="subscription.quantity">
                            </user-count>
                        </v-flex>
                        <v-flex xs6>
                            <text-button
                                v-if="showManageUsers(subscription)"
                                :text="getManageUsersText(subscription)"
                                @button-action="manageUsers">
                            </text-button>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-else>––</div>
            </td>
            <td class="status" :class="subscription.status" tabindex="0">
                <expiry-status
                    :item-status="subscription.status"
                    :next-renewal-date-formatted="subscription.nextRenewalDateFormatted"
                    hide-warning-icon>
                </expiry-status>
            </td>
            <td class="expand-button-wrapper">
                <button class="expand-button" @click="expandOrCollapse">
                    <i v-if="isExpanded" class="material-icons icon">remove</i>
                    <i v-else class="material-icons icon">add</i>
                </button>
            </td>
        </tr>
        <tr class="expand row-border" v-if="isExpanded">
            <td colspan="100%">
                <licenses-new
                    :licenses="subscription.assets"
                    :is-other="!!(subscription.isLegacy || subscription.isUnclaimed)">
                </licenses-new>
            </td>
        </tr>
    </div>
</template>
<script>
    import LicensesNew from '../components/LicensesNew.vue'
    import UserCount from '../components/UserCount.vue'
    import TextButton from '../components/TextButton.vue'
    import ExpiryStatus from '../components/ExpiryStatus.vue'
    import CautionIcon from './CautionIcon.vue'
    import { SubscriptionStates } from '../enums'

    export default {
        components: {
            LicensesNew,
            TextButton,
            ExpiryStatus,
            UserCount,
            CautionIcon
        },
        props: {
            subscription: {
                type: Object,
                default: () => {}
            },
            isExpanded: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            showCautionIcon() {
                return !this.subscription.isLegacy
            }
        },
        methods: {
            showManageUsers(subscription) {
                return subscription.assignedUsersCount > 0 || subscription.status !== SubscriptionStates.EXPIRED
            },
            getManageUsersText(license) {
                if (license.assignedUsersCount !== 0) {
                    return this.$t('subscriptions.manageUsers')
                }
                return this.$t('subscriptions.addUsers')
            },
            expandOrCollapse () {
                this.$emit('expand-or-collapse', this.subscription.id, !this.isExpanded)
            },
            manageUsers(item) {
                this.$emit('manage-users', item)
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

.product {
    color: #000102B2;
}

.v-data-table {
    .v-data-table__wrapper {
        tr {
            background: $pallete-light-gray;
            width: 100%;
            &.expand {
                background: $pallete-white;
            }
        }
    }
}

.no-org-description-padding {
    padding-top: 20px;
}

.org-name {
    overflow: hidden;
    text-overflow: ellipsis;
}

.usage {
    width: 100%;
    display: block;
    white-space: nowrap;
}
</style>
<style lang="scss">

@import "../theming/fixed-headers-table.scss";

.tbody-no-content .v-data-table__wrapper > * > tbody {
    height: 0 !important;
}

.expand-button-wrapper {
    text-align: end;
    padding-left: 0 !important;
    .expand-button {
        i {
            color: $theme-primary !important;
            font-weight: bold;
        }
    }
}

</style>
