<template>
    <v-layout wrap>
        <v-flex xs4 class="org-menu-container">
            <v-layout class="org-menu-wrapper">
                <v-flex shrink class="org-menu-option">
                    <div :class="{'selected-menu-option': selectedMenuOption === orgTable}"
                         class="org-menu-option-fit"
                         @click="setManagementSelectedMenuOption(orgTable)">
                        {{$t('management.menu.manage')}}
                    </div>
                </v-flex>
                <v-flex class="org-menu-option administration">
                    <div :class="{'selected-menu-option': selectedMenuOption === adminTable}"
                         class="org-menu-option-fit"
                         @click="viewAllAdminsForAllOrgs">
                        {{$t('management.menu.admin')}}
                    </div>
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex xs8 class="org-menu-container search-bars">
            <OrgManagementDropdown v-if="selectedMenuOption === orgTable"></OrgManagementDropdown>
            <AdminManagementDropdown v-else></AdminManagementDropdown>
        </v-flex>
    </v-layout>

</template>

<script>

    import AdminManagementDropdown from './AdminManagementDropdown'
    import OrgManagementDropdown from './OrgManagementDropdown'
    import { MANAGEMENT } from '../../enums/admin-management-constants'

    export default {
        name: 'ManagementDropdown',
        components: {AdminManagementDropdown, OrgManagementDropdown},
        props: ['managementView'],
        data () {
            return {
            }
        },
        computed: {
            adminTable () {
                return MANAGEMENT.admin
            },
            orgTable () {
                return MANAGEMENT.org
            },
            selectedMenuOption () {
                return this.$store.getters.getManagementSelectedMenuOption
            }
        },
        methods: {
            setManagementSelectedMenuOption (value) {
                this.$store.commit('setManagementSelectedMenuOption', value)
            },
            viewAllAdminsForAllOrgs () {
                this.$store.commit('setManagementOptionToViewAdminForOrg',
                    { selectedMenuOption: MANAGEMENT.admin, orgId: ''})
            }
        }
    }
</script>

<style lang="scss" scoped>

    .org-menu-container {
        height: 100%;
    }

    .search-bars {
        position: relative;
        top: -5px;
    }

    .org-menu-wrapper {
        padding: 10px 0px 0px 10px;
    }

    .org-menu-option {
        height: 100%;
        font-size: 18px;
        color: rgba(76, 77, 77, 0.7);
        cursor: pointer;
    }

    .org-menu-option-fit:hover {
        border-bottom: #388bd1 solid 3px;
    }

    .org-menu-option-fit {
        display: inline-block;
        min-height: 35px;
    }

    .selected-menu-option {
        color: #4c4d4d;
        border-bottom: #3487c9 solid 4px !important;
    }

    .administration {
        padding-left: 70px;
    }
</style>
