<template>
    <v-layout column>
        <v-card-title class="text-h6 font-weight-bold text--primary">
            {{ $t('activeProvisionsWarning.title') }}
        </v-card-title>
        <v-card-text class="text-block">
            {{ $t('activeProvisionsWarning.message') }}
        </v-card-text>
        <v-card-text class="text-block">
            <b>{{ $t('addUsersModal.note') }}</b>{{ $t('activeProvisionsWarning.manageProvisions') }}
        </v-card-text>
        <v-card-text>
            <v-layout>
                <v-flex xs6>
                    <v-btn
                        id="cancelAutoProvisioning"
                        block
                        outlined
                        @click.native="cancel()"
                    >{{ $t('buttons.cancel') }}</v-btn>
                </v-flex>
                <v-flex xs6>
                    <v-btn
                        id="continueToAutoProvisioning"
                        block primary light
                        class="white--text"
                        @click.native="continueToSync()">
                        {{ $t('buttons.continue') }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>

    </v-layout>
</template>

<script>
    export default {
        name: 'AddUsersWarning',
        props: ['nextComponent'],
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            continueToSync () {
                this.$emit('change-component', this.nextComponent)
            }
        }
    }
</script>

<style scoped>
    .text-block {
        padding: 10px 16px !important;
    }

</style>
