<template>
    <v-layout column>
        <v-card-title class="text-h6 font-weight-bold text--primary">
            <span v-if="addUser.error">{{ $t('addUsersModal.addUsersError') }}</span>
            <span v-else>{{ $t('addUsersModal.addUsers') }}</span>
        </v-card-title>

        <v-card-text v-if="!addUser.error">
            {{ $t('addUsersModal.addUsersValidationMessage') }}
        </v-card-text>
        <v-card-text v-else>
            {{ $t('addUsersModal.addUsersErrorMessage') }}
        </v-card-text>

        <v-card-text>
            <v-textarea id="addUsersTextArea"
                          class="textarea" hide-details
                          v-model="addUserInput"
                          autofocus
                          flat
                          solo
                          :readonly="loading"
                          rows="6"
                          multi-line></v-textarea>
        </v-card-text>

        <v-card-text>
            <v-layout>
                <v-flex xs6>
                    <v-btn id="cancelAddUsers"
                           block outlined
                           :disabled="loading"
                           @click.native="cancelAdd">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                </v-flex>
                <v-flex xs6>
                    <v-btn id="addUsers"
                           block primary light
                           class="white--text"
                           :loading="loading"
                           :disabled="loading || !addUserInput"
                           @click.native="addUsers">
                        <span v-if="addUser.error">{{ $t('buttons.tryAgain') }}</span>
                        <span v-else>{{ $t('buttons.add') }}</span>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-layout>
</template>

<script>
    export default {
        props: ['disabled', 'loading', 'addUser'],
        data () {
            return {
                addUserInput: ''
            }
        },
        created () {
            if (this.addUser.couldNotAdd) {
                this.addUserInput = this.addUser.couldNotAdd
            }
        },
        methods: {
            addUsers () {
                let users = this.addUserInput.match(/\S+/g) || []
                this.$emit('add-users', users)
                this.addUserInput = ''
            },
            cancelAdd () {
                this.$emit('cancel-dialog')
            }
        },
        watch: {
            'addUser.couldNotAdd': function () {
                if (this.addUser.error && this.addUser.couldNotAdd) {
                    this.addUserInput = this.addUser.couldNotAdd
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .textarea.input-group {
        margin: 0;
        border: 1px solid;
    }
</style>
