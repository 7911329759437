
import environ from 'smarttech-identity-environment'

export const UI_CONFIG = {
    SHOW_RECENT_THRESHOLD: 12,
    VIEW_ALL_ORG_THRESHOLD: 12,
    RECENT_PREFIX: 'recent_',
    EXPIRATION_WARNING_THRESHOLD_IN_MONTHS: 3,
    EXPIRATION_WARNING_THRESHOLD_IN_DAYS: 90,
    MAX_RECENT_ORGS: 3,
    MAX_LICENSE_COUNT: 50
}

const env = environ.getEnvironmentFromHost()

export const LINKS = {
    HELP: 'https://support.smarttech.com/docs/redirect/?product=adminportal',
    IP_DOC: '/ip.html',
    PRIVACY_DOC: 'https://www.smarttech.com/legal/privacy-policies',
    TERMS_OF_USE: 'https://www.smarttech.com/legal/legal-notice',
    LEARN_MORE: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=Claim',
    GOOGLE_PROVISION_SUPPORT: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=ProvisionGoogle',
    SUPPORT: 'https://www.smarttech.com/connect-with-smart/contact-support',
    MICROSOFT_PROVISION_SUPPORT: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=ProvisionMicrosoft',
    CLASSLINK_PROVISION_SUPPORT: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=ProvisionClassLink',
    ADMIN_MANAGEMENT_SUPPORT: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=managing-admins',
    REGION_SELECTION_LEARN_MORE: 'https://support.smarttech.com/docs/redirect/?product=smartaccount&context=service-region',
    MANUALLY_CLAIMING_SOFTWARE: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=claim-manually',
    LUMIO: 'https://lum.io',
    PARTNER_PORTAL: 'https://partnerportal.smarttech.com/s/login/',
    PRICING: 'https://www.smarttech.com/lumio/pricing',
    ORG_LIBRARIES_SUPPORT: 'https://support.smarttech.com/docs/redirect/?product=adminportal&context=spark-access',
    LUMIO_ORG_LIBRARY_CONTENT: `https://suite.smarttech-${env}.com/shared-libraries`
}

export let configPlugin = {
    install (Vue, options) {
        Vue.prototype.$LINKS = LINKS
        Vue.prototype.$UI_CONFIG = UI_CONFIG
        Vue.prototype.$IMAGES = {
            GOOGLE: {
                connect: require('./assets/google_sync.png'),
                disconnect: require('./assets/google_disconnect.png')
            },
            CLEVER: {
                connect: require('./assets/clever_sync.png'),
                disconnect: require('./assets/clever_disconnect.png')
            },
            MICROSOFT: {
                connect: require('./assets/microsoft_sync.png'),
                disconnect: require('./assets/microsoft_disconnect.png')
            },
            CLASSLINK: {
                connect: require('./assets/classlink_sync.png'),
                disconnect: require('./assets/classlink_disconnect.png')
            }

        }
    }
}
