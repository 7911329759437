export default {
    data () {
        return {
            useStackedButtons: false,
            thresholdWidth: 0
        }
    },
    mounted () {
        this.thresholdWidth = window.innerWidth
        this.determineOverflow()
    },
    created () {
        window.addEventListener('resize', this.determineOverflow)
    },
    destroyed () {
        window.removeEventListener('resize', this.determineOverflow)
    },
    methods: {
        determineOverflow () {
            let button = document.getElementById('authorizeSync')
            let buttonWidth = button.clientWidth
            let contentWidth = button.getElementsByTagName('span')[0].clientWidth + 24
            let windowWidth = window.innerWidth

            if (contentWidth >= buttonWidth && !this.useStackedButtons) {
                this.thresholdWidth = windowWidth
                this.useStackedButtons = true
            } else {
                if (windowWidth > this.thresholdWidth) {
                    this.useStackedButtons = false
                }
            }
        }
    }
}
