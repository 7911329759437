<template>
    <div/>
</template>

<script>
    import {bus} from '../event-bus'

    export default {
        mounted () {
            let self = this
            bus.$emit('openPersistedModal', {
                title: self.$t('applicationErrors.pageNotFound'),
                message: self.$t('applicationErrors.pageNotFoundMessage'),
                primaryActionLabel: self.$t('buttons.backHome'),
                primaryAction: function () {
                    if (self.$store.getters.error) {
                        self.$router.push('/login')
                    } else {
                        self.$router.push('/')
                    }
                }
            })
        }
    }
</script>
