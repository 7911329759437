<template>
    <div class="admin-info-container">
        <v-layout column fill-height>
            <v-flex class="d-flex flex-column overflow-hidden top-container">
                <div class="thank-you-header text--center">
                    {{ $t('activation.finalInformation.thank') }}
                </div>
                <div class="organization-name text--center">
                    {{ resolvedOrgName }}
                </div>
                <div class="add-admins-text">
                    {{ $t('activation.adminInformation.addAdmins') }}
                </div>
                <div v-if="adminsLoading" class="spinner text--center">
                    <v-progress-circular indeterminate size="40" color="#159bd9" ></v-progress-circular>
                </div>
                <template v-else>
                    <div v-if="showAllAdminsRemovedMessage" class="admins-removed-text">
                        {{ $t('activation.adminInformation.allAdminsRemovedHybrid') }}
                    </div>
                    <v-layout v-else-if="adminsExist" class="admins-container" flex-column justify-start align-end>
                        <div class="remove-all-btn" @click="removeAll(true)">
                            {{ $t('buttons.removeAll') }}
                        </div>
                        <div class="admin-list">
                            <role-selector v-for="admin in adminList"
                                :key="admin.email"
                                :id="admin.email"
                                :role-mapping="roleMapping"
                                :lighter-text-color="true"
                                :is-valid="true">
                                <template v-slot:title>
                                    <div class="admin-row">
                                        <v-icon class="remove-icon" color="#159bd9" @click="removeAdmin(admin.email)">remove_circle</v-icon>
                                        <p class="admin-name">{{ concatName(admin.firstName, admin.lastName) }}</p>
                                    </div>
                                </template>
                            </role-selector>
                        </div>
                    </v-layout>
                </template>
            </v-flex>
            <v-flex v-if="submissionError" class="error-text d-flex align-end">
                {{ $t('activation.adminInformation.retrySubmission') }}
            </v-flex>
            <v-flex v-else-if="fetchAdminsError" class="error-text d-flex">
                {{ $t('activation.adminInformation.failedSubmissionHybrid') }}
            </v-flex>
            <v-flex id="done-btn-container" :class="['button-container', {'error-text-displayed': submissionError || fetchAdminsError }]">
                <v-btn class="primary white--text large btn--block continue-btn"
                    @click="submitRoleChanges"
                    :disabled="loading || adminsLoading"
                    :loading="loading">
                    {{ $t('buttons.continue') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { MENU_STATE } from '../../../enums/subscription-menu-state.js'
    import RoleSelector from '../../Management/RoleSelector'
    import { REQUEST_TYPE, ACCESSIBILITY_LEVEL } from '../../../enums/admin-management-constants'
    import { accessibilityLevelSort } from '../../../utils/role-accessibility-sorting-util'

    export default {
        name: 'AddAdminInformationHybrid',
        components: { RoleSelector },
        data () {
            return {
                roleMapping: {},
                adminsLoading: false,
                loading: false,
                showAllAdminsRemovedMessage: false,
                storeRoleMapping: true,
                submissionError: false
            }
        },
        props: {
            fetchAdminsError: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            resolvedOrgName () {
                if (strUtils.isBlank(this.activation.selectedOrganizationName)) {
                    if (strUtils.isBlank(this.activation.userAccount.name)) {
                        return this.$t('activation.assignEntitlement.inputPlaceHolder')
                    }
                    return this.activation.userAccount.name
                }
                return this.activation.selectedOrganizationName
            },
            newOrgCreated () {
                return strUtils.isBlank(this.activation.selectedOrganizationId) && strUtils.isNotBlank(this.activation.userAccount.name)
            },
            adminsExist () {
                return Object.keys(this.roleMapping).length !== 0
            },
            adminList() {
                return Object.values(this.roleMapping)
            }
        },
        methods: {
            ...mapMutations([
                'setActivationProcessSteps',
                'cleanActivationMemory',
                'setSubscriptionSelectedMenuOption',
                'updateActivationAdminState'
            ]),
            removeAll (clickEvent) {
                this.roleMapping = {}
                this.$delete(this.roleMapping, '')
                if (clickEvent) {
                    this.showAllAdminsRemovedMessage = true
                }
            },
            removeAdmin (email) {
                this.$delete(this.roleMapping, email)
                if (!this.adminsExist) {
                    this.showAllAdminsRemovedMessage = true
                }
            },
            concatName (firstName, lastName) {
                return `${firstName} ${lastName}`
            },
            createEditRequest () {
                let request = []
                Object.values(this.roleMapping).forEach(admin => {
                    request.push({
                        email: admin.email,
                        roleName: admin.role,
                        orgId: admin.orgId,
                        requestType: REQUEST_TYPE.EDIT
                    })
                })
                return request
            },
            goToNextStep() {
                this.setActivationProcessSteps(this.activation.processSteps + 1)
            },
            submitRoleChanges () {
                if (this.adminsExist) {
                    this.loading = true
                    this.$store.dispatch('AddRemoveEditUsersFromOrganization', this.createEditRequest()).then(() => {
                        this.storeRoleMapping = false
                        this.goToNextStep()
                    }).catch(() => {
                        this.submissionError = true
                        this.removeAll(false)
                        this.loading = false
                    })
                } else {
                    this.storeRoleMapping = false
                    this.goToNextStep()
                }
            },
            exitClaimingProcess () {
                this.loading = false
                this.submissionError = false
                this.showAllAdminsRemovedMessage = false
                this.roleMapping = {}
                this.cleanActivationMemory()
                this.setSubscriptionSelectedMenuOption(MENU_STATE.SUBSCRIPTION)
            },
            initializeAdminSelector () {
                this.adminsLoading = true
                if (this.$store.getters.isActivationAdminListLoaded) {
                    this.getExistingActivationAdminState()
                } else {
                    this.getNewActivationAdminList()
                }
            },
            getLowerAccessibilityRole (a, b) {
                return ACCESSIBILITY_LEVEL[a] <= ACCESSIBILITY_LEVEL[b] ? a : b
            },
            sortByAccessibility (a, b) {
                return accessibilityLevelSort(a.role, b.role)
            },
            getExistingActivationAdminState () {
                let previousState = this.$store.getters.getExistingActivationAdminState
                this.showAllAdminsRemovedMessage = previousState.showAllAdminsRemovedMessage
                previousState.adminList.forEach(admin => {
                    this.$set(this.roleMapping, admin.email, admin)
                })
                this.finishLoadingAdmins()
            },
            getNewActivationAdminList () {
                let adminList = []
                this.$store.getters.getAdminsOfAllOrgs.forEach(admin => {
                    let organizations = Object.values(admin.organizations)
                    let lowestRole = organizations[0].role
                    for (let i = 1; i < organizations.length; i++) {
                        lowestRole = this.getLowerAccessibilityRole(lowestRole, organizations[i].role)
                    }
                    adminList.push({
                        email: admin.email,
                        firstName: admin.firstName,
                        lastName: admin.lastName,
                        orgId: this.activation.userAccount.id,
                        role: lowestRole
                    })
                })
                adminList.sort(this.sortByAccessibility)
                adminList.forEach(admin => {
                    this.$set(this.roleMapping, admin.email, admin)
                })
                this.finishLoadingAdmins()
            },
            finishLoadingAdmins () {
                this.adminsLoading = false
            }
        },
        beforeDestroy () {
            if (this.storeRoleMapping) {
                this.updateActivationAdminState({
                    roleMapping: Object.values(this.roleMapping),
                    showAllAdminsRemovedMessage: this.showAllAdminsRemovedMessage
                })
            }
        },
        mounted () {
            if (!this.fetchAdminsError) {
                this.initializeAdminSelector()
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../../../theming/main.scss';
@import '../../../theming/mixins.scss';

    .admin-info-container {
        height: 100%;
        width: 100%;
        max-width: 605px;
    }

    .top-container {
        flex: 0 1 auto;
    }

    .thank-you-header {
        font-size: 28px;
        font-weight: 400;
        color: $pallete-dark-gray;
        margin-bottom: 40px;
        padding: 0 15px;
    }

    .organization-name {
        font-size: 18px;
        font-weight: 700;
        color: $pallete-dark-gray;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .add-admins-text {
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .spinner {
        padding-top: 60px;
    }

    .admins-removed-text {
        padding: 30px 15px 0px 15px;
        font-style: italic;
    }

    .admins-container {
        overflow-y: hidden;
        width: 100%;

        .remove-all-btn {
            font-size: 16px;
            padding: 0 15px;
            color: $pallete-dark-blue;
            padding-bottom: 5px;
            cursor: pointer;
        }

        .admin-list {
            width: 100%;
            padding: 0 15px;
            max-height: 30vmin;
            @include visible-scrollbar;

            .admin-row {
                height: 100%;
                display: flex;
                flex-direction: row;

                .remove-icon {
                    padding-right: 10px;
                }

                .admin-name {
                    color: $pallete-dark-gray;
                    margin: auto;
                    font-size: 16px;
                }
            }
        }
    }

    .error-text {
        color: $theme-error;
        padding: 10px 15px 0 15px;
    }

    .remove-icon {
        padding-right: 10px;
    }

    .button-container {
        display: flex;
        flex: 1 0 auto;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 10px 15px 0 15px;
        &.error-text-displayed {
            flex-grow: 0;
        }

        .continue-btn {
            width: 575px;
            font-size: 24px;
            height: 57px !important;
        }
    }

</style>
