import {
    CLAIM_CODE,
    ILLEGAL_CHAR,
    ILLEGAL_CHAR_GLOBAL,
    NON_DIGIT_REGEX,
    ORG_NAME_ILLEGAL_CHAR,
    PHONE_NUMBER,
    PHONE_NUMBER_ALLOWED_CHAR,
    POSTAL_CODE,
    SERIAL_NUMBER,
    UUID_REGEX
} from '../services/regex-patterns'
import strUtils from 'smarttech-identity-string-utils'
import regionMappingService from '../services/region-mapping-service'

const MAX_BOARDS_PURCHASE = 50
const ESCAPE_CHARACTERS = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
}

export function validateNumber (value) {
    let number = value.replace(NON_DIGIT_REGEX, '')
    if (parseInt(number) === 0) {
        return ''
    }
    return number
}

export function validateUUID (uuid) {
    return UUID_REGEX.test(uuid)
}

export let entitlement = {
    validateCreatedOrganization: function (organization) {
        return organization !== null && organization !== undefined &&
            strUtils.isNotBlank(organization.name) &&
            this.validateOrgName(organization.name) &&
            strUtils.isNotBlank(organization.isIndividual) &&
            strUtils.isNotBlank(organization.industry) &&
            this.validateLocationData(organization.country, organization.state, organization.city, organization.address1, organization.zipPostal) &&
            this.validatePhone(organization.phone) &&
            this.validateRegionAndDataLocation(organization.country, organization.region, organization.dataLocation)
    },
    validateLocationData: function (country, state, city, address, zipPostal) {
        return security.requiredAndValidate(city) &&
            security.requiredAndValidate(country) &&
            security.requiredAndValidate(address) &&
            security.requiredAndValidate(state) &&
            this.validatePostalCode(zipPostal)
    },
    validateRegionAndDataLocation: function (country, region, dataLocation) {
        if (security.requiredAndValidate(region) && strUtils.isNotBlank(country)) {
            if (region === regionMappingService.getRegionFromCountryCode(country)) {
                return strUtils.isBlank(dataLocation) // dataLocation should be empty when region and country are matched.
            } else {
                return security.requiredAndValidate(dataLocation)
            }
        }

        return false
    },
    validateOrgName: function (name) {
        return !ORG_NAME_ILLEGAL_CHAR.test(name)
    },
    validatePhone: function (phone) {
        return strUtils.isBlank(phone) || PHONE_NUMBER.test(phone)
    },
    validatePostalCode: function (zipPostal) {
        return strUtils.isNotBlank(zipPostal) && POSTAL_CODE.test(zipPostal)
    },
    validateClaimCode: function (code) {
        return strUtils.isNotBlank(code) &&
            CLAIM_CODE.test(code)
    },
    validateClaimHardware: function (serialNumber, quantityOfBoards) {
        return this.validateSerialNumber(serialNumber) &&
            this.validateNumber(quantityOfBoards)
    },
    validateNumber (number) {
        let numberValue = parseInt(number)
        return !Number.isNaN(numberValue) &&
            numberValue > 0
    },
    validateIsMoreThanMaxNumber (number) {
        let numberValue = parseInt(number)
        return numberValue !== null && numberValue !== undefined &&
            numberValue > MAX_BOARDS_PURCHASE
    },
    validateSerialNumber (serialNumber) {
        return security.requiredAndValidate(serialNumber) &&
            SERIAL_NUMBER.test(serialNumber)
    }
}

export let reseller = {
    validateReseller: function (resellerInfo) {
        return resellerInfo !== null && resellerInfo !== undefined &&
            (security.validate(resellerInfo.name) &&
                security.validate(resellerInfo.city))
    }
}

export let security = {
    escapeString (str) {
        return str.replace(ILLEGAL_CHAR_GLOBAL, function (char) {
            return ESCAPE_CHARACTERS[char]
        })
    },
    sanitizeString (str) {
        return str.replace(ILLEGAL_CHAR_GLOBAL, '')
    },
    requiredAndValidate (str) {
        return strUtils.isNotBlank(str) && security.validate(str)
    },
    validate (str) {
        return !ILLEGAL_CHAR.test(str)
    }
}

export function cleanObject (obj) {
    for (let i in obj) {
        if (!(obj[i])) {
            delete obj[i]
        } else if (typeof obj[i] === 'object') {
            cleanObject(obj[i])
            if (Object.keys(obj[i]).length === 0) {
                delete obj[i]
            }
        }
    }
    return obj
};

export function validateOptionalPhoneNumber (number) {
    if (strUtils.isBlank(number)) {
        return true
    } else {
        let digitsOnly = number.replace(/[xX()-]/g, '')
        let allowedOnly = number.replace(PHONE_NUMBER_ALLOWED_CHAR, '')
        return digitsOnly.length ? PHONE_NUMBER.test(allowedOnly) : false
    }
};
