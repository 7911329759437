<template>
    <v-layout column>
        <v-card-title class="text-h6 font-weight-bold text--primary">{{ $t('microsoftModal.importAndSync')}}</v-card-title>
        <v-img
            :aspect-ratio="16/9"
            :height="70"
            contain
            :src="require('@/assets/microsoft_sync.png')">
        </v-img>

        <v-card-text>
            <p style="margin-bottom: 0px"><RequiredTag/>{{ $t('microsoftModal.information')}}</p>
        </v-card-text>
        <v-card-text>
            <v-layout class="radio-button-label">
                <v-radio-group v-model="syncWithMicrosoftGroups">
                    <v-radio
                        v-bind:value=true
                        :disabled="loading">
                    </v-radio>
                </v-radio-group>
                <v-card-text class="radio-button-text">
                     {{ $t('microsoftModal.groupId') }}
                </v-card-text>
            </v-layout>
            <v-text-field
                v-bind:placeholder="$t('microsoftModal.sampleUUID')"
                :disabled="!syncWithMicrosoftGroups || loading"
                v-model="groupUUID"
                class="input-text-field"
                flat
                solo
                maxlength="36"
                :rules="[xssCharacters, validUUID]">
            </v-text-field>
            <v-layout class="radio-button-label">
                <v-radio-group v-model="syncWithMicrosoftGroups">
                    <v-radio
                        v-bind:value=false
                        :disabled="loading">
                    </v-radio>
                </v-radio-group>
                <v-card-text class="radio-button-text">
                    {{ $t('microsoftModal.sdsId') }}
                </v-card-text>
            </v-layout>
            <v-text-field
                v-bind:placeholder="$t('googleClassroomModal.sampleDomain')"
                :disabled="syncWithMicrosoftGroups || loading"
                v-model="sdsDomain"
                class="input-text-field"
                flat
                solo
                maxlength="254"
                :rules="[xssCharacters, validDomain]">
            </v-text-field>
        </v-card-text>
        <v-card-text>
            <p class="provision-message">
                <b>{{ $t('addUsersModal.note') }}</b>
                {{ $t('addUsersModal.provisionsMessage') }}
            </p>
            <a :href="$LINKS.MICROSOFT_PROVISION_SUPPORT" target="_blank" class="learn-more">{{ $t('microsoftModal.learnMore') }}
                <i class="material-icons icon icon-open learn-more-icon">launch</i>
            </a>
        </v-card-text>
        <v-card-text v-if="useStackedButtons">
            <v-btn
                id="authorizeSync"
                block primary light
                class="white--text"
                :loading="loading"
                :disabled="loading || validateForm"
                @click="authorizeMicrosoftSync()">
                <span>{{ $t('microsoftModal.authorize') }}</span>
                <br>
                <i class="material-icons icon icon-open">launch</i>
            </v-btn>
            <v-btn
                id="cancelSync"
                block
                outlined
                :disabled="loading"
                @click.native="cancel()"
            >{{ $t('buttons.cancel') }}</v-btn>
        </v-card-text>
        <v-card-text v-else>
            <v-layout>
                <v-flex md6>
                    <v-btn
                        id="cancelSync"
                        block
                        outlined
                        :disabled="loading"
                        class="block-button"
                        @click.native="cancel()"
                    >{{ $t('buttons.cancel') }}</v-btn>
                </v-flex>
                <v-flex md6>
                    <v-btn
                        id="authorizeSync"
                        block primary light
                        class="block-button white--text"
                        :loading="loading"
                        :disabled="loading || validateForm"
                        @click="authorizeMicrosoftSync()">
                        <span>{{ $t('microsoftModal.authorize') }}</span>
                        <br>
                        <i class="material-icons icon icon-open">launch</i>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-layout>
</template>

<script>
    import {Sources} from '../../enums/'
    import {security, validateUUID} from '../../utils/validate-utils'
    import ValidEmailUtil from '../../utils/valid-email-util'
    import ModalMixin from '../../mixins/sync-modal-mixin'
    import StrUtils from 'smarttech-identity-string-utils'
    import RequiredTag from '../RequiredTag'

    export default {
        props: ['loading'],
        name: 'MicrosoftAddUsers',
        mixins: [ModalMixin],
        components: {RequiredTag},
        data () {
            return {
                syncWithMicrosoftGroups: true,
                groupUUID: '',
                sdsDomain: '',
                validUUID: v => StrUtils.isBlank(v) ? true : validateUUID(v) || this.$t('formErrors.validUUID'),
                validDomain: v => StrUtils.isBlank(v) ? true : ValidEmailUtil.isValidDomain(v) || this.$t('googleClassroomModal.domainError'),
                xssCharacters: v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters')
            }
        },
        computed: {
            validateForm () {
                if (this.syncWithMicrosoftGroups) {
                    return !validateUUID(this.groupUUID)
                } else {
                    return StrUtils.isBlank(this.sdsDomain) ? true : !ValidEmailUtil.isValidDomain(this.sdsDomain)
                }
            }
        },
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            authorizeMicrosoftSync () {
                let groupKey = this.syncWithMicrosoftGroups ? this.groupUUID : this.sdsDomain
                let microsoftDialog = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,width=620,height=750')
                let microsoftInfo = {
                    groupKey: groupKey,
                    windowRef: microsoftDialog,
                    source: this.syncWithMicrosoftGroups ? Sources.SIS_SOURCES.Microsoft_Groups : Sources.SIS_SOURCES.Microsoft_SDS
                }
                this.$emit('sync-thirdParty', microsoftInfo)
            }
        }
    }
</script>

<style scoped>
    .learn-more {
        color: #159bd9;
        text-decoration: none;
    }

    .learn-more:hover {
        text-decoration: underline;
    }

    .learn-more-icon {
        font-size: 16px !important;
        color: #159bd9 !important;
    }

    .provision-message {
        padding-bottom: 5px;
    }

    .radio-button-text {
        padding: 5px 10px !important;
    }

    .block-button {
        max-width: 100%;
    }
</style>
