<template>
    <div class="d-flex flex-column fill-height overflow-hidden">
        <v-flex class="activation-content-container overflow-y-auto overflow-x-hidden">
            <v-layout class="item-container" column>
                <div class="sub-header left-align remove-padding">
                    {{ $t('activation.confirmationPage.description') }}
                </div>
                <div class="description left-align" style="padding-bottom: 30px;" v-html="subDescription"></div>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="item-container">
                <v-flex class="item-description" grow pa-1 shrink>{{ $t('activation.confirmationPage.displayPurchased') }}</v-flex>
                <v-flex class="item-value" pa-1>{{ activation.claim.purchasedBoardCount }}</v-flex>
                <v-icon class="clickable" @click="goToStep(1)">edit</v-icon>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="item-container">
                <v-flex class="item-description" grow pa-1 shrink>{{ $t('activation.confirmationPage.purchaseProof') }}</v-flex>
                <v-flex class="item-value" pa-1>{{ activation.claim.serialNumber }}</v-flex>
                <v-icon class="clickable" @click="goToStep(1)">edit</v-icon>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="item-container">
                <v-flex class="item-description" grow pa-1>{{ $t('activation.confirmationPage.resellerInfo') }}</v-flex>
                <v-flex class="item-value" pa-1 shrink>
                    <div class="item-value">{{ activation.reseller.name }}</div>
                    <div class="item-sub-item">{{ activation.reseller.city }}</div>
                </v-flex>
                <v-icon class="clickable" @click="goToStep(2)">edit</v-icon>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="item-container">
                <v-flex class="item-description" grow pa-1 shrink>{{ $t('activation.confirmationPage.assignedTo') }}</v-flex>
                <v-flex class="item-value" pa-1>{{ assignedTo }}</v-flex>
                <v-icon class="clickable" @click="goToStep(3)">edit</v-icon>
            </v-layout>
            <v-divider></v-divider>
        </v-flex>
        <v-flex class="button-container">
            <v-btn :disabled="loading" :loading="loading"
                class="primary white--text activation-button large btn--block" @click="submitClaim">{{ $t('buttons.submit') }}
            </v-btn>
        </v-flex>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { ClaimErrorUtils } from '../../../utils/claim-error-util'
    import ErrorHandler from '../../../utils/error-handler'
    import { isEnabled } from '../../../services/feature-switch'
    import { DEBUG_STOCKING_CLAIM_FLOW } from '../../../enums/feature-switch-constants'

    export default {
        name: 'SubmitClaim',
        data () {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            ...mapGetters([
                'orgMsdSubscription'
            ]),
            assignedTo () {
                return this.activation.selectedOrganizationName.length ? this.activation.selectedOrganizationName
                    : this.activation.userAccount.name
            },
            subDescription () {
                let boldStr = this.$t('buttons.submit').bold()
                return strUtils.format(this.$t('activation.confirmationPage.subDescription'), boldStr)
            }
        },
        methods: {
            ...mapMutations([
                'setActivationProcessSteps'
            ]),
            ...mapActions([
                'claimEntitlement'
            ]),
            goToStep (value) {
                this.setActivationProcessSteps(value)
            },
            submitClaim () {
                this.loading = true
                return this.claimEntitlement()
                    .then(() => {
                        this.loading = false
                        this.goToStep(this.activation.processSteps + 1)
                    })
                    .catch(err => {
                        // Fake error used to test the new partial claim activation error flow
                        if (isEnabled(DEBUG_STOCKING_CLAIM_FLOW)) {
                            const subscriptionName = 'Fake Subscription Name'
                            const subscriptionId = 'fakeSubId1'
                            err.message = this.$t(ClaimErrorUtils.getErrorMessage('OTHERTIEREXIST'), { subscriptionName, subscriptionId })
                            err.traceId = 'fakeErrorTraceId1'
                            ErrorHandler.handleError(err, ErrorHandler.otherSLSExistsClaimError)
                        } else {
                            const productsNumber = this.activation.productList.length
                            const { message, stringParams, errorGroup } = ClaimErrorUtils.getClaimError({
                                err,
                                productsNumber,
                                productList: this.activation.productList,
                                orgMsdSubscription: this.orgMsdSubscription(this.activation.selectedOrganizationId)
                            })
                            err.message = this.$tc(message, productsNumber, stringParams)
                            ErrorHandler.handleError(err, errorGroup)
                        }
                        this.loading = false
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .activation-content-container {
        padding: 0 15px;
    }

    .item-description {
        font-size: 14px;
        color: rgba(0, 1, 2, 0.7);
        white-space: nowrap;
    }

    .item-value {
        color: #017db7;
        text-align: right;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.29;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .item-sub-item {
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .item-container {
        padding: 8px 0;
    }

    .button-container {
        padding: 10px 15px 0 15px;
    }
</style>
