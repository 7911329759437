<template>
    <div class="subscriptions-route scrollable-activation">
        <v-layout pl-6 pt-6 pr-6 pb-6 v-show="!showLoader" column fill-height fluid>
            <subscriptions-header></subscriptions-header>
            <v-flex class="no-overflow-table-container" v-if="subscriptionMenu.selectedMenuOption===MENU_STATE.SUBSCRIPTION">
                <v-data-table
                    id="usersTable"
                    :headers="headers"
                    :items="subscriptions"
                    selected-key="id"
                    :loading="loaders.fetchSubs"
                    :options.sync="options"
                    :customSort="sortFunction"
                    :sort-by.sync="sortOptions.sortBy"
                    :sort-desc.sync="sortOptions.sortDesc"
                    :class="['fixed-headers', {'tbody-no-content': !subscriptions || !subscriptions.length }]"
                    hide-default-header
                    hide-default-footer
                    loader-height=3
                    loading-text="">
                    <template v-slot:header="{ props: { headers } }">
                     <thead class="v-data-table-header">
                         <tr>
                            <th v-for="header in headers"
                                :key="header.text"
                                :class="['column',
                                    sortOptions.sortDesc ? 'desc' : 'asc',
                                    header.value === sortOptions.sortBy ? 'active' : '',
                                    header.sortable ? 'enable-sort' : 'disable-sort'
                                    ]"
                                    :tabindex="header.sortable ? 0 : -1"
                                    @click="header.sortable ? changeSort(header.value) : null"
                                    >
                                    <div class="header-text">
                                        {{ header.text }}
                                    </div>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                            </th>
                        </tr>
                    </thead>
                </template>
                    <template v-slot:item="props" class="heading">
                        <tr class="table-row" :class="subscriptionRow(props.index)">
                            <td class="product font--bold">
                                <span>
                                    <span>
                                        <a @click="manageLicenseView(props.item.id)">
                                            <i v-if="expandedLicense.isExpanded && expandedLicense.value === props.item.id"
                                               class="material-icons icon icon-open">remove</i>
                                            <i v-else class="material-icons icon icon-open">add</i>
                                            {{props.item.name}}
                                        </a>
                                    </span>
                                </span>
                            </td>
                            <td class="orgName">{{props.item.accountName}}</td>
                            <td>
                                <div v-if="props.item.assignedUsersCount || props.item.quantity"
                                     class="usage">
                                    <div>
                                        <div class="seatCount">
                                            <span :class="{ 'error--text': props.item.assignedUsersCount > props.item.quantity}">
                                                {{props.item.assignedUsersCount}} / {{props.item.quantity}}
                                            </span>
                                        </div>
                                        <div v-if="props.item.isDirect"
                                             class="manageUsers">
                                            <a @click="manageUsers(props.item)" class="font--bold">
                                                {{ $t('subscriptions.manageUsers') }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>––</div>
                            </td>
                            <td class="status" :class="props.item.status">
                                <span v-if="props.item.status === SubscriptionStates.EXPIRED">
                                    {{ $t('statuses.expired') }}
                                </span>
                                <span v-if="props.item.status === SubscriptionStates.WARNING" class="font--bold">
                                    <i class="material-icons icon">warning</i> {{ $t('subscriptions.nextRenewal') }} {{props.item.nextRenewalDateFormatted}}
                                </span>
                                <span v-if="props.item.status === SubscriptionStates.ACTIVE">
                                {{ $t('subscriptions.nextRenewal') }} {{props.item.nextRenewalDateFormatted}}
                                </span>
                                <span v-if="!props.item.status">––</span>
                            </td>
                        </tr>
                        <tr class="expand" v-if="expandedLicense.value === props.item.id">
                            <td colspan="100%">
                                <licenses :licenses="props.item.assets"
                                          :isExpanded="expandedLicense.isExpanded"
                                          :isOther="props.item.isLegacy || props.item.isUnclaimed"
                                ></licenses>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-layout v-if="!subscriptions || !subscriptions.length" wrap>
                    <v-flex xs4></v-flex>
                    <v-flex xs4>
                        <br/><br/><br/><br/>
                        <div v-if="!loaders.fetchSubs" class="italic no-users-help-text">
                            <p v-if="selectedOrgId === null">
                                {{ $t('subscriptions.helpText') }}
                            </p>
                            <div v-else-if="!hasOrganizations">
                                <div class="header">{{$t('subscriptions.helpTextNoOrganizations.title')}}</div>
                                <div class="description no-org-description-padding" v-html="manageTabDescriptionText"></div>
                                <div class="description no-org-description-padding" v-html="getSoftwareDescriptionText"></div>
                            </div>
                            <p v-else>
                                {{ $t('subscriptions.noSubscriptionsMessage') }}
                            </p>
                        </div>
                    </v-flex>
                    <v-flex xs4></v-flex>
                </v-layout>
            </v-flex>
            <v-flex v-else>
                <ActivationCode v-if="isClaimCodeFlow"></ActivationCode>
                <Activation v-else></Activation>
            </v-flex>
        </v-layout>
        <v-container v-if="showLoader" fluid>
            <init-skeleton-loader></init-skeleton-loader>
        </v-container>
    </div>
</template>

<script>
    import SubscriptionsHeader from '../components/SubscriptionsHeader.vue'
    import {bus} from '../event-bus'
    import Licenses from '../components/Licenses.vue'
    import InitSkeletonLoader from '../components/InitSkeletonLoader.vue'
    import {AppStates, SubscriptionStates} from '../enums/index'
    import LicenseRequestService from '../services/license-request-service'
    import SubscriptionUpdater from '../utils/subscription-updater'
    import ResizeMixin from '../mixins/resize-mixin'
    import {mapState} from 'vuex'
    import Activation from '../components/dialogs/Activation/Activation.vue'
    import ActivationCode from '../components/dialogs/Activation/ActivationCode.vue'
    import {MENU_STATE} from '../enums/subscription-menu-state.js'
    import strUtils from 'smarttech-identity-string-utils'
    import { changeSortByOrder } from '../utils/pagination-utils'
    import ErrorHandler from '../utils/error-handler'
    import { DEBUG_NON_STOCKING_CLAIM_FLOW } from '../enums/feature-switch-constants'
    import { isEnabled } from '../services/feature-switch'

    const SORT_PROPS = {
        EXPIRY: 'expiry',
        NAME: 'name',
        ORG: 'org',
        USAGE: 'usage'
    }

    let comparitors = {}

    comparitors[SORT_PROPS.NAME] = function (a, b, descMultiplier) {
        return descMultiplier * a.name.localeCompare(b.name)
    }

    comparitors[SORT_PROPS.ORG] = function (a, b, descMultiplier) {
        return descMultiplier * a.accountName.localeCompare(b.accountName)
    }

    comparitors[SORT_PROPS.USAGE] = function (a, b, descMultiplier) {
        if (a.isLegacy) {
            return 1
        } else if (b.isLegacy) {
            return -1
        }
        let seatsRemainingA = a.quantity - a.assignedUsersCount
        let seatsRemainingB = b.quantity - b.assignedUsersCount
        return descMultiplier * (seatsRemainingB - seatsRemainingA)
    }

    comparitors[SORT_PROPS.EXPIRY] = function (a, b, descMultiplier) {
        if (a.isLegacy || b.isLegacy) {
            if (!a.status) {
                return 1
            } else if (!b.status) {
                return -1
            }
        }
        if (a.status !== b.status) {
            if (a.status === SubscriptionStates.EXPIRED) {
                return 1
            } else if (b.status === SubscriptionStates.EXPIRED) {
                return -1
            }
        }
        return descMultiplier * (a.nextRenewalDate - b.nextRenewalDate)
    }

    export default {
        components: {
            SubscriptionsHeader,
            Licenses,
            InitSkeletonLoader,
            Activation,
            ActivationCode
        },
        mixins: [ResizeMixin],
        data () {
            return {
                debounceTimeout: 0,
                expandedLicense: {
                    isExpanded: false,
                    value: ''
                },
                options: {
                    itemsPerPage: -1
                },
                sortOptions: {
                    sortBy: SORT_PROPS.EXPIRY,
                    sortDesc: false
                },
                loaders: {
                    fetchSubs: false,
                    firstLoad: true
                },
                selected: [],
                headers: [
                    {text: this.$t('subscriptions.product'), value: SORT_PROPS.NAME, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.organization'), value: SORT_PROPS.ORG, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.usage'), value: SORT_PROPS.USAGE, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.status'), value: SORT_PROPS.EXPIRY, align: 'left', sortable: true}
                ],
                SubscriptionStates
            }
        },
        computed: {
            ...mapState([
                'user',
                'subscriptionMenu',
                'activation',
                'organizations'
            ]),
            hasOrganizations () {
                return this.organizations.direct.length + this.organizations.related.length > 0
            },
            selectedOrgId () {
                if (this.$store.getters.selectedOrganizationId === null && this.appIsInitialized) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.loaders.firstLoad = false
                }
                return this.$store.getters.selectedOrganizationId
            },
            isOrgSelected () {
                return this.$store.getters.selectedOrganizationId !== null
            },
            isAllOrgsSelected () {
                return this.isOrgSelected && !this.selectedOrgId
            },
            subscriptions () {
                if (this.isAllOrgsSelected) {
                    return this.$store.getters.getAllSubscriptions
                }

                return this.$store.getters.selectedOrganizationSubscriptions
            },
            showLoader () {
                return !this.appIsInitialized || (this.loaders.fetchSubs && this.loaders.firstLoad)
            },
            appIsInitialized () {
                return this.$store.getters.appState === AppStates.INITIALIZED
            },
            isClaimCodeFlow () {
                if (isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
                    return true
                }
                return this.activation.redeem.code.length > 0
            },
            manageTabDescriptionText () {
                return strUtils.format(this.$t('subscriptions.helpTextNoOrganizations.getSoftwareDescription'),
                    this.$t('subscriptions.menu.getYourSoftware').bold())
            },
            getSoftwareDescriptionText () {
                return strUtils.format(this.$t('subscriptions.helpTextNoOrganizations.managerDescription'),
                    this.$t('subscriptions.menu.manage').bold())
            },
            MENU_STATE () {
                return MENU_STATE
            }
        },
        watch: {
            'selectedOrgId' (to, from) {
                this.closeLicenseView()
                this.fetchSubscriptions()
            }
        },
        mounted () {
            this.fetchSubscriptions()
            bus.$on('doLicenseUpdate', this.doLicenseUpdate)

            window.addEventListener('resize', this.onResize)
        },
        beforeDestroy () {
            bus.$off('doLicenseUpdate', this.doLicenseUpdate)
            LicenseRequestService._cancelRequests()

            window.removeEventListener('resize', this.onResize)
        },
        methods: {
            onResize () {
                const closeMenu = () => {
                    const el = this.$el.querySelector('.no-overflow-table-container')
                    if (el) {
                        el.click()
                    }
                }

                // Bugfix: We debounce resizing to ensure that Vuetify updates the org menu's
                // dimensions properly prior to closing the menu; otherwise, the menu
                // is not positioned correctly when making the window smaller.
                // NOTE: the debounce timeout delay MUST BE >= 500ms to ensure Vuetify
                // updates its dimensions properly
                clearTimeout(this.debounceTimeout)
                this.debounceTimeout = setTimeout(closeMenu, 500)
            },
            subscriptionRow (rowIndex) {
                return rowIndex % 2 ? 'evenRow' : 'oddRow'
            },
            fetchSubscriptions () {
                this.refreshTitle()

                if (this.isOrgSelected) {
                    let organizationId = this.selectedOrgId
                    this.updateSubscriptionLoader(organizationId, true)
                    this.$store.dispatch('WaitOnApplicationInitialization')
                        .then(() => {
                            this.$store.dispatch('FetchSubscriptionsForOrganization',
                                {organizationId, respListener: this.onLicenseUpdateReceived, expireListener: this.onDataExpired})
                                .then(numOrgsToProcess => {
                                    // If there aren't any orgs left to request licenses for, just update the UI
                                    if (!numOrgsToProcess) {
                                        this.updateSubscriptionLoader(organizationId, false)
                                        this.refreshTitle()
                                    }
                                })
                        }).catch(err => {
                            ErrorHandler.handleError(err)
                        })
                }
            },
            onDataExpired (orgId) {
                this.$store.commit('ClearSubscriptionsForOrganization', orgId)
            },
            onLicenseUpdateReceived (updateInfo, isLoadingCompleted) {
                // Use bus to send response event to detach call from request's Promise scope
                bus.$emit('doLicenseUpdate', {updateInfo, isLoadingCompleted})
            },
            doLicenseUpdate ({updateInfo, isLoadingCompleted}) {
                if (updateInfo.error) {
                    LicenseRequestService._cancelRequests()
                    ErrorHandler.handleError(updateInfo.data)
                } else {
                    SubscriptionUpdater.updateSubscription(updateInfo, this.$store)

                    let doneLoading = isLoadingCompleted && (this.selectedOrgId === updateInfo.orgId || this.isAllOrgsSelected)
                    this.updateSubscriptionLoader(updateInfo.orgId, !doneLoading)

                    if (doneLoading) {
                        this.refreshTitle()
                    }

                    this.loaders.firstLoad = false
                }
            },
            updateSubscriptionLoader (orgId, loading) {
                if (orgId === this.selectedOrgId || this.isAllOrgsSelected) {
                    this.loaders.fetchSubs = loading
                }
            },
            manageUsers (subscription) {
                this.$router.push('/' + subscription.accountId + '/' + subscription.id + '/users')
            },
            closeLicenseView () {
                this.expandedLicense.value = ''
                this.expandedLicense.isExpanded = false
            },
            manageLicenseView (orgName) {
                if (orgName === this.expandedLicense.value) {
                    this.closeLicenseView()
                } else {
                    this.closeLicenseView()
                    let self = this
                    setTimeout(function () {
                        self.expandedLicense.value = orgName
                        self.expandedLicense.isExpanded = true
                    }, 100)
                }
            },
            refreshTitle () {
                let pageTitle = ''
                if (this.subscriptions) {
                    let activeSubscriptions = this.subscriptions
                        .filter(item => [SubscriptionStates.ACTIVE, SubscriptionStates.WARNING].includes(item.status) && !item.isLegacy)
                        .sort((a, b) => a.nextRenewalDate - b.nextRenewalDate)

                    if (activeSubscriptions.length) {
                        pageTitle = this.$t('subscriptions.pageTitle', {date: activeSubscriptions[0].nextRenewalDateFormatted})
                    } else if (this.subscriptions.find(x => x.status === SubscriptionStates.EXPIRED)) {
                        // if there are no active / warning statused subscriptions,
                        // but we can find at least 1 expired one, then show 'all expired' title
                        pageTitle = this.$t('subscriptions.allExpiredTitle')
                    }
                }

                this.$store.dispatch('UpdatePageTitle', pageTitle)
            },
            changeSort (column) {
                const allowNoSort = true
                changeSortByOrder(this.sortOptions, column, allowNoSort)
            },
            sortFunction (items, sortProps, descArray) {
                const index = sortProps[0]
                const desc = descArray[0]
                let descMultiplier = (desc ? -1 : 1)

                if (index) {
                    return items.sort(function (a, b) {
                        return comparitors[index](a, b, descMultiplier)
                    })
                } else {
                    return items.sort(function (a, b) {
                        if (a.status === b.status) {
                            return 0
                        } else if (a.status === SubscriptionStates.EXPIRED) {
                            return 1
                        } else if (b.status === SubscriptionStates.EXPIRED) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .scrollable-activation {
        overflow: auto;
    }
    .v-data-table {
        .v-data-table__wrapper {
            tr {
                width: 100%;
            }
        }
    }

    .no-org-description-padding {
        padding-top: 20px;
    }

    .orgName {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .usage {
        width: 100%;
        display: block;
        white-space: nowrap;

        .seatCount {
            width: 4.5rem;
            vertical-align: middle;
            line-height: 1.1rem;
            display: inline-block;
            white-space: nowrap;
        }

        .manageUsers {
            display: inline-block;
            margin: 0 10px;

            a {
                max-width: 20rem;
                vertical-align: middle;
                display: inline-block;
                overflow: hidden;
                white-space: normal;
                overflow-wrap: break-word;
            }
        }
    }
</style>
<style lang="scss">

@import "../theming/fixed-headers-table.scss";

.tbody-no-content .v-data-table__wrapper > * > tbody {
    height: 0 !important;
}

.subscriptions-route {
    @include table-fixed-headers(
        $containerHeight: 100vh,
        $heightOffset: 235px,
        $containerWidth: 100vw,
        $widthOffset: 200px,
        $headerHeight: 56px,
        $numCols: 4,
        $colRatios: 1 1 1 1
    )
}

</style>
