<template>
    <div class="org-library-settings px-12">
        <div class="title mt-4 mb-2"> {{ $t('management.orgSettings.title') }} </div>
        <hr>
        <div v-if="!isSparkOrganization" class="message mt-3">
            {{ $t('management.orgSettings.description') }}
            <!-- TODO: include link to the Lumio Spark marketing page -->
            <a class="font--bold text-decoration-none" :href="$LINKS.LUMIO" target="_blank"> {{ $t('management.orgSettings.learnMore') }}</a>.
        </div>
        <toggle-section
            :allow-upload="allowUpload"
            :disabled="!isSparkOrganization"
            @allow-upload-modified="onAllowUploadModified">
        </toggle-section>
        <div :class="['message', 'my-2', { disabled: !isSparkOrganization }]">
            {{ $t('management.orgSettings.bottomMessage') }}
            <a v-if="isSparkOrganization" class="font--bold text-decoration-none" :href="getLibraryLink" target="_blank"> {{ $t('management.orgSettings.lumio') }}</a>
            <span v-else class="font--bold"> {{ $t('management.orgSettings.lumio') }}</span>.
        </div>
    </div>
</template>

<script>
    import ToggleSection from '../ToggleSection.vue'
    export default {
        name: 'OrgLibrarySettings',
        components: {
            ToggleSection
        },
        props: {
            allowUpload: {
                type: Boolean,
                default: false
            },
            isSparkOrganization: {
                type: Boolean,
                default: false
            },
            libraryId: {
                type: String,
                default: ''
            }
        },
        computed: {
            getLibraryLink() {
                return `${this.$LINKS.LUMIO_ORG_LIBRARY_CONTENT}/${this.libraryId}`
            }
        },
        methods: {
            onAllowUploadModified(event) {
                this.$emit('allow-upload-modified', event)
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../theming/main.scss";

.org-library-settings div {
    color: $pallete-dark-gray;
}

.title {
    font-size: 18px;
}

hr {
    border: none;
    height: 1px;
    background-color: $pallete-offwhite;
}

.message {
    font-size: 16px;
    &.disabled {
        color:  $theme-disabled;
    }
}
</style>
