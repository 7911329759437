import Vue from 'vue'
import SubscriptionsUtil from '../utils/subscriptions-util'
import OrganizationUtil from '../utils/organization-util'
import {AppStates} from '../enums/'
import {defaultState} from '../store/storeDefault'
import StrUtils from 'smarttech-identity-string-utils'
import {UI_CONFIG} from '../config.js'
import {MANAGEMENT, ADMIN} from '../enums/admin-management-constants'
import { TIER_TYPES } from '../enums/subscription-constants'

let getSubscriptionUsers = function (state, subscriptionId) {
    if (state.subscriptionUsers.subscriptionId === subscriptionId) {
        return state.subscriptionUsers.users
    }

    return []
}

let setSubscriptionUsers = function (state, subscriptionId, users, tierType) {
    state.subscriptionUsers.subscriptionId = subscriptionId
    state.subscriptionUsers.users = users

    let userCount = 0
    if (users) {
        userCount = users.length
    }
    if (!state.subscriptionUsers.loadingFlag) {
        updateSubscriptionDetails({ state, subscriptionId, userCount, tierType })
    }
}

let appendSubscriptionUsers = function (state, subscriptionId, users, tierType) {
    state.subscriptionUsers.subscriptionId = subscriptionId
    state.subscriptionUsers.users = state.subscriptionUsers.users.concat(users)

    let userCount = 0
    if (users) {
        userCount = state.subscriptionUsers.users.length
    }
    if (!state.subscriptionUsers.loadingFlag) {
        updateSubscriptionDetails({ state, subscriptionId, userCount, tierType })
    }
}

let updateSubscriptionUsers = function (state, users) {
    for (let i = 0; i < users.length; i++) {
        updateSubscriptionUser(state, users[i])
    }
}

let updateSubscriptionUser = function (state, user) {
    const userIndex = state.subscriptionUsers.users.findIndex(item => item.email === user.email)
    if (userIndex !== -1) {
        Vue.set(state.subscriptionUsers.users, userIndex, user)
    }
}

let addSubscription = function ({ state, subscriptionId, organizationId, subscriptionData, isMsdSubscription }) {
    if (!subscriptionId || !organizationId || !subscriptionData) {
        return
    }
    isMsdSubscription = isMsdSubscription || false
    const subscription = {
        ...subscriptionData,
        organizationId,
        isMsdSubscription
    }
    Vue.set(state.subscriptions, subscriptionId, subscription)
}

let updateSubscriptionDetails = function ({ state, subscriptionId, userCount, internalSubscriptionId, internalOrganizationId, source, serialNumber, tierType, tiers }) {
    const subscription = state.subscriptions[subscriptionId]
    if (!subscription) {
        return
    }

    if (!isNaN(userCount)) {
        // TODO: remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
        if (state.subscriptions[subscriptionId].tiers) {
            Vue.set(state.subscriptions[subscriptionId].tiers[tierType], 'assignedUsersCount', userCount)
        } else {
            Vue.set(subscription, 'assignedUsersCount', userCount)
        }
    }
    if (source) {
        Vue.set(subscription, 'source', source)
    }
    if (internalSubscriptionId) {
        Vue.set(subscription, 'internalSubscriptionId', internalSubscriptionId)
    }
    if (internalOrganizationId) {
        Vue.set(subscription, 'internalOrganizationId', internalOrganizationId)
    }
    if (serialNumber) {
        Vue.set(subscription, 'serialNumber', serialNumber)
    }
    if (tiers) {
        if (!subscription.tiers) {
            Vue.set(subscription, 'tierId', tiers[TIER_TYPES.BASE].tierId)
        } else {
            Object.keys(tiers).forEach(tier => {
                const mergedTier = {...subscription.tiers[tier], ...tiers[tier]}
                Vue.set(subscription.tiers, tier, mergedTier)
            })
        }
    }
    Vue.set(state.subscriptions, subscriptionId, subscription)
}

let getUniqueId = function (user) {
    return user.id + ':' + user.email
}

let constructFullName = function (firstName, lastName) {
    return firstName.toLowerCase().concat(' ', lastName.toLowerCase())
}

let applyNameFilterToAdminList = function (adminList, name) {
    return adminList.filter(admin => constructFullName(admin.firstName, admin.lastName).includes(name.toLowerCase()) ||
        admin.email.toLowerCase().includes(name.toLowerCase()))
}

let applyRoleFilterToAdminList = function (adminList, roleName) {
    return adminList.filter(admin => {
        return Object.values(admin.organizations).some(org => org.role === roleName)
    })
}

let applyNameAndRoleFilter = function (state, filterByName, filterByRole) {
    let adminList = Object.values(state.organizationAdmins.adminsForOrg)
    if (noFilterApplied(filterByName, filterByRole)) {
        // Case 1: Empty search by name and empty search by role
        state.organizationAdmins.filtered = []
    } else if (roleFilterApplied(filterByName, filterByRole)) {
        // Case 2: Filter by role only
        state.organizationAdmins.filtered = applyRoleFilterToAdminList(adminList, filterByRole)
    } else if (nameFilterApplied(filterByName, filterByRole)) {
        // Case 3: Filter by name only
        state.organizationAdmins.filtered = applyNameFilterToAdminList(adminList, filterByName)
    } else {
        // Case 4: Filter by name and role
        state.organizationAdmins.filtered = applyNameFilterToAdminList(applyRoleFilterToAdminList(adminList, filterByRole), filterByName)
    }
}

let noFilterApplied = function (filterByName, filterByRole) {
    return StrUtils.isBlank(filterByName) && StrUtils.isBlank(filterByRole)
}

let roleFilterApplied = function (filterByName, filterByRole) {
    return StrUtils.isBlank(filterByName) && StrUtils.isNotBlank(filterByRole)
}

let nameFilterApplied = function (filterByName, filterByRole) {
    return StrUtils.isNotBlank(filterByName) && StrUtils.isBlank(filterByRole)
}

let addAdminCountInOrg = function (state, admin, org) {
    updateAdminCount(state, admin, org, 1)
}

let subtractAdminCountInOrg = function (state, admin, org) {
    updateAdminCount(state, admin, org, -1)
}

let updateAdminCount = function (state, admin, orgId, count) {
    if (admin.roleName === ADMIN && !admin.registrationPending) {
        if (!state.organizationAdmins.adminCountForOrg[orgId]) {
            state.organizationAdmins.adminCountForOrg[orgId] = 0
        }
        state.organizationAdmins.adminCountForOrg[orgId] += count
    }
}

let addAdminToTable = function (state, admin) {
    // If email already loaded, just append to organizations
    if (state.organizationAdmins.allAdmins[admin.email]) {
        Vue.set(state.organizationAdmins.allAdmins[admin.email].organizations, admin.orgId,
            {orgId: admin.orgId, orgName: admin.orgName, role: admin.roleName})
    } else {
        Vue.set(state.organizationAdmins.allAdmins, admin.email, {
            email: admin.email,
            firstName: admin.firstName,
            lastName: admin.lastName,
            registrationPending: admin.registrationPending,
            organizations: {
                [admin.orgId]: {orgId: admin.orgId, orgName: admin.orgName, role: admin.roleName}
            }
        })
    }
    addAdminCountInOrg(state, admin, admin.orgId)
}

let removeOrgInfoForAdmin = function (state, admin) {
    if (state.organizationAdmins.allAdmins[admin.email] !== undefined) {
        if (state.organizationAdmins.allAdmins[admin.email].organizations[admin.orgId] &&
            Object.keys(state.organizationAdmins.allAdmins[admin.email].organizations).length === 1) {
            Vue.delete(state.organizationAdmins.allAdmins, admin.email)
        } else {
            Vue.delete(state.organizationAdmins.allAdmins[admin.email].organizations, admin.orgId)
        }
    }
}

let filterAdminsByOrg = function (state, orgId) {
    state.organizationAdmins.adminsForOrg = {}
    if (StrUtils.isBlank(orgId)) {
        state.organizationAdmins.adminsForOrg = state.organizationAdmins.allAdmins
    } else {
        Object.values(state.organizationAdmins.allAdmins).forEach(admin => {
            if (admin.organizations[orgId]) {
                Vue.set(state.organizationAdmins.adminsForOrg, admin.email, admin)
            }
        })
    }
}

let updateFilteredAdminLists = function (state) {
    state.organizationAdmins.filtered = []
    filterAdminsByOrg(state, state.organizationAdmins.orgId)
    if (StrUtils.isNotBlank(state.organizationAdmins.filterByName) || StrUtils.isNotBlank(state.organizationAdmins.filterByRole)) {
        applyNameAndRoleFilter(state, state.organizationAdmins.filterByName, state.organizationAdmins.filterByRole)
    }
}

let removeOrgInfoFromAllViews = function (state, removedAdmin) {
    // Remove information for the organization removed.
    removeOrgFromOrganizationInfo(state, removedAdmin.orgId)
    // Remove traces of organization from list of admins
    let removedOrgId = removedAdmin.orgId
    Vue.delete(state.organizationAdmins.adminCountForOrg, removedOrgId)
    Object.values(state.organizationAdmins.allAdmins).forEach(admin => {
        if (admin.organizations[removedOrgId]) {
            if (Object.keys(admin.organizations).length === 1) {
                Vue.delete(state.organizationAdmins.allAdmins, admin.email)
            } else {
                Vue.delete(state.organizationAdmins.allAdmins[admin.email].organizations, removedOrgId)
            }
        }
    })
}

let removeOrgFromOrganizationInfo = function (state, orgId) {
    let directOrgListIndex = state.organizations.direct.findIndex(org => org.id === orgId)
    if (directOrgListIndex !== -1) {
        let orgName = state.organizations.direct[directOrgListIndex].name
        Vue.delete(state.organizations.tracking.directNameTracker, orgName)
        Vue.delete(state.organizations.direct, directOrgListIndex)
    }
    removeAllOrgSubscriptions(state, orgId)
    Vue.delete(state.organizations.permissionsByOrg, orgId)
    Vue.delete(state.organizations.tracking.directMap, orgId)
}

let removeAllOrgSubscriptions = function (state, organizationId) {
    let subscriptionIds = Object.keys(state.subscriptions)
    subscriptionIds.forEach(subscriptionId => {
        if (organizationId === subscriptionIds.organizationId) {
            Vue.delete(state.subscriptions, subscriptionId)
        }
    })
}

let updateDirectOrg = function (state, toBeUpdatedOrg) {
    // update org information in direct organization
    if (state.organizations.direct) {
        let directOrgListIndex = state.organizations.direct.findIndex(org => org.id === toBeUpdatedOrg.id)

        if (directOrgListIndex !== -1) {
            let newMergedOrg = Object.assign(state.organizations.direct[directOrgListIndex], toBeUpdatedOrg)
            Vue.set(state.organizations.direct, directOrgListIndex, newMergedOrg)
        }
    }

    // update org information in subscription
    let subscriptionIds = Object.keys(state.subscriptions)
    subscriptionIds.forEach(subscriptionId => {
        const subscription = state.subscriptions[subscriptionId]
        if (subscription.organizationId === toBeUpdatedOrg.id) {
            // update org information in subscription
            subscription.accountName = toBeUpdatedOrg.name
            let endCustomerDetailsString = '{0}, {1}, {2}, {3}, {4}, {5}'
            let updatedDetail = StrUtils.format(endCustomerDetailsString, toBeUpdatedOrg.name, toBeUpdatedOrg.address,
                toBeUpdatedOrg.city, toBeUpdatedOrg.state, toBeUpdatedOrg.zipPostal, toBeUpdatedOrg.country)
            subscription.endCustomerDetails = updatedDetail

            if (subscription.assets) {
                // update org information in subscription asset
                subscription.assets.forEach(asset => {
                    asset.accountName = toBeUpdatedOrg.name
                    asset.endCustomerDetails = updatedDetail
                })
            }
        }
        Vue.set(state.subscriptions, subscriptionId, subscription)
    })
}

let updateDirectOrgName = function (state, toBeUpdatedOrg) {
    // update org name in direct organization
    if (state.organizations.recent) {
        let recentOrgListIndex = state.organizations.recent.findIndex(org => org.id === toBeUpdatedOrg.id)

        if (recentOrgListIndex !== -1) {
            // update org information in recent organization
            let newRecentOrg = state.organizations.recent[recentOrgListIndex]
            newRecentOrg.name = toBeUpdatedOrg.name
            Vue.set(state.organizations.recent, recentOrgListIndex, newRecentOrg)
        }
    }

    // update org name in admin
    if (state.organizationAdmins.allAdmins) {
        let allAdmin = Object.values(state.organizationAdmins.allAdmins)
        allAdmin.forEach(admin => {
            if (admin.organizations && admin.organizations[toBeUpdatedOrg.id]) {
                admin.organizations[toBeUpdatedOrg.id].orgName = toBeUpdatedOrg.name
            }
            Vue.set(state.organizationAdmins.allAdmins, admin.email, admin)
        })
    }
}

export default {
    setAccessToken (state, accessToken) {
        state.accessToken = accessToken
    },
    AppInitBegin (state) {
        state.appState = AppStates.INITIALIZING
    },
    AppInitCompleted (state) {
        state.appState = AppStates.INITIALIZED
    },
    AddSubscription (state, { subscriptionId, organizationId, subscriptionData, isMsdSubscription }) {
        addSubscription({ state, subscriptionId, organizationId, subscriptionData, isMsdSubscription })
    },
    UpdateSubscription(state, { subscriptionId, userCount, internalSubscriptionId, internalOrganizationId, source, serialNumber, tierType, tiers }) {
        updateSubscriptionDetails({ state, subscriptionId, userCount, internalSubscriptionId, internalOrganizationId, source, serialNumber, tierType, tiers })
    },
    DeleteSubscription(state, subscriptionId) {
        Vue.delete(state.subscriptions, subscriptionId)
    },
    ChangeDialogState (state, value) {
        state.dialogState = value
    },
    error (state, value) {
        state.user.error = value
    },
    ProfileInfoReceived (state, profileInfo) {
        state.user.profile = profileInfo
    },
    InsertDirectOrganization (state, orgList) {
        orgList = OrganizationUtil.resolveNotificationsPerOrganization(state.notifications, orgList)
        OrganizationUtil.insertDirectOrganizationList(state.organizations, orgList)
    },
    InsertRelatedOrganization (state, orgList) {
        OrganizationUtil.insertRelatedOrganizationList(state.organizations, orgList)
    },
    RecentOrganizationsReceived (state, recentOrgList) {
        if (recentOrgList && recentOrgList.length) {
            state.ui.selectedOrganizationId = recentOrgList[0].id
            state.ui.selectedOrganizationName = recentOrgList[0].name
            state.organizations.recent = recentOrgList
        }
    },
    RecentOrganizationsUpdated (state, recentOrgs) {
        state.ui.selectedOrganizationId = recentOrgs[0].id
        state.ui.selectedOrganizationName = recentOrgs[0].name
        state.organizations.recent = recentOrgs
    },
    SelectedAllOrganizations (state) {
        if (state.organizations.direct.length + state.organizations.related.length <= UI_CONFIG.VIEW_ALL_ORG_THRESHOLD) {
            state.ui.selectedOrganizationId = ''
            state.ui.selectedOrganizationName = ''
        }
    },
    SubscriptionReceived (state, {organizationId, organizationName, subscription}) {
        SubscriptionsUtil.resolveSubscriptionInformation(subscription, organizationName)

        const isMsdSubscription = !!subscription.tiers
        let existingSubscription = state.subscriptions[subscription.id]
        let subscriptionData = subscription

        if (existingSubscription) {
            // Merge updates with existing subscription
            subscriptionData = SubscriptionsUtil.mergeSubscriptionDetails(subscription, existingSubscription)
        }
        addSubscription({ state, organizationId, subscriptionId: subscription.id, isMsdSubscription, subscriptionData })
    },
    UsersAdded (state, {subscriptionId, users, tierType}) {
        let oldUsers = getSubscriptionUsers(state, subscriptionId)
        let uniqueUsers = []
        let unique = {}

        if (oldUsers && oldUsers.length) {
            // for each old user, create a map of all the current users
            for (let i = 0; i < oldUsers.length; i++) {
                unique[getUniqueId(oldUsers[i])] = true
            }
            // for each new user check if user exists, if they do not add them to the uniqueUsers array.
            for (let i = 0; i < users.length; i++) {
                var uniqueId = getUniqueId(users[i])
                if (!unique[uniqueId]) {
                    unique[uniqueId] = true
                    uniqueUsers.push(users[i])
                }
            }
        } else {
            // if no old users, then the new users is the unique list
            uniqueUsers = users
        }

        appendSubscriptionUsers(state, subscriptionId, uniqueUsers, tierType)
    },
    UsersRemoved (state, {subscriptionId, users, tierType}) {
        let userIds = []
        for (let i = 0; i < users.length; i++) {
            userIds.push(getUniqueId(users[i]))
        }

        let userList = getSubscriptionUsers(state, subscriptionId)
            .filter(item => !userIds.includes(getUniqueId(item)))
        setSubscriptionUsers(state, subscriptionId, userList, tierType)
    },
    UserSettingsFetched (state, userSettings) {
        state.userSettings = userSettings
    },
    UserSettingsOnboarding (state, setManageUsersOnboardingStep) {
        state.userSettings.manageUsersOnboardingStep = setManageUsersOnboardingStep
    },
    StatusFetched (state, outageStatus) {
        state.outageStatus.showAlert = outageStatus.showAlert
        state.outageStatus.outageType = outageStatus.outageType
    },
    UpdatePageTitle (state, pageTitle) {
        state.ui.pageTitle = pageTitle
    },
    ClearSubscriptionsForOrganization (state, organizationId) {
        removeAllOrgSubscriptions(state, organizationId)
    },
    ConfirmBrowserResizeWarning (state) {
        state.confirmedBrowserResizeWarning = true
    },
    RemoveAllSubscriptionsUserList (state) {
        state.subscriptionUsers.users = []
    },
    UpdateSubscriptionUsage (state, { subscriptionId, tierType }) {
        updateSubscriptionDetails({ state, subscriptionId, userCount: state.subscriptionUsers.users.length, tierType })
    },
    setLoadingFlag (state, value) {
        state.subscriptionUsers.loadingFlag = value
    },
    setNewEntitlementClaimSelected (state, value) {
        state.activation.newEntitlementClaimSelected = value
    },
    setActivationProcessSteps (state, value) {
        state.activation.processSteps = value
    },
    setSerialNumber (state, value) {
        if (state.activation.claim.serialNumber !== value) {
            state.activation.claim.updated = true
            state.activation.claim.serialNumber = value
        }
    },
    setSeatCount (state, value) {
        if (state.activation.claim.purchasedBoardCount !== value) {
            state.activation.claim.updated = true
            state.activation.claim.purchasedBoardCount = value
        }
    },
    setActivationEnabledValue (state, value) {
        state.activation.enable = value
        // TODO: defer code here if it was never used.
        state.activation.redeem.code = ''
    },
    setActivationOrganizationList (state, value) {
        state.activation.organizationList = value
    },
    setActivationRedeemCodeValidated (state, value) {
        state.activation.redeem.codeValidated = value
    },
    setActivationClaimUpdated (state, value) {
        state.activation.claim.updated = value
    },
    setActivationRedeemCode (state, value) {
        state.activation.redeem.code = value
        state.activation.enable = true // enables the dialog if claim code found.
    },
    setCreatedOrganizationId (state, value) {
        state.activation.userAccount.id = value
    },
    setCurrentComponentValidated (state, value) {
        state.activation.CurrentComponentValidated = value
    },
    setClaimSerialNumber (state, value) {
        state.activation.claim.claimSerialNumber = value
    },
    setResellerName (state, value) {
        state.activation.reseller.name = value
    },
    setResellerCity (state, value) {
        state.activation.reseller.city = value
    },
    setSelectedOrganizationId (state, value) {
        state.activation.selectedOrganizationId = value
        state.activation.userAccount = defaultState().activation.userAccount
    },
    setSelectedOrganizationName (state, value) {
        state.activation.selectedOrganizationName = value
    },
    cleanActivationMemory (state) {
        Object.assign(state.activation, defaultState().activation)
    },
    clearSelectedOrganization (state) {
        state.activation.selectedOrganizationId = ''
        state.activation.selectedOrganizationName = ''
    },
    clearActivationUserAccount (state) {
        Object.assign(state.activation.userAccount, defaultState().activation.userAccount)
    },
    setSubscriptionSelectedMenuOption (state, value) {
        state.subscriptionMenu.selectedMenuOption = value
    },
    setCreatedOrgName (state, value) {
        state.activation.userAccount.name = value
    },
    setCreatedOrgAddress (state, value) {
        state.activation.userAccount.address1 = value
    },
    setCreatedOrgCity (state, value) {
        state.activation.userAccount.city = value
    },
    setCreatedOrgCountry (state, value) {
        state.activation.userAccount.country = value
    },
    setCreatedOrgState (state, value) {
        state.activation.userAccount.state = value
    },
    setCreatedOrgZipPostal (state, value) {
        state.activation.userAccount.zipPostal = value
    },
    setCreatedOrgPhone (state, value) {
        state.activation.userAccount.phone = value
    },
    setCreatedOrgIsIndividual (state, value) {
        state.activation.userAccount.isIndividual = value
    },
    setCreatedOrgIndustry (state, value) {
        state.activation.userAccount.industry = value
    },
    setCreatedOrgRegion (state, value) {
        state.activation.userAccount.region = value
    },
    setCreatedOrgDataLocation (state, value) {
        state.activation.userAccount.dataLocation = value
    },
    setOrgInformation (state, value) {
        state.activation.userAccount.id = value.id
        state.activation.userAccount.name = value.name ? value.name : ''
        state.activation.userAccount.country = value.country ? value.country : ''
        state.activation.userAccount.address1 = value.address ? value.address : ''
        state.activation.userAccount.city = value.city ? value.city : ''
        state.activation.userAccount.state = value.state ? value.state : ''
        state.activation.userAccount.zipPostal = value.zipPostal ? value.zipPostal : ''
        state.activation.userAccount.industry = value.industry ? value.industry : ''
        state.activation.userAccount.phone = value.phone ? value.phone : ''
        state.activation.userAccount.region = value.region ? value.region : ''
        state.activation.userAccount.dataLocation = value.dataLocation ? value.dataLocation : ''
    },
    setOrgWithLoadedSubscriptions (state, organizationId) {
        state.organizations.direct.find(org => org.id === organizationId).subscriptionsLoaded = true
    },
    setProductList (state, value) {
        state.activation.productList = value
    },
    setClientConnectionWindowReference (state, value) {
        state.clientConnection.windowReference = value
    },
    setClientConnectionPullingIntervalReference (state, value) {
        state.clientConnection.pullingIntervalReference = value
    },
    setManagementSelectedMenuOption (state, value) {
        state.managementMenu.selectedMenuOption = value
    },
    setManagementOptionToViewAdminForOrg (state, value) {
        if (state.organizationAdmins.orgId !== value.orgId) {
            state.organizationAdmins.selected = []
            state.organizationAdmins.filtered = []
            state.organizationAdmins.filterByName = ''
            state.organizationAdmins.filterByRole = ''
            state.organizationAdmins.orgId = value.orgId
        }
        state.managementMenu.selectedMenuOption = value.selectedMenuOption
    },
    adminsForOrganizationsReceived (state, admins) {
        admins.forEach(admin => addAdminToTable(state, admin))
    },
    setSelectedAdminsInAdminTable (state, admins) {
        state.organizationAdmins.selected = admins
    },
    filterAdminsForSelectedOrg (state) {
        filterAdminsByOrg(state, state.organizationAdmins.orgId)
    },
    updateAdminsInAdminTable (state, editedAdmins) {
        editedAdmins.forEach(admin => {
            removeOrgInfoForAdmin(state, admin)
            addAdminToTable(state, admin)
        })
        updateFilteredAdminLists(state)
    },
    removeAdminsFromAdminTable (state, removedAdmins) {
        let viewResetRequired = true
        removedAdmins.forEach(admin => {
            if (state.user.profile.email === admin.email) {
                removeOrgInfoFromAllViews(state, admin)
                if (state.organizationAdmins.orgId === admin.orgId) {
                    viewResetRequired = false
                    state.managementMenu.selectedMenuOption = MANAGEMENT.org
                    state.organizationAdmins.filterByName = ''
                    state.organizationAdmins.filterByRole = ''
                    state.organizationAdmins.filtered = []
                    state.organizationAdmins.adminsForOrg = {}
                    state.organizationAdmins.orgId = ''
                }
            } else {
                removeOrgInfoForAdmin(state, admin)
                subtractAdminCountInOrg(state, admin, admin.orgId)
            }
        })
        state.organizationAdmins.selected = []
        if (viewResetRequired) {
            updateFilteredAdminLists(state)
        }
    },
    filterAdminsByName (state, name) {
        applyNameAndRoleFilter(state, name, state.organizationAdmins.filterByRole)
        state.organizationAdmins.filterByName = name
    },
    filterAdminsByRole (state, role) {
        applyNameAndRoleFilter(state, state.organizationAdmins.filterByName, role)
        state.organizationAdmins.filterByRole = role
    },
    filterOrganizationsByName (state, name) {
        state.organizations.filterByName = name
        state.organizations.filtered = state.organizations.direct.filter(org => org.name.toLowerCase().includes(name.toLowerCase()))
    },
    clearNotifications (state, declinedInvites) {
        state.notifications = defaultState().notifications
        if (declinedInvites.length) {
            declinedInvites.forEach(orgId => {
                removeOrgFromOrganizationInfo(state, orgId)
            })
        }
    },
    updateActivationAdminState (state, adminState) {
        state.activation.admins.previouslyLoaded = true
        state.activation.admins.adminList = adminState.roleMapping
        state.activation.admins.errorCount = adminState.errorCount
        state.activation.admins.showAllAdminsRemovedMessage = adminState.showAllAdminsRemovedMessage
    },
    updateDirectOrgs (state, directOrgs) {
        directOrgs.forEach(org => {
            updateDirectOrg(state, org)
        })
    },
    updateDirectOrgsName (state, directOrgs) {
        directOrgs.forEach(org => {
            updateDirectOrgName(state, org)
        })
    },
    cacheDistrictLibrary(state, districtLibraryMetadata) {
        const { districtId } = districtLibraryMetadata
        state.districtLibraries[districtId] = districtLibraryMetadata
    },
    updateDistrictLibrary(state, { districtId, allowUpload } = {}) {
        state.districtLibraries[districtId] = {
            ...state.districtLibraries[districtId],
            ...(allowUpload && { allowUpload })
        }
    },
    updateDistrictLibraryUserRolesInSubscription(state, { users }) {
        updateSubscriptionUsers(state, users)
    },
    setLicenseKeyRequested (state, subscriptionId) {
        state.subscriptions[subscriptionId].licenseKeyRequested = true
    },
    setIsModalOpen(state, value) {
        state.isModalOpen = value
    },
    setHideOnboarding(state, value) {
        state.onboarding.hideOnboarding = value
    },
    setIsOnboardingActiveFlow(state, value) {
        state.onboarding.isActiveFlow = value
    }
}
