import ErrorHandler from './error-handler'

export default {
    hideBody () {
        document.getElementsByTagName('body')[0].style.display = 'none'
    },

    showBody () {
        document.getElementsByTagName('body')[0].style.display = 'block'
    },

    handleApiErrorBeforeSignIn (error) {
        this.showBody()
        ErrorHandler.handleError(error, ErrorHandler.signinError)
    }
}
