let VALID_EMAIL_PATTERN = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
let VALID_DOMAIN_PATTERN = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
let VALID_PHONE_NUMBER_AS_UPN_PATTERN = /^([+]?[0-9]{1,2})?([(0-9)]{3,5}){1}([-]?[0-9]{3}){1}([-]?[0-9]{4}){1}$/
let VALID_KH_STUDENT_ACCOUNT_ID_PATTERN = /^[sS]{1}[0-9]{7}$/
let VALID_KH_TEACHER_ACCOUNT_ID_PATTERN = /^[a-z][\w-]{3,11}$/
let VALID_VIDIS_ACCOUNT_ID_PATTERN = /^[a-z0-9-]{36}$/

export default {
    isValid: (email) => {
        return VALID_EMAIL_PATTERN.test(email)
    },
    isValidUpn: (upn) => {
        return VALID_EMAIL_PATTERN.test(upn) ||
            VALID_PHONE_NUMBER_AS_UPN_PATTERN.test(upn) ||
            VALID_KH_STUDENT_ACCOUNT_ID_PATTERN.test(upn) ||
            VALID_KH_TEACHER_ACCOUNT_ID_PATTERN.test(upn) ||
            VALID_VIDIS_ACCOUNT_ID_PATTERN.test(upn)
    },
    isValidDomain: (domain) => {
        return VALID_DOMAIN_PATTERN.test(domain)
    }
}
