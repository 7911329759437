<template>
    <div class="white--text summary-component">
        <span class="font-medium">{{pageTitle}}</span>
    </div>
</template>

<script>
    export default {
        computed: {
            pageTitle () {
                return this.$store.getters.pageTitle
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-component {
        text-align: center;
        padding: 50px;
        width: 100%;
    }
</style>
