<template>
    <v-expansion-panels focusable>
        <v-expansion-panel>
            <div v-for="tier in tiers" :key="tier.id" class="expanded-row">
                <v-layout class="tier-row">
                    <v-flex class="tier-name font--bold">
                        <span>
                            {{ tier.name }}
                        </span>
                    </v-flex>
                    <v-flex class="usage">
                        <div v-if="tier.assignedUsersCount || tier.quantity">
                            <div>
                                <user-count
                                    :assigned-users-count="tier.assignedUsersCount"
                                    :total-seats="tier.quantity">
                                </user-count>
                            </div>
                        </div>
                        <div v-else>––</div>
                    </v-flex>
                    <v-flex class="manage-users">
                        <text-button
                            v-if="showManageUsers(tier)"
                            :text="getManageUsersText(tier)"
                            @button-action="manageUsers(tier)">
                        </text-button>
                    </v-flex>
                    <v-flex class="renewal-date" :class="tier.status">
                        <expiry-status
                            v-if="isExceptionCase"
                            :item-status="tier.status"
                            :next-renewal-date-formatted="tier.usageEndDateFormatted">
                        </expiry-status>
                    </v-flex>
                </v-layout>
            </div>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import UserCount from '../components/UserCount.vue'
    import TextButton from '../components/TextButton.vue'
    import ExpiryStatus from '../components/ExpiryStatus.vue'
    import { SubscriptionStates } from '../enums'

    export default {
        props: {
            tiers: {
                type: Object,
                default: () => {}
            },
            isExceptionCase: {
                type: Boolean,
                default: false
            }
        },
        components: {
            TextButton,
            UserCount,
            ExpiryStatus
        },
        methods: {
            showManageUsers(tier) {
                return tier.assignedUsersCount > 0 || tier.status !== SubscriptionStates.EXPIRED
            },
            getManageUsersText(tier) {
                if (tier.assignedUsersCount !== 0) {
                    return this.$t('subscriptions.manageUsers')
                }
                return this.$t('subscriptions.addUsers')
            },
            manageUsers(item) {
                this.$emit('manage-users', item)
            }
        }
    }
</script>
<style lang="scss" scoped>
.tier-name {
    margin-left: 40px;
    flex: 3;
}

.usage {
    flex: 1;
}

.manage-users {
    flex: 4;
}

.renewal-date {
    flex: 2;
    margin-right: 1.5vw;
}
</style>
