<template>
    <div class="activation-content-container">
        <v-layout align-center justify-start column fill-height>
            <v-flex class="mb-3 normal-text">
                <div class="text-h6">{{ $t('activation.finalInformation.thank') }}</div>
                <div>{{ $t('activation.finalInformation.description') }}</div>
                <div><b>{{ orgNameAssignedTo }}</b></div>
                <div>{{ $t('activation.finalInformation.licenseKeyHelp') }}</div>
                <div v-html="licenseKeyText"/>
                <div>{{ $t('activation.adminInformation.adminManagement') }}</div>
                <div>
                    <div><b>{{ $t('activation.finalInformation.note') }}</b></div>
                    <div>{{ $t('activation.finalInformation.noteBody') }}</div>
                    <div>{{ $t('activation.finalInformation.noteAction') }}
                        <a :href="$LINKS.SUPPORT" target="_blank">{{ $t('buttons.contactSupport') }}</a>
                    </div>
                </div>
            </v-flex>
            <v-flex class="button-container">
                <v-btn class="primary white--text claim-to-manage-button large btn--block" @click="close">{{ buttonText }}</v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import strUtils from 'smarttech-identity-string-utils'
    import { MENU_STATE } from '../../../enums/subscription-menu-state.js'

    export default {
        name: 'ClaimInformation',
        methods: {
            ...mapMutations([
                'setSubscriptionSelectedMenuOption',
                'cleanActivationMemory'
            ]),
            close () {
                this.cleanActivationMemory()
                this.setSubscriptionSelectedMenuOption(MENU_STATE.SUBSCRIPTION)
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            orgNameAssignedTo () {
                if (strUtils.isBlank(this.activation.selectedOrganizationName)) {
                    if (strUtils.isBlank(this.activation.userAccount.name)) {
                        return this.$t('activation.assignEntitlement.inputPlaceHolder')
                    }
                    return this.activation.userAccount.name
                }
                return this.activation.selectedOrganizationName
            },
            licenseKeyText () {
                return strUtils.format(this.$t('activation.finalInformation.licenseKeyInfo'), this.$t('subscriptions.menu.manage').bold())
            },
            buttonText () {
                return this.activation.redeem.code.length ? this.$t('buttons.continueToManage') : this.$t('buttons.returnToManage')
            }
        },
        beforeDestroy () {
            this.close()
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        font-size: 28px;
        line-height: 1.07;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
        margin-bottom: 50px;
    }

    .thank-you {
        font-size: 18px;
    }

    .normal-text > div {
        font-size: 14px;
        line-height: 1.29;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
        padding: 10px;
    }

    .activation-content-container {
        margin: 0;
    }

    .button-container {
        flex: 0 0 auto;
        padding: 0;
    }

</style>
