import environ from 'smarttech-identity-environment'
import { createApiClient } from './api-client.js'

import {ADMIN_PORTAL_SWITCH_CLIENT} from '../enums/feature-switch-constants'
const SWITCH = 'switch'
const switchList = {}
let initialized = false

/**
 * @param {String} urlSearchString - parameter String
 * @returns {Array.<String>} - Array of feature flags, if empty or on prod then will return null
 */
export function parseFeatureString (urlSearchString) {
    if (environ.getEnvironmentFromHost() !== environ.PROD) {
        const urlParams = new URLSearchParams(urlSearchString)
        const featureparams = urlParams.getAll('feature').toString().split(',')
        const filteredFeature = featureparams.filter(function (part) {
            return part !== '' && part !== '~off'
        })
        return filteredFeature.length === 0 ? null : filteredFeature
    }
    return null
};
/**
 *
 * @param {String} urlSearchString -parameter string
 * @param {Array.<String>} switchListArg the list of features we want to override
 */
export function overrideFeatureSwitch (urlSearchString, switchListArg) {
    const queries = parseFeatureString(urlSearchString)
    if (queries === null) {
        return
    }
    for (var i = 0; i < queries.length; i++) {
        let fullSwitchName
        let switchValue = true
        let switchName = queries[i]
        if (switchName.includes('~off')) {
            switchValue = false
            switchName = queries[i].replace(/~off/i, '')
        }
        if (switchName.includes('/')) {
            let tempSwitchName = switchName.split('/')[1]
            if (tempSwitchName.length === 0) {
                continue
            }
            fullSwitchName = `${SWITCH}:${switchName}`
        } else {
            fullSwitchName = `${SWITCH}:${ADMIN_PORTAL_SWITCH_CLIENT}/${switchName}`
        }
        switchListArg[fullSwitchName] = switchValue
    }
    console.log('Feature flag override completed')
    console.log(switchListArg)
};
export function loadFeatureSwitches () {
    if (!initialized) {
        const apiClient = createApiClient()
        return apiClient.readFeatureFlagsFromServer().then(result => {
            const features = result.json
            for (var i = 0; i < features.length; i++) {
                switchList[features[i].name] = features[i].enabled
            }
            overrideFeatureSwitch(window.location.search, switchList)
            initialized = true
        })
    } else {
        throw new Error('feature list has already been loaded')
    }
}

export function isEnabled (switchId, switchListArg = switchList) {
    return initialized && switchListArg[switchId]
}
