<template>
    <div class="subscriptions-hybrid-route scrollable-activation">
        <v-layout pl-6 pt-6 pr-6 pb-6 v-show="!showLoader" column fill-height fluid>
            <subscriptions-header></subscriptions-header>
            <v-flex v-if="showSubscriptionsSection" class="tables-container">
                <migrated-subscription-table v-if="showMigratedSubscriptionsTable"
                    :full-height="!showNonMigratedSubscriptionsTable"
                    :subscriptions="migratedSubscriptions"
                    :loading="loaders.fetchSubs"
                    :has-organizations="hasOrganizations"
                    :expanded-subscriptions="expandedSubscriptions"
                    @expand-or-collapse="expandOrCollapse">
                </migrated-subscription-table>
                <non-migrated-subscription-table v-if="showNonMigratedSubscriptionsTable"
                    :full-height="!showMigratedSubscriptionsTable"
                    :subscriptions="nonMigratedSubscriptions"
                    :loading="loaders.fetchSubs"
                    :expanded-subscriptions="expandedSubscriptions"
                    @expand-or-collapse="expandOrCollapse">
                </non-migrated-subscription-table>
            </v-flex>
            <v-flex v-else class="activation-container">
                <activation-code v-if="isClaimCodeFlow"></activation-code>
                <activation v-else></activation>
            </v-flex>
        </v-layout>
        <v-container v-if="showLoader" fluid>
            <init-skeleton-loader></init-skeleton-loader>
        </v-container>
    </div>
</template>

<script>
    import SubscriptionsHeader from '../components/SubscriptionsHeader.vue'
    import MigratedSubscriptionTable from '../components/MigratedSubscriptionTable.vue'
    import NonMigratedSubscriptionTable from '../components/NonMigratedSubscriptionTable.vue'
    import InitSkeletonLoader from '../components/InitSkeletonLoader.vue'
    import { AppStates, SubscriptionStates } from '../enums/index'
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import Activation from '../components/dialogs/Activation/Activation.vue'
    import ActivationCode from '../components/dialogs/Activation/ActivationCode.vue'
    import { MENU_STATE } from '../enums/subscription-menu-state.js'
    import ErrorHandler from '../utils/error-handler'
    import LicenseRequestService from '../services/license-request-service'
    import SubscriptionUpdater from '../utils/subscription-updater'
    import Vue from 'vue'
    import { DEBUG_NON_STOCKING_CLAIM_FLOW, SHOW_EXPIRY_MODAL, SHOW_UNPOPULATED_SUBSCRIPTION_MODAL } from '../enums/feature-switch-constants'
    import { isEnabled } from '../services/feature-switch'
    import ExpiryModal from '../components/ExpiryModal.vue'
    import UnpopulatedSubscriptionsModal from '../components/UnpopulatedSubscriptionsModal.vue'
    import { ADMIN, SUPERVISOR, TECH_INSTRUCTOR } from '../enums/admin-management-constants'
    import { bus } from '../event-bus'
    import { UI_CONFIG } from '../config.js'

    export default {
        name: 'SubscriptionsNew',
        components: {
            SubscriptionsHeader,
            MigratedSubscriptionTable,
            NonMigratedSubscriptionTable,
            InitSkeletonLoader,
            Activation,
            ActivationCode
        },
        data () {
            return {
                loaders: {
                    fetchSubs: false,
                    firstLoad: true
                },
                expandedSubscriptions: {},
                queuedUnpopulatedSubscriptionsModal: false
            }
        },
        computed: {
            ...mapState([
                'subscriptionMenu',
                'activation',
                'organizations'
            ]),
            ...mapGetters([
                'appState',
                'getAllSubscriptions',
                'selectedOrganizationSubscriptions',
                'selectedOrganizationId',
                'getSparkSubscriptions',
                'orgSparkSubscription',
                'getOrganizationById',
                'getSubscriptionExpiryConfirmedDate',
                'getOrganizations',
                'getIsModalOpen'
            ]),
            hasOrganizations () {
                return this.organizations.direct.length + this.organizations.related.length > 0
            },
            appIsInitialized () {
                return this.appState === AppStates.INITIALIZED
            },
            showLoader () {
                return !this.appIsInitialized || (this.loaders.fetchSubs && this.loaders.firstLoad)
            },
            showSubscriptionsSection() {
                return this.subscriptionMenu.selectedMenuOption === MENU_STATE.SUBSCRIPTION
            },
            isClaimCodeFlow () {
                if (isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
                    return true
                }
                return this.activation.redeem.code.length > 0
            },
            selectedOrgId () {
                return this.selectedOrganizationId
            },
            isOrgSelected () {
                return this.selectedOrganizationId !== null
            },
            isAllOrgsSelected () {
                return this.isOrgSelected && !this.selectedOrgId
            },
            migratedSubscriptions () {
                if (this.isAllOrgsSelected) {
                    return this.getAllSubscriptions.filter(subscription => subscription.isMsdSubscription)
                }
                return this.selectedOrganizationSubscriptions.filter(subscription => subscription.isMsdSubscription)
            },
            nonMigratedSubscriptions () {
                if (this.isAllOrgsSelected) {
                    return this.getAllSubscriptions.filter(subscription => !subscription.isMsdSubscription)
                }
                return this.selectedOrganizationSubscriptions.filter(subscription => !subscription.isMsdSubscription)
            },
            showNonMigratedSubscriptionsTable () {
                return this.nonMigratedSubscriptions.length > 0
            },
            showMigratedSubscriptionsTable () {
                // Show migrated subscriptions table also when user doesn't have any organization to show (empty stage)
                return this.migratedSubscriptions.length > 0 || !this.showNonMigratedSubscriptionsTable
            },
            getSparkSubscriptionsWithNoUsers() {
                return this.getOrganizations.filter(organization => this.shouldShowUnpopulatedModal(organization))
            }
        },
        watch: {
            'selectedOrgId' (to, from) {
                if (this.selectedOrganizationId === null && this.appIsInitialized) {
                    this.loaders.firstLoad = false
                }
                this.fetchSubscriptions()
            },
            'loaders.fetchSubs' (to) {
                // Expiry modal has proprity over unpopulated modal. If expiry modal is shown, unpopulated modal will not be shown.
                // If the user reloads the page after dismissing the expiry modal, the unpopulated modal will be shown.
                let expiryModalShown = false
                if (!to && isEnabled(SHOW_EXPIRY_MODAL) && this.isAllOrgsSelected) {
                    const {expiredSubscriptions, warningSubscriptions} = this.getExpiryModalSubscriptions()
                    if (expiredSubscriptions.length > 0 || warningSubscriptions.length > 0) {
                        expiryModalShown = true
                        this.showExpiryModal(expiredSubscriptions, warningSubscriptions)
                    }
                }
                if (!to && !expiryModalShown && (this.getSparkSubscriptionsWithNoUsers.length !== 0 && this.isAllOrgsSelected)) {
                    this.showUnpopulatedSubscriptionModal(this.getSparkSubscriptionsWithNoUsers)
                }
            },
            'showSubscriptionsSection' (to, from) {
                if (!from && to) {
                    if ((this.getSparkSubscriptionsWithNoUsers.length !== 0 && this.isAllOrgsSelected)) {
                        this.showUnpopulatedSubscriptionModal(this.getSparkSubscriptionsWithNoUsers)
                    }
                }
            },
            // Adding this watcher we can make sure that if a modal for unpopulated subscription was not displayed because there was
            // another visible modal and it was therefore queued, we can then display it when the modal is closed.
            'getIsModalOpen' (to, from) {
                if (from && !to) {
                    if ((this.isAllOrgsSelected && this.queuedUnpopulatedSubscriptionsModal)) {
                        // To avoid a flashing modal shown when the previous modal is closed and triggers a page reload,
                        // we add a one second timeout. It's not guaranteed the modal will not flash but less likely.
                        setTimeout(() => {
                            this.showUnpopulatedSubscriptionModal(this.getSparkSubscriptionsWithNoUsers)
                        }, 1000)
                    }
                }
            }
        },
        mounted () {
            this.fetchSubscriptions()
        },
        beforeDestroy () {
            LicenseRequestService._cancelRequests()
        },
        methods: {
            ...mapMutations({
                clearSubscriptionsForOrganization: 'ClearSubscriptionsForOrganization',
                setOrgWithLoadedSubscriptions: 'setOrgWithLoadedSubscriptions'
            }),
            ...mapActions({
                waitOnApplicationInitialization: 'WaitOnApplicationInitialization',
                fetchSubscriptionsForOrganizationHybrid: 'FetchSubscriptionsForOrganizationHybrid'
            }),
            isAdminOrSupervisor(organizationId) {
                const role = this.getOrganizations.find(org => org.id === organizationId).role.name
                return role === ADMIN || role === SUPERVISOR
            },
            isAdminOrTechInstructor(organizationId) {
                const role = this.getOrganizations.find(org => org.id === organizationId).role.name
                return role === ADMIN || role === TECH_INSTRUCTOR
            },
            showExpiryModal(expiredSubscriptions, warningSubscriptions) {
                const EXPIRY_MODAL_WIDTH = 430
                bus.$emit('openPersistedModal', {
                    propComponent: ExpiryModal,
                    componentProps: {
                        expiredSubscriptions,
                        warningSubscriptions
                    },
                    width: EXPIRY_MODAL_WIDTH
                })
            },
            showUnpopulatedSubscriptionModal(organizations) {
                if (this.getIsModalOpen) {
                    this.queuedUnpopulatedSubscriptionsModal = true
                } else {
                    this.queuedUnpopulatedSubscriptionsModal = false
                    bus.$emit('openPersistedModal', {
                        propComponent: UnpopulatedSubscriptionsModal,
                        width: 500,
                        componentProps: {
                            organizations
                        }
                    })
                }
            },
            expandOrCollapse(subscriptionId, isExpanded) {
                Vue.set(this.expandedSubscriptions, subscriptionId, isExpanded)
            },
            onSubscriptionUpdateReceived (updateInfo, isLoadingCompleted) {
                // Use bus to send response event to detach call from request's Promise scope
                if (updateInfo.error) {
                    LicenseRequestService._cancelRequests()
                    ErrorHandler.handleError(updateInfo.data)
                } else {
                    SubscriptionUpdater.updateSubscription(updateInfo, this.$store)
                    if (isLoadingCompleted) {
                        // Edge case where the user selects an organization before all subscriptions have loaded.
                        // In this case we show the modal for that specific organization and then when the user selects "All organizations" tab,
                        // We display the modal for the remaining organizations.
                        this.setOrgWithLoadedSubscriptions(updateInfo.orgId)
                        if (!this.isAllOrgsSelected) {
                            const org = this.getOrganizationById(this.selectedOrgId)
                            if (this.loaders.fetchSubs && this.isOrgSelected && this.shouldShowUnpopulatedModal(org)) {
                                this.showUnpopulatedSubscriptionModal([org])
                            }
                        }
                    }

                    let doneLoading = isLoadingCompleted && (this.selectedOrgId === updateInfo.orgId || this.isAllOrgsSelected)
                    this.updateSubscriptionLoader(updateInfo.orgId, !doneLoading)

                    this.loaders.firstLoad = false
                }
            },
            hasSeenUnpopulatedModalForOrg(elementId) {
                const seenUnpopulatedOrgs = localStorage.getItem('seenUnpopulatedOrgs')
                if (seenUnpopulatedOrgs) {
                    const parsedSeenUnpopulatedOrgs = JSON.parse(seenUnpopulatedOrgs)
                    return parsedSeenUnpopulatedOrgs.includes(elementId)
                }
                return false
            },
            updateSubscriptionLoader (orgId, loading) {
                if (orgId === this.selectedOrgId || this.isAllOrgsSelected) {
                    this.loaders.fetchSubs = loading
                }
            },
            onDataExpired (orgId) {
                this.clearSubscriptionsForOrganization(orgId)
            },
            fetchSubscriptions () {
                if (this.isOrgSelected) {
                    let organizationId = this.selectedOrgId
                    this.updateSubscriptionLoader(organizationId, true)
                    this.waitOnApplicationInitialization().then(() => {
                        this.fetchSubscriptionsForOrganizationHybrid({organizationId, respListener: this.onSubscriptionUpdateReceived, expireListener: this.onDataExpired})
                            .then((allOrgsLoaded) => {
                                if (allOrgsLoaded) {
                                    this.updateSubscriptionLoader(organizationId, false)
                                }
                            })
                    }).catch(err => {
                        ErrorHandler.handleError(err)
                    })
                }
            },
            getExpiryModalSubscriptions() {
                const expiredSubscriptions = []
                const warningSubscriptions = []
                const expiryConfirmedDateObj = this.getSubscriptionExpiryConfirmedDate ? new Date(this.getSubscriptionExpiryConfirmedDate) : ''
                this.getSparkSubscriptions.filter(subscription => this.isAdminOrSupervisor(subscription.organizationId))
                    .forEach(subscription => {
                        const status = subscription.status
                        const expiryDate = subscription.nextRenewalDate
                        if (status === SubscriptionStates.EXPIRED) {
                            if (!this.getSubscriptionExpiryConfirmedDate || expiryConfirmedDateObj < expiryDate) {
                                expiredSubscriptions.push(subscription)
                            }
                        } else if (status === SubscriptionStates.WARNING) {
                            const expiryDate90daysBefore = new Date(expiryDate)
                            expiryDate90daysBefore.setDate(expiryDate.getDate() - UI_CONFIG.EXPIRATION_WARNING_THRESHOLD_IN_DAYS)
                            if (!this.getSubscriptionExpiryConfirmedDate || expiryConfirmedDateObj < expiryDate90daysBefore) {
                                warningSubscriptions.push(subscription)
                            }
                        }
                    })
                return { expiredSubscriptions, warningSubscriptions }
            },
            shouldShowUnpopulatedModal(org) {
                return isEnabled(SHOW_UNPOPULATED_SUBSCRIPTION_MODAL) &&
                    this.orgSparkSubscription(org.id) &&
                    this.orgSparkSubscription(org.id).tiers.SPARK.assignedUserCount === 0 &&
                    this.orgSparkSubscription(org.id).tiers.SPARK.status !== SubscriptionStates.EXPIRED &&
                    !this.hasSeenUnpopulatedModalForOrg(org.id) &&
                    this.isAdminOrTechInstructor(org.id) &&
                    this.showSubscriptionsSection
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tables-container, .activation-container {
        overflow: hidden;
        flex: 1;
    }
</style>
