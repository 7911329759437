<template>
    <v-layout justify-center>
        <v-dialog v-model="isOpen" width=450px content-class="license-detail-dialog">
            <v-card class="compact">
                <v-card-title>
                    <v-layout class="licenseDetailsHeading">
                        <v-flex xs11>
                            {{ $t('licenseDetails.title') }}
                        </v-flex>
                        <v-flex xs1>
                            <v-btn medium icon text color="#cccccc" @click.native="$emit('close-dialog')"><i class="material-icons md-light md-inactive">clear</i></v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-title>
                <v-card-text class="licenseDetailForm">
                    <v-layout class="formDialog striped" v-for="detail in detailsFormatted" :key="detail.key">
                        <v-flex xs6>
                            <span>{{detail.key}}</span>
                        </v-flex>
                        <v-flex xs6>
                            <span v-if="detail.value">{{detail.value}}</span>
                            <span v-else>--</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    export default {
        props: ['licenseDetails'],
        data () {
            return {
                isOpen: true
            }
        },
        computed: {
            detailsFormatted () {
                return this.buildLicenseDetailObject(this.licenseDetails)
            }
        },
        watch: {
            isOpen (val) {
                this.$emit('close-dialog')
            }
        },
        methods: {
            buildLicenseDetailObject (licenseDetailsToDisplay) {
                return [{key: this.$t('licenseDetails.productKey'), value: licenseDetailsToDisplay.serialNumber},
                    {key: this.$t('licenseDetails.startDate'), value: licenseDetailsToDisplay.startDateFormatted},
                    {key: this.$t('licenseDetails.endDate'), value: licenseDetailsToDisplay.usageEndDateFormatted},
                    {key: this.$t('licenseDetails.orderNum'), value: licenseDetailsToDisplay.orderNumber},
                    {key: this.$t('licenseDetails.channelPO'), value: licenseDetailsToDisplay.channelPO},
                    {key: this.$t('licenseDetails.customerPO'), value: licenseDetailsToDisplay.customerPO},
                    {key: this.$t('licenseDetails.reseller'), value: licenseDetailsToDisplay.reseller},
                    {key: this.$t('licenseDetails.distributor'), value: licenseDetailsToDisplay.distributor},
                    {key: this.$t('licenseDetails.organization'), value: licenseDetailsToDisplay.accountName},
                    {key: this.$t('licenseDetails.endCustomer'), value: licenseDetailsToDisplay.endCustomerDetails}]
            }
        }
    }
</script>

<style lang="scss" scoped>
.licenseDetailsHeading {
    display: flex;
    align-items: center;
    font-size: 18px !important;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;

}

.v-card__text.licenseDetailForm {
    padding: 0px !important;
}
</style>
