<template>
    <div class="caution-icon">
        <div class="icon-wrapper align-center d-flex">
            <i class="material-icons">warning</i>
        </div>
    </div>
</template>
<script>
    export default {
    }
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

.caution-icon {
    margin-right: 10px;
    i {
        color: $pallete-yellow;
    }
}

</style>
