import {ACCESSIBILITY_LEVEL} from '../enums/admin-management-constants'

export let accessibilityLevelSort = function (a, b) {
    if (ACCESSIBILITY_LEVEL[a] > ACCESSIBILITY_LEVEL[b]) {
        return -1
    }
    if (ACCESSIBILITY_LEVEL[a] < ACCESSIBILITY_LEVEL[b]) {
        return 1
    }
    return 0
}
