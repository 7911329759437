<template>
    <v-layout column>
        <v-card-title class="text-h5">
            {{ $t('addUsersModal.csvInstructionHeader') }}
        </v-card-title>

        <v-card-text>
            {{ $t('addUsersModal.csvInstructions') }}
        </v-card-text>

        <v-card-text class="my-3">
            <v-layout justify-center>
                <v-flex xs12>
                    <csv-instruction-image></csv-instruction-image>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-text>
            <v-checkbox
                class="blue--text darken-1"
                :label="$t('addUsersModal.csvInstructionsDoNotShowLabel')"
                v-model="doNotShowInstructionsAgain"
                :disabled="loading"
                hide-details
            ></v-checkbox>
        </v-card-text>

        <v-card-text>
            <v-layout>
                <v-flex xs6>
                    <input ref="csvFileInput"
                           type="file"
                           style="display:none"
                           @change="onCsvUpload"
                           accept=".csv">
                    <v-btn id="cancelAddUsers"
                           block outlined
                           :disabled="loading"
                           @click.native="cancel()">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                </v-flex>
                <v-flex xs6>
                    <v-btn id="addUsersByCSV"
                           block primary light
                           class="white--text"
                           :loading="loading"
                           :disabled="loading"
                           @click.native="triggerCsvFileInput()">
                        {{ $t('buttons.import') }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-layout>
</template>

<script>
    import CsvInstructionImage from './CsvInstructionImage.vue'
    export default {
        name: 'CsvInstructions',
        props: ['loading'],
        components: {
            CsvInstructionImage
        },
        data () {
            return {
                doNotShowInstructionsAgain: true
            }
        },
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            triggerCsvFileInput () {
                this.$refs.csvFileInput.click()
            },
            onCsvUpload () {
                let el = this.$refs.csvFileInput
                this.$emit('csv-upload', {
                    files: el.files,
                    showCsvInstructions: !this.doNotShowInstructionsAgain
                })
                el.value = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
