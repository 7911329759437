<template>
    <v-layout column>
        <v-card-title class="text-h6 font-weight-bold text--primary">
            {{ $t('addUsersModal.addUsers') }}
        </v-card-title>

        <v-card-text v-show="!isConnected">
            {{ $t('addUsersModal.addUsersMethod') }}
        </v-card-text>

        <v-layout justify-center>
            <v-flex xs11 sm10 md9 lg7>
                <v-btn v-show="!isConnected" id="addUsersByText"
                       block primary light
                       class="modal-button white--text"
                       :disabled="loading || !displayAllOptions"
                       @click.native="addUsersByText">
                    {{ $t('addUsersModal.addUsersByText') }}
                </v-btn>
                <input ref="csvFileInput"
                       type="file"
                       style="display:none"
                       @change="onCsvUpload"
                       accept=".csv">
                <v-btn v-show="!isConnected" id="addUsersByCSV"
                       block primary light
                       class="modal-button white--text"
                       :loading="loading"
                       :disabled="loading || !displayAllOptions"
                       @click.native="addUsersByCsv">
                    {{ $t('addUsersModal.addUsersByCsv') }}
                </v-btn>
                <v-btn v-show="!isConnected && !isDisabled && !isEuRegion" id="importTeachers"
                       block primary light
                       class="modal-button white--text"
                       :disabled="loading || !displayAllOptions"
                       @click.native="importTeachers">
                    {{ "ClassLink (One Roster)" }}
                </v-btn>
                <v-btn v-show="!isConnected && !isDisabled" id="importGoogleClassroom"
                       block primary light
                       class="modal-button white--text"
                       :disabled="loading || !displayAllOptions"
                       @click.native="importGoogleClassroom">
                    {{ $t('addUsersModal.addUsersGoogle') }}
                </v-btn>

                <v-btn v-show="!isConnected && !isDisabled" id="importMicrosoft"
                       block primary light
                       class="modal-button white--text"
                       :disabled="loading || !displayAllOptions"
                       @click.native="importMicrosoft">
                    {{ $t('addUsersModal.addUsersMicrosoft')}}
                </v-btn>

                <v-card-text v-show="isConnected">{{ $t('users.rosterConnected', { source: sourceName}) }}</v-card-text>
                <v-btn id="cancelAddUsers"
                       block outlined
                       :disabled="loading"
                       @click.native="cancel">
                    {{ $t('buttons.cancel') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </v-layout>
</template>

<script>
    import {AddUserComponents, Sources} from '../../enums/'
    import StrUtils from 'smarttech-identity-string-utils'
    import {AUTO_PROVISION_DISABLED} from '../../enums/feature-switch-constants.js'
    import {isEnabled} from '../../services/feature-switch.js'
    import environ, { EU1 } from 'smarttech-identity-environment'

    export default {
        props: ['organizationId', 'subscriptionId', 'loading', 'activeProvisions'],
        computed: {
            source () {
                return this.$store.getters.getSubscriptionSource(this.subscriptionId)
            },
            sourceName () {
                let source = Object.keys(Sources.SIS_SOURCES).find(key => Sources.SIS_SOURCES[key] === this.source)
                if (source !== undefined && source.includes('_')) {
                    source = source.replace('_', ' ')
                }
                return source
            },
            sourceOneRoster () {
                return this.source === Sources.SIS_SOURCES.ClassLink
            },
            sourceGoogleClassroom () {
                return this.source === Sources.SIS_SOURCES.Google
            },
            isConnected () {
                return (this.source !== Sources.SIS_SOURCES.Undecided) &&
                    (this.source !== Sources.SIS_SOURCES.Manual) &&
                    (this.source !== null) &&
                    (this.source !== undefined) &&
                    StrUtils.isNotBlank(this.source)
            },
            isDisabled () {
                let result = isEnabled(AUTO_PROVISION_DISABLED)
                return result
            },
            isEuRegion () {
                return environ.getRegion() === EU1
            },
            displayAllOptions () {
                return this.source === null || this.source === undefined ||
                    this.source === Sources.SIS_SOURCES.Manual ||
                    this.source === Sources.SIS_SOURCES.Undecided ||
                    StrUtils.isBlank(this.source)
            }
        },
        methods: {
            cancel () {
                this.$emit('cancel-dialog')
            },
            addUsersByText () {
                this.$emit('change-component', AddUserComponents.TEXT_ADD_USERS)
            },
            addUsersByCsv () {
                let showCsvInstructions = this.$store.state.userSettings.showCsvInstructions
                if (showCsvInstructions) {
                    this.$emit('change-component', AddUserComponents.CSV_INSTRUCTIONS)
                } else {
                    this.triggerCsvFileInput()
                }
            },
            triggerCsvFileInput () {
                this.$refs.csvFileInput.click()
            },
            importTeachers () {
                this.checkForActiveManualProvisions(AddUserComponents.ROSTER_ADD_USERS)
            },
            onCsvUpload () {
                let el = this.$refs.csvFileInput
                this.$emit('csv-upload', {files: el.files})
                el.value = ''
            },
            importGoogleClassroom () {
                this.checkForActiveManualProvisions(AddUserComponents.GOOGLE_CLASSROOM_ADD_USERS)
            },
            importMicrosoft () {
                this.checkForActiveManualProvisions(AddUserComponents.MICROSOFT_ADD_USERS)
            },
            checkForActiveManualProvisions (autoProvisionComponent) {
                if (this.activeProvisions !== 0) {
                    this.$emit('show-provision-warning', {nextComponent: autoProvisionComponent})
                } else {
                    this.$emit('change-component', autoProvisionComponent)
                }
            }
        }
    }
</script>
