<template>
    <div class="migrated-entry expandable-row">
        <tr class="table-row no-hover row-border">
            <td class="product">
                <text-button
                    :text="subscription.name"
                    @button-action="onSubscriptionDetailsOpened">
                </text-button>
            </td>
            <td class="subscriptionId" tabindex="0">{{ subscription.msdSubscriptionNumber }}</td>
            <td class="org-name" tabindex="0">{{ subscription.accountName }}</td>
            <td class="status" :class="subscription.status" tabindex="0">
                <expiry-status
                    v-if="!isExceptionCase"
                    :item-status="subscription.status"
                    :next-renewal-date-formatted="subscription.nextRenewalDateFormatted">
                </expiry-status>
                <div v-else>--</div>
            </td>
            <td class="expand-button-wrapper">
                <button class="expand-button" @click="expandOrCollapse">
                    <i v-if="isExpanded" class="material-icons icon">remove</i>
                    <i v-else class="material-icons icon">add</i>
                </button>
            </td>
        </tr>
        <tr class="expand row-border" v-if="isExpanded">
            <td colspan="100%">
                <tiers :tiers="subscription.tiers"
                       :is-exception-case="isExceptionCase"
                       @manage-users="manageUsers">
                </tiers>
            </td>
        </tr>
    </div>
</template>
<script>
    import Tiers from '../components/Tiers.vue'
    import TextButton from '../components/TextButton.vue'
    import ExpiryStatus from '../components/ExpiryStatus.vue'

    export default {
        components: {
            Tiers,
            TextButton,
            ExpiryStatus
        },
        props: {
            subscription: {
                type: Object,
                default: () => {}
            },
            isExpanded: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            isExceptionCase() {
                const tiers = Object.values(this.subscription.tiers)
                if (tiers.length > 1) {
                    return tiers.some(tier => tier.usageEndDateFormatted !== tiers[0].usageEndDateFormatted)
                }
                return false
            }
        },
        methods: {
            expandOrCollapse () {
                this.$emit('expand-or-collapse', this.subscription.id, !this.isExpanded)
            },
            manageUsers(tier) {
                this.$router.push({
                    name: 'users',
                    params: {
                        organizationId: this.subscription.organizationId,
                        subscriptionId: this.subscription.id,
                        tierId: tier.id
                    },
                    query: this.$route.query
                })
            },
            onSubscriptionDetailsOpened () {
                this.$emit('open-subscription-details')
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

.v-data-table {
    .v-data-table__wrapper {
        tr {
            background: $pallete-light-gray;
            width: 100%;
            &.expand {
                background: $pallete-white;
            }
        }
    }
}

.org-name {
    overflow: hidden;
    text-overflow: ellipsis;
}

.expand-button-wrapper {
    text-align: end;
    padding-left: 0 !important;
    .expand-button {
        i {
            color: $theme-primary !important;
            font-weight: bold;
        }
    }
}

</style>
<style lang="scss">

.tbody-no-content .v-data-table__wrapper > * > tbody {
    height: 0 !important;
}

</style>
