<template>
    <v-dialog v-model="openDialog" width="590px" persistent>
        <v-card id="orgUpdateForm" class="pa-0">
            <v-card-title class="text-h6 font-weight-bold text--primary dialog-component">{{ $t('management.orgUpdateDialog.title') }}</v-card-title>
            <v-card-text class="text--secondary dialog-component">{{ $t('management.orgUpdateDialog.description') }}</v-card-text>
            <v-card-text>
                <v-card-text>
                    <div class="py-0">
                        <orgForm :openForm="orgFormProp"></orgForm>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="py-0 px-3">
                        <v-btn id="updateOrgInfo"
                               block primary light
                               class="white--text"
                               :disabled="sentRequest || !isUpdatedOrgValid"
                               @click="triggerRequest()">
                            {{ $t('buttons.updateOrg') }}</v-btn>
                        <v-btn id="closeUpdateOrgDialog"
                               block text color="primary"
                               class="v-btn-no-background"
                               @click="resetAndCloseDialog()">
                            {{ $t('buttons.cancel') }}</v-btn>
                    </div>
                </v-card-text>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapState} from 'vuex'
    import {bus} from '../../event-bus'
    import orgForm from '../dialogs/Activation/CreateOrganizationForm'
    import {entitlement} from '../../utils/validate-utils'
    import ErrorHandler from '../../utils/error-handler'

    export default {
        name: 'OrgUpdateDialog',
        components: {orgForm},
        data () {
            return {
                openDialog: false,
                originalOrg: {},
                sentRequest: false
            }
        },
        watch: {
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            isUpdatedOrgValid () {
                return this.isOrgUpdated() &&
                    entitlement.validateCreatedOrganization(this.activation.userAccount)
            },
            orgFormProp () {
                return {
                    isFormOpen: this.openDialog,
                    source: 'updateOrg'
                }
            }
        },
        created () {
            bus.$on('openOrgUpdateDialog', (org) => {
                try {
                    this.$store.commit('setOrgInformation', org)
                    this.setLocalOrg(org)
                    this.openDialog = true
                } catch (err) {
                    this.handleError(err)
                }
            })
        },
        methods: {
            resetAndCloseDialog () {
                this.sentRequest = false
                this.openDialog = false
                this.setLocalOrg({})
            },
            setLocalOrg (orgToUpdate) {
                this.originalOrg.id = orgToUpdate.id ? orgToUpdate.id : ''
                this.originalOrg.name = orgToUpdate.name ? orgToUpdate.name : ''
                this.originalOrg.address = orgToUpdate.address ? orgToUpdate.address : ''
                this.originalOrg.city = orgToUpdate.city ? orgToUpdate.city : ''
                this.originalOrg.country = orgToUpdate.country ? orgToUpdate.country : ''
                this.originalOrg.state = orgToUpdate.state ? orgToUpdate.state : ''
                this.originalOrg.zipPostal = orgToUpdate.zipPostal ? orgToUpdate.zipPostal : ''
                this.originalOrg.industry = orgToUpdate.industry ? orgToUpdate.industry : ''
                this.originalOrg.phone = orgToUpdate.phone ? orgToUpdate.phone : ''
                this.originalOrg.region = orgToUpdate.region ? orgToUpdate.region : ''
                this.originalOrg.dataLocation = orgToUpdate.dataLocation ? orgToUpdate.dataLocation : ''
            },
            triggerRequest () {
                this.sentRequest = true
                try {
                    this.$store.dispatch('UpdateOrgInfo', this.createRequestBody())
                        .catch(err => {
                            this.handleError(err)
                        })
                    this.resetAndCloseDialog()
                } catch (err) {
                    this.handleError(err)
                }
            },
            isOrgUpdated () {
                return this.isElementUpdated('name') || this.isElementUpdated('address', 'address1') ||
                    this.isElementUpdated('city') || this.isElementUpdated('state') ||
                    this.isElementUpdated('country') || this.isElementUpdated('zipPostal') ||
                    this.isElementUpdated('phone') || this.isElementUpdated('industry') ||
                    this.isElementUpdated('region') || this.isElementUpdated('dataLocation')
            },
            isElementUpdated (orgKey, userAccountKey) {
                return userAccountKey ? this.originalOrg[orgKey] !== this.activation.userAccount[userAccountKey]
                    : this.originalOrg[orgKey] !== this.activation.userAccount[orgKey]
            },
            createRequestBody () {
                let toBeUpdatedOrg = {}

                toBeUpdatedOrg.id = this.activation.userAccount.id

                if (this.originalOrg.name !== this.activation.userAccount.name) {
                    toBeUpdatedOrg.name = this.activation.userAccount.name
                }
                if (this.originalOrg.address !== this.activation.userAccount.address1) {
                    toBeUpdatedOrg.address1 = this.activation.userAccount.address1
                }
                if (this.originalOrg.city !== this.activation.userAccount.city) {
                    toBeUpdatedOrg.city = this.activation.userAccount.city
                }
                if (this.originalOrg.state !== this.activation.userAccount.state) {
                    toBeUpdatedOrg.state = this.activation.userAccount.state
                }
                if (this.originalOrg.country !== this.activation.userAccount.country) {
                    toBeUpdatedOrg.country = this.activation.userAccount.country
                }
                if (this.originalOrg.zipPostal !== this.activation.userAccount.zipPostal) {
                    toBeUpdatedOrg.zipPostal = this.activation.userAccount.zipPostal
                }
                if (this.originalOrg.phone !== this.activation.userAccount.phone) {
                    toBeUpdatedOrg.phone = this.activation.userAccount.phone
                }
                if (this.originalOrg.industry !== this.activation.userAccount.industry) {
                    toBeUpdatedOrg.industry = this.activation.userAccount.industry
                }
                if (this.originalOrg.region !== this.activation.userAccount.region) {
                    toBeUpdatedOrg.region = this.activation.userAccount.region
                }
                if (this.originalOrg.dataLocation !== this.activation.userAccount.dataLocation) {
                    toBeUpdatedOrg.dataLocation = this.activation.userAccount.dataLocation
                }

                return toBeUpdatedOrg
            },
            handleError (err) {
                ErrorHandler.handleError(err, ErrorHandler.orgUpdateError)
                this.resetAndCloseDialog()
            }
        }
    }
</script>

<style scoped lang="scss">

.v-dialog > .v-card > {
    .dialog-component:first-child {
        padding-top: 48px;
    }

    .dialog-component {
        padding-left: 48px;
        padding-right: 48px;
    }
}

</style>
