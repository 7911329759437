<template>
  <div />
</template>

<script>
    import {idAuth} from '../services/identity-auth'
    import BodyUtil from '../utils/body-visibility-util'
    import router from '../routes'
    import ErrorHandler from '../utils/error-handler'

    export default {
        mounted () {
            if (this.$route.query.code && !this.$store.getters.accessToken) {
                let state = { claimCode: this.$route.query.code }
                BodyUtil.hideBody()
                this.$store.dispatch('ValidateClaimCode', {claimCode: state.claimCode})
                .then(() => {
                    idAuth.login(state)
                }).catch(error => {
                    BodyUtil.showBody()
                    ErrorHandler.handleError(error, ErrorHandler.claimValidationError)
                })
            } else if (this.$route.query.code) {
                BodyUtil.hideBody()
                idAuth.validateClaimCode(this.$route.query.code)
                router.push('/products')
            } else {
                // Redirect to products immediately, set timeout ensures the browser will add this to the history stack
                setTimeout(() => this.$router.push('/products'), 1)
            }
        }
    }
</script>
