<template>
    <div class="activation-main-container">
        <v-icon v-if="showExitButton" class="exit-button" large v-on:click="close">clear</v-icon>
        <div class="activation-container-content">
            <v-layout align-center column fill-height justify-start>
                <v-flex class="flex-container">
                    <assign-entitlement v-if="step===1" :step-id="1"></assign-entitlement>
                    <div v-else-if="adminsLoading" class="spinner fill-height d-flex align-center">
                        <v-progress-circular indeterminate size="40" color="#159bd9"></v-progress-circular>
                    </div>
                    <div v-else-if="showHybridView" class="flex-container">
                        <add-admin-information-hybrid v-if="newOrgCreated && step===2 && (nonEmptyAdminList || fetchAdminsError)"
                            :fetch-admins-error="fetchAdminsError">
                        </add-admin-information-hybrid>
                        <claim-information-hybrid v-else></claim-information-hybrid>
                    </div>
                    <v-flex v-else-if="step===2" class="flex-container">
                        <add-admin-information v-if="newOrgCreated"></add-admin-information>
                        <claim-information v-else></claim-information>
                    </v-flex>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import AssignEntitlement from './AssignEntitlement.vue'
    import ClaimInformation from './ClaimInformation.vue'
    import ClaimInformationHybrid from './ClaimInformationHybrid.vue'
    import AddAdminInformation from './AddAdminInformation.vue'
    import AddAdminInformationHybrid from './AddAdminInformationHybrid.vue'
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import StrUtils from 'smarttech-identity-string-utils'
    import { DEBUG_NON_STOCKING_CLAIM_FLOW, HYBRID_VIEW } from '../../../enums/feature-switch-constants'
    import { isEnabled } from '../../../services/feature-switch'

    export default {
        name: 'ActivationCode',
        data () {
            return {
                adminsLoading: false,
                fetchAdminsError: false
            }
        },
        props: {
            stepId: {
                type: Number,
                default: 1
            }
        },
        components: {
            AssignEntitlement,
            ClaimInformation,
            ClaimInformationHybrid,
            AddAdminInformation,
            AddAdminInformationHybrid
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            ...mapGetters([
                'getAdminsOfAllOrgs',
                'isActivationAdminListLoaded'
            ]),
            step: {
                get () {
                    return this.activation.processSteps
                },
                set (value) {
                    this.setActivationProcessSteps(value)
                }
            },
            newOrgCreated () {
                return StrUtils.isBlank(this.activation.selectedOrganizationId) && StrUtils.isNotBlank(this.activation.userAccount.name)
            },
            showHybridView() {
                return isEnabled(HYBRID_VIEW)
            },
            showExitButton() {
                return this.step === 1 || !this.showHybridView
            },
            nonEmptyAdminList() {
                return this.getAdminsOfAllOrgs?.length > 0
            }
        },
        methods: {
            ...mapMutations([
                'cleanActivationMemory',
                'setActivationProcessSteps',
                'setActivationRedeemCode',
                'setActivationRedeemCodeValidated',
                'setActivationClaimUpdated',
                'setProductList'
            ]),
            ...mapActions([
                'FetchAdminsForUser'
            ]),
            initializeAdminSelector () {
                if (!this.isActivationAdminListLoaded) {
                    this.adminsLoading = true
                    this.$store.dispatch('FetchAdminsForUser').catch(() => {
                        this.fetchAdminsError = true
                    }).finally(() => {
                        this.adminsLoading = false
                    })
                }
            },
            close () {
                this.cleanActivationMemory()
            }
        },
        mounted() {
            // Use fake activation code data when debugging claim flows
            if (isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
                const activationList = [
                    {Name: 'Fake Subscription 1', Quantity: 10},
                    {Name: 'Fake Subscription 2', Quantity: 10},
                    {Name: 'Fake Subscription 3', Quantity: 10},
                    {Name: 'Fake Subscription 4', Quantity: 10},
                    {Name: 'Fake Subscription 5', Quantity: 10},
                    {Name: 'Fake Subscription 6', Quantity: 10}
                ]
                this.setActivationRedeemCode('fakeActivationCode1')
                this.setActivationRedeemCodeValidated(true)
                this.setActivationClaimUpdated(false)
                this.setProductList(activationList)
            }
        },
        watch: {
            step(newVal) {
                if (newVal === 2 && this.newOrgCreated && this.showHybridView) {
                    this.initializeAdminSelector()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../theming/main.scss';
    .activation-container-content {
        padding: 50px 0;
        height: 100%;
        min-width: 500px;
        border: 1px solid $theme-primary;
        background-color: $pallete-white;
        .flex-container {
            flex-direction: column;
            display: flex;
            height: 100%;
            flex: 1;
        }
    }
</style>
