<template>
    <div class="extend-offer">
        <v-layout column fill-height>
            <v-flex class="d-flex flex-column overflow-y-auto top-container">
                <div class="text-h6">{{$t('activation.resellerInformation.title')}}</div>
                <div class="description">{{$t('activation.resellerInformation.subTitle')}}</div>
                <div class="input-box">
                    <label for="resellerInput">{{$t('activation.resellerInformation.vendorName')}}</label>
                    <v-text-field class="medium"
                                flat
                                solo
                                :placeholder="$t('activation.resellerInformation.inputNamePlaceholder')"
                                :rules="rules"
                                autofocus
                                id="resellerInput"
                                maxlength="75"
                                v-model="resellerName"></v-text-field>
                </div>
                <div class="input-box">
                    <label for="resellerCityInput">{{$t('activation.resellerInformation.vendorCity')}}</label>
                    <v-text-field class="medium"
                                flat
                                solo
                                :placeholder="$t('activation.resellerInformation.inputCityPlaceholder')"
                                :rules="rules"
                                id="resellerCityInput"
                                maxlength="75"
                                v-model="resellerCity"></v-text-field>
                </div>
            </v-flex>
            <v-flex class="button-container">
                <v-btn :disabled="!validate" class="primary white--text activation-button large btn--block"
                    v-on:click="next">{{ buttonLabel }}
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {reseller, security} from '../../../utils/validate-utils.js'
    import { isEnabled } from '../../../services/feature-switch'
    import { HYBRID_VIEW } from '../../../enums/feature-switch-constants'

    export default {
        name: 'ExtendOffer',
        props: ['stepId'],
        data () {
            return {
                rules: [
                    v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters')
                ]
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            resellerName: {
                get () {
                    return this.activation.reseller.name
                },
                set (value) {
                    this.$store.commit('setResellerName', value)
                }
            },
            resellerCity: {
                get () {
                    return this.activation.reseller.city
                },
                set (value) {
                    this.$store.commit('setResellerCity', value)
                }
            },
            validate () {
                return reseller.validateReseller(this.activation.reseller)
            },
            buttonLabel () {
                if (isEnabled(HYBRID_VIEW)) {
                    return this.$t('buttons.continue')
                }
                return this.$t('buttons.next')
            }
        },
        methods: {
            next () {
                this.$store.commit('setActivationProcessSteps', this.stepId + 1)
            }
        }
    }
</script>

<style lang="scss" scoped>

    .extend-offer {
        height: 100%;
        width: 605px;
        .top-container {
            padding: 0 15px;
        }
    }

    .description {
        padding: 15px 0px 25px 0px;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: rgba(0, 1, 2, 0.7);
    }

    .button-container {
        padding: 10px 15px 0 15px;
        flex: 0 0 auto;
    }

</style>
