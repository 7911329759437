<template>
  <tr class="banner-row d-flex align-center text-center">
      <v-layout justify-center align-center>
        <div class="d-flex align-center">
            <caution-icon></caution-icon>
            <div class="banner-text d-flex align-center">
                <span @click="bannerClicked" v-html="$t('users.districtLibrary.noLibraryAdmin.warningBannerText')"></span>
            </div>
        </div>
    </v-layout>
  </tr>
</template>

<script>
import CautionIcon from './CautionIcon.vue'
export default {
    name: 'NoLibraryAdminBanner',
    components: {
        CautionIcon
    },
    mounted() {
        this.$emit('banner-rendered')
    },
    methods: {
        bannerClicked(event) {
            if (event.target.tagName === 'A') {
                window.open(this.$LINKS.ORG_LIBRARIES_SUPPORT, '_blank')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../theming/main.scss";

.banner-row {
    position: relative;
    min-height: 37px;
    border-bottom: 1px solid $pallete-offwhite;
    padding: 0px 15px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: $pallete-light-gray;
}

.banner-text {
    padding: 5px 0;
    font-size: 14px;
    line-height: 20px;
}
</style>
