const ADMIN_MANAGEMENT = 'ADMIN_MANAGEMENT'
const CLAIM_ENTITLEMENT = 'CLAIM_ENTITLEMENT'
const CLIENT_CONNECTION = 'CLIENT_CONNECTION'
const DISCONNECT_SUBSCRIPTION = 'DISCONNECT_SUBSCRIPTION'
const EXPERIENCES = 'EXPERIENCES'
const FEATURE_SWITCHES = 'FEATURE_SWITCHES'
const LEGACY_SUBSCRIPTIONS = 'LEGACY_SUBSCRIPTIONS'
const ORGANIZATIONS = 'ORGANIZATIONS'
const ORGANIZATIONS_RECENT = 'ORGANIZATIONS_RECENT'
const RELATED_ORGANIZATIONS = 'RELATED_ORGANIZATIONS'
const RELATED_LEGACY_SUBSCRIPTIONS = 'RELATED_LEGACY_SUBSCRIPTIONS'
const RELATED_SUBSCRIPTIONS = 'RELATED_SUBSCRIPTIONS'
const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
const STATUS = 'STATUS'
const SUBSCRIPTION = 'SUBSCRIPTION'
const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
const SUBSCRIPTIONS_POST = 'SUBSCRIPTIONS_POST'
const SYNC_TEACHERS = 'SYNC_TEACHERS'
const UPDATE_SF_COUNTER = 'UPDATE_SF_COUNTER'
const USER_SETTINGS = 'USER_SETTINGS'
const USERS = 'USERS'
const VALIDATE_ENTITLEMENT_CLAIM = 'VALIDATE_ENTITLEMENT_CLAIM'
const DISTRICT_LIBRARY = 'DISTRICT_LIBRARY'
const UPDATE_ALLOW_UPLOAD = 'UPDATE_ALLOW_UPLOAD'
const GENERATE_LICENSE_KEY = 'GENERATE_LICENSE_KEY'

export const ENDPOINTS = {
    ADMIN_MANAGEMENT,
    CLAIM_ENTITLEMENT,
    CLIENT_CONNECTION,
    DISCONNECT_SUBSCRIPTION,
    EXPERIENCES,
    FEATURE_SWITCHES,
    LEGACY_SUBSCRIPTIONS,
    ORGANIZATIONS,
    ORGANIZATIONS_RECENT,
    RELATED_LEGACY_SUBSCRIPTIONS,
    RELATED_ORGANIZATIONS,
    RELATED_SUBSCRIPTIONS,
    SIGN_IN_ERROR,
    STATUS,
    SUBSCRIPTION,
    SUBSCRIPTIONS,
    SYNC_TEACHERS,
    UPDATE_SF_COUNTER,
    USER_SETTINGS,
    USERS,
    VALIDATE_ENTITLEMENT_CLAIM,
    DISTRICT_LIBRARY,
    UPDATE_ALLOW_UPLOAD,
    GENERATE_LICENSE_KEY
}

export const PATHS = {
    [ADMIN_MANAGEMENT]: 'portalusers',
    [CLAIM_ENTITLEMENT]: 'entitlement/claim',
    [CLIENT_CONNECTION]: 'connect',
    [DISCONNECT_SUBSCRIPTION]: '{organizationId}/subscription/{subscriptionId}/users/disconnect',
    [EXPERIENCES]: 'experiences',
    [FEATURE_SWITCHES]: 'switches',
    [LEGACY_SUBSCRIPTIONS]: 'organizations/{organizationId}/smart-other-products-licenses',
    [ORGANIZATIONS]: 'organizations',
    [ORGANIZATIONS_RECENT]: 'organizations/recent',
    [SIGN_IN_ERROR]: 'error',
    [STATUS]: 'status',
    [SUBSCRIPTION]: '{organizationId}/subscription/{subscriptionId}',
    [SUBSCRIPTIONS]: 'organizations/{organizationId}/subscriptions',
    [SUBSCRIPTIONS_POST]: 'organizations/subscriptions',
    [SYNC_TEACHERS]: '{organizationId}/subscription/{subscriptionId}/users/sync',
    [UPDATE_SF_COUNTER]: '{organizationId}/subscription/{subscriptionId}/sfcounter/update',
    [USER_SETTINGS]: 'user-settings',
    [USERS]: '{organizationId}/subscription/{subscriptionId}/users',
    [VALIDATE_ENTITLEMENT_CLAIM]: 'entitlement/validate',
    [DISTRICT_LIBRARY]: 'dsl/organization/{orgId}',
    [UPDATE_ALLOW_UPLOAD]: 'dsl/{districtLibraryId}/organization/{extOrgId}',
    [GENERATE_LICENSE_KEY]: '{organizationId}/subscription/{subscriptionId}/setLicenseKeyRequested'
}

export const INTERNAL_SUBSCRIPTION_ID = 'internalSubscriptionId'
export const UNIT_ID = 'unitId'
