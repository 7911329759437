<template>
    <div :class="['non-migrated-subscription-table', { 'partial-height': !fullHeight, 'banner-visible': showBanner }]"
         :style="{'--banner-height': bannerHeight}">
        <div class="no-overflow-table-container subscriptions-new-table">
            <v-data-table
                id="nonMigratedSubscriptionsTable"
                :headers="headers"
                :items="subscriptions"
                selected-key="id"
                :loading="loading"
                :options.sync="options"
                :customSort="sortFunction"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.sortDesc"
                :class="['fixed-headers', {'tbody-no-content': !subscriptions || !subscriptions.length }]"
                hide-default-header
                hide-default-footer
                loader-height=3
                loading-text="">
                <template v-slot:header="{ props: { headers } }">
                    <table-header
                        class="table-header"
                        :headers="headers"
                        :sortOptions="sortOptions"
                        @change-sort="changeSort">
                    </table-header>
                    <non-migrated-banner v-if="showBanner" ref="banner" @banner-rendered="onBannerRendered"></non-migrated-banner>
                </template>
                <template v-slot:item="props">
                    <non-migrated-entry
                        :subscription="props.item"
                        :is-expanded="isExpandedSubscription(props.item.id)"
                        @manage-users="manageUsers(props.item)"
                        @expand-or-collapse="expandOrCollapse">
                    </non-migrated-entry>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
    import { SubscriptionStates } from '../enums/index'
    import * as SortProps from '../enums/sort-props'
    import { changeSortByOrder } from '../utils/pagination-utils'
    import comparitors from '../utils/comparitor-utils'
    import TableHeader from '../components/TableHeader.vue'
    import NonMigratedEntry from './NonMigratedEntry.vue'
    import NonMigratedBanner from './NonMigratedBanner.vue'

    export default {
        components: {
            NonMigratedEntry,
            NonMigratedBanner,
            TableHeader
        },
        data () {
            return {
                options: {
                    itemsPerPage: -1
                },
                sortOptions: {
                    sortBy: SortProps.NAME,
                    sortDesc: false
                },
                headers: [
                    {text: this.$t('subscriptions.product'), value: SortProps.NAME, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.organization'), value: SortProps.ORG, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.assignedEntitlements'), value: SortProps.USAGE, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.renewalDate'), value: SortProps.EXPIRY, align: 'left', sortable: true},
                    {text: '', align: 'right', sortable: false} // blank header column for expand button
                ],
                SubscriptionStates,
                bannerHeight: '0px'
            }
        },
        props: {
            subscriptions: {
                type: Array,
                default: () => []
            },
            loading: {
                type: Boolean,
                default: false
            },
            fullHeight: {
                type: Boolean,
                default: false
            },
            expandedSubscriptions: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            showBanner() {
                return this.subscriptions.some(subscription => !subscription.isLegacy)
            },
            bannerOffsetHeight() {
                return this.$refs.banner.$el.offsetHeight
            }
        },
        mounted() {
            window.addEventListener('resize', this.updateBannerHeight)
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.updateBannerHeight)
        },
        methods: {
            manageUsers (subscription) {
                this.$router.push({
                    name: 'users',
                    params: {
                        organizationId: subscription.organizationId,
                        subscriptionId: subscription.id
                    },
                    query: this.$route.query
                })
            },
            expandOrCollapse(subscriptionId, isExpanded) {
                this.$emit('expand-or-collapse', subscriptionId, isExpanded)
            },
            changeSort (column) {
                const allowNoSort = true
                changeSortByOrder(this.sortOptions, column, allowNoSort)
            },
            sortFunction (items, sortProps, descArray) {
                const index = sortProps[0]
                const desc = descArray[0]
                let descMultiplier = (desc ? -1 : 1)

                if (index) {
                    return items.sort(function (a, b) {
                        return comparitors[index](a, b, descMultiplier, SortProps.ORG)
                    })
                } else {
                    return items.sort(function (a, b) {
                        if (a.status === b.status) {
                            return 0
                        } else if (a.status === SubscriptionStates.EXPIRED) {
                            return 1
                        } else if (b.status === SubscriptionStates.EXPIRED) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                }
            },
            updateBannerHeight() {
                this.bannerHeight = this.showBanner ? `${this.bannerOffsetHeight}px` : '0px'
            },
            onBannerRendered() {
                this.updateBannerHeight()
            },
            isExpandedSubscription(id) {
                // If the subscription is not defined in the expandedSubscriptions object, set it as collapsed,
                // as it's the default behavior for non migrated subscriptions
                return this.expandedSubscriptions[id] ?? false
            }
        }
    }
</script>
<style lang="scss" scoped>
.v-data-table {
    .v-data-table__wrapper {
        tr {
            width: 100%;
        }
    }
}
</style>
<style lang="scss">

@import "../theming/fixed-headers-table.scss";

$container-offset: 200px;
$banner-height: var(--banner-height);

@mixin non-migrated-table-fixed-headers(
  $containerHeight,
  $heightOffset
) {
  @include table-fixed-headers(
    $containerHeight: $containerHeight,
    $heightOffset: $heightOffset,
    $containerWidth: 100vw,
    $widthOffset: 215px,
    $headerHeight: 48px,
    $numCols: 5,
    $colRatios: 1.4 1.3 1.2 0.8 0.3
  );
}

.tbody-no-content .v-data-table__wrapper > * > tbody {
    height: 0 !important;
}

.non-migrated-subscription-table {
    @include non-migrated-table-fixed-headers(calc(100vh - #{$container-offset}), $banner-height);
    &.partial-height {
        @include non-migrated-table-fixed-headers(calc((100vh - #{$container-offset}) / 2), $banner-height);
    }
}
</style>
