export default {
    getViewportSize () {
        let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

        return {
            width,
            height
        }
    }
}
