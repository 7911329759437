<template>
    <div>
        <v-layout align-center justify-start>
            <v-flex xs6 d-flex align-center style="min-height: 50px;">
                <slot name="title"></slot>
            </v-flex>
            <v-flex xs6>
                <v-select v-model="roleMapping[id].role"
                          class="selected-item role-dropdown"
                          :items="rolesList"
                          :disabled="!isValid || roleMapping[id].removeOnly"
                          item-text="name"
                          solo flat
                          :height=40
                          item-value="key">
                    <template v-slot:selection="{item}">
                        <span :class="['selected-role-text', {'gray-text-color': lighterTextColor}]">
                            {{ item.name }}
                        </span>
                        </template>
                </v-select>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: 'RoleSelector',
        props: ['id', 'isValid', 'roleMapping', 'lighterTextColor'],
        data () {
            return {
                rolesList: []
            }
        },
        created () {
            Object.keys(this.$t('management.adminRoles')).forEach(role => {
                this.rolesList.push({
                    name: this.$t('management.adminRoles')[role].name,
                    key: role
                })
            })
        }
    }
</script>

<style scoped lang="scss">
@import '../../theming/main.scss';

    .selected-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .role-dropdown {
        height: 50px;
        padding-top: 5px !important;

        .selected-role-text {
            font-size: 16px;
            font-weight: bold;
        }

        .gray-text-color {
            color: $pallete-dark-gray;
        }
    }
</style>
