export const fakeOrganizationsResponse = {
    json: [
        {
            role: {
                name: 'techInstructor',
                permissions: [
                    'manage_users'
                ]
            },
            previousRole: 'techInstructor',
            changedBy: 'Pedro new account',
            active: true,
            previousActive: true,
            userName: 'rbelmonte@smarttech.com',
            lastUpdated: '2023-09-13T12:35:19.095Z',
            address: 'Calle el pozo',
            city: 'Madrid',
            state: 'Madrid',
            country: 'ES',
            zipPostal: '28005',
            industry: 'Elementary School',
            phone: '',
            region: 'eu1',
            dataLocation: '',
            id: '2ac7839f-88ab-4ccd-b9fd-35fd455d7152',
            name: 'Raquel\u0027s organization',
            isDirect: true
        },
        {
            role: {
                name: 'techInstructor',
                permissions: [
                    'manage_users'
                ]
            },
            previousRole: 'techInstructor',
            changedBy: 'Pedro new account',
            active: true,
            previousActive: true,
            userName: 'rbelmonte@smarttech.com',
            lastUpdated: '2023-09-13T12:35:19.095Z',
            address: 'a',
            city: 'd',
            state: 'd',
            country: 'AE',
            zipPostal: 'd',
            industry: 'Elementary School',
            phone: '',
            region: 'default',
            dataLocation: '',
            id: '149d9cf3-6504-4213-8fbc-5c57a2499efb',
            name: 'Lulu testing 12',
            isDirect: true
        },
        {
            role: {
                name: 'admin',
                permissions: [
                    'manage_admins',
                    'manage_users',
                    'manage_orgs',
                    'claim_subscriptions'
                ]
            },
            previousRole: 'admin',
            changedBy: 'Pedro new account',
            active: true,
            previousActive: true,
            userName: 'rbelmonte@smarttech.com',
            lastUpdated: '2023-09-13T12:35:19.095Z',
            address: 'test adress 12',
            city: 'test city',
            state: 'Andorra',
            country: 'AD',
            zipPostal: '12345',
            industry: 'Junior High School',
            phone: '',
            region: 'eu1',
            dataLocation: '',
            id: 'f0a7fa89-8e6e-4ca9-b42e-f29972f9cb3e',
            name: 'Pedro\u0027s Organisation',
            isDirect: true
        },
        {
            role: {
                name: 'admin',
                permissions: [
                    'manage_admins',
                    'manage_users',
                    'manage_orgs',
                    'claim_subscriptions'
                ]
            },
            previousRole: 'admin',
            active: true,
            previousActive: true,
            userName: 'rbelmonte@smarttech.com',
            lastUpdated: '2023-09-13T12:35:19.095Z',
            address: '3120 S SR 100',
            city: 'Tiffin',
            state: 'OH',
            country: 'US',
            zipPostal: '44883',
            industry: 'Other Education',
            phone: '4194477852',
            region: 'default',
            dataLocation: '',
            id: '6ac0d7e1-0d30-4559-b86c-4f2ba4b822f1',
            name: 'Seneca County Youth Center',
            isDirect: true
        }
    ]
}

export const fakeSubscriptionsResponse = {
    json: [
        {
            id: '2ac7839f-88ab-4ccd-b9fd-35fd455d7152',
            name: 'Raquel\u0027s organization',
            msdSubscriptions: [
                {
                    id: 'fakeId',
                    msdSubscriptionNumber: 'FAKE-MSD-ID',
                    isSubscription: false,
                    tiers: {
                        BASE: {
                            id: 'fakeBaseTierId',
                            licenseKeyRequested: true,
                            name: 'NC-2AAAA-ANRYY-HT9CT-SWCAA - (ED-SW-1)',
                            serialNumber: 'NC-2AAAA-ANRYY-HT9CT-SWCAA',
                            orderNumber: '2600057125',
                            usageEndDate: '2024-02-29T00:00:00.000Z',
                            dateShipped: '2020-12-08T00:00:00.000Z',
                            assignedUserCount: 0,
                            quantity: 4,
                            partnerCompanyId: '34d8ee91-b1b3-4104-9db3-dd55d525ea03',
                            distributorId: '14bec631-c734-435d-926f-239ea66241a5',
                            distributorName: 'Schoolhouse Electronics LLC',
                            productId: '5f2d556c-bf81-4ddd-9b9a-bf9ad58da864',
                            productName: 'SMART Learning Suite, 1 year subscription',
                            reseller: 'Fake Reseller Name',
                            soldToCompanyName: 'Schoolhouse Electronics LLC'
                        },
                        SPARK: {
                            id: 'fakeSparkTierId',
                            licenseKeyRequested: true,
                            name: 'NC-2AAAA-ANRYY-HT9CT-SWCAA-T2 - (LUM-SPK-1)',
                            serialNumber: 'NC-2AAAA-ANRYY-HT9CT-SWCAA',
                            orderNumber: '2600057120',
                            usageEndDate: '2025-05-02T00:00:00.000Z',
                            dateShipped: '2021-08-12T00:00:00.000Z',
                            assignedUserCount: 2,
                            quantity: 1,
                            distributorId: '14bec631-c734-435d-926f-239ea66241a5',
                            distributorName: 'Schoolhouse Electronics LLC',
                            productId: 'bbc2d8c6-650d-4645-8244-6a81a12aa994',
                            productName: 'Lumio Spark, 1 year subscription',
                            soldToCompanyName: 'Schoolhouse Electronics LLC'
                        }
                    }
                }
            ],
            sfSubscriptions: []
        },
        {
            id: '6ac0d7e1-0d30-4559-b86c-4f2ba4b822f1',
            name: 'Seneca County Youth Center',
            msdSubscriptions: [
                {
                    id: '9d42ab8f-ef05-4b61-af70-428f4d4fd36c',
                    msdSubscriptionNumber: 'SENECA-FAKE-MSD-ID',
                    isSubscription: false,
                    tiers: {
                        BASE: {
                            id: '0c244313-8cf2-400e-bb43-76ab5ad35384',
                            licenseKeyRequested: true,
                            name: 'NC-2AAAA-ANRYY-HT9CT-SWCAA - (ED-SW-1)',
                            serialNumber: 'NC-2AAAA-ANRYY-HT9CT-SWCAA',
                            orderNumber: '2600057125',
                            usageEndDate: '2024-02-29T00:00:00.000Z',
                            dateShipped: '2020-12-08T00:00:00.000Z',
                            assignedUserCount: 12,
                            quantity: 15,
                            partnerCompanyId: '34d8ee91-b1b3-4104-9db3-dd55d525ea03',
                            distributorId: '14bec631-c734-435d-926f-239ea66241a5',
                            distributorName: 'Schoolhouse Electronics LLC',
                            productId: '5f2d556c-bf81-4ddd-9b9a-bf9ad58da864',
                            productName: 'SMART Learning Suite, 1 year subscription',
                            soldToCompanyName: 'Schoolhouse Electronics LLC'
                        },
                        SPARK: {
                            id: 'b4dd71c3-48c4-49a8-b78d-829c391458ec',
                            licenseKeyRequested: true,
                            name: 'NC-2AAAA-ANRYY-HT9CT-SWCAA-T2 - (LUM-SPK-1)',
                            serialNumber: 'NC-2AAAA-ANRYY-HT9CT-SWCAA',
                            orderNumber: '2600057125',
                            usageEndDate: '2024-02-29T00:00:00.000Z',
                            dateShipped: '2020-12-08T00:00:00.000Z',
                            assignedUserCount: 0,
                            quantity: 1,
                            distributorId: '14bec631-c734-435d-926f-239ea66241a5',
                            distributorName: 'Schoolhouse Electronics LLC',
                            productId: 'bbc2d8c6-650d-4645-8244-6a81a12aa994',
                            productName: 'Lumio Spark, 1 year subscription',
                            soldToCompanyName: 'Schoolhouse Electronics LLC'
                        }
                    }
                }
            ],
            sfSubscriptions: []
        },
        {
            id: 'f0a7fa89-8e6e-4ca9-b42e-f29972f9cb3e',
            name: 'Pedro\u0027s Organisation',
            msdSubscriptions: [
                {
                    id: 'fakeId2',
                    msdSubscriptionNumber: 'STANDARD-FAKE-MSD-ID',
                    isSubscription: false,
                    tiers: {
                        BASE: {
                            id: 'fakeBaseTierId2',
                            licenseKeyRequested: true,
                            name: 'NC-2AAAA-ANRYY-HT9CT-SWCAA - (ED-SW-1)',
                            serialNumber: 'NC-2AAAA-ANRYY-HT9CT-SWCAA',
                            orderNumber: '2600057125',
                            usageEndDate: '2022-05-14T00:00:00.000Z',
                            dateShipped: '2021-12-08T00:00:00.000Z',
                            assignedUserCount: 4,
                            quantity: 0,
                            partnerCompanyId: '34d8ee91-b1b3-4104-9db3-dd55d525ea03',
                            distributorId: '14bec631-c734-435d-926f-239ea66241a5',
                            distributorName: 'Schoolhouse Electronics LLC',
                            productId: '5f2d556c-bf81-4ddd-9b9a-bf9ad58da864',
                            productName: 'SMART Learning Suite, 1 year subscription',
                            soldToCompanyName: 'Schoolhouse Electronics LLC'
                        }
                    }
                }
            ],
            sfSubscriptions: []
        },
        {
            id: '149d9cf3-6504-4213-8fbc-5c57a2499efb',
            name: 'Lulu testing 12',
            msdSubscriptions: [],
            sfSubscriptions: [
                {
                    id: '3ae1c4fd-3354-4653-8699-2ccd90851390',
                    expiryDate: '2024-06-30T00:00:00.000Z',
                    assignedUsers: 55,
                    quantity: 1,
                    assets: [
                        {
                            assetId: '002a2dc8-6891-493c-9af6-b3dc8ecd5391',
                            name: 'NC-2ACAY-BEMJR-TATAA-SEAAA - (ED-SW-1-ZERO)',
                            orderNumber: '2600057070',
                            channelPO: 'V125585',
                            customerPO: '2701202201',
                            usageEndDate: '2024-06-30T00:00:00.000Z',
                            dateShipped: '2022-06-26T00:00:00.000Z',
                            assignedUserCount: 0,
                            quantity: 1,
                            soldToCompanyId: '0014U000028DawGQAS',
                            partnerCompanyId: 'b7668b8c-7a9b-4179-ae03-ff48fcec8ffe',
                            distributorId: 'b7668b8c-7a9b-4179-ae03-ff48fcec8ffe',
                            distributorName: 'Visual Sound Company',
                            productId: 'fbcceab1-4e42-43ad-9712-96162b0fe864',
                            productName: 'SMART Learning Suite, 1 year subscription',
                            endCustomerDetails: 'Lulu testing 12, a, d, d, d, AE'
                        }
                    ]
                }
            ]
        }
    ]
}

export const fakeLegacySubscriptionsResponse = {
    '149d9cf3-6504-4213-8fbc-5c57a2499efb': {
        json: [
            {
                assetName: 'Request added to queue',
                usageEndDate: '2025-06-30',
                id: '149d9cf3-6504-4213-8fbc-5c57a2499efb',
                accountId: '149d9cf3-6504-4213-8fbc-5c57a2499efb',
                accountName: 'lulu testing 1',
                productId: 'f74accd3-d6a5-4daa-97db-acddc9a8e7c1',
                productName: 'SMART Remote Management - 3 year subscription',
                seatCount: 0,
                quantity: 1,
                soldToCompanyId: '0014U000028DawGQAS',
                partnerCompanyId: '0b44729b-5705-48aa-9b85-27d44baf835f',
                distributorId: '0b44729b-5705-48aa-9b85-27d44baf835f',
                isDirect: false,
                assignedUsersCount: 0,
                channelPO: 'V125585',
                customerPO: '2701202201',
                startDate: '2022-06-27',
                orderNumber: '2600057070',
                company: 'Visual Sound Company',
                reseller: 'Visual Sound Company',
                distributor: 'Visual Sound Company',
                endCustomerDetails: 'lulu testing 1, a, d, d, d, AE'
            }
        ]
    },
    '2ac7839f-88ab-4ccd-b9fd-35fd455d7152': {
        json: [
            {
                serialNumber: '029866-452707-874820-903145',
                assetName: '029866-452707-874820-903145 - (SRM-3-ZERO)',
                usageEndDate: '2026-05-31',
                id: '2ac7839f-88ab-4ccd-b9fd-35fd455d7152',
                accountId: '2ac7839f-88ab-4ccd-b9fd-35fd455d7152',
                accountName: "Raquel's organization",
                productId: 'f74accd3-d6a5-4daa-97db-acddc9a8e7c1',
                productName: 'SMART Remote Management - 3 year subscription',
                seatCount: 0,
                quantity: 1,
                soldToCompanyId: '001U000000J3ei9IAB',
                distributorId: '3718f9a7-5878-4a53-80ab-d4cb6077a4eb',
                isDirect: false,
                assignedUsersCount: 0,
                startDate: '2022-02-13',
                orderNumber: '5600057894',
                company: 'AVI-SPL LLC',
                distributor: 'AVI-SPL LLC',
                endCustomerDetails: "Raquel's organization, Calle de Arganda, 30, 7ºB, Madrid, Madrid, 28005, ES"
            }
        ]
    },
    'f0a7fa89-8e6e-4ca9-b42e-f29972f9cb3e': {
        json: []
    },
    '6ac0d7e1-0d30-4559-b86c-4f2ba4b822f1': {
        json: []
    }
}

export const fakeUsersResponse = {
    json: [
        {
            id: '70458e2a1e4105d5ac2eb9186376094d',
            email: 'malkiatrandhawa@smartwizardschool.com',
            name: 'Malkiat.',
            idps: [
                'google'
            ]
        },
        {
            id: '95c8ad03-5e5b-400a-b16b-f4ced794904a',
            email: 'constantinevlahos.teacher@smartwizardschool.com',
            name: 'Cons test',
            idps: [
                'google'
            ]
        },
        {
            id: '2989fec90285eb6d903ac9975da07080',
            email: 'm.danielbeg.teacher@smartwizardschool.com',
            name: 'Daniel Beg Teacher',
            idps: [
                'google'
            ]
        },
        {
            id: '87bd09b0-71ee-4726-9f01-bbd44b9fcb39',
            email: 'malkiatrandhawa@smartwizardschool.com',
            name: 'Malkiat Randhawa (SF)',
            idps: [
                'softwareportal'
            ]
        },
        {
            id: 'a5f6826e-e8ca-4732-bc06-0abc8ca45557',
            email: 'mrstudent@smartwizardschool.com',
            name: 'MrStudent',
            idps: [
                'google'
            ]
        },
        {
            email: 'benyavitz.teacher@smartwizardschool.com',
            idps: []
        },
        {
            id: '64bb3ffb-c538-403b-af0a-43c54a671ef6',
            email: 'mrteacher@smartwizardschool.com',
            name: 'Mr Teacher',
            idps: [
                'google'
            ]
        }
    ]
}
// Note: here we're adding 2 responses in order to test the allowUpload value on and off
export const fakeDLMetadataResponse = {
    districtId1: {
        districtId: 'districtId1',
        title: 'Pedros District Library',
        description: 'Fake Pedros District Library Description',
        color: '#4074FF',
        uploadedBy: '8ba3bd21-551f-4564-bf98-43c81fc33bc6',
        allowUpload: false
    },
    districtId2: {
        districtId: 'districtId2',
        title: 'Lulus District Library',
        description: 'Fake Lulus District Library Description',
        color: '#4074FF',
        uploadedBy: '4a1edb09-eb1c-4049-a9fc-fa3ebe51a080',
        allowUpload: true
    }
}
