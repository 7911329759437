<template>
    <v-layout v-if="isNotloggedIn" align-center class="login-content" fill-height justify-center wrap>
        <v-flex class="login-picture" grow xs7>
            <!-- Photo by Stefan Stefancik on unsplash.com -->
            <img src="../assets/adminPortal-imgAsset-signInPg.jpg" class="admin-portal-login-pic" alt="SMART Sign-in"/>
            <a :href="$LINKS.IP_DOC" target="_blank" class="legal-footer">{{ $t('login.home.ip') }}</a>
        </v-flex>
        <v-flex class="login-action" xs5>
            <div class="login-action-wrapper">
                <v-layout class="login-action-container" align-center column justify-center>
                    <div class="header">{{ $t('login.welcome') }}</div>
                    <div class="center-align login-description">
                        {{ $t('login.home.description') }}
                    </div>
                    <div class="login-description text-blue learn-more">
                        <a :href="$LINKS.LEARN_MORE" target="_blank" class="login-description text-blue">{{ $t('login.home.learnMore') }}</a>
                    </div>
                    <v-btn class="primary white--text login-button" light width="290" height="44" v-on:click="signin">{{ $t('login.home.signIn') }}</v-btn>
                    <div>
                        <a v-on:click="signin" class="sign-up">{{ $t('login.home.signup') }}</a>
                    </div>
                </v-layout>
                <v-layout align-center class="login-action-footer" column justify-center>
                    <v-layout align-center fill-height justify-center wrap>
                        <span>{{$t('login.home.signingInto') + ': '}}</span>
                        <RegionSelectDialog :has-url-param="false"
                                            :id-auth="currentIdAuth"
                                            :environ="currentEnviron">
                        </RegionSelectDialog>
                        <span> | </span>
                        <a :href="$LINKS.LEARN_MORE" target="_blank" class="footer-action"> {{ $t('login.home.learnMore') }}</a>
                    </v-layout>
                    <v-layout align-center fill-height justify-center wrap>
                        <span>{{ $t('login.home.rightsReserved', {year: new Date().getFullYear()}) }}</span>
                        <a :href="$LINKS.TERMS_OF_USE" target="_blank" class="footer-action"> {{ $t('login.home.termsOfUse') }} </a>
                        <span> | </span>
                        <a :href="$LINKS.PRIVACY_DOC" target="_blank" class="footer-action">{{ $t('login.home.privacyCookies') }}</a>
                    </v-layout>
                </v-layout>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import ResizeMixin from '../mixins/resize-mixin'
    import {idAuth} from '../services/identity-auth'
    import environ from 'smarttech-identity-environment'

    export default {
        name: 'LoginPage',
        mixins: [ResizeMixin],
        data () {
            return {
                currentIdAuth: idAuth,
                currentEnviron: environ
            }
        },
        computed: {
            isNotloggedIn () {
                return !this.$store.getters.accessToken
            }
        },
        methods: {
            signin () {
                idAuth.login('', this.$route.query.idp_expid)
            }
        },
        beforeCreate () {
            let loginHint = this.$route.query.login_hint
            if (loginHint) {
                idAuth.login('', this.$route.query.idp_expid, loginHint)
            }
        }
    }
</script>

<style scoped>

    .login-action-wrapper {
        width: 100%;
        height: 100%;
        min-height: 350px;
    }

    .login-action-container {
        width: 100%;
        height: calc(100% - 50px);
    }

    .login-header > .header {
        top: 0px !important;
    }

    .header {
        font-size: 42px;
        padding-bottom: 28px;
    }

    .login-button {
        margin-bottom: 8px;
        font-size: 14px;
        flex: 0 0 auto;
    }

    .login-description {
        font-size: 18px;
        width: 500px;
        max-width: 95%;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 1, 2, 0.7);
    }

    .login-description.text-blue {
        color: #29a6db;
    }

    .login-description.text-blue:hover {
        text-decoration: underline;
    }

    .login-description.learn-more {
        padding-bottom: 68px;
    }

    .login-content {
        position: relative;
        background-color: #f3f3f3;
    }

    .admin-portal-login-pic {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .login-picture {
        height: 100%;
    }

    .legal-footer {
        bottom: 10px;
        left: 10px;
        cursor: pointer;
        position: absolute;
        color: white;
    }

    .login-action {
        height: 100%;
        position: relative;
        overflow-y: auto;
    }

    .login-action-footer {
        font-size: 10.1px;
        line-height: 1.24;
        color: #7a8b99;
    }

    .footer-action {
        padding: 0 5px;
        color: #7a8b99;
    }

    .sign-up {
        color: #29a6db;
        font-size: 14px;
    }

    .sign-up:hover {
        text-decoration: underline;
    }

    .clickable .v-icon {
        vertical-align: middle;
    }

    @media screen and (max-width: 1023px) {
        .login-picture {
            background-color: green;
            height: 30%;
            min-width: 100vw;
            width: 100vw;
        }

        .login-action {
            height: 70%;
            width: 100vw;
            min-width: 100vw;
            position: relative;
        }

        .login-description.learn-more {
            padding-bottom: 30px;
        }

        .legal-footer {
            color: #7a8b99;
            z-index: 1;
            font-size: 10px;
        }

        .login-button {
            font-size: 14px !important;
        }
    }
</style>
