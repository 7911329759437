import 'babel-polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import Components from 'smarttech-identity-vue-components'
import 'smarttech-identity-vue-components/dist/sharedVue.css'
import 'url-search-params-polyfill'
// local imports
import {store} from './store/store'
import router from './routes.js'
import TranslationService from './services/translation-service'
import requirejs from 'requirejs'
import {idAuth} from './services/identity-auth'
import {i18n} from './locales/'
import './theming/'
import environ, { DEV, UAT, LOCAL } from 'smarttech-identity-environment'
import Main from './routes/Main.vue'
import localization from 'smarttech-identity-localization'
import cookiesCheck from './utils/cookies'
import ErrorHandler from './utils/error-handler'
import DataValidator from 'smarttech-identity-data-validator'
import {ErrorCodes} from './enums'
import BodyUtil from './utils/body-visibility-util'
import prodRedirect from 'smarttech-identity-redirect'
import {isEnabled, loadFeatureSwitches} from './services/feature-switch.js'
import {SERVICE_UNAVAILABLE} from './enums/feature-switch-constants.js'
import regionMappingService from './services/region-mapping-service'
import {configPlugin} from './config.js'
import 'floating-vue/dist/style.css'
import { Dropdown } from 'floating-vue'

const ENVIRONMENT_OVERRIDE_KEY = 'environment-override'

DataValidator.setInternalErrorCode(ErrorCodes.INTERNAL_ERROR)

Vue.config.productionTip = false
prodRedirect.performRedirectIfRequired('adminportal')

Vue.use(configPlugin)
Vue.component('RegionSelectDialog', Components.RegionSelectDialog)

Vue.component('VDropdown', Dropdown)

let language = localization.getBrowserLanguageCode().toLowerCase()
// eslint-disable-next-line no-console
console.log(`Setting localization to ${language}.`)

i18n.locale = language

window.onbeforeunload = function (event) {
    // eslint-disable-next-line no-unused-expressions
    event
}

let idJsPath = '/static/js/signin'

// eslint-disable-next-line no-console
console.info('Debug signin.js ' + process.env.VUE_APP_ID_JS_DEBUG)

/*
if (process.env.VUE_APP_ID_JS_DEBUG) {
    idJsPath = '/static/js/dev/signin';
}
*/

router.beforeEach((to, from, next) => {
    store.dispatch('UpdatePageTitle', '')
    if (!store.getters.hasAccessToken && to.meta.requiresAuth) {
        next({
            path: '/login'
        })
    } else if (store.getters.hasAccessToken && to.path === '/login') {
        next({
            path: '/products'
        })
    } else if (!/error*/i.test(to.path)) {
        const params = new URLSearchParams(location.search)
        if (params.has('errorGroup')) {
            router.push('/error')
        } else {
            next()
        }
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    if (to.query && Object.keys(to.query).length) {
        return
    }
    if (from.query && from.query.feature) {
        router.replace({query: {feature: from.query.feature}})
    }
})

function setUpRouterHookAndInitializeVueInstance () {
    // Initial the Vue app
    const softwarePortal = new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(Main)
    }).$mount('#app')

    TranslationService.init(softwarePortal)
    regionMappingService.init()
}

function initializeIdClient () {
    let idUrl = environ.getIDServiceUrl()

    requirejs.config({
        baseUrl: '/',
        waitSeconds: 60,
        paths: {
            smartid: idUrl + idJsPath
        }
    })

    requirejs(['smartid'], function (smartId) {
        BodyUtil.hideBody()
        idAuth.initialize(smartId)
            .then(() => {
                setUpRouterHookAndInitializeVueInstance()
                BodyUtil.showBody() // show it as late as possible and there is another checking in LoginPage.vue
            }).catch((error) => {
                ErrorHandler.handleError(error, ErrorHandler.idLibraryError)
            })
    }, function (error) {
        ErrorHandler.handleError(error, ErrorHandler.idLibraryLoadError)
    })
}

function isValidLocalEnvOverride(env) {
    // preprod and prod are not valid environments for overriding as ID throws an "Invalid origin" error when pointing to them from local
    return [DEV, UAT, LOCAL].indexOf(env) > -1
}

function getBackendEnvVariable(urlOverride) {
    if (urlOverride === UAT) {
        return process.env.VUE_APP_BE_UAT_ENV
    } else if (urlOverride === LOCAL) {
        return ''
    }
    return process.env.VUE_APP_BE_DEV_ENV
}

function determineBackendEnvVariable() {
    const urlOverride = /env=([^&]*)/i.exec(window.location.search)
    const environmentOverride = window.sessionStorage.getItem(ENVIRONMENT_OVERRIDE_KEY)
    if (urlOverride && isValidLocalEnvOverride(urlOverride[1])) {
        // We cache ?env=ENV as 'environment-override' in sessionStorage because all query params are lost in the post-auth redirect to Admin Portal
        window.sessionStorage.setItem(ENVIRONMENT_OVERRIDE_KEY, urlOverride[1])
        return getBackendEnvVariable(urlOverride[1])
    } else if (environmentOverride && isValidLocalEnvOverride(environmentOverride)) {
        return getBackendEnvVariable(environmentOverride)
    }
    return process.env.VUE_APP_BE_DEV_ENV
}

async function initialize () {
    // In development mode we override the backend environment by passing in a parameter
    const env = process.env.VUE_APP_LOCAL_ENV ? determineBackendEnvVariable() : null

    await environ.determineEnvironment(env).catch(error => {
        ErrorHandler.handleError(error, ErrorHandler.environmentError)
    })
    await loadFeatureSwitches().catch(error => {
        ErrorHandler.handleError(error, ErrorHandler.featureSwitchError)
    })
    if (isEnabled(SERVICE_UNAVAILABLE)) {
        document.getElementById('service-unavailable').classList.remove('hidden')
    } else if (cookiesCheck.cookiesEnabled()) {
        initializeIdClient()
    } else {
        document.getElementById('no-cookies').classList.remove('hidden')
    }
}
initialize()
