<template>
    <div>
        <v-expansion-panels focusable>
            <v-expansion-panel>
                <div v-for="license in licensePages[pageNumber]" :key="license.id">
                    <v-layout v-if="!isOther" :key="license.id">
                        <v-flex xs6 class="serial">
                            <text-button
                                :text="license.serialNumber"
                                @button-action="onLicenseDetailsOpened(license)">
                            </text-button>
                        </v-flex>
                        <v-flex xs3 class="quantity">
                            {{ $tc('subscriptionDetails.entitlements', license.quantity) }}
                        </v-flex>
                        <v-flex xs3 class="expiry">
                            <expiry-status
                                :item-status="license.status"
                                :next-renewal-date-formatted="license.usageEndDateFormatted">
                            </expiry-status>
                        </v-flex>
                    </v-layout>
                    <v-layout v-else>
                        <v-flex xs6 class="serial">
                            <text-button
                                :text="license.serialNumber"
                                @button-action="onLicenseDetailsOpened(license)">
                            </text-button>
                        </v-flex>
                        <v-flex xs6>
                            <span class="legacyProductName">{{ license.productName }}</span>
                        </v-flex>
                    </v-layout>
                </div>
                <v-layout v-if="pageCount >= 2" class="pager">
                    <v-flex xs6 offset-xs3>
                        <v-pagination :length="thePageCount" v-model="page" :total-visible="6"></v-pagination>
                    </v-flex>
                </v-layout>
            </v-expansion-panel>
        </v-expansion-panels>
        <license-details v-if="openLicenseDetails"
                        :license-details="openLicense"
                        @close-dialog="onLicenseDetailClosed"
        ></license-details>
    </div>
</template>

<script>
    import LicenseDetails from '../components/LicenseDetails.vue'
    import ExpiryStatus from '../components/ExpiryStatus.vue'
    import TextButton from '../components/TextButton.vue'

    const RENEWABLE_LICENSE_PREFIXES = ['NC-', 'MC-', 'MS-', 'PE-', 'MP-', 'SR-', 'RV-', 'RH-']
    const LICENSE_KEY_SUFFIX_REGEX = /.*-AA[A-Z]$/
    const LICENSE_KEY_SUFFIX_PLACEHOLDER = '-AAA'

    export default {
        props:
        {
            licenses: {
                type: Array,
                default: () => []
            },
            isOther: {
                type: Boolean,
                required: true
            }
        },
        components: {
            LicenseDetails,
            ExpiryStatus,
            TextButton
        },
        data () {
            return {
                page: 1,
                pageCount: 0,
                licensePages: [],
                openLicenseDetails: false,
                openLicense: {}
            }
        },
        computed: {
            pageNumber () {
                return this.page - 1
            },
            thePageCount () {
                return this.pageCount
            }
        },
        methods: {
            onLicenseDetailsOpened (license) {
                this.openLicense = license
                this.openLicenseDetails = true
            },
            onLicenseDetailClosed () {
                this.openLicense = {}
                this.openLicenseDetails = false
            },
            formatNotebookLicenses (licenses) {
                licenses.forEach(license => {
                    if (license.serialNumber) {
                        let licenseUC = license.serialNumber.toUpperCase()
                        if (RENEWABLE_LICENSE_PREFIXES.some(prefix => licenseUC.startsWith(prefix)) &&
                            !licenseUC.match(LICENSE_KEY_SUFFIX_REGEX)) {
                            license.serialNumber = license.serialNumber + LICENSE_KEY_SUFFIX_PLACEHOLDER
                        }
                    }
                })
            }
        },
        watch: {
            licenses: {
                immediate: true,
                deep: true,
                handler(licenses) {
                    this.licensePages = []
                    let licenseCount = licenses.length
                    this.pageCount = Math.floor((licenseCount + this.$UI_CONFIG.MAX_LICENSE_COUNT - 1) / this.$UI_CONFIG.MAX_LICENSE_COUNT)
                    for (let x = 0, y = 0; y < this.pageCount; x += this.$UI_CONFIG.MAX_LICENSE_COUNT, y++) {
                        this.licensePages[y] = licenses.slice(x, this.$UI_CONFIG.MAX_LICENSE_COUNT + x)
                        this.formatNotebookLicenses(this.licensePages[y])
                    }
                }
            }
        }
    }
</script>
<style scoped lang="scss">
.serial {
    padding-left: 3vw;
}
.quantity {
    padding-left: 4.5vw;
}

.expiry {
    padding-left: 4vw;
}
</style>
