export const fakeValidateClaimResponse = {
    json: [
        {
            Name: 'Fake Subscription Name',
            Quantity: 5,
            id: 'fakeClaimId1'
        }
    ]
}

export const fakeAdminsForAllOrgsGetter =
    [
        {
            email: 'elopez@smarttech.com',
            firstName: 'Eleazar',
            lastName: 'López Fernández',
            registrationPending: false,
            organizations: {
                'fd483d14-c8a1-4fbb-9579-6d1038871d96': {
                    orgId: 'fd483d14-c8a1-4fbb-9579-6d1038871d96',
                    orgName: "Raquel's organization",
                    role: 'admin'
                },
                '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0': {
                    orgId: '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0',
                    orgName: "Pedro's Organisation",
                    role: 'admin'
                },
                '5bdf4566-6c8c-4944-a0ba-6166b74afe14': {
                    orgId: '5bdf4566-6c8c-4944-a0ba-6166b74afe14',
                    orgName: 'lulu testing 1',
                    role: 'admin'
                }
            }
        },
        {
            email: 'pschulze@smarttech.com',
            firstName: 'Pedro',
            lastName: 'new account',
            registrationPending: false,
            organizations: {
                'fd483d14-c8a1-4fbb-9579-6d1038871d96': {
                    orgId: 'fd483d14-c8a1-4fbb-9579-6d1038871d96',
                    orgName: "Raquel's organization",
                    role: 'admin'
                },
                '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0': {
                    orgId: '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0',
                    orgName: "Pedro's Organisation",
                    role: 'admin'
                },
                '5bdf4566-6c8c-4944-a0ba-6166b74afe14': {
                    orgId: '5bdf4566-6c8c-4944-a0ba-6166b74afe14',
                    orgName: 'lulu testing 1',
                    role: 'admin'
                }
            }
        },
        {
            email: 'rbelmonte@smarttech.com',
            firstName: 'Raquel',
            lastName: 'Belmonte',
            registrationPending: false,
            organizations: {
                '5bdf4566-6c8c-4944-a0ba-6166b74afe14': {
                    orgId: '5bdf4566-6c8c-4944-a0ba-6166b74afe14',
                    orgName: 'lulu testing 1',
                    role: 'admin'
                },
                'fd483d14-c8a1-4fbb-9579-6d1038871d96': {
                    orgId: 'fd483d14-c8a1-4fbb-9579-6d1038871d96',
                    orgName: "Raquel's organization",
                    role: 'admin'
                },
                '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0': {
                    orgId: '9d2034fc-e0a4-4b17-a6ad-a60e6ca5f3f0',
                    orgName: "Pedro's Organisation",
                    role: 'admin'
                }
            }
        }
    ]
