<template>
    <div :class="['migrated-subscription-table', { 'partial-height': !fullHeight }]">
        <div class="no-overflow-table-container subscriptions-new-table">
            <v-data-table
                id="migratedSubscriptionsTable"
                :headers="headers"
                :items="subscriptions"
                selected-key="id"
                :loading="loading"
                :options.sync="options"
                :customSort="sortFunction"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.sortDesc"
                :class="['fixed-headers', { 'tbody-no-content': !subscriptions || !subscriptions.length }]"
                hide-default-header
                hide-default-footer
                loader-height=3
                loading-text="">
                <template v-slot:header="{ props: { headers } }">
                    <table-header
                        class="table-header"
                        :headers="headers"
                        :sortOptions="sortOptions"
                        @change-sort="changeSort">
                    </table-header>
                </template>
                <template v-slot:item="props">
                    <migrated-entry
                        :subscription="props.item"
                        :is-expanded="isExpandedSubscription(props.item.id)"
                        @expand-or-collapse="expandOrCollapse"
                        @open-subscription-details="onSubscriptionDetailsOpened(props.item)">
                    </migrated-entry>
                </template>
            </v-data-table>
            <v-layout v-if="!subscriptions || !subscriptions.length" wrap justify-center align-center class="empty-stage">
                <v-flex xs4>
                    <div v-if="!loading" class="italic no-users-help-text">
                        <div v-if="!hasOrganizations">
                            <div class="header">{{$t('subscriptions.helpTextNoOrganizations.title')}}</div>
                            <div class="description no-org-description-padding" v-html="manageTabDescriptionText"></div>
                            <div class="description no-org-description-padding" v-html="getSoftwareDescriptionText"></div>
                        </div>
                        <p v-else>
                            {{ $t('subscriptions.noSubscriptionsMessage') }}
                        </p>
                    </div>
                </v-flex>
            </v-layout>
        </div>
        <subscription-details v-if="openSubscriptionDetails"
            :subscription="openSubscription"
            @close-dialog="onSubscriptionDetailClosed">
        </subscription-details>
    </div>
</template>
<script>
    import TableHeader from '../components/TableHeader.vue'
    import MigratedEntry from '../components/MigratedEntry.vue'
    import SubscriptionDetails from '../components/SubscriptionDetails.vue'
    import { SubscriptionStates } from '../enums/index'
    import * as SortProps from '../enums/sort-props'
    import { changeSortByOrder } from '../utils/pagination-utils'
    import comparitors from '../utils/comparitor-utils'

    export default {
        components: {
            TableHeader,
            MigratedEntry,
            SubscriptionDetails
        },
        data () {
            return {
                options: {
                    itemsPerPage: -1
                },
                sortOptions: {
                    sortBy: SortProps.NAME,
                    sortDesc: false
                },
                selected: [],
                headers: [
                    {text: this.$t('subscriptions.product'), value: SortProps.NAME, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.subscriptionID'), value: SortProps.SUBSCRIPTION_ID, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.organization'), value: SortProps.ORG, align: 'left', sortable: true},
                    {text: this.$t('subscriptions.renewalDate'), value: SortProps.EXPIRY, align: 'left', sortable: true},
                    {text: '', align: 'right', sortable: false} // blank header column for expand button
                ],
                SubscriptionStates,
                openSubscriptionDetails: false
            }
        },
        props: {
            subscriptions: {
                type: Array,
                default: () => []
            },
            hasOrganizations: {
                type: Boolean,
                default: false
            },
            fullHeight: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            expandedSubscriptions: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            manageTabDescriptionText () {
                const getYourSoftware = this.$t('subscriptions.menu.getYourSoftwareHybrid')
                return this.$t('subscriptions.helpTextNoOrganizations.getSoftwareDescriptionHybrid', { getYourSoftware })
            },
            getSoftwareDescriptionText () {
                const manage = this.$t('subscriptions.menu.manage')
                return this.$t('subscriptions.helpTextNoOrganizations.managerDescriptionHybrid', { manage })
            }
        },
        methods: {
            expandOrCollapse(subscriptionId, isExpanded) {
                this.$emit('expand-or-collapse', subscriptionId, isExpanded)
            },
            changeSort (column) {
                const allowNoSort = true
                changeSortByOrder(this.sortOptions, column, allowNoSort)
            },
            sortFunction (items, sortProps, descArray) {
                const index = sortProps[0]
                const desc = descArray[0]
                let descMultiplier = (desc ? -1 : 1)

                if (index) {
                    return items.sort(function (a, b) {
                        return comparitors[index](a, b, descMultiplier, SortProps.ORG)
                    })
                } else {
                    return items.sort(function (a, b) {
                        if (a.status === b.status) {
                            return 0
                        } else if (a.status === SubscriptionStates.EXPIRED) {
                            return 1
                        } else if (b.status === SubscriptionStates.EXPIRED) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                }
            },
            onSubscriptionDetailsOpened (subscription) {
                this.openSubscription = subscription
                this.openSubscriptionDetails = true
            },
            onSubscriptionDetailClosed () {
                this.openSubscription = {}
                this.openSubscriptionDetails = false
            },
            isExpandedSubscription(id) {
                // If the subscription is not defined in the expandedSubscriptions object, set it as expanded,
                // as it's the default behavior for migrated subscriptions
                return this.expandedSubscriptions[id] ?? true
            }
        }
    }
</script>
<style lang="scss" scoped>
.v-data-table {
    .v-data-table__wrapper {
        tr {
            width: 100%;
        }
    }
}

.empty-stage {
    margin-top: 80px;
}

.no-org-description-padding {
    padding-top: 20px;
}

</style>
<style lang="scss">

@import "../theming/fixed-headers-table.scss";

$container-offset: 200px;
$margin-bottom: 24px;

.tbody-no-content .v-data-table__wrapper > * > tbody {
    height: 0 !important;
}

@mixin migrated-table-fixed-headers(
  $containerHeight
) {
  @include table-fixed-headers(
    $containerHeight: $containerHeight,
    $heightOffset: 0px,
    $containerWidth: 100vw,
    $widthOffset: 215px,
    $headerHeight: 48px,
    $numCols: 5,
    $colRatios: 1.4 1.3 1.2 0.8 0.3
  );
}

.migrated-subscription-table {
    @include migrated-table-fixed-headers(calc(100vh - #{$container-offset}));

    &.partial-height {
        margin-bottom: $margin-bottom;
        @include migrated-table-fixed-headers(calc((100vh - #{$container-offset}) / 2 - #{$margin-bottom}));
    }
}

</style>
