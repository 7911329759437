<template>
    <div>
        <span v-if="itemStatus === SubscriptionStates.EXPIRED">
            {{ $t('statuses.expired') }} {{ nextRenewalDateFormatted }}
        </span>
        <span v-if="itemStatus === SubscriptionStates.WARNING">
            <i v-if="!hideWarningIcon" class="material-icons icon">warning</i> {{ nextRenewalDateFormatted }}
        </span>
        <span v-if="itemStatus === SubscriptionStates.ACTIVE">
            {{ nextRenewalDateFormatted }}
        </span>
        <span v-if="!itemStatus">––</span>
    </div>

</template>
<script>
    import { SubscriptionStates } from '../enums/index'

    export default {
        data () {
            return {
                SubscriptionStates
            }
        },
        props: {
            itemStatus: {
                type: String,
                default: ''
            },
            nextRenewalDateFormatted: {
                type: String,
                default: ''
            },
            hideWarningIcon: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
