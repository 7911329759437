<template>
    <div class="users-route" :class="{'spark-mode': isSparkSubscription}">
        <v-container fluid>
            <users-settings-modal
                v-if="showUsersSettingsModal"
                :subscriptionId="subscriptionId"
                :rosterSource="rosterSource"
                :provider="sourceName"
                @close-dialog="setOpenSettings(false)">
            </users-settings-modal>
            <users-edit-library-role-modal
                v-if="openEditLibraryRole"
                :selectedUsers="filteredSelectedUsers"
                :organizationId="organizationId"
                :subscriptionId="subscriptionId"
                :tierType="tierType"
                :openDialog="openEditLibraryRole"
                @close-edit-library-roles-modal="closeEditLibraryRoleModal">
            </users-edit-library-role-modal>
            <v-layout class="custom-menu" justify-space-between>
                <v-flex grow>
                    <div v-show="rosterSource && manageUsers">
                        <manage-auto-provision-modal
                                        :source="source"
                                        :sourceName="sourceName"
                                        :subscriptionId="subscriptionId"
                                        :organizationId="organizationId"></manage-auto-provision-modal>
                    </div>
                    <div v-show="showAddUsersButton">
                        <add-users-modal
                                        :open="openAddUsers"
                                        :disabled="selected.length > 0 || rosterLoading"
                                        :organizationId="organizationId"
                                        :subscriptionId="subscriptionId"
                                        :internalSubscriptionId="internalSubscriptionId"
                                        :tierType="tierType"
                                        :cancelToken="cancelToken"
                                        :active-provisions="totalUsersInSubscription"
                                        :onClose="onAddClosed"
                                        @open-initial-setup-modal="setOpenSettings(true)"></add-users-modal>
                    </div>
                    <div v-show="selected.length > 0 && !rosterSource && manageUsers">
                        <remove-users-modal
                                            :organizationId="organizationId"
                                            :subscriptionId="subscriptionId"
                                            :users="selected"
                                            :tierType="tierType"
                                            :allUsersSelected="selected.length === totalUsersInSubscription"
                                            :success="clearSelectedUsers"></remove-users-modal>
                    </div>
                </v-flex>
                <v-flex shrink class="mr-6">
                    <callout v-if="isSparkSubscription && manageUsers && !subscriptionExpired"
                            :popover-props="getCurrentStepProps">
                        <template v-slot:outerPopper>
                            <v-btn :disabled="editLibraryRoleButtonDisabled"
                                    outlined
                                    class="edit-library-role-button primary"
                                    @click="openEditLibraryRoleModal">
                            {{ $t('buttons.editLibraryRole') }}
                            </v-btn>
                        </template>
                    </callout>
                </v-flex>
                <v-flex class="custom-flex-max-width">
                    <div class="custom-text-container">
                        <input type="text"
                               class="custom-text-input"
                               v-model="search"
                               :placeholder="$t('users.searchText')"
                               @keypress="updatePageIndex"
                               @focus="searchInputFocused = true"
                               @blur="searchInputFocused = false"
                                :disabled="users.length === 0">
                        <v-icon :color="searchInputFocused ?  'blue' : ''" size="large">search</v-icon>
                    </div>
                </v-flex>
            </v-layout>
            <div class="no-overflow-table-container">
                <v-data-table
                    id="usersTable"
                    ref="usersTable"
                    v-model="selected"
                    :items="filteredUsers"
                    :headers="headers"
                    :options.sync="options"
                    :sort-by.sync="sortOptions.sortBy"
                    :sort-desc.sync="sortOptions.sortDesc"
                    :headers-length=5
                    :loading="loading"
                    :class="['fixed-headers', {'tbody-no-content': !loading && (!users || !users.length)}]"
                    hide-default-header
                    hide-default-footer
                    selected-key="id"
                    item-key="uid"
                    loading-text="">
                    <template v-slot:header="{ props: { headers } }">
                        <thead class="v-data-table-header">
                            <tr>
                                <th align="left">
                                    <span v-if="manageUsers" @click="selected.length === 0 && loading ? null : toggleAll()" >
                                        <v-icon
                                            v-if="selected.length === 0"
                                            :disabled="selected.length === 0 && loading">
                                            check_box_outline_blank
                                        </v-icon>
                                        <v-icon
                                            v-else-if="filteredSelectedUsers.length === filteredUsers.length && filteredUsers.length > 0"
                                            :disabled="selected.length === 0 && loading">
                                            check_box
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            :disabled="selected.length === 0 && loading">
                                            check
                                        </v-icon>
                                    </span>
                                </th>
                                <th align="left"
                                    v-for="header in headers"
                                    :key="header.text"
                                    :class="['column',
                                        sortOptions.sortDesc ? 'desc' : 'asc',
                                        header.value === sortOptions.sortBy ? 'active' : '',
                                        header.sortable ? 'enable-sort' : 'disable-sort'
                                    ]"
                                    :tabindex="header.sortable ? 0 : -1"
                                    @click="header.sortable ? changeSort(header.value) : null"
                                    >
                                    <div class="header-text">
                                        {{ header.text }}
                                    </div>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                </th>
                            </tr>
                            <no-library-admin-banner
                                v-if="showNoAdminForDistrictLibraryItems"
                                @banner-rendered="onNoLibraryAdminBannerRendered"
                                ref="noLibraryAdminBanner">
                            </no-library-admin-banner>
                        </thead>
                    </template>
                    <template v-slot:item="props">
                        <tr class="heading" v-if="isSparkSubscription">
                            <td>
                                <v-checkbox
                                    v-if="manageUsers"
                                    primary
                                    @change="changeSelection(props.item)"
                                    hide-details
                                    v-model="props.isSelected">
                                </v-checkbox>
                            </td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.name || '––'}}</td>
                            <td>{{getIdpsValue(props.item.idps)}}</td>
                            <td>{{getRoleLabel(props.item.dlHighestRole)}}</td>
                        </tr>
                        <tr class="heading" v-else>
                            <td>
                                <v-checkbox
                                    v-if="manageUsers"
                                    primary
                                    @change="changeSelection(props.item)"
                                    hide-details
                                    v-model="props.isSelected">
                                </v-checkbox>
                            </td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.id}}</td>
                            <td>{{getIdpsValue(props.item.idps)}}</td>
                        </tr>
                    </template>
                    <template v-slot:footer >
                        <div :colspan="headers.length" v-if="users.length > 0" class="footer position-relative">
                            <div class="loading-status">
                                <div>
                                    {{ $t('users.users')}}
                                    <span v-if="loading">{{ $t('users.loading') }} {{totalUsersFetched}} / {{totalUsersInSubscription}}</span>
                                    <span v-else>{{totalUsersInSubscription}}</span>
                                </div>
                                <div v-if="!showHybridView">
                                    {{$t('users.seats')}} <span>{{totalSeatsInSubscription}}</span>
                                </div>
                                <div v-else>
                                    {{$t('users.entitlements')}} <span>{{totalSeatsInSubscription}}</span>
                                </div>
                            </div>
                            <div class="text-center pt-2 pagination-position">
                                <v-pagination v-model="options.page" :length="pages" :total-visible="9" @input="scrollToTop()"/>
                            </div>
                        </div>
                    </template>
                    <template slot="no-data">
                        <div class="no-content" v-if="!loading && search">
                            {{ $t('users.noResultsFound') }} {{search}}
                        </div>
                    </template>
                </v-data-table>

                <v-layout v-if="!users || !users.length" :class="['wrap', 'justify-center', 'align-center', showHybridView ? 'fill-body-height' : 'pt-10']">
                    <v-flex xs4 v-if="subscriptionExpired">
                        <p class="text-center" v-html="$t('users.subscriptionExpiredText')"></p>
                    </v-flex>
                    <v-flex xs4 v-else>
                        <div v-if="!loading && !rosterSource && manageUsers" class="italic no-users-help-text">
                            <div v-if="showHybridView" class="text-center">
                                <p v-html="$t('users.helpTextLine1Hybrid')"></p>
                            </div>
                            <div v-else>
                                <p>{{ $t('users.helpTextLine1') }}</p>
                                <p>{{ $t('users.helpTextLine2') }}</p>
                            </div>
                            <p class="text-center">
                                <v-btn v-if="manageUsers"
                                       id="openAddUsers_helpArea"
                                       :disabled="rosterLoading"
                                       class="primary white--text"
                                       @click.native="openAddUsers = true">
                                    {{ $t('addUsersModal.addUsers') }}
                                </v-btn>
                            </p>
                            <div v-if="showHybridView" class="text-center">
                                <p>{{ $t('users.helpTextLine3Hybrid') }}</p>
                            </div>
                            <div v-else>
                                <p>{{ $t('users.helpTextLine3') }}</p>
                            </div>
                        </div>
                        <div v-if="!loading && !manageUsers" class="italic no-users-help-text">
                            <p>{{ $t('users.nonAdminHelpText1') }}</p>
                            <p>{{ $t('users.nonAdminHelpText2') }}</p>
                        </div>
                        <div v-show="!loading && rosterSource && manageUsers" class="italic no-users-help-text">
                            <template v-if="!isOnlyDsl">
                                <p>{{ $t('users.rosterHelpTextLine1') }}</p>
                                <p>{{ $t('users.rosterHelpTextLine2') }}</p>
                            </template>
                            <div v-else class="waiting-screen">
                                <p>{{ $t('users.rosterHelpDistrictTextLine1', {provider: sourceName}) }}</p>
                                <p>{{ $t('users.rosterHelpDistrictTextLine2') }}</p>
                                <p v-if="isSparkSubscription">{{ $t('users.rosterHelpDistrictTextLine3') }}</p>
                                <div class="logos">
                                    <img :src="usersImportSourceLogo.url" class="logo" :alt="usersImportSourceLogo.alt"/>
                                    <div class="d-flex justify-center">
                                        <i class="material-icons md-light arrow-downward">arrow_downward</i>
                                    </div>
                                    <img :src="icons.smart" class="logo" :alt="$t('users.smartLogo')"/>
                                </div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
        </v-container>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import AddUsersModal from '../components/SubscriptionUsers/AddUsersModal.vue'
    import RemoveUsersModal from '../components/SubscriptionUsers/RemoveUsersModal.vue'
    import UsersEditLibraryRoleModal from '../components/SubscriptionUsers/UsersEditLibraryRoleModal.vue'
    import UsersSettingsModal from '../components/SubscriptionUsers/UsersSettingsModal.vue'
    import LicenseRequestService from '../services/license-request-service'
    import SubscriptionUpdater from '../utils/subscription-updater'
    import { bus } from '../event-bus'
    import ResizeMixin from '../mixins/resize-mixin'
    import { ErrorCodes, Sources } from '../enums/'
    import ManageAutoProvisionModal from '../components/SubscriptionUsers/ManageAutoProvisionModal.vue'
    import NoLibraryAdminBanner from '../components/NoLibraryAdminBanner.vue'
    import { PERMISSIONS } from '../enums/admin-management-constants'
    import PermissionValidator from '../utils/permission-validator'
    import { changeSortByOrder, scrollToTopOfTable } from '../utils/pagination-utils'
    import { EXPIRED } from '../enums/subscription-states'
    import ErrorHandler from '../utils/error-handler'
    import { isEnabled } from '../services/feature-switch'
    import Callout from '../components/dialogs/Callout.vue'
    import { FUSE_API, HYBRID_VIEW, ONLY_DSL } from '../enums/feature-switch-constants'
    import { TIER_TYPES } from '../enums/subscription-constants'
    import smartLogo from '../assets/smart-logo.svg'
    import classlinkLogo from '../assets/classlink-logo.svg'
    import googleLogo from '../assets/google-logo.svg'
    import microsoftLogo from '../assets/microsoft-logo.svg'

    const USERS_PER_PAGE = 30
    export default {
        components: {
            ManageAutoProvisionModal,
            AddUsersModal,
            RemoveUsersModal,
            UsersSettingsModal,
            UsersEditLibraryRoleModal,
            NoLibraryAdminBanner,
            Callout
        },
        mixins: [ResizeMixin],
        data () {
            return {
                search: '',
                searchInputFocused: false,
                options: {
                    itemsPerPage: USERS_PER_PAGE,
                    page: 1
                },
                sortOptions: {
                    sortBy: 'email',
                    sortDesc: false
                },
                cancelToken: {},
                organizationId: this.$route.params.organizationId,
                subscriptionId: this.$route.params.subscriptionId,
                internalSubscriptionId: '',
                extTierId: this.$route.params.tierId,
                titleHasUpdated: false,
                loading: true,
                rosterLoading: true,
                openAddUsers: false,
                openSettings: false,
                openEditLibraryRole: false,
                selected: [],
                manageUsers: false,
                noLibraryAdminBannerHeight: 0,
                icons: {
                    smart: smartLogo,
                    classlink: classlinkLogo,
                    google: googleLogo,
                    microsoft: microsoftLogo
                }
            }
        },
        computed: {
            ...mapGetters([
                'users',
                'getOnboardingTotalSteps',
                'isMsdSubscription',
                'getSubscriptionById',
                'getTierById',
                'getManageUsersOnboardingStep',
                'getIsModalOpen',
                'getOnboardingTotalSteps',
                'getTierType'
            ]),
            isOnlyDsl() {
                return isEnabled(ONLY_DSL)
            },
            subscriptionOrTierData() {
                // While in non msd subscriptions users are assigned to the subscription, in msd subscriptions users are assigned to each tier.
                if (this.isMsdSubscription(this.subscriptionId)) {
                    return this.getTierById(this.subscriptionId, this.tierType)
                }
                return this.getSubscriptionById(this.subscriptionId)
            },
            onboarding() {
                return [
                    {
                        step: 1,
                        hide: this.shouldHideOnboarding,
                        placement: 'bottom',
                        title: this.$t('onboarding.users.step1.title'),
                        description: this.$t('onboarding.users.step1.description')
                    },
                    {
                        step: 2,
                        hide: this.shouldHideOnboarding,
                        placement: 'bottom',
                        title: this.$t('onboarding.users.step2.title'),
                        description: this.$t('onboarding.users.step2.description')
                    }
                ]
            },
            shouldHideOnboarding() {
                return this.loading || this.isRoasterWaitingScreenActive || !this.manageUsers
            },
            showAddUsersButton() {
                return this.selected.length === 0 && !this.rosterSource && this.manageUsers && this.subscriptionOrTierData && !this.subscriptionExpired
            },
            // Computed prop to avoid onboarding of being displayed in the waiting screen of classlink
            isRoasterWaitingScreenActive() {
                return (!this.users || !this.users.length) && !this.loading && this.rosterSource && this.manageUsers
            },
            usersImportSourceLogo() {
                if (this.source === Sources.SIS_SOURCES.ClassLink) {
                    return {url: this.icons.classlink, alt: this.$t('users.classlinkLogo')}
                } else if (this.source === Sources.SIS_SOURCES.Google) {
                    return {url: this.icons.google, alt: this.$t('users.googleLogo')}
                } else if (this.source === Sources.SIS_SOURCES.Microsoft_Groups || this.source === Sources.SIS_SOURCES.Microsoft_SDS) {
                    return {url: this.icons.microsoft, alt: this.$t('users.microsoftLogo')}
                } else {
                    return {url: this.icons.smart, alt: this.$t('users.smartLogo')}
                }
            },
            showUsersSettingsModal() {
                return this.manageUsers && this.openSettings
            },
            districtLibraryHasNoAdmin() {
                if (this.isSparkSubscription && this.totalUsersInSubscription > 0) {
                    return !this.$store.getters.organizationDistrictLibraryHasAdmin
                }
                return false
            },
            showNoAdminForDistrictLibraryItems() {
                return this.districtLibraryHasNoAdmin && !this.loading && !this.isRoasterWaitingScreenActive && this.manageUsers
            },
            tierType() {
                return this.extTierId ? this.getTierType(this.subscriptionId, this.extTierId) : TIER_TYPES.BASE
            },
            isSparkSubscription() {
                return this.tierType === TIER_TYPES.SPARK && this.isOnlyDsl
            },
            showHybridView() {
                return isEnabled(HYBRID_VIEW) || isEnabled(FUSE_API)
            },
            totalSeatsInSubscription () {
                return this.subscriptionOrTierData ? this.subscriptionOrTierData.quantity : 0
            },
            source() {
                return this.$store.getters.getSubscriptionSource(this.subscriptionId)
            },
            rosterSource () {
                let source = this.source
                if (source) {
                    return (source !== Sources.SIS_SOURCES.Undecided) && (source !== Sources.SIS_SOURCES.Manual)
                } else {
                    return false
                }
            },
            sourceName () {
                if (this.source) {
                    let sourceName = Object.keys(Sources.SIS_SOURCES).find(key => Sources.SIS_SOURCES[key] === this.source)
                    if (sourceName.includes('_')) {
                        sourceName = sourceName.replace('_', ' ')
                    }
                    return sourceName
                } else {
                    return null
                }
            },
            disableAddDialog () {
                return this.selected.length > 0
            },
            disableRemoveDialog () {
                return this.selected.length === 0
            },
            pages () {
                return Math.ceil(this.filteredUsers.length / this.options.itemsPerPage)
            },
            filteredSelectedUsers () {
                const usersFilteredAndSelected = this.selected.filter(user => {
                    return user.email.toLowerCase().includes(this.search.toLowerCase()) ||
                        user.name.toLowerCase().includes(this.search.toLowerCase())
                })
                return this.applyCurrentTableSort(usersFilteredAndSelected)
            },
            filteredUsers () {
                return this.users.filter(user => {
                    return user.email.toLowerCase().includes(this.search.toLowerCase()) ||
                    user.name.toLowerCase().includes(this.search.toLowerCase())
                })
            },
            totalUsersFetched () {
                return this.users.length
            },
            totalUsersInSubscription () {
                return this.subscriptionOrTierData ? this.subscriptionOrTierData.assignedUsersCount : 0
            },
            subscriptionExpired () {
                return this.subscriptionOrTierData ? this.subscriptionOrTierData.status === EXPIRED : false
            },
            headers() {
                if (this.isSparkSubscription) {
                    return [
                        {text: this.$t('users.email'), value: 'email', align: 'left', sortable: true},
                        {text: this.$t('users.displayName'), value: 'name', align: 'left', sortable: true},
                        {text: this.$t('users.idps'), value: 'idps', align: 'left', sortable: true},
                        {text: this.$t('users.libraryRole'), value: 'dlHighestRole', align: 'left', sortable: true}
                    ]
                }
                return [
                    {text: this.$t('users.email'), value: 'email', align: 'left', sortable: true},
                    {text: this.$t('users.displayName'), value: 'name', align: 'left', sortable: true},
                    {text: this.$t('users.accountId'), value: 'id', align: 'left', sortable: true},
                    {text: this.$t('users.idps'), value: 'idps', align: 'left', sortable: true}
                ]
            },
            editLibraryRoleButtonDisabled () {
                return !(this.selected.length > 0 && this.manageUsers)
            },
            getCurrentStepProps() {
                return this.onboarding.find(callout => callout.step === this.getManageUsersOnboardingStep)
            }
        },
        watch: {
            '$route' (to, from) {
                this.loading = true
                this.organizationId = this.$route.params.organizationId
                this.subscriptionId = this.$route.params.subscriptionId
                if (this.$store.getters.getLoadingStatus) {
                    this.cancelToken.cancel(true)
                } else {
                    this.$store.dispatch('ClearSubscriptionsUserList')
                }
                this.fetchSLSSubscription()
            },
            noLibraryAdminBannerHeight(newHeight) {
                document.documentElement.style.setProperty('--topBannerOffset', `${newHeight}px`)
            },
            shouldHideOnboarding: {
                handler: function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.$store.commit('setHideOnboarding', newVal)
                    }
                },
                immediate: true
            },
            openEditLibraryRole: {
                handler: function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.$store.commit('setIsModalOpen', newVal)
                    }
                },
                immediate: true
            }
        },
        created () {
            this.$store.dispatch('ClearSubscriptionsUserList', {
                organizationId: this.organizationId,
                subscriptionId: this.subscriptionId
            })
        },
        mounted () {
            this.$store.dispatch('WaitOnApplicationInitialization').then(() => {
                this.refreshPageTitle()
                this.fetchUserSettings()
                this.checkPermissionForOrg()
                bus.$on('doLicenseUpdate', this.doLicenseUpdate)
            }).catch(err => {
                ErrorHandler.handleError(err)
            })
        },
        beforeDestroy () {
            bus.$off('doLicenseUpdate', this.doLicenseUpdate)
            LicenseRequestService._cancelRequests()
            this.cancelToken.cancel(false)
            window.removeEventListener('resize', this.calculateNoLibraryAdminBannerHeight)
        },
        beforeRouteLeave (to, from, next) {
            if (this.showNoAdminForDistrictLibraryItems && !this.getIsModalOpen) {
                bus.$emit('openModal', {
                    title: this.$t('users.districtLibrary.noLibraryAdmin.warningModal.title'),
                    message: this.$t('users.districtLibrary.noLibraryAdmin.warningModal.description'),
                    primaryActionLabel: this.$t('buttons.continue'),
                    secondaryActionLabel: this.$t('buttons.cancel'),
                    primaryAction: () => {
                        next()
                    },
                    secondaryAction: () => {
                        next(false)
                    }
                })
            } else {
                next()
            }
        },
        methods: {
            setOpenSettings(value) {
                this.openSettings = value
            },
            checkPermissionForOrg () {
                this.manageUsers = PermissionValidator.validateUserPermission(this.organizationId, PERMISSIONS.ManageUsers)
            },
            scrollToTop () {
                scrollToTopOfTable('usersTable')
            },
            changeSort (column) {
                changeSortByOrder(this.sortOptions, column)
            },
            updatePageIndex () {
                this.options.page = 1
            },
            toggleAll () {
                if (this.selected.length) {
                    this.selected = []
                } else {
                    this.selected = this.filteredUsers
                }
            },
            refreshPageTitle () {
                let sub = this.$store.getters.getSubscriptionById(this.subscriptionId)

                if (!sub) {
                    // TODO: remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
                    const actionFetchName = isEnabled(FUSE_API) ? 'FetchSubscriptionsForOrganizationHybrid' : 'FetchSubscriptionsForOrganization'
                    this.$store.dispatch(actionFetchName,
                        { organizationId: this.organizationId, respListener: this.onLicenseUpdateReceived })
                        .then(result => {
                            if (!result && !isEnabled(FUSE_API)) {
                                let err = {
                                    traceId: '',
                                    errorCode: ErrorCodes.BAD_REQUEST_ERROR
                                }
                                ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                            }
                        }).catch(err => {
                            ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                        })
                } else {
                    this.$store.dispatch('UpdatePageTitle', sub.name + ', ' + sub.accountName)
                    this.fetchSLSSubscription()
                }
            },
            onLicenseUpdateReceived (updateInfo, isLoadingCompleted) {
                bus.$emit('doLicenseUpdate', { updateInfo, isLoadingCompleted })
            },
            doLicenseUpdate ({ updateInfo, isLoadingCompleted }) {
                if (updateInfo.error) {
                    LicenseRequestService._cancelRequests()
                    ErrorHandler.handleError(updateInfo.data, ErrorHandler.subscriptionError)
                } else {
                    SubscriptionUpdater.updateSubscription(updateInfo, this.$store)
                    if (!this.titleHasUpdated && isLoadingCompleted) {
                        let sub = this.$store.getters.getSubscriptionById(this.subscriptionId)
                        if (sub) {
                            this.$store.dispatch('UpdatePageTitle', sub.name + ', ' + sub.accountName)
                            this.titleHasUpdated = true
                        }
                    }

                    if (isLoadingCompleted) {
                        this.fetchSLSSubscription()
                    }
                }
            },
            fetchUsers () {
                this.loading = true
                this.$store.dispatch('FetchUsersForSubscription', {
                    organizationId: this.organizationId,
                    subscriptionId: this.subscriptionId,
                    tierType: this.tierType,
                    token: this.cancelToken
                }).then(() => {
                    this.loading = false
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                })
            },
            fetchUserSettings () {
                this.$store.dispatch('FetchUserSettings')
                    .catch(err => {
                        ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                    })
            },
            fetchSLSSubscription () {
                this.rosterLoading = true
                this.$store.dispatch('FetchSLSSubscription', {
                    subscriptionId: this.subscriptionId,
                    organizationId: this.organizationId,
                    tierType: this.tierType
                }).then(result => {
                    this.fetchUsers()
                    this.rosterLoading = false
                    this.internalSubscriptionId = result.subscriptionId
                }).catch(err => {
                    ErrorHandler.handleError(err, ErrorHandler.subscriptionError)
                })
            },
            clearSelectedUsers () {
                this.selected = []
                this.updatePageIndex()
            },
            onAddClosed () {
                this.openAddUsers = false
            },
            changeSelection (item) {
                const selectedItemIndex = this.selected.indexOf(item)
                if (selectedItemIndex > -1) {
                    this.selected.splice(selectedItemIndex, 1)
                } else {
                    this.selected.push(item)
                }
            },
            getRoleLabel (role) {
                return this.$tc('users.districtLibrary.dslRoles.' + role.toLowerCase() + '.name')
            },
            getIdpsValue (idps) {
                return idps.map(idp => !idp || idp.length === 0 ? this.$t('users.unknown') : idp).join(', ')
            },
            onNoLibraryAdminBannerRendered () {
                // initial calculation
                this.calculateNoLibraryAdminBannerHeight()
                // make sure we recalculate if the window is resized
                window.addEventListener('resize', this.calculateNoLibraryAdminBannerHeight)
            },
            calculateNoLibraryAdminBannerHeight () {
                if (this.$refs.noLibraryAdminBanner) {
                    this.noLibraryAdminBannerHeight = this.$refs.noLibraryAdminBanner.$el.offsetHeight
                }
            },
            onDismiss() {
                this.$store.dispatch('UpdateUserSettings', { manageUsersOnboardingStep: this.getOnboardingTotalSteps })
            },
            openEditLibraryRoleModal() {
                this.openEditLibraryRole = true
            },
            closeEditLibraryRoleModal(deselectUsers) {
                this.openEditLibraryRole = false
                if (deselectUsers) {
                    this.clearSelectedUsers()
                }
            },
            applyCurrentTableSort(items) {
                const sortBy = this.sortOptions.sortBy
                const sortDesc = this.sortOptions.sortDesc
                return items.sort((a, b) => {
                    const isSortingByColumnWithMultipleValues = Array.isArray(a[sortBy])
                    const valueA = isSortingByColumnWithMultipleValues ? a[sortBy].join(', ').toLowerCase() : a[sortBy].toLowerCase()
                    const valueB = isSortingByColumnWithMultipleValues ? b[sortBy].join(', ').toLowerCase() : b[sortBy].toLowerCase()
                    if (valueA === valueB) {
                        return 0
                    }
                    if (valueA === null || valueA === undefined) {
                        return 1
                    }
                    if (valueB === null || valueB === undefined) {
                        return -1
                    }
                    if (sortDesc) {
                        return valueA < valueB ? 1 : -1
                    } else {
                        return valueA > valueB ? 1 : -1
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../theming/main.scss";
    $header-height: 56px;

    .no-users-help-text {
        max-width: 400px;
        margin: auto;
    }
    .loading-status {
        font-size: 10pt;
        color: #555555;
        top: 0;
        left: 0;
        position: absolute;
        padding: 10px 20px;
        padding-left: 10px;
    }
    .pagination-position {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }
    .position-relative {
        position: relative;
    }
    .custom-menu {
        margin-bottom: 19px;
    }
    .custom-flex-max-width {
        max-width: 400px;
        margin-left: 2em;
    }
    .input-group--disabled {
        opacity: 0.3;
    }
    .custom-checkbox {
        cursor: pointer;
    }
    .no_content {
        text-align: left;
    }
    .alert-container {
        padding-left: 16px;
        padding-right:16px;
    }
    .custom-text-container {
        padding: 1px 6px 1px 16px;
        background-color: white;
        border: solid 2px $pallete-offwhite;
        border-radius: 2px;
    }
    .custom-text-input {
        width: calc(100% - 22px);
        outline: none;
    }
    .fill-body-height {
        overflow-y: auto;
        height: calc(100% - #{$header-height});
    }
    .edit-library-role-button {
        height: fit-content;
    }

</style>

<style lang="scss">
    @import "../theming/fixed-headers-table.scss";
    :root {
        --topBannerOffset: 0px;
    }
    .tbody-no-content .v-data-table__wrapper > * > tbody {
        height: 0 !important;
    }
    $table-fixed-header-settings: (
        containerHeight: 100vh,
        heightOffset: 235px,
        containerWidth: 100vw,
        widthOffset: 200px,
        headerHeight: 56px,
        numCols: 5,
        fixedWidthCols: 42 0 0 0 0
    );
    .users-route {
        @include table-fixed-headers(
            $colRatios: 0 2 2 3 3,
            $table-fixed-header-settings...
        );
        &.spark-mode {
            @include table-fixed-headers(
                $colRatios: 0 2 3 2 2,
                $additionalContentHeight: var(--topBannerOffset),
                $table-fixed-header-settings...
            );
        }
    }

    .waiting-screen {
        margin-top: 37px;
        p {
            &:last-of-type {
                margin-bottom: 29px;
            }
        }
    }

    .logos {
        display: flex;
        flex-direction: column;
        .arrow-downward {
            font-size:5rem;
            color:#d5d5d5;
        }
        .logo {
            margin: auto;
            &:first-of-type {
                margin-bottom: 30px;
            }
        }
    }
</style>
