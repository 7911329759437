<template>
    <div class="claim-activation-container">
        <div v-if="!isHybridViewEnabled" class="header" style="font-size: 28px">{{ $t('activation.claim.title') }}</div>
        <div v-else class="header" style="font-size: 28px">{{ $t('activation.claim.titleHybrid') }}</div>
        <div class="sub-header left-align">
            <span v-if="!isHybridViewEnabled">{{ $t('activation.claim.subTitle') }} </span>
            <span v-else>{{ $t('activation.claim.subTitleHybrid') }} </span>
            <a class="learn-more" :href="$LINKS.MANUALLY_CLAIMING_SOFTWARE" target="_blank">{{$t('activation.claim.learnMore')}}</a>
        </div>
        <div class="activation-content-container">
            <div class="box">
                <div class="input-box">
                    <div>{{ $t('activation.claim.default.description') }}</div>
                </div>
                <div>
                    <label>
                        <span class="required">* </span>
                        {{ $t('activation.claim.default.boardsPurchasedInputLabel') }}
                    </label>
                    <v-text-field v-model="purchasedBoardCount"
                                v-mask="'#####'"
                                flat
                                solo
                                maxlength="5" autofocus
                                :class="{'input--error text-field-hide-details': !validateNumberValue}"
                                required
                                :rules="requireRules"/>
                    <div class="boards-purchased-notification" v-if="validateMaxNumberValue">
                        <v-layout>
                            <v-flex xs1>
                                <v-checkbox v-model="checked" color="info" class="checkbox"></v-checkbox>
                            </v-flex>
                            <v-flex xs11>
                                <v-card-text v-if="checked" class="boards-purchased-notification-checked">
                                    {{ $t('activation.claim.default.boardBigNumberNotification') }}
                                </v-card-text>
                                <v-card-text v-else class="boards-purchased-notification-unchecked">
                                    {{ $t('activation.claim.default.boardBigNumberNotification') }}
                                </v-card-text>
                            </v-flex>
                        </v-layout>
                    </div>
                </div>
                <div v-if="validateNumberValue">
                    <label><span class="required">* </span>
                        {{ $t('activation.claim.default.serialNumberInputLabel') }}
                    </label>
                    <v-text-field v-model="serialNumber" flat solo maxlength="50" required :rules="requireRules"></v-text-field>
                </div>
                <div v-else style="height: 35px;"></div>

                <div class="button-container">
                    <v-btn :disabled="disableContinueButton"
                            :loading="loading"
                            class="activation-button primary white--text btn--block large"
                            v-on:click="next">
                        {{$t('buttons.continue')}}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { entitlement, security } from '../../../utils/validate-utils'
    import { ClaimErrorUtils, VALIDATE_SN } from '../../../utils/claim-error-util'
    import ErrorHandler from '../../../utils/error-handler'
    import { isEnabled } from '../../../services/feature-switch'
    import { HYBRID_VIEW } from '../../../enums/feature-switch-constants'

    export default {
        name: 'ClaimActivation',
        props: ['stepId'],
        data: function () {
            return {
                loading: false,
                MAX_BOARD_INPUT: 100,
                requireRules: [
                    v => security.validate(v) || this.$t('formErrors.XSSUnsupportedCharacters'),
                    v => !!v || this.$t('applicationErrors.required')
                ],
                boardsQuantityNotification: false,
                checked: false
            }
        },
        computed: {
            ...mapState([
                'activation'
            ]),
            claimDeferredCode: {
                get () {
                    return !this.$store.state.activation.claim.claimSerialNumber
                },
                set (value) {
                    if (!this.deferredDisabled) {
                        this.$store.commit('setClaimSerialNumber', !value)
                    }
                }
            },
            claimSerialNumber: {
                get () {
                    return this.$store.state.activation.claim.claimSerialNumber
                },
                set (value) {
                    if (!this.deferredDisabled) {
                        this.$store.commit('setClaimSerialNumber', value)
                    }
                }
            },
            serialNumber: {
                get () {
                    return this.$store.state.activation.claim.serialNumber
                },
                set (value) {
                    this.$store.commit('setSerialNumber', value.trim())
                }
            },
            purchasedBoardCount: {
                get () {
                    return this.$store.state.activation.claim.purchasedBoardCount
                },
                set (value) {
                    this.$store.commit('setSeatCount', value)
                }
            },
            deferredDisabled () {
                return this.activation.deferredActivationCode.length === 0
            },
            validateNumberValue () {
                return entitlement.validateNumber(this.purchasedBoardCount) || this.purchasedBoardCount.length === 0
            },
            validateMaxNumberValue () {
                return entitlement.validateIsMoreThanMaxNumber(this.purchasedBoardCount)
            },
            validateSerialNumber () {
                return entitlement.validateSerialNumber(this.serialNumber)
            },
            validateCheck () {
                return this.validateNumberValue && this.validateSerialNumber
            },
            disableContinueButton () {
                return !this.validateCheck || (this.validateMaxNumberValue && !this.checked) || this.loading
            },
            isHybridViewEnabled() {
                return isEnabled(HYBRID_VIEW)
            }
        },
        methods: {
            next () {
                if (this.validateCheck) {
                    return this.validateEntitlement()
                }
            },
            validateChar (value) {
                return security.sanitizeString(value)
            },
            validateEntitlement () {
                this.loading = true
                return this.$store.dispatch('ValidateCodeOrHardwareClaim')
                    .then(result => {
                        this.loading = false
                        this.$store.commit('setActivationProcessSteps', this.stepId + 1)
                    }).catch(err => {
                        err.message = this.$t(ClaimErrorUtils.getErrorMessage(err.message, VALIDATE_SN))
                        ErrorHandler.handleError(err, ErrorHandler.claimActivationError)
                        this.loading = false
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .claim-activation-container {
        width:100vw;
        max-width: 605px;
        .header {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .sub-header {
        font-size: 18px;
        padding: 24px 10px 20px 10px;
    }

    label {
        top: 20px !important;
    }

    .box {
        border-radius: 5px;
        box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.24);
        background-color: #ffffff;
        position: relative;
        padding: 20px;
        margin: 0 15px;
    }

    .checkbox {
        transform: scale(0.8);
    }

    .boards-purchased-notification {
        left: 100px;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .boards-purchased-notification-unchecked {
        color: red;
        padding: 5px;
        margin-top: 3px;
    }

    .boards-purchased-notification-checked {
        color: black;
        padding: 5px;
        margin-top: 3px;
    }

    .learn-more {
        text-decoration: none;
    }

    .learn-more :hover {
        text-decoration: underline;
    }
</style>
