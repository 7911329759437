<template>
    <subscriptions-new v-if="showHybridView" />
    <subscriptions v-else />
</template>

<script>
import Subscriptions from '../routes/Subscriptions.vue'
import SubscriptionsNew from '../routes/SubscriptionsNew.vue'
import { isEnabled } from '../services/feature-switch'
import { FUSE_API, HYBRID_VIEW } from '../enums/feature-switch-constants'

export default {
    components: {
        Subscriptions,
        SubscriptionsNew
    },
    computed: {
        showHybridView() {
            return isEnabled(HYBRID_VIEW) || isEnabled(FUSE_API)
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
