const claimCode = {code: ''}

export default {
    init () {
        let claimCodeElement = document.getElementById('claim_code')
        if (claimCodeElement != null) {
            claimCode.code = claimCodeElement.dataset.claimCode
            claimCodeElement.parentNode.removeChild(claimCodeElement)
            window.history.replaceState({}, document.title, window.location.toString().replace(/\?(activation_state|state)=[A-Za-z0-9-_]+/, ''))
        }
    },

    getClaimCode () {
        return claimCode.code
    }
}
